import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropDownDefaultOnlyOne } from '../../../utils/FormsComponents';
import moment from 'moment';

require('./headerchart.css');

class HeaderChart extends Component {
  constructor() {
    super();
    this.state = {
      completed: 50,
      filter: 'Último mês',
      opendialogprogress: false
    };
    this.setValuesFilter = this.setValuesFilter.bind(this);
  }

  componentWillMount() {
    if (this.props.dashboardTypeFilter) {
      this.setState({ filter: this.props.dashboardTypeFilter });
    }
  }

  setValuesFilter(event, index, value) {
    this.setState({ filter: value });

    let date = '';
    switch (value) {
      case 'Último ano':
        date = moment(new Date()).subtract(1, 'year');
        break;
      case 'Último mês':
        date = moment(new Date()).subtract(1, 'month');
        break;
      case 'Há sete dias':
        date = moment(new Date()).subtract(7, 'days');
        break;
      case 'Há três dias':
        date = moment(new Date()).subtract(3, 'days');
        break;

      default:
        break;
    }
    this.props.setTypeFilter(value);
    this.props.getDashBoardValues(moment(date).format('L'));
  }

  render() {
    return (
      <div className="header-chart-display">
        <div className="header-chart-publication-description">
          <label className="main-label-chart-publication">
            Interação com publicações
          </label>
          <label className="secondary-label-chart-publication">
            Contagem total das interações no mural por dia{' '}
          </label>
        </div>
        <div>
          <DropDownDefaultOnlyOne
            multiple={false}
            value={this.state.filter}
            onChange={this.setValuesFilter}
            array={['Último ano', 'Último mês', 'Há sete dias', 'Há três dias']}
            floatingLabelText="Selecione o intervalo"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardTypeFilter: state.dashboardTypeFilter
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTypeFilter: (typeFilter) => {
      dispatch({ type: 'SET_TYPE_FILTER', typeFilter: typeFilter });
    }
  };
};

const HeaderChartContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderChart);

export default HeaderChartContainer;
