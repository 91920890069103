export function repositories(state = [], action) {
  if (action.type === 'GET_FOLDERS') {
    if (action.files == undefined) {
      return action.folders;
    } else {
      return action.files;
    }
  }
  if (action.type === 'EDIT_FOLDER') {
    return { data: action.folder };
  }
  if (action.type === 'EDIT_FILE') {
    return action.file;
  }

  return state;
}
