import API from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import moment from 'moment-timezone';
import PubSub from 'pubsub-js';
import errorHandler from '../componentes/utils/errorHandler';
import { storeCopy } from '../index';
import { style } from '../style';

export const saveContentQuestion =
  (id, values, edit, type) => async (dispatch) => {
    dispatch({
      type: 'SHOW_STATUS',
      enablesubmit: false,
    });

    const CONFI = Config.headers();
    CONFI.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      dispatch({
        type: 'SHOW_PROGRESS',
        alert: {
          visible: true,
          percentage: percentCompleted,
        },
      });
    };

    const formData = new FormData();
    formData.append('text', '');
    if (type === 'open_question') {
      formData.append('kind', 'open_question');
      formData.append(
        '[open_question_attributes][question]',
        'pergunta qualquer'
      );
    } else {
      formData.append('kind', 'survey');
      formData.append('end_date', values.end_date);
      if (type === 'quiz') {
        formData.append('allows_more_than_one_vote', true);
      }
    }

    try {
      const { data } = await API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      );

      dispatch({
        type: 'SHOW_PROGRESS',
        alert: {
          visible: false,
          percentage: 0,
        },
      });
      dispatch({
        type: 'NO_SUBMITTING',
      });
      dispatch({
        type: 'formPostEdit',
        kind: type,
        value: data,
        insertText: true,
        edittext: true,
      });
      dispatch({
        type: 'HIDE_STATUS',
        enablesubmit: true,
      });
      PubSub.publish('re-render-canvas', true);
    } catch (error) {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: errorHandler(error),
        },
      });
    }
  };

export const saveOptionQuestion =
  (idPublish, idContent) => async (dispatch) => {
    dispatch({
      type: 'SHOW_STATUS',
      enablesubmit: false,
    });

    const CONFI = Config.headers();
    CONFI.onUploadProgress = (progressEvent) => {
      const percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      dispatch({
        type: 'SHOW_PROGRESS',
        alert: {
          visible: true,
          percentage: percentCompleted,
        },
      });
    };

    const formData = new FormData();
    formData.append('[surveys_attributes][][title]', '..');

    try {
      const { data } = await API.patch(
        `canvas_publications/${idPublish}/canvas_contents/${idContent}`,
        formData,
        CONFI
      );

      dispatch({
        type: 'SHOW_PROGRESS',
        alert: {
          visible: false,
          percentage: 0,
        },
      });
      dispatch({
        type: 'NO_SUBMITTING',
      });
      dispatch({
        type: 'formPostEdit',
        kind: data.kind,
        value: data,
        createOptionQuestion: true,
      });
      dispatch({
        type: 'HIDE_STATUS',
        enablesubmit: true,
      });
    } catch (error) {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: errorHandler(error),
        },
      });
    }
  };

export const saveContentEditOptions =
  (id, value, idContent, idOption, type) => async (dispatch) => {
    const {
      request: { pendingRequests },
    } = storeCopy.getState();

    dispatch({
      type: 'PATCHING_POST_START',
    });

    dispatch({
      type: 'SHOW_STATUS',
      enablesubmit: false,
    });
    const CONFI = Config.headers();
    CONFI.onUploadProgress = (progressEvent) => {
      dispatch({
        type: 'SHOW_PROGRESS',
        alert: {
          visible: true,
          percentage: progressEvent,
        },
      });
      dispatch({
        type: 'HIDE_STATUS',
        enablesubmit: true,
      });
      dispatch({
        type: 'NO_SUBMITTING',
      });
      PubSub.publish('re-render-canvas', true);
    };
    const formData = new FormData();
    formData.append('[surveys_attributes][][id]', idOption);
    formData.append('[surveys_attributes][][title]', value);

    try {
      const { data } = await API.patch(
        `canvas_publications/${id}/canvas_contents/${idContent}`,
        formData,
        CONFI
      );

      dispatch({
        type: 'SHOW_PROGRESS',
        alert: {
          visible: false,
          percentage: 0,
        },
      });
      dispatch({
        type: 'NO_SUBMITTING',
      });
      dispatch({
        type: 'formPostEdit',
        kind: type,
        idOption: idOption,
        value: data,
        patchOptionValue: true,
      });
      dispatch({
        type: 'HIDE_STATUS',
        enablesubmit: true,
      });

      if (pendingRequests === 0) {
        dispatch({ type: 'PATCHING_POST_FINISH' });
      }

      PubSub.publish('re-render-canvas', true);
    } catch (error) {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: errorHandler(error),
        },
      });
    }
  };

export const saveContentEditQuestions =
  (id, values, idpatch, type) => async (dispatch) => {
    if (idpatch) {
      const {
        request: { pendingRequests },
      } = storeCopy.getState();

      dispatch({
        type: 'PATCHING_POST_START',
      });

      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false,
      });
      const CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: true,
            percentage: progressEvent,
          },
        });
        dispatch({
          type: 'HIDE_STATUS',
          enablesubmit: true,
        });
        dispatch({
          type: 'NO_SUBMITTING',
        });
        PubSub.publish('re-render-canvas', true);
      };
      const formData = new FormData();
      formData.append('text', values);
      if (type === 'open_question') {
        formData.append('[open_question_attributes][question]', values);
      }

      try {
        const { data } = await API.patch(
          `canvas_publications/${id}/canvas_contents/${idpatch}`,
          formData,
          CONFI
        );

        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: false,
            percentage: 0,
          },
        });
        dispatch({
          type: 'NO_SUBMITTING',
        });
        dispatch({
          type: 'formPostEdit',
          kind: type,
          value: data,
          patchTextValue: true,
        });
        dispatch({
          type: 'HIDE_STATUS',
          enablesubmit: true,
        });

        if (pendingRequests === 0) {
          dispatch({ type: 'PATCHING_POST_FINISH' });
        }

        PubSub.publish('re-render-canvas', true);
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error),
          },
        });
      }
    }
  };

export const deleteQuestionContent =
  (idPublish, idContent, type) => async (dispatch) => {
    try {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      await API_CLIENT.delete(
        `canvas_publications/${idPublish}/canvas_contents/${idContent}`,
        Config.headers()
      );

      dispatch({
        type: 'formPostEdit',
        kind: type,
        idContent: idContent,
        deleteContent: true,
      });
      PubSub.publish('re-render-canvas', true);
      dispatch({
        type: 'NO_SUBMITTING',
      });
    } catch (error) {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: errorHandler(error),
        },
      });
    }
  };

export const searchUsersVoters = (searchValue) => async (dispatch) => {
  try {
    dispatch({
      type: 'SEARCH_USERS_VOTERS',
      searchValue,
    });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error),
      },
    });
  }
};

export const downloadSpreadsheet = (formId, formTitle) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

    const { data } = await API.get(
      `canvas_publications/${formId}/export_form_report.xlsx`,
      {
        responseType: 'blob',
        headers: Config.headers().headers,
      }
    );
    const src = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = src;
    link.setAttribute(
      'download',
      `respostas__${formTitle
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()}__${moment().format('L')}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();

    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error),
      },
    });
  }
};
