import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import HistoricalApi from '../../../Logicas/HistoricalApi';

import { BodyTable } from '../../utils/BodyTable';
import { FooterPagination } from '../../utils/Pagination';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableData,
  TableHeadRow
} from '../../utils/Table';

import MoreIcon from '../../utils/img/more-icon.svg';
import not_img from '../../utils/img/ico-external.png';

class ComponentListUser extends Component {
  redirectUserDetails = (userId, userName, useImage) => {
    browserHistory.push('/historical/userDetails');
    this.props.getUserDetailsHistory(userId, userName, useImage);
  };

  render() {
    return (
      <BodyTable>
        <Table>
          <TableHead>
            <TableHeadRow>Nome</TableHeadRow>
            <TableHeadRow>E-mail</TableHeadRow>
            <TableHeadRow>Pontos</TableHeadRow>
            <TableHeadRow>Ultima Pontuação</TableHeadRow>
            <TableHeadRow></TableHeadRow>
          </TableHead>

          <TableBody>
            {this.props.listUser.users_score &&
              this.props.listUser.users_score.map((listUser, index) => (
                <TableRow className="content-list-history" key={index}>
                  <TableData>
                    <img
                      src={listUser.image.url ? listUser.image.url : not_img}
                      alt="Foto do Usuario"
                    />
                    {listUser.name && listUser.name.length > 30
                      ? `${listUser.name.slice(0, 30)}...`
                      : listUser.name}
                  </TableData>
                  <TableData>{listUser.uid}</TableData>
                  <TableData>
                    <div className="text-color-historical">
                      {listUser.score}
                    </div>
                  </TableData>
                  <TableData>
                    {listUser.last_scoring
                      ? `
                      ${moment
                        .tz(listUser.last_scoring, 'America/Sao_Paulo')
                        .format('DD/MM/YYYY')}
                        às
                        ${moment
                          .tz(listUser.last_scoring, 'America/Sao_Paulo')
                          .format('LT')}
                          `
                      : 'Data inexistente.'}
                  </TableData>
                  <TableData>
                    <div className="text-color-historical">
                      <img
                        src={MoreIcon}
                        alt="ver historico dessa publicação"
                        onClick={() =>
                          this.redirectUserDetails(
                            listUser.id,
                            listUser.name,
                            listUser.image.url
                          )
                        }
                      />
                    </div>
                  </TableData>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {this.props.listUser.size &&
          this.props.listUser.size[1] > 0 &&
          this.props.listUser.users_score && (
            <FooterPagination
              activePage={this.props.pageActive}
              totalItemsCount={this.props.listUser.size[0]}
              handlePageChange={this.props.getListUsersHistory.bind(
                this,
                this.props.filterName
              )}
            />
          )}
      </BodyTable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageActive: state.historical_points.pageActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListUsersHistory: (name, page) => {
      dispatch(HistoricalApi.getListUsersHistory(page, name));
    },
    getUserDetailsHistory: (userId, userName, useImage) => {
      dispatch(HistoricalApi.getUserDetailsHistory(userId, userName, useImage));
    }
  };
};

const ComponentListUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentListUser);

export default ComponentListUserContainer;
