import React, { Component } from 'react';
import { connect } from 'react-redux';

import SurveyAnswers from '../Cards/SurveyAnswers/SurveyAnswers';
import QuizAnswers from '../Cards/QuizAnswers/QuizAnswers';
import OpenAnswers from '../Cards/OpenAnswers/OpenAnswers';
import UsersModal from './UsersModal/UsersModal';

import * as S from './AnswersTab.style';

export class AnswersTab extends Component {
  render() {
    return (
      <S.AnswersContainer>
        {this.props.valuesForm.contents.map((content) => {
          const contentKind = {
            default: () => null,
            open_question: () => <OpenAnswers content={content} />,
            quiz: () => <QuizAnswers content={content} />,
            survey: () => <SurveyAnswers content={content} />
          };

          return (contentKind[content.kind] || contentKind['default'])();
        })}
        <UsersModal />
      </S.AnswersContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    valuesForm: state.getForm.formDetails
  };
};

const AnswersTabContainer = connect(mapStateToProps)(AnswersTab);

export default AnswersTabContainer;
