import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ButtonDefault from '../../../utils/FormsComponents';
import {
  TextFieldForm,
  LongTextFieldTitle,
  SelectForm
} from '../../../utils_form/FormComponents';
import { DialogYesNo } from '../../../utils/DailogComponentes';
import {
  required,
  ValidImage,
  doesContainHttp
} from '../../../validation/FormValidation';
import * as SectionApi from '../../../../Logicas/SectionApi';
import DropZoneImage from '../../ToolsList/Components/DropZoneTools/DropZoneImage';

import * as S from './LinkEdit.styles';

export class ToolsEdit extends Component {
  constructor() {
    super();
    this.state = {
      sectorNames: [],
      selectedSectorId: '',
      previousSectorId: '',
      selectedSectorName: '',
      image: '',
      file: [{ preview: '' }],
      showModal: false,
      formValues: {}
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { initialValues } = this.props;

    if (!Object.keys(this.props.initialValues).length) {
      browserHistory.push('/tools/list');
      return null;
    }

    this.setState({
      selectedSectorId: initialValues.external_link_section_id,
      previousSectorId: initialValues.external_link_section_id,
      file: [
        {
          preview: initialValues.image.url
        }
      ]
    });
  }

  validateLinkUrl = (formValues) => {
    if (!doesContainHttp(formValues.url)) {
      this.setState({ showModal: true, formValues });
    } else {
      this.patchTool(formValues);
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  setSectorId = (fieldValue) => {
    const {
      sectionList,
      initialValues: { external_link_section_id: originalSectionId }
    } = this.props;

    delete fieldValue.preventDefault;
    const sectionName = Object.values(fieldValue).join('');

    const { id: selectedSectorId } = sectionList.find(
      (section) => sectionName === section.name
    );

    this.setState({
      previousSectorId: originalSectionId,
      selectedSectorId: selectedSectorId
    });
  };

  updateFile(image) {
    if (!ValidImage(image[0].type)) {
      this.props.showErroAlert(
        'Arquivo não suportado. Envie apenas imagens PNG ou JPG/JPEG'
      );
      return;
    }
    this.setState({ file: image });
  }

  patchTool = (formValues) => {
    const { file, selectedSectorId, previousSectorId } = this.state;
    const { patchTool } = this.props;

    formValues.image = file;
    formValues.sectorId = selectedSectorId;

    patchTool(formValues, previousSectorId);
  };

  render() {
    const { handleSubmit, sectionNameList } = this.props;
    const { showModal, formValues } = this.state;

    return (
      <>
        <MuiThemeProvider>
          <S.ToolsComponentHeader>
            <S.ToolsComponentHeaderTitle>
              <S.ToolsComponentHeaderTitleValue>
                Editar Link
              </S.ToolsComponentHeaderTitleValue>
            </S.ToolsComponentHeaderTitle>
            <S.ToolsComponentHeaderButtons>
              <ButtonDefault
                label="Salvar"
                onClick={() =>
                  this.formRef.current.dispatchEvent(new Event('submit'))
                }
              />
            </S.ToolsComponentHeaderButtons>
          </S.ToolsComponentHeader>
          <S.FormContainer>
            <S.ToolsEditForm
              onSubmit={handleSubmit(this.validateLinkUrl)}
              ref={this.formRef}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6">
                        <LongTextFieldTitle
                          fullWidth={true}
                          name="name"
                          fullWidth={true}
                          validations={[required]}
                          id="name"
                          label="Nome do link*"
                          floatingLabelText="Digite ou cole o nome do recurso*"
                          type="text"
                        />
                      </div>
                      <div className="col-md-6">
                        <SelectForm
                          name="sector"
                          multiple={false}
                          label="Selecione um setor"
                          elements={sectionNameList}
                          onChange={(e) => this.setSectorId(e)}
                          value={this.state.selectedSectorName}
                        />
                      </div>
                    </div>
                    <div>
                      <TextFieldForm
                        fullWidth={true}
                        name="url"
                        rows={1}
                        validations={[required]}
                        id="name"
                        floatingLabelText="Digite ou cole o link do recurso, com http:// ou https://**"
                        type="text"
                      />
                    </div>
                  </div>
                  <S.DropZone className="col md-4">
                    <S.DropZoneActions>
                      <S.DropZoneField>
                        <DropZoneImage
                          onDrop={(image) => this.updateFile(image)}
                          file={this.state.file}
                          name="image"
                        />
                      </S.DropZoneField>
                      <S.DropZoneText>
                        Clique ou arraste imagens para aqui
                      </S.DropZoneText>
                    </S.DropZoneActions>
                  </S.DropZone>
                </div>
              </div>
            </S.ToolsEditForm>
          </S.FormContainer>
          <DialogYesNo
            noclick={() => this.closeModal()}
            yesclick={() => this.patchTool(formValues)}
            message="Será adicionado https:// ao início do link, tudo bem?"
            visible={showModal}
          />
        </MuiThemeProvider>
      </>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'createtool'
})(ToolsEdit);

const mapDispatchToProps = (dispatch) => {
  return {
    getToolsList: async () => {
      await dispatch(SectionApi.getSectionList());
    },
    patchTool: async (values, previousSectorId) => {
      await dispatch(SectionApi.patchTool(values, previousSectorId));
    },
    showErroAlert: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: message }
      })
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.section.toolEdit,
    sectionList: state.section.sectionList,
    sectionNameList: state.section.sectionNameList,
    alert: state.dialog
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
