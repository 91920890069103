import React, { Component } from 'react';
import ComponentHeader from '../utils/ComponenteHeader';
import { Body } from '../utils/BodyComponente';
import { reduxForm } from 'redux-form';
import { required } from '../validation/FormValidation';
import {
  LongTextFieldTitle,
  AutoCompleteForm
} from '../utils_form/FormComponents';
import { connect } from 'react-redux';
import TagApi from '../../Logicas/TagApi';
import RepositorieApi from '../../Logicas/RepositoryApi';
import { formValueSelector } from 'redux-form';
class EditFolder extends Component {
  constructor() {
    super();
    this.state = { tags: [] };
    this.saveFolder = this.saveFolder.bind(this);
  }

  componentWillMount() {
    this.props.getTags();
  }

  saveFolder(values) {
    this.props.patchFolder(values);
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.saveFolder)}>
        <ComponentHeader
          disabled={submitting}
          actions={[]}
          name="Editar pasta"
          nameaction="Salvar"
          onClick={handleSubmit(this.saveFolder)}
        />
        <Body>
          <div className="row">
            <div className="col-md-6">
              <LongTextFieldTitle
                name="name_folder"
                validations={[required]}
                id="name"
                label="Nome da pasta"
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <AutoCompleteForm
                name="tags_folder"
                isLoading={this.props.isLoadingTags}
                validations={[required]}
                label="Tags permitidas*"
                getTagAutoComplete={this.props.getTagAutoComplete}
                getAuto={this.props.getTagAutoComplete}
                tags={this.props.tags}
              />
            </div>
          </div>
        </Body>
      </form>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'editfolder'
})(EditFolder);
const selector = formValueSelector('editfolder');
const mapDispatchToProps = (dispatch) => {
  return {
    patchFolder: (values) => {
      dispatch(RepositorieApi.patchFolder(values));
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
  };
};

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.repositories.data,
    alert: state.dialog,
    tags: state.tagsparsed,
    tags_selected: selector(state, 'tags_folder'),
    isLoadingTags: state.isLoadingtags,
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
