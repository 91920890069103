import styled, { keyframes } from 'styled-components';
import { style as teamStyle } from '../../../../style';

const bounce = keyframes`
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
`;

export const LoaderWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  margin-left: calc(50% - 45px);
  display: flex;
  flex-direction: column;
  transition: opacity 0.15s ease-in;
  opacity: ${(props) => (props.loading ? 1 : 0)};
`;

export const ElementWrapper = styled.div`
  display: flex;
  justify-content: center;

  & + & {
    margin-top: 8px;
  }
`;

export const Text = styled.span`
  font-family: Rubik;
  font-size: 14px;
  color: ${teamStyle.inpuStyle.colorInput};
  text-align: center;
  min-width: 85px;
  min-height: 30px;
  display:flex;
  justify-content:center;
  align-items:center;
`;

export const Circle = styled.div`
  background-color: ${teamStyle.inpuStyle.colorInput};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px;
  animation: ${bounce} 0.5s ease-in infinite;

  &:nth-of-type(2) {
    animation-delay: 0.1s;
  }

  &:nth-of-type(3) {
    animation-delay: 0.2s;
  }
`;
