import React from 'react';
import { style } from '../../style';

import './componentSegmentationTagsStyle.css';

export const ComponentSegmentationTags = (props) => {
  return (
    <div className="view-segmentation-tag">
      {props.tagsExpression &&
        props.tagsExpression.map((tagExpression, index) => {
          return (
            <div className="segmentation-tag-content">
              <div
                className="tag-segmentation-content-modal"
                style={{ backgroundColor: style.inpuStyle.colorInput }}
              >
                {tagExpression.valueTag}
              </div>
              {props.tagsExpression.length > index + 1 && (
                <img
                  src={tagExpression.valueExpression.image}
                  alt={tagExpression.valueExpression.text}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};
