import React, { useState, useEffect } from "react";

import { ReactComponent as IconActive } from "./img/iconActive.svg";
import { ReactComponent as IconDraft } from "./img/iconDraft.svg";

import * as S from "./CourseSearchPreviewStyle";

const CourseSearchPreview = ({ coursesAvailable, coursesNotAvailable, editCourse, functionSearchAll }) => {

  const countAvailableCanBeRendered = 
    coursesNotAvailable !== undefined && coursesNotAvailable.length < 3 ? 
      3 + (3 - coursesNotAvailable.length) 
      : 3;

  const countNotAvailableCanBeRendered = 
    coursesAvailable !== undefined && coursesAvailable.length < 3 ? 
      3 + (3 - coursesAvailable.length) 
      : 3;

  const goToMoreResultsIsDisabled = 
    (coursesAvailable !== undefined && coursesAvailable.length === 0 
    && coursesNotAvailable !== undefined && coursesNotAvailable.length === 0)
    || (coursesAvailable === undefined && coursesNotAvailable === undefined );

  return (
    <S.ContainerOutPreviewSearch id="ContainerOutPreviewSearch">
      <S.ContainerInnerPreviewSearch>
        
        <S.ContainerType>
          <div className="header-title">
            <IconActive/>
            <span>Ativos</span>
          </div>

          {coursesAvailable && coursesAvailable.length > 0 ? 
            coursesAvailable.slice(0,countAvailableCanBeRendered).map(course => (
              <S.MiniCardCourse index={course.id} onClick={()=>editCourse(course.id, course.kind)}>
                <S.ContainerImageMiniCard>
                    <S.ImageMiniCard src={course.image.url}/>
                </S.ContainerImageMiniCard>

                <S.ContainerTexts>
                    <h4 title={course.title}>{course.title.length > 30 ? `${course.title.slice(0,30)}...` : course.title}</h4>
                    <span title={course.description}>{course.description.length > 45 ? `${course.title.slice(0,45)}...` : course.description}</span>
                </S.ContainerTexts>
              </S.MiniCardCourse>
            ))
            : (
              <span className="results-not-found">Nenhum resultado, nos cursos ativos.</span>
            )
        }
        </S.ContainerType>

        <S.ContainerType>
          <div className="header-title">
            <IconDraft/>
            <span>Em rascunho</span>
          </div>

          {coursesNotAvailable && coursesNotAvailable.length > 0 ? 
            coursesNotAvailable.slice(0,countNotAvailableCanBeRendered).map(course => (
              <S.MiniCardCourse index={course.id} onClick={()=>editCourse(course.id, course.kind)}>
                <S.ContainerImageMiniCard>
                    <S.ImageMiniCard src={course.image.url} className='image-not-active'/>
                </S.ContainerImageMiniCard>

                <S.ContainerTexts>
                    <h4 title={course.title}>{course.title.length > 30 ? `${course.title.slice(0,30)}...` : course.title}</h4>
                    <span title={course.description}>{course.description.length > 45 ? `${course.title.slice(0,45)}...` : course.description}</span>
                </S.ContainerTexts>
              </S.MiniCardCourse>
            ))
            : (
              <span className="results-not-found">Nenhum resultado, nos cursos em rascunho.</span>
            )
          }
        </S.ContainerType>

        <S.ContainerGoToMoreResults>
          <S.GoToMoreResults

            isDisabled={goToMoreResultsIsDisabled}
            onClick={
              goToMoreResultsIsDisabled ? 
                ()=>{}
                : () => functionSearchAll()
            }
          >
            Ver mais resultados
          </S.GoToMoreResults>
        </S.ContainerGoToMoreResults>
  
      </S.ContainerInnerPreviewSearch>
    </S.ContainerOutPreviewSearch>
  );
};

export default CourseSearchPreview;
