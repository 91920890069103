import styled from 'styled-components';

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const SelectWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.open ? '400px' : '128px')};
  ${defaultTransition};
  transform-origin: top;
  overflow: scroll;
  z-index: 2;
  cursor: pointer;
`;

export const Description = styled.p``;

export const SelectBody = styled.ul`
  width: 100%;
  border-top: none;
  border-right: none;
  border-bottom: solid 1px #e2e2e2;
  border-left: none;
  border-radius: 8px 8px 0 0;
  padding: 8px 12px;
  line-height: 24px;
`;

export const OptionBody = styled.li`
  position: relative;
  border: none;
  display: flex;
  white-space: pre;
  align-items: center;
  min-height: 20px;
  padding: 0px 24px 0px 64px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: inherit;
  line-height: 32px;
  ${defaultTransition};

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }

  & + & {
    margin-top: 16px;
  }
`;

export const TagName = styled.span``;

export const SelectTagsOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
`;
