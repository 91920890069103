import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const FormTitle = styled.h3`
  color: #403f4c;
  font-size: 32px;
  text-align: center;
  font-weight: 500;
  font-family: Rubik, sans-serif;
  margin-top: 10px;
`;

export const DrawForm = styled.form`
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;
`;

export const InputContainer = styled.div`
  width: calc(50% - 16px);
  &:nth-of-type(2) {
    margin-left: 16px;
  }
`;

export const Line = styled.div`
  width: 450px;
  heigth: 400px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`
