import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import DialogApi from '../../../Logicas/DialogApi';
import CourseApi from '../../../Logicas/CourseApi';

import CourseListCard from './CourseListCards';
import CourseSearchPreview from './CourseSearchPreview';
import ComponentHeader from '../../utils/ComponenteHeader';
import { DialogYesNo } from '../../utils/DailogComponentes';
import ImgPlaceHolderCurso from '../../utils/img/place-holder-curso.png';

import teamSettings from '../../utils/TeamsSettings/generalSettings';

const { hasCourses } = teamSettings();

require('./courselist.css');

class CourseList extends Component {
  constructor() {
    super();
    this.state = {
      courses: [],
      progress: true,
      page: 1,
      tab: "actives"
    };
    this.getNextPage = this.getNextPage.bind(this);
    this.redirectNewCourse = this.redirectNewCourse.bind(this);
    this.editCourseRedirect = this.editCourseRedirect.bind(this);
    this.goToActivesList = this.goToActivesList.bind(this);
    this.goToDraftList = this.goToDraftList.bind(this);
  }

  componentWillMount() {
    this.props.getCourses(1,1);
    this.props.clearCourseSearch();
    this.props.setCourseSearchNotActive();

    PubSub.subscribe(
      're-render-course-list',
      function(render, canpage) {
        this.setState({ getNew: canpage });
      }.bind(this)
    );
  }
  redirectNewCourse() {
    this.props.redirectNewCourse();
    browserHistory.push('/course/create');
  }

  getNextPage() {
    if (this.props.list.length % 10 === 0 && this.props.list.length !== 0) {
      this.setState({ getNew: false, page: this.state.page + 1 });
      this.props.getCourses(this.state.page);
    }
  }

  editCourseRedirect(id, typeCourse) {
    localStorage.setItem('IdCourseEdit', id);
    this.props.getHeaderCourseDetails('Iniciados');
    this.props.getUsersCourse(id,'not_finished');
    if (typeCourse === 'long') {
      this.props.editCourseV2(id);
    } else {
      this.props.editCourse(id);
    }
  }

  goToDraftList(){
    this.props.getCourses(1,0);
    this.setState({tab:"draft"});
  }

  goToActivesList(){
    this.props.getCourses(1,1);
    this.setState({tab:"actives"});
  }

  componentDidUpdate(){
    if(this.props.list && this.props.list.length > 0){
      if(this.props.list[0].available === false && this.state.tab === "actives"){
        this.setState({tab: "draft"})
      } else if(this.props.list[0].available === true && this.state.tab === "draft"){
        this.setState({tab: "actives"})
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <ComponentHeader
          actions={
            this.state.tab === "actives" ? 
              [{ name: 'Em rascunho', action: this.goToDraftList}]
              : [{ name: 'Ativos', action: this.goToActivesList}]
          }
          name={this.state.tab === "actives" ? "Ativos" : "Em rascunho"}
          nameaction="Novo Curso"
          onClick={this.redirectNewCourse}
          functionSearch={(search)=>this.props.getCoursesBySearch(1,search)}
          clearSearch={this.props.clearCourseSearch}
          setActiveSearch={this.props.setCourseSearchActive}
          setNotActiveSearch={this.props.setCourseSearchNotActive}
          course_search_active={this.props.course_search_active}
        />

        {(this.props.course_search_active) && 
          <CourseSearchPreview 
            coursesAvailable={this.props.list_search_available}
            coursesNotAvailable={this.props.list_search_not_available} 
            editCourse={this.editCourseRedirect}
            functionSearchAll={()=>this.props.getAllCoursesBySearch(1, this.props.search_actual)}
          />
        }
        
        {this.props.list && this.props.list.length > 0  ? (
          <CourseListCard
            deleteCourse={this.props.deleteShowDialogCourse}
            canpaginate={this.state.getNew}
            nextpage={this.getNextPage}
            editCourse={this.editCourseRedirect}
            cards={this.props.list}
            changeAvaiableCourse={this.props.changeAvaiableCourse}
          />
        ):(
          <div className="place-holder">
            <img className="img-place-holder" src={ImgPlaceHolderCurso} alt="imagem do curso"/>
          </div>
        )}

        <DialogYesNo
          yesclick={this.props.deleteCourse.bind(
            this,
            this.props.alert.id_elemente,
            this.props.alert.avaiable
          )}
          noclick={this.props.closeDialog}
          onClick={this.props.closeDialog}
          visible={this.props.alert.visible}
          message="Tem certeza que deseja remover este curso ?"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.course.course_list.course,
    list_search_available: state.course.course_list_search_available.course,
    list_search_not_available: state.course.course_list_search_not_available.course,
    course_search_active: state.course.course_search_active,
    search_actual: state.course.search_actual,
    courseSize: state.course.course_list.size,
    alert: state.dialog,
    pagination: state.pagination
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCourses: (page,avaiable) => {
      dispatch(CourseApi.getCourses(page,avaiable));
    },
    getCoursesBySearch: (page, search) => {
      dispatch(CourseApi.getCoursesBySearch(page, search));
    },
    getAllCoursesBySearch: (page, search) => {
      dispatch(CourseApi.getAllCoursesBySearch(page, search));
    },
    setCourseSearchActive: () => {
      dispatch({ type: 'SET_COURSE_SEARCH_ACTIVE' });
    },
    setCourseSearchNotActive: () => {
      dispatch({ type: 'SET_COURSE_SEARCH_NOT_ACTIVE' });
    },
    clearCourseSearch: () => {
      dispatch({ type: 'CLEAR_COURSE_SEARCH' });
    },
    editCourse: (id) => {
      dispatch(CourseApi.editCourse(id));
    },
    editCourseV2: (id) => {
      dispatch(CourseApi.editCourseV2(id, true));
    },
    getHeaderCourseDetails: (titleName) => {
      dispatch(CourseApi.getHeaderCourseDetails(titleName));
    },
    getUsersCourse:(idCourse,kind,page,per_page) =>{
      dispatch({type: 'INITIAL_SEARCH_BY_USERS'});
      dispatch(CourseApi.getUsersCourse(idCourse,kind,page,per_page));
    },
    changeAvaiableCourse: (id, tags, avaiable, values) => {
      dispatch(CourseApi.changeAvaiableCourse(id, tags, avaiable, values));
    },
    deleteCourse: (id, avaiable) => {
      dispatch(CourseApi.deleteCourse(id, avaiable));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deleteShowDialogCourse: (values, avaiable) => {
      dispatch(DialogApi.deleteAlert(values, false, avaiable));
    },
    redirectNewCourse: () => {
      dispatch({ type: 'EDIT_COURSE', course: {} });
    }
  };
};
const CourseListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseList);

export default CourseListContainer;
