import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Modal from 'react-awesome-modal';
import moment from 'moment';
import { style } from '../../../../../style';
import PostApi from '../../../../../Logicas/PostApi';
import { ButtonConfirm } from '../../../../utils/FormsComponents';
import { DatePickerForm } from '../../../../utils_form/FormComponents';
import HourSelect from '../HourSelect/HourSelect';
import { required } from '../../../../validation/FormValidation';
import { CheckboxDefaultList } from '../../../FormsComponents';

import { MuiThemeProvider } from 'material-ui/styles';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import IcoExit from '../../../../utils/img/exit-dialog.png';
import * as S from './ScheduleModal.styles';

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput },
  modal: {
    backgroundColor: style.inpuStyle.colorInput
  }
});

class ScheduleModal extends Component {
  constructor() {
    super();

    this.state = {
      selectedTime: { selectedHour: '00', selectedMinutes: '00' },
      selectedTimeExpiration: { selectedHour: '00', selectedMinutes: '00' },
      has_expiration: false
    };

    this.toggleHasExpiration = this.toggleHasExpiration.bind(this);
  }

  handleSelectTime = (selectedTime) => {
    this.setState({ selectedTime });
  };
  handleSelectTimeExpiration = (selectedTimeExpiration) => {
    this.setState({ selectedTimeExpiration });
  };

  publishSchedule = (values) => {
    const { canvasPublishSchedule, postId } = this.props;
    const { selectedTime, selectedTimeExpiration, has_expiration } = this.state;
    const { publish_in, expiration_in } = values;

    const parsedDate = moment(publish_in).format('YYYY-MM-DD');
    const parsedTime = `${selectedTime.selectedHour}:${selectedTime.selectedMinutes}`;
    const publishDate = moment(new Date(`${parsedDate} ${parsedTime}`)).format(
      'YYYY-MM-DDTHH:mm:SSZ'
    );

    const parsedExpirationDate = moment(expiration_in).format('YYYY-MM-DD');
    const parsedExpirationTime = `${selectedTimeExpiration.selectedHour}:${selectedTimeExpiration.selectedMinutes}`;
    const expirationDate = moment(
      new Date(`${parsedExpirationDate} ${parsedExpirationTime}`)
    ).format('YYYY-MM-DDTHH:mm:SSZ');

    // console.log(postId, publishDate, expirationDate, has_expiration);

    // const dateNow = new Date();
    // console.log('Data atual:', dateNow);
    // console.log('Data Pub:', publishDate);
    // console.log('Data Exp:', expirationDate);
    // if (publishDate < dateNow) {
    //   console.log('Data de publicação inválida');
    // } else if (has_expiration && expirationDate < publishDate) {
    //   console.log('Data de expiração inválida');
    // } else {
    //   console.log('Tudo certinho!');
    // }

    if (has_expiration) {
      canvasPublishSchedule(postId, publishDate, expirationDate);
    } else {
      canvasPublishSchedule(postId, publishDate);
    }
  };

  toggleHasExpiration() {
    this.setState({ has_expiration: !this.state.has_expiration });
  }

  render() {
    const {
      handleSubmit,
      showScheduleModal,
      schedulePublicationSuccess,
      closeModal,
      initialValues: { publish_in, expiration_in }
    } = this.props;

    return (
      <S.ModalContainer isVisible={showScheduleModal}>
        <Modal
          visible={showScheduleModal}
          width="40%"
          height="60%"
          effect="fadeInUp"
        >
          <MuiThemeProvider muiTheme={muiTheme}>
            <S.ModalArea>
              <S.ScheduleHeader>
                <img
                  onClick={() => closeModal()}
                  className="btn-exit"
                  src={IcoExit}
                  alt="fechar"
                />
              </S.ScheduleHeader>
              {!schedulePublicationSuccess ? (
                <>
                  <S.ScheduleContainer>
                    <S.ScheduleTitle>AGENDAR POST</S.ScheduleTitle>
                    <S.ScheduleMain>
                      <S.ScheduleColumn>
                        <span>Publicar:</span>
                        <form>
                          <DatePickerForm
                            validations={[required]}
                            name="publish_in"
                            label="Data*"
                          />
                        </form>
                        <S.ScheduleHourContainer>
                          <HourSelect
                            titleLeft={true}
                            handleSelectTime={this.handleSelectTime}
                            date={publish_in}
                            allowSelection={true}
                          />
                        </S.ScheduleHourContainer>
                      </S.ScheduleColumn>
                      <div className="divisor"></div>
                      <S.ScheduleColumn
                        className={
                          this.state.has_expiration
                            ? 'expiration'
                            : 'expiration disabled'
                        }
                      >
                        <div className="checkboxLine">
                          <CheckboxDefaultList
                            checked={this.state.has_expiration}
                            onCheck={this.toggleHasExpiration}
                          />
                          <span>Expirar:</span>
                        </div>
                        <>
                          <form>
                            <DatePickerForm
                              validations={[required]}
                              name="expiration_in"
                              label="Data*"
                              minDate={publish_in}
                              disabled={!this.state.has_expiration}
                            />
                          </form>
                          <S.ScheduleHourContainer>
                            <HourSelect
                              titleLeft={true}
                              handleSelectTime={this.handleSelectTimeExpiration}
                              // date={expiration_in}
                              allowSelection={this.state.has_expiration}
                            />
                          </S.ScheduleHourContainer>
                        </>
                      </S.ScheduleColumn>
                    </S.ScheduleMain>
                    <S.ScheduleFooter>
                      <ButtonConfirm
                        className="buttonSchedule"
                        label="  Agendar  "
                        onClick={handleSubmit((values) =>
                          this.publishSchedule({ ...values })
                        )}
                      />
                    </S.ScheduleFooter>
                  </S.ScheduleContainer>
                </>
              ) : (
                <S.ScheduleContainer>
                  <S.ScheduleMessage>
                    Publicação agendada com sucesso!
                  </S.ScheduleMessage>
                </S.ScheduleContainer>
              )}
            </S.ModalArea>
          </MuiThemeProvider>
        </Modal>
      </S.ModalContainer>
    );
  }
}

let ScheduleModalForm = reduxForm({
  form: 'scheduleModalForm',
  enableReinitialize: true
})(ScheduleModal);

const mapStateToProps = (state) => {
  return {
    initialValues: state.editpostform,
    postId: state.editpostform.id,
    showScheduleModal: state.editpostform.showScheduleModal,
    schedulePublicationSuccess: state.editpostform.schedulePublicationSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    canvasPublishSchedule: (id, publish_in, expiration_in) => {
      dispatch(PostApi.canvasPublishSchedule(id, publish_in, expiration_in));
    },
    closeModal: () => {
      dispatch({ type: 'SCHEDULE_MODAL_HIDE' });
    }
  };
};

const ScheduleModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleModalForm);

export default ScheduleModalContainer;
