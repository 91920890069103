export function createpost(state = {}, action) {
  if (action.type === 'formPost') {
    switch (action.kind) {
      case 'canvas':
        state.title = action.canvas.title;
        state.score = action.canvas.score;
        state.tags = action.canvas.tags;
        state.id = action.canvas.id;
        state.text = [];
        state.image = [];
        state.video = [];
        state.carousel = [];
        state.link = [];
        return state;
      case 'score':
        state.score = action.value.target.value;
        return state;
      case 'tags':
        state.tags = action.value;
        return state;
      case 'link':
        if (state.link === undefined) {
          state.link = [];
        }
        state.link.push(action.value);
        return state;
      case 'image':
        if (state.image === undefined) {
          state.image = [];
        }
        state.image = state.image;
        state.image.push(action.value);
        return state;
      case 'video':
        if (state.video === undefined) {
          state.video = [];
        }
        state.video.push(action.value);
        return state;
      case 'carousel':
        if (state.carousel === undefined) {
          state.carousel = [];
        }
        state.carousel.push(action.value);
        return state;
      case 'text':
        if (state.text === undefined) {
          state.text = [];
        }
        state.text.push(action.value);
        state.text.map(function(text) {
          if (text.id === action.value.id) {
            text = action.value;
          }
        });
    }
  }
  return state;
}
