import React, { Component } from 'react';

// import './NoContent.css';
import * as S from './NoContent.style';

export class NoContent extends Component {
  render() {
    return (
      <>
        <S.Container>
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
          <div className="particle" />
        </S.Container>
        <S.NoContentMessage>
          Nenhum conteúdo cadastrado nesta seção.
        </S.NoContentMessage>
      </>
    );
  }
}

export default NoContent;
