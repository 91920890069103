import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 0 50px;
  max-width: 1140px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextContainer = styled.div``;

export const Title = styled.h2`
  font-family: Rubik, sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #333333;
`;

export const ComparisonContainer = styled.div`
  margin-left: 32px;
  display: flex;
`;

export const Description = styled.span`
  font-family: Rubik, sans-serif;
  font-size: ${(props) => (props.highLight ? '20px' : '16px')};
  font-weight: ${(props) => (props.highLight ? '600' : '500')};
  color: #999999;
`;

export const ToggleFormContainer = styled.div`
  margin-right: 16px;
`;

export const RangeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RangeForm = styled.form`
  width: 100%;
  display: flex;
`;

export const PickerPair = styled.div`
  width: calc(50% - 32px);
  display: flex;
  flex-direction: column;
  transition: transform 0.33s ease;

  & + & {
    margin-left: 64px;
  }

  &:nth-child(1) {
    transform: ${(props) =>
      props.comparePeriods
        ? 'translateX(0px) scale(1)'
        : 'translateX(255px) scale(1.5)'};
  }

  &:nth-child(2) {
    opacity: ${(props) => (props.comparePeriods ? '1' : '0')};
  }
`;

export const PickerWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const RangeDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: margin 0.1s ease;

  margin-top: ${(props) => (props.comparePeriods ? '16px' : '0')};
`;
