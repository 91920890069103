export function erroalert(state = { visible: false, message: '' }, action) {
  if (action.type === 'SHOW_ERRO_ALERT') {
    return action.alert;
  }
  if (action.type === 'CLOSE_ERRO_ALERT') {
    return action.alert;
  }

  return state;
}
