import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PostApi from '../../../../Logicas/PostApi';
import CircularProgress from 'material-ui/CircularProgress';

require('./tooltip.css');

class ToolTipText extends Component {
  constructor() {
    super();
    this.state = { class: 'btn-edit-image' };
    this.EditImage = this.EditImage.bind(this);
    this.removeText = this.removeText.bind(this);
  }
  EditImage() {
    this.setState({ enable_edit: true });
  }

  removeText() {
    const elements = this.props.elements;
    const newElements = elements.filter(
      (element) => element.id !== Number(this.props.id.split('_')[1])
    );

    this.props.setComponentsToState(newElements);

    this.props.RemoveText(this.props.id_canvas, this.props.id.split('_')[1]);
  }

  render() {
    return (
      <div
        className={
          this.props.showbtn ? 'btn-edit-image-show' : 'btn-edit-image'
        }
      >
        <div className="container-tooltip-text">
          {this.props.load_status_delete === true ? (
            <CircularProgress
              style={{ marginLeft: '-20px' }}
              color="#444444"
              size={30}
              thickness={7}
            />
          ) : (
            <label onClick={() => this.removeText()} className="label-btn-text">
              Remover texto
            </label>
          )}
        </div>
      </div>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'tooltiptext'
})(ToolTipText);

const mapDispatchToProps = (dispatch) => {
  return {
    EditImagemForm: (id, idcontent) => {
      dispatch(PostApi.PatchContentImage(id, idcontent));
    },
    showDialogUploadFile: (kind, id) => {
      dispatch({
        type: 'SHOW_DIALOG_FILE_MIDIA',
        alert: { visible: true, kind: kind, id: id }
      });
    },
    RemoveText: (idcanvas, idtext) => {
      dispatch(PostApi.DeleteCotent(idcanvas, idtext));
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    creatpost: state.createpost,
    initialValues: state.createpost,
    dialoglink: state.dialogeditmidia,
    load_status_delete: state.load_status_delete
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
