import React, { Component } from 'react';
import { getMuiTheme, MuiThemeProvider } from 'material-ui/styles/';
import { style } from '../../../style';
import { CheckboxDefaultList,ButtonConfirm,TextFieldWithoutForm } from '../../utils/FormsComponents';
import {
  CircularProgressIndeterminate,
  DatePickerForm
} from '../../utils_form/FormComponents';
import { reduxForm } from 'redux-form';
import CardProductHistory from './CardProductHistory';
import { BodyTable } from '../../utils/BodyTable';
import { connect } from 'react-redux';
import styled from 'styled-components';
import IcoPlaceHolder from '../../utils/img/place_holder_product.png';
import StoreApi from '../../../Logicas/StoreApi';
import DialogApi from '../../../Logicas/DialogApi';
import { FooterPagination } from '../../utils/Pagination';
import TabLabel from './Components/TabLabel/TabLabel';
import DialogDispatchProduct from './DialogHistory';
import DialogReportEmail from '../../publication/dialog/DialogReportEmail/DialogReportEmail';
import DashboardButton from '../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';



require('./historycss.css');

const DatePickerFormDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    border-bottom: 2.1px ${style.inpuStyle.colorInput} solid ;
  }
`

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  timePicker: {
    pickerHeaderColor: style.inpuStyle.colorInput,
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput,
    pickerSelectColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

function transformAddressToString(address) {
  if (address === null) {
    return '';
  }
  return `${address.street}, ${address.number} - ${address.complement} - ${address.neighborhood} - ${address.city} / ${address.state}, ${address.zip_code}`;
}

function getPhoneFromContacts(contacts) {
  if (contacts.length === 0) {
    return '';
  }
  let numberPhone = '';
  contacts.forEach((contact) => {
    if (contact.kind === 'phone') numberPhone = contact.contact;
  });

  return numberPhone;
}

class ProductHistory extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: 1,
      getDispatchedProducts: false,
      kind: 'not_despatched',
      startDate: null,
      endDate: null,
      minDate: new Date('2018-01-01T00:00:00'),
      name_product: '',
      selectedsCarts: [],
      allCartsAreSelecteds: false,
    };
  }

  handleSelectedsCarts(newIdCart, cartsQuantity){
    let newSelectedsCarts = this.state.selectedsCarts.includes(newIdCart) ?
      this.state.selectedsCarts.filter(ids => ids !== newIdCart)
      : [...this.state.selectedsCarts, newIdCart]
    this.setState({selectedsCarts:newSelectedsCarts, allCartsAreSelecteds: newSelectedsCarts.length === cartsQuantity ? true : false})
  }

  setSelectedsCarts(list){
    if(this.state.allCartsAreSelecteds){
      this.setState({
        allCartsAreSelecteds:false, selectedsCarts:[]
      });
    } else {
      this.setState({
        allCartsAreSelecteds:true, selectedsCarts:list
      })
    }
  }

  getAllCartsIds(products){
    let allCartsIds = products.map(product => product.cart_id);
    return allCartsIds;
  }

  setAllProductsToDispatch(products, filtersInformation){
    let allProductsToDispatch = products.map(product => this.state.selectedsCarts.includes(product.cart_id) && 
      {
        cart_id: product.cart_id,
        product_id: product.product.id,
        user_id: product.user.id
      }
    )

    allProductsToDispatch = allProductsToDispatch.filter(product => !!product && product);
    this.props.dispatchProducts(allProductsToDispatch, filtersInformation);
    this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
  }  

  componentDidMount() {
    const { list, getInitialProductList } = this.props;

    if (!list.notDispatchedProducts.length && !list.dispatchedProducts.length) {
      getInitialProductList();
    }
  }

  searchProduct = (name_product) => {
    const { getDispatchedProducts, startDate, endDate } = this.state;
    const {
      getDispatchedProductList,
      getNotDispatchedProductList
    } = this.props;

    this.setState({
      name_product
    });

    if (getDispatchedProducts) {
      getDispatchedProductList(1, name_product, startDate, endDate);
      this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
    } else {
      getNotDispatchedProductList(1, name_product, startDate, endDate);
      this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
    }
  };

  getProductsPageList = (nextPage) => {
    const {
      getDispatchedProducts,
      startDate,
      endDate,
      name_product
    } = this.state;
    const {
      getDispatchedProductList,
      getNotDispatchedProductList
    } = this.props;

    if (getDispatchedProducts) {
      getDispatchedProductList(nextPage, name_product, startDate, endDate);
      this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
    } else {
      getNotDispatchedProductList(nextPage, name_product, startDate, endDate);
      this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
    }
  };

  changePeriodStart = (value, date) => {
    const { getDispatchedProducts, endDate, name_product } = this.state;
    const {
      getDispatchedProductList,
      getNotDispatchedProductList
    } = this.props;

    this.setState({
      startDate: date
    });

    if (endDate) {
      if (getDispatchedProducts) {
        getDispatchedProductList(1, name_product, date, endDate);
        this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
      } else {
        getNotDispatchedProductList(1, name_product, date, endDate);
        this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
      }
    }
  };

  changePeriodEnd = (value, date) => {
    const { getDispatchedProducts, startDate, name_product } = this.state;
    const {
      getDispatchedProductList,
      getNotDispatchedProductList
    } = this.props;

    this.setState({
      endDate: date
    });

    if (startDate) {
      if (getDispatchedProducts) {
        getDispatchedProductList(1, name_product, startDate, date);
        this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
      } else {
        getNotDispatchedProductList(1, name_product, startDate, date);
        this.setState({selectedsCarts: [], allCartsAreSelecteds:false});
      }
    }
  };

  openStartDateCalendar(){
    var element = document.querySelector('#start_date');
    if(element !== null)
      element.click();
  }
  openEndDateCalendar(){
    var element = document.querySelector('#end_date');
    if(element !== null)
      element.click();
  }

  render() {
    const { activeTab, kind, startDate, endDate } = this.state;
    const {
      list,
      loading,
      loadingProducts,
      getDispatchedProductList,
      getNotDispatchedProductList,
      openReportEmailDialog
    } = this.props;
    return (
      <>
        <MuiThemeProvider muiTheme={muiTheme}>
          <div className="container">
            <div style={{ marginTop: '20px' }} className="row">
              <div className="col-md-4">
                <TabLabel
                  active={activeTab === 1}
                  label="Solicitações aguardando confirmação"
                  onClick={() => (
                    this.setState({
                      activeTab: 1,
                      getDispatchedProducts: false,
                      kind: 'not_despatched'
                    }),
                    getNotDispatchedProductList(
                      1,
                      this.state.name_product,
                      startDate && endDate && startDate,
                      endDate
                    )
                  )}
                />
              </div>
              <div className="col-md-3">
                <TabLabel
                  active={activeTab === 2}
                  label="Solicitações aprovadas"
                  onClick={() => (
                    this.setState({
                      activeTab: 2,
                      getDispatchedProducts: true,
                      kind: 'despatched'
                    }),
                    getDispatchedProductList(
                      1,
                      this.state.name_product,
                      startDate && endDate && startDate,
                      endDate
                    )
                  )}
                />
              </div>
              <div className="col-md-3">
                <TextFieldWithoutForm
                  onChange={(values) => this.searchProduct(values.target.value)}
                  style={{ marginTop: '-100px' }}
                  floatingLabelText="Pesquise um produto solicitado"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="row">
                  <h3>Baixar histórico de pedidos</h3>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <DatePickerFormDiv>
                      <DatePickerForm
                        id="start_date"
                        name="start_date"
                        label="Início do período"
                        minDate={this.state.minDate}
                        maxDate={this.state.endDate}
                        selected={this.state.startDate}
                        onChange={this.changePeriodStart}
                      />
                    </DatePickerFormDiv>
                  </div>
                  <div className="col-md-2">
                    <DatePickerFormDiv>
                      <DatePickerForm
                        id="end_date"
                        name="end_date"
                        label="Final do período"
                        selected={this.state.endDate}
                        minDate={this.state.startDate}
                        onChange={this.changePeriodEnd}
                      />
                    </DatePickerFormDiv>
                  </div>
                  <div className="col-md-2 d-flex justify-content-center align-items-center">
                    <DashboardButton
                      label="Obter histórico"
                      // bothDatesRequired={startDate !== null && endDate !== null}
                      onClick={
                        startDate === null ? this.openStartDateCalendar
                          : endDate === null ? this.openEndDateCalendar 
                          : () => openReportEmailDialog(kind, startDate, endDate)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        {loadingProducts && list.notDispatchedProducts.products.length === 0 && (
          <div className="loader-container">
            <MuiThemeProvider>
              <CircularProgressIndeterminate />
              <h2 className="loader-container__heading">Carregando...</h2>
            </MuiThemeProvider>
          </div>
        )}

        {
          <div style={{
            "border-top": "solid 1px #cccccc",
            "border-bottom": "solid 1px #cccccc",
          }}>
            <div className="body-card" style={{width:"80%", height:"75px",marginLeft:"auto",marginRight:"auto", border:"none"}}>
              {activeTab === 1 &&
                <>
                  <div className="card-product-column">
                      <CheckboxDefaultList
                        checked={this.state.allCartsAreSelecteds}
                        onCheck={()=>this.setSelectedsCarts(this.getAllCartsIds(list.notDispatchedProducts.products))}
                      />
                  </div>
                  <div className="card-product-column"style={{fontSize: "18px", fontWeight:"600"}}>
                    {this.state.selectedsCarts.length}/{activeTab === 1? list.notDispatchedProducts.products.length : list.dispatchedProducts.products.length}
                    <br/>Selecionados
                  </div>
                </>
              } 
              {activeTab === 2 && 
                <div className="card-product-column"style={{fontSize: "18px", fontWeight:"600"}}>
                  
                </div>
              } 
              <div className="card-product-column" style={{minWidth:"180px", fontSize: "18px", fontWeight:"600"}}>
                  Produto
              </div>
              <div className="card-product-column" style={{fontSize: "18px", fontWeight:"600"}}>
                  Quantidade disponível
              </div>
              <div className="card-product-column" style={{fontSize: "18px", fontWeight:"600"}}>
                  Solicitante
              </div>
              {(style.teamName.name === 'Pintou Parceria Suvinil' || style.teamName.name === 'sample') && (
                <div className="card-product-column" style={{fontSize: "18px", fontWeight:"600"}}>
                    Endereço de entrega
                </div>
              )}
              <div className="card-product-column" style={{fontSize: "18px", fontWeight:"600"}}>
                  Data da solicitação
              </div>
              { activeTab === 1 && 
                <>
                  <div className="card-product-column" style={{fontSize: "18px", fontWeight:"600"}}>
                      Excluir
                  </div>
                  <div className="card-product-column" style={{fontSize: "18px"}}>
                    {
                      <ButtonConfirm
                        onClick={() => this.setAllProductsToDispatch(
                                        list.notDispatchedProducts.products, 
                                        {
                                          page:1,
                                          name_product:this.state.name_product,
                                          startDate: startDate,
                                          endDate: endDate
                                        })
                        }
                        label="Confirmar selecionados"
                        height="none"
                        disabled={this.state.selectedsCarts.length <= 1}
                      />
                    }
                  </div>
                </>
              }
              
            </div>
          </div>
        }

        <BodyTable isDispatchProducts={true}>
          <section>
            {activeTab === 1 && (
              <div className="tab__waiting-confirmation" style={{display:"flex", alignItems:"center", flexDirection: "column"}}>
                {list.notDispatchedProducts.products.length > 0 ? (
                  list.notDispatchedProducts.products.map(
                    function(product) {
                      return (
                        <CardProductHistory
                          key={product.cart_id}
                          hasCheckbox={true}
                          checked={this.state.selectedsCarts.includes(product.cart_id)}
                          onCheck={()=>this.handleSelectedsCarts(product.cart_id, list.notDispatchedProducts.products.length)}
                          send={true}
                          closeDialog={this.props.closeDialog}
                          dialogOpen={this.props.dialogOpen}
                          alert={this.props.alert}
                          id_product={product.product.id}
                          user_id={product.user.id}
                          cart_id={product.cart_id}
                          user_name={product.user.name}
                          user_address={transformAddressToString(
                            product.user_address
                          )}
                          user_phoneNumber={getPhoneFromContacts(
                            product.user_contact
                          )}
                          amount={product.product.amount}
                          score={product.product.valuescore}
                          name_product={product.product.name}
                          img={product.product.image.url}
                          requested_date={product.created_at}
                        />
                      );
                    }.bind(this)
                  )
                ) : (
                  <div className="row">
                    {!loading &&
                      list.notDispatchedProducts.products.length === 0 && (
                        <div className="col-md-4 offset-md-4">
                          <img
                            className="img-place-holder"
                            src={IcoPlaceHolder}
                            alt="ícone carrinho"
                          />
                        </div>
                      )}
                  </div>
                )}
              </div>
            )}

            {activeTab === 2 && (
              <div className="tab__solicitation-approved" style={{display:"flex", alignItems:"center", flexDirection: "column"}}>
                {list.dispatchedProducts.products.length > 0 ? (
                  list.dispatchedProducts.products.map(function(product) {
                    return (
                      <CardProductHistory
                        key={product.id_cart}
                        cart_id={product.id_cart}
                        user_name={product.user.name}
                        user_address={transformAddressToString(
                          product.user_address
                        )}
                        user_phoneNumber={getPhoneFromContacts(
                          product.user_contact
                        )}
                        amount={product.product.amount}
                        score={product.product.valuescore}
                        name_product={product.product.name}
                        img={product.product.image.url}
                        requested_date={product.created_at}
                      />
                    );
                  })
                ) : (
                  <div className="row">
                    {!loading && !list.dispatchedProducts.products && (
                      <div className="col-md-4 offset-md-4">
                        <img
                          className="img-place-holder"
                          src={IcoPlaceHolder}
                          alt="ícone carrinho"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </section>

          {activeTab === 1 && (
            <>
              {list.notDispatchedProducts.pagination.total_pages > 0 && (
                <FooterPagination
                  activePage={
                    list.notDispatchedProducts.pagination.current_page
                  }
                  totalItemsCount={
                    list.notDispatchedProducts.pagination.total_count
                  }
                  handlePageChange={this.getProductsPageList}
                />
              )}
            </>
          )}

          {activeTab === 2 && (
            <>
              {list.dispatchedProducts.pagination.total_pages > 0 && (
                <FooterPagination
                  activePage={list.dispatchedProducts.pagination.current_page}
                  totalItemsCount={
                    list.dispatchedProducts.pagination.total_count
                  }
                  handlePageChange={this.getProductsPageList}
                />
              )}
            </>
          )}
        </BodyTable>
        
        <DialogDispatchProduct 
          filtersInformation={
            {
              page:1,
              name_product:this.state.name_product,
              startDate: startDate,
              endDate: endDate
            }
          }
        />

        <MuiThemeProvider>
          <DialogReportEmail />
        </MuiThemeProvider>
        </MuiThemeProvider>
      </>
    );
  }
}

ProductHistory = reduxForm({ form: 'productHistoryForm' })(ProductHistory);

const mapStateToProps = (state) => {
  return {
    list: state.storeList.historyList,
    alert: state.dialog,
    pagination: state.pagination,
    loading: state.progress_indeterminate,
    loadingProducts: state.loadingProducts.loading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dialogOpen: (user_id, cart_id, id_product) => {
      dispatch(
        DialogApi.deleteAlertProductHistory(user_id, id_product, cart_id)
      );
    },
    getDispatchedProductList: (page, startDate, endDate, name_product) => {
      dispatch(
        StoreApi.getDispatchedProductList(
          page,
          startDate,
          endDate,
          name_product
        )
      );
    },
    getNotDispatchedProductList: (page, start_date, end_date, name_product) => {
      dispatch(
        StoreApi.getNotDispatchedProductList(
          page,
          start_date,
          end_date,
          name_product
        )
      );
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    getByNameProduct: (name) => {
      dispatch(StoreApi.getProductsHistoryPage(name));
    },
    getProducthistory: (page) => {
      dispatch(StoreApi.getProductsHistory(page));
    },
    getInitialProductList: () => {
      dispatch(StoreApi.getInitialProductList());
    },
    openReportEmailDialog: (kind, startDate, endDate) => {
      dispatch({
        type: 'OPEN_REPORT_EMAIL_MODAL',
        payload: { kind, startDate, endDate }
      });
    },
    dispatchProducts: (product_purchase, filtersInformation) => {
      dispatch(
        StoreApi.dispatchProducts(product_purchase,filtersInformation)
      );
    },
  };
};
const ProducHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductHistory);

export default ProducHistoryContainer;
