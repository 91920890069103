import React, { Component } from 'react';
import 'video-react/dist/video-react.css';
import QuizImage from '../utils/img/ic-quiz.svg';
require('./previewcontent.css');

class ContentPreviewQuiz extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="preview-content-quiz">
        <div className="row">
          <div className="col-md-12 preview-content-img">
            <img className="img-default-quiz" src={QuizImage} alt="ícone quiz"/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 preview-content-img">
            <label className="text-area-question">{this.props.question}</label>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="preview-content-text ">
              <div className="col-md-12">
                <div className="option">a) {this.props.answer1}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="preview-content-text ">
              <div className="col-md-2">
                <div className="option">b) {this.props.answer2}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="preview-content-text ">
              <div className="col-md-2">
                <div className="option">c) {this.props.answer3}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="preview-content-text ">
              <div className="col-md-2">
                <div className="option">d) {this.props.answer4}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContentPreviewQuiz;
