import React, { Component } from 'react';
import { connect } from 'react-redux';
import PublicationList from './PublicationList';
import HeaderInteraction from '../HeaderInteraction/HeaderInteraction';
import HeaderComparison from '../HeaderInteraction/HeaderComparison';
import ChartCardPublication from './ChartCardPublication';
import TagGraphc from './TagPublication';
import DateRange from './Components/DateRange';

class WrapPublicationDash extends Component {
  render() {
    return (
      <div>
        {this.props.periodsComparison === false ? (
          <HeaderInteraction
            interaction={this.props.interaction}
            likes={this.props.likes}
            comments={this.props.commets}
            views={this.props.views}
          />
        ) : (
          <HeaderComparison
            interaction={this.props.interaction}
            likes={this.props.likes}
            comments={this.props.commets}
            views={this.props.views}
          />
        )}
        <DateRange />
        <ChartCardPublication data={this.props.more_popular_graphic} />
        <div className="display-row-home-dash">
          <PublicationList
            publication_list={this.props.more_popular_publication}
          />
          <TagGraphc tag_use={this.props.tag_use} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    periodsComparison: state.dashboard.periodsComparison
  };
};

const WrapPublicationDashContainer = connect(
  mapStateToProps,
  null
)(WrapPublicationDash);

export default WrapPublicationDashContainer;
