import React, { Component } from 'react';
import {
  AreaChart,
  linearGradient,
  stop,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import HeaderChart from './HeaderChart';
import moment from 'moment-timezone';
import { style } from '../../../../style';
var Spinner = require('react-spinkit');

require('./chartcardpublication.css');

class ChartCardPublication extends Component {
  constructor() {
    super();
    this.state = { completed: 50, opendialogprogress: false };
  }

  componentWillMount() {}

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="card-chart">
              <div style={{ marginLeft: '50px' }} className="row">
                <div
                  style={{
                    marginTop: '10px',
                    marginBottom: '20px',
                    marginLeft: '20px'
                  }}
                  className="col-md-11 teste"
                >
                  {this.props.data !== undefined ? (
                    <ResponsiveContainer width="100%" height="80%">
                      <AreaChart
                        width={700}
                        height={300}
                        data={this.props.data}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                      >
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor={style.inpuStyle.colorInput}
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor={style.inpuStyle.colorInput}
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis
                          dataKey="date"
                          domain={['2018-01-01T00:00:00', new Date(Date.now())]}
                          minTickGap={30}
                          tickFormatter={(date) =>
                            moment.tz(date, 'America/Sao_Paulo').format('DD/MM')
                          }
                        />
                        <YAxis />
                        <Tooltip
                          viewBox={{ borderRadius: '25px' }}
                          labelFormatter={(date) =>
                            moment.tz(date, 'America/Sao_Paulo').format('DD/MM')
                          }
                        />
                        <Area
                          type="monotone"
                          dataKey="Interações"
                          stackId="1"
                          stroke={style.inpuStyle.colorInput}
                          fillOpacity={1}
                          fill="url(#colorUv)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  ) : (
                    <div className="load-graphic ">
                      <Spinner
                        name="cube-grid"
                        color={style.inpuStyle.colorInput}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChartCardPublication;
