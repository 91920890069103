import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import ButtoDefault, { TextFieldSearch } from '../../utils/FormsComponents';
import { MuiThemeProvider } from 'material-ui/styles';
import { LabelLink } from '../../utils/utils_styled';
import teamSettings from '../../utils/TeamsSettings/generalSettings';
import CalendarApi from '../../../Logicas/CalendarApi';

const { hasEvents } = teamSettings();
require('./headerlist.css');

class HeaderCalendarList extends Component {
  constructor() {
    super();
    this.state = {
      searchValue: ''
    };
  }

  delayedQuery = debounce((inputValue) => {
    const { getThisMonthEvents, getEventByName } = this.props;

    if (inputValue) {
      getEventByName(inputValue);
    } else {
      getThisMonthEvents();
    }
  }, 1500);

  handleOnChange = (searchInputValue) => {
    const inputValue = searchInputValue.target.value;

    if (inputValue !== '') {
      this.setState({ searchValue: inputValue }, this.delayedQuery(inputValue));
    } else {
      this.setState({ searchValue: '' }, this.delayedQuery());
    }
  };
  render() {
    return (
      <MuiThemeProvider>
        <div className="container">
          <div className="header-actions">
            <div className="actions-calendar-title">
              <label className="calendar-header-title">
                {hasEvents.value}{' '}
              </label>
              <LabelLink
                onClick={this.props.getPreviousMonth.bind(
                  this,
                  this.props.month_in_list
                )}
              >
                Mês passado
              </LabelLink>
              <LabelLink
                onClick={this.props.getNextMonth.bind(
                  this,
                  this.props.month_in_list
                )}
              >
                Próximo mês
              </LabelLink>
            </div>
            <ButtoDefault
              onClick={this.props.createNewEvent}
              label="Criar evento"
            />
          </div>
          <div className="header-actions-row-2">
            <TextFieldSearch
              value={this.state.searchvalue}
              onChange={this.handleOnChange}
              floatingLabelText="Busque por eventos"
            />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getThisMonthEvents: () => dispatch(CalendarApi.getThisMonthEvents()),
    getEventByName: (searchValue) =>
      dispatch(CalendarApi.getEventByName(searchValue)),
    createNewEvent: () => dispatch(CalendarApi.createNewEvent())
  };
};

const HeaderCalendarListContainer = connect(
  null,
  mapDispatchToProps
)(HeaderCalendarList);

export default HeaderCalendarListContainer;
