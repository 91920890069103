import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import ToolTipCarousel from '../../toolstips/TooltipImageCarousel';

require('../../css/reactResponsiveCarousel.css');

export class CarouselModule extends Component {
  constructor() {
    super();
    this.state = {
      currentSlide: 0,
      show: false
    };
  }

  setPreviousSlide() {
    const currentSlide = this.state.currentSlide;

    if (currentSlide > 0) {
      this.setState({ currentSlide: this.state.currentSlide - 1 });
    }
  }

  setNextSlide() {
    const slidesLength = this.props.element.canvas_content_images.length;
    const currentSlide = this.state.currentSlide;

    if (currentSlide < slidesLength - 1) {
      this.setState({ currentSlide: this.state.currentSlide + 1 });
    }
  }

  render() {
    return (
      <div style={{ marginLeft: '2px',maxWidth:'90%', marginTop: '10px'}} className="row">
        <div
          onMouseLeave={() => this.setState({ show: false })}
          data-tip
          data-for={'carousel_' + this.props.element.id}
          className="col-md-8"
        >
          <button
            type="button"
            className="caroussel__btn caroussel__btn--left"
            onClick={() => this.setPreviousSlide()}
          >
            &#x2039;
          </button>
          <div onMouseOver={() => this.setState({ show: true })}>
            <Carousel
              showArrows={false}
              swipeable={false}
              selectedItem={this.state.currentSlide}
            >
              {this.props.element.canvas_content_images.map((image) => (
                <div onMouseOver={this.props.showBtnCarousel} key={image.id}>
                  <img style={{ position: 'relative' }} src={image.image.url} alt="imagem do carrossel"/>
                </div>
              ))}
            </Carousel>
          </div>
          <button
            type="button"
            className="caroussel__btn caroussel__btn--right"
            onClick={() => this.setNextSlide()}
          >
            &#x203A;
          </button>
          <ToolTipCarousel
            showbtn={this.state.show}
            currentSlide={this.state.currentSlide}
            id_canvas={this.props.id_canvas}
            id={'carousel_' + this.props.element.id}
            link={this.props.element.link}
            elements={this.props.elements}
            setComponentsToState={this.props.setComponentsToState}
            element={this.props.element}
          />
        </div>
      </div>
    );
  }
}

export default CarouselModule;
