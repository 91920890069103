import styled from 'styled-components';

export const SelectorContainer = styled.div`
  display: flex;
  height: 100%;

  .emoji-mart {
    transition: 0.15s all;
    left: -300px;
  }

  .emoji-mart-search {
    display: none;
  }

  .emoji-mart-category .emoji-mart-emoji span {
    cursor:pointer !important;
  }
`;

export const EmojiButton = styled.button.attrs({ type: 'button' })`
  margin-top: 22px;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: none;
  transition: 0.15s all;
  /* box-shadow: ${({ selectorActive }) =>
    selectorActive
      ? '0px 2px 4px 0px rgba(0, 0, 0, 0.19)'
      : '0px 2px 4px 2px rgba(0, 0, 0, 0.4)'}; */
  z-index: 2;

  background: none;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }
`;

export const SelectorOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  visibility: ${({ selectorActive }) =>
    selectorActive ? 'visible' : 'hidden'};
`;
