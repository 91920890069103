import styled from 'styled-components';
import { style } from '../../../../style';

export const ModalContentBody = styled.div`
  padding: 32px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ModalForm = styled.form`
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
`;

export const CloseButton = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const ModalTitle = styled.h2`
  font-family: Rubik;
  font-size: 18.5px;
  font-weight: 500;
  color: #3b3b3b;
  text-align: center;
`;

export const ModalText = styled.p`
  font-family: Rubik;
  font-size: 15px;
  color: #3b3b3b;
  margin: 8px 16px 18px;
  text-align: center;
`;

export const ModalTextSuccess = styled(ModalText)`
  font-size: 16px;
`;

export const WarningText = styled.strong`
  color: #b3247c;
  margin-top: 10px;
  font-weight: 600;
  background-color: transparent;
`;

export const LineDates = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const DatePickerFormDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
text-align: center;

border-bottom: 2.1px transparent solid ;

&:hover{
  border-color: ${style.inpuStyle.colorInput};
}
`
