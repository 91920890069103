import React, { Component } from 'react';
import { debounce } from 'lodash';

import Chip from 'material-ui/Chip';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { browserHistory } from 'react-router';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import TagApi from '../../Logicas/TagApi';
import PostApi from '../../Logicas/PostApi';
import * as PostApiAsync from '../../Logicas/PostApiAsync';
import { BodyTable } from '../utils/BodyTable';
import DialogApi from '../../Logicas/DialogApi';
import not_img from '../utils/img/ico-external.png';
import DialogLikes from '../utils/dialogs/DialogLikes';
import ComponentHeader from '../utils/ComponenteHeader';
import DialogComments from '../utils/dialogs/DialogComments';
import { DialogYesNo, DialogAlert } from '../utils/DailogComponentes';
import { ComponentSegmentationTags } from '../utils_form/ShowViewSegmentationTags';
import ButtonDefault, {
  DropDownDefaultOnlyOne,
  TextFieldWithoutForm,
  ButtonDisable
} from '../utils/FormsComponents';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEdit
} from '../utils/Table';
import Pagination from '../utils/AutoPagination/Pagination';
import Loader from '../utils/AutoPagination/Loader';
import DownloadPostData from '../_shared/DownloadPostData/DownloadPostData';
import UserApi from '../../Logicas/UserApi';
import { FooterPagination } from '../utils/Pagination';

require('../publication/publicationlist.css');

class PostList extends Component {
  constructor() {
    super();
    this.state = {
      filterType: '',
      filter: '',
      searchvalue: '',
      searchTag: '',
      btnActive: true
    };
    this.showComments = this.showComments.bind(this);
    this.showLikes = this.showLikes.bind(this);
    this.getFilter = this.getFilter.bind(this);
    this.getByTitle = this.getByTitle.bind(this);
    this.redirectNewPost = this.redirectNewPost.bind(this);
    this.getByTitleDebounced = this.getByTitleDebounced.bind(this);
    this.getTagByName = this.getTagByName.bind(this);
    this.getTagByNameDebounced = this.getTagByNameDebounced.bind(this);
  }

  componentWillMount() {
    (!this.props.list.publications  || this.props.searchActive) && this.props.getPostList('publication');
    this.props.getUserListName();
  }

  redirectNewPost() {
    this.props.getTypeForm(false);
    browserHistory.push('/post/create');
  }

  showComments(publicationid) {
    this.setState({ comments: true });
    this.props.getComments(publicationid);
  }

  showLikes(publicationid) {
    this.setState({ comments: true });
    this.props.getLikes(publicationid);
  }

  getFilter(event, index, value) {
    this.setState({ filter: value, btnActive: false }, () => {
      this.props.getPage(
        this.state.filterType,
        1,
        this.state.searchvalue,
        this.state.filter
      );
    });
  }

  getByTitle({ target: { value } }) {
    if (value === '' && this.state.filter === '') {
      this.setState({ searchvalue: value, btnActive: true }, () => {
        this.props.getPage(
          this.state.filterType,
          1,
          this.state.searchvalue,
          this.state.filter
        );
      });
    } else {
      this.setState({ searchvalue: value, btnActive: false }, () => {
        this.getByTitleDebounced(
          this.state.filterType,
          1,
          this.state.searchvalue,
          this.state.filter
        )

      });
    }
  }

  getTagByName({target : {value}}) {
    this.setState({ searchTag: value, btnActive: false }, () => {
      this.getTagByNameDebounced(
        1,
        value
      )
    });
  }

  getTagByNameDebounced = debounce((page, search) => {
    this.props.getTags(page, search);
  }, 1500);

  getByTitleDebounced = debounce((filterType, page, search, filter) => {
    this.props.getPage(filterType, page, search, filter)
  }, 1500);

  getPagination(page) {
    this.props.getPage(
      this.state.filterType,
      page,
      this.state.searchvalue,
      this.state.filter
    );
  }

  getClearFilter() {
    this.setState(
      { filterType: '', filter: '', searchvalue: '', searchTag: '', btnActive: true },
      () => {
        this.props.getPage(
          this.state.filterType,
          1,
          this.state.searchvalue,
          this.state.filter
        );
        this.props.getTags(1);
      }
    );
  }

  render() {

    const {listRequestsActive, listTagName} = this.props;
    const heightDropdown = (listTagName.length*34)+70;
    const dropdownMenuProps={
      menuStyle:{
        height: `${heightDropdown}px`,
        overflow: "hidden",
      },
    }
    if (this.props.list) {
      return (
        <div>
          <MuiThemeProvider>
            <ComponentHeader
              onClick={this.redirectNewPost}
              tooltip={
                <p>
                  Atualizar publicação
                  <br />
                </p>
              }
              actions={[
                { name: 'Enquetes', action: this.props.getSurveyPublication },
                {
                  name: 'Formulários',
                  action: () => this.props.getPostList('form')
                },
                {
                  name: 'Sorteios',
                  action: () => this.props.getPostList('draw', 'raffle')
                },
                { name: 'Rascunhos', action: this.props.getDraftPublication }
              ]}
              name="Publicações"
              nameaction="Nova publicação"
              enablesubmit={listRequestsActive.length > 0}
            />
            <div className="container-row" style={{display:"flex", alignItems:"initial"}}>
              <div className="container-filter-type" style={{display:"flex", flexDirection:"column"}}>
                <>
                  <DropDownDefaultOnlyOne
                    multiple={false}
                    value={this.state.filterType}
                    onChange={(event, index, value) =>{
                        this.setState({ filterType: value })
                        value === 'Tag' && this.props.listTagName.length === 0 && this.props.getTags(1);
                      }
                    }
                    array={['Tag', 'Mês', 'Autor(a)']}
                    floatingLabelText="Filtrar por:"
                  />
                </> 
              </div>
              <div className="container-filter-type" id="tag-dropdown-container">
                {this.state.filterType === 'Tag' ? (
                <>
                  {this.state.filterType === 'Tag' && (
                      <TextFieldWithoutForm
                        value={this.state.searchTag}
                        onChange={this.getTagByName}
                        floatingLabelText="Digite o nome da tag"
                        style={{zIndex:"3"}}
                      /> 
                    )}
                  <DropDownDefaultOnlyOne
                    multiple={false}
                    value={this.state.filter}
                    onChange={this.getFilter}
                    array={this.props.listTagName}
                    floatingLabelText="Selecione a Tag"

                    withPagination={true}
                    style={{width: "350px", padding:"0", zIndex:"999"}}
                    activePage={this.props.tagsPagination.current_page}
                    totalItemsCount={this.props.tagsPagination.total_count}
                    handlePageChange={(pageNumber) =>
                      this.props.getTags(pageNumber, this.state.searchTag)
                    }
                    dropdownMenuProps={dropdownMenuProps}
                  />
                </>
                ) : this.state.filterType === 'Mês' ? (
                  <DropDownDefaultOnlyOne
                    multiple={false}
                    value={this.state.filter}
                    onChange={this.getFilter}
                    array={[
                      'Janeiro',
                      'Fevereiro',
                      'Março',
                      'Abril',
                      'Maio',
                      'Junho',
                      'Agosto',
                      'Setembro',
                      'Outubro',
                      'Novembro',
                      'Dezembro'
                    ]}
                    floatingLabelText="Selecione o mês"
                  />
                ) : (
                  this.state.filterType === 'Autor(a)' && (
                    <DropDownDefaultOnlyOne
                      multiple={false}
                      value={this.state.filter}
                      onChange={this.getFilter}
                      elementsName={this.props.userListName}
                      floatingLabelText="Selecione o(a) Autor(a) "
                    />
                  )
                )}
              </div>
              <div className="button-action-clear" style={{marginRight:"30px", marginTop:"30px"}}>
                {this.state.btnActive ? (
                  <ButtonDisable
                    onClick={() => this.getClearFilter()}
                    label="Limpar Filtros"
                  />
                ) : (
                  <ButtonDefault
                    onClick={() => this.getClearFilter()}
                    label="Limpar Filtros"
                  />
                )}
              </div>
              <div className="container-row-last">
                <TextFieldWithoutForm
                  value={this.state.searchvalue}
                  onChange={this.getByTitle}
                  floatingLabelText="Pesquisar publicação"
                />
              </div>
            </div>
            <DownloadPostData kind="publication" containerStyle={this.state.filterType === 'Tag' ? {marginTop:"-80px"} : {}}/>
            <BodyTable>
              <Table>
                <TableHead>
                  <TableHeadRow>Id</TableHeadRow>
                  <TableHeadRow>Publicação</TableHeadRow>
                  <TableHeadRow></TableHeadRow>
                  <TableHeadRow>Tags</TableHeadRow>
                  <TableHeadRow>Curtidas</TableHeadRow>
                  <TableHeadRow>Visualizações</TableHeadRow>
                  <TableHeadRow>Comentários</TableHeadRow>
                  <TableHeadRow>Data</TableHeadRow>
                  <TableHeadRow>Ações</TableHeadRow>
                </TableHead>
                <TableBody>
                  {this.props.list.publications &&
                    this.props.list.publications.map(
                      function(publication, i) {
                        return (
                          <TableRow key={i} onClik={this.props.editUser}>
                            <TableData>{publication.id}</TableData>
                            <TableData>
                              {publication.title.length > 30
                                ? publication.title.slice(0, 30) + '...'
                                : publication.title}
                            </TableData>
                            <TableData>
                              <div>
                                {publication.cover ? (
                                  <img
                                    src={
                                      publication.cover.url &&
                                      publication.cover.kind !== 'video'
                                        ? publication.cover.url
                                        : not_img
                                    }
                                    style={{
                                      width: '80px',
                                      height: '50px',
                                      'object-fit': 'cover'
                                    }}
                                    alt="capa da publicação"
                                  />
                                ) : (
                                  <img
                                    src={not_img}
                                    style={{
                                      width: '80px',
                                      height: '50px',
                                      'object-fit': 'cover'
                                    }}
                                    alt="ícone publicação sem capa"
                                  />
                                )}
                              </div>
                            </TableData>
                            <TableData>
                              {publication.tagsExpression ? (
                                <ComponentSegmentationTags
                                  tagsExpression={publication.tagsExpression}
                                />
                              ) : (
                                <div className="tags">
                                  <Chip>
                                    {publication.tags.length && (
                                      <>
                                        {publication.tags[0].name.length > 30
                                          ? publication.tags[0].name.substring(
                                              0,
                                              20
                                            ) + '...'
                                          : publication.tags[0].name}
                                      </>
                                    )}
                                  </Chip>
                                  {publication.tags.length > 1 && (
                                    <Chip>{publication.tags.length - 1}</Chip>
                                  )}
                                </div>
                              )}
                            </TableData>
                            <TableData
                              onClick={
                                publication.likes > 0 ?
                                this.showLikes.bind(this, publication.id) : undefined
                              }
                              datatip="pessoas que curtiram"
                            >
                              {publication.likes}
                            </TableData>
                            <TableData>{publication.visualization}</TableData>
                            <TableData
                              datatip="ver comentários"
                              onClick={
                                publication.comments > 0 ?
                                this.showComments.bind(this, publication.id) : undefined
                              }
                            >
                              {publication.comments}
                            </TableData>
                            <TableData>
                              {publication.created_at}
                              <br />
                              <label>{publication.user_name}</label>
                            </TableData>
                            <TableData>
                              <RemoveEdit
                                Delete={this.props.deleteShowDialogPublication.bind(
                                  this,
                                  publication.id,
                                  publication.is_publication
                                )}
                                Edit={() => {
                                  this.props.editPost(
                                    publication.id,
                                    publication.is_publication
                                  );
                                  this.props.setNotificationShow();
                                }}
                                enablesubmit={listRequestsActive.length > 0}
                              />
                            </TableData>
                          </TableRow>
                        );
                      }.bind(this)
                    )}
                </TableBody>
              </Table>

              {this.props.list.size > 0 && (
                <footer className="footer-pagination">
                  <FooterPagination
                    activePage={this.props.pagination}
                    totalItemsCount={this.props.list.size}
                    handlePageChange={(pageNumber) =>
                      this.getPagination(pageNumber)
                    }
                  />
                </footer>
              )}
              <ReactTooltip className="tooltip" />
            </BodyTable>
            <Loader loading={this.props.loading} />
            <DialogYesNo
              yesclick={this.props.deletePost.bind(
                this,
                this.props.alert.id_elemente,
                this.props.alert.is_publication
              )}
              noclick={this.props.closeDialog}
              onClick={this.props.closeDialog}
              visible={this.props.alert.visible}
              message="Tem certeza que deseja remover esta publicação ?"
            />
            <DialogAlert
              closeDialog={this.props.closeDialogAlert}
              message="Esta publicação não pode ser editada"
              visible={this.props.edit_old_publication}
            />

            {this.props.comments_list_alert ? (
              <DialogComments visible={this.props.comments_list_alert} />
            ) : null}

            {this.props.likes_list_alert ? (
              <DialogLikes visible={this.props.likes_list_alert} />
            ) : null}
          </MuiThemeProvider>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.post.list,
    searchActive: state.post.searchActive,
    page: state.page,
    alert: state.dialog,
    listTagName: state.tagsWithPagination.tags,
    tagsPagination: state.tagsWithPagination.pagination,
    pagination: state.pagination,
    edit_old_publication: state.edit_old_publication,
    comments_list: state.post_comments.data,
    comments_list_alert: state.post_comments.visible,
    likes_list_alert: state.post_likes.visible,
    likes_list: state.post_likes.data,
    userListName: state.user.userListName,
    loading: state.progress_indeterminate,
    listRequestsActive: state.post.listRequestsActive,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPostList: (kind, raffle = '') => {
      dispatch(PostApi.getPostListV2(kind, raffle));
    },
    getMorePublications: (page, kind, raffle) => {
      dispatch(PostApiAsync.getMorePublications(page, 'publication', ''));
    },
    getPage: (typeFilter, page, search, tag, per_page) => {
      dispatch(PostApi.getPostPage(typeFilter, page, search, tag, per_page));
    },
    deleteShowDialogPublication: (id, is_publication) => {
      dispatch(DialogApi.deleteAlert(id, is_publication));
    },
    editPost: (id, is_publication) => {
      dispatch(PostApi.editPost(id, is_publication));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deletePost: (id, values) => {
      dispatch(PostApi.deletePost(id, values));
    },
    getDraftPublication: () => {
      dispatch(PostApi.getPostListDraft());
    },
    closeDialogAlert: () => {
      dispatch({ type: 'CLOSE_ALERT_NO_EDIT', alert: false });
    },
    getComments: (id) => {
      dispatch(PostApi.getComments(id, 1));
    },
    getLikes: (id) => {
      dispatch(PostApi.getLikes(id, 1));
    },
    getTags: (page, search) => {
      dispatch(TagApi.getTagsUserV2(page, search));
    },
    getUserListName: () => {
      dispatch(UserApi.getAuthors());
    },
    getSurveyPublication: () => {
      dispatch(PostApi.getSurveyList(1));
    },
    getTypeForm: (isForm) => {
      dispatch(PostApi.getTypeForm(isForm));
    },
    setNotificationShow: () => {
      dispatch({ type: 'ENABLE_NOTIFICATION_SHOW' });
    }
  };
};
const PostListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostList);

export default PostListContainer;
