import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { BodyTable } from '../utils/BodyTable';
import ComponentHeader from '../utils/ComponenteHeader';
import { style } from '../../style';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData
} from '../utils/Table';

require('../utils/PaginationSurvey.css');
class SurveyView extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const teamName = style.CurrencyName.name.split('/');
    if (!Object.values(this.props.surveyDetails).length) {
      browserHistory.push('/post/survey');
      return null;
    } else {
      return (
        <div>
          <ComponentHeader
            actions={[]}
            name={this.props.survey.text}
            fieldDescription="Pergunta realizada"
            minWidth={500}
            nameaction="Voltar"
            onClick={() => browserHistory.push('/post/survey')}
          >
            <div className="body_container">
                <div className="score">
                  <span>Pontuação:</span>
                    {this.props.surveyDetails.score} {this.props.surveyDetails.score > 1 ? teamName[1] : teamName[0]}
                </div>
                <div className="segmentation">
                  <span>Segmentação:</span>
                  <ul>
                    {this.props.surveyDetails.tags.map(function(tagName, i) {
                      return (
                        <li className="" key={i}>#{tagName.name}</li>
                      );
                    })}
                  </ul>
                </div>
                <div className="end-date">
                  <span>Prazo para respostas:</span>
                  {this.props.survey.end_date !== null && 
                    this.props.survey.end_date !== undefined
                      ? moment
                          .tz(this.props.survey.end_date, 'Europe/Berlin')
                          .format('DD/MM/YYYY')
                      : 'Sem data final'}
                </div>
                <div className="not_score_after">
                  <span>Prazo para gamificação:</span>
                  {this.props.survey.not_score_after !== null && 
                    this.props.survey.not_score_after !== undefined
                      ? moment
                          .tz(this.props.survey.not_score_after, 'Europe/Berlin')
                          .format('DD/MM/YYYY')
                      : 'Sem data final'}
                </div>
            </div>
          </ComponentHeader>
          <BodyTable>
            <Table>
              <TableHead>
                <TableHeadRow>Posição</TableHeadRow>
                <TableHeadRow>Respostas</TableHeadRow>
                <TableHeadRow>Porcentagem</TableHeadRow>
                <TableHeadRow>Votos</TableHeadRow>
              </TableHead>
              <TableBody>
                {this.props.survey.content_survey.map(
                  function(surveyInfo, i) {
                    return (
                      <TableRow key={i}>
                        <TableData>{i + 1}</TableData>

                        <TableData>{surveyInfo.title}</TableData>

                        <TableData>
                          {(surveyInfo.votes_amount &&
                            this.props.survey.votes_amount) !== 0
                            ? parseFloat(
                                (surveyInfo.votes_amount /
                                  this.props.survey.votes_amount) *
                                  100
                              ).toFixed(2)
                            : 0}{' '}
                          %
                        </TableData>

                        <TableData>
                          {parseInt(surveyInfo.votes_amount)}
                        </TableData>
                      </TableRow>
                    );
                  }.bind(this)
                )}
              </TableBody>
            </Table>
          </BodyTable>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    survey: state.survery.filter_survey,
    surveyDetails: state.survery.filter_details
  };
};

const SurveyViewContainer = connect(mapStateToProps, null)(SurveyView);

export default SurveyViewContainer;
