import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import IcoExit from '../../utils/img/exit-dialog.png';
import { MuiThemeProvider } from 'material-ui/styles';
import { Avatar } from 'material-ui';
import { TextFieldWithoutForm } from '../FormsComponents';
import PostApi from '../../../Logicas/PostApi';
import moment from 'moment';
import Zoom from 'react-reveal/Zoom';
import PubSub from 'pubsub-js';
import CourseApi from '../../../Logicas/CourseApi';
require('./dialogcomments.css');
export class DialogVouchers extends Component {
  constructor() {
    super();
    this.state = { page: 1, request: true, showLabel: true, id_comment: 0 };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillMount() {
    window.onscroll = () => this.handleScroll();
    PubSub.subscribe(
      'get_next_page_comments',
      function(render) {
        this.setState({ request: true });
      }.bind(this)
    );
  }

  handleScroll() {
    if (document.getElementById('#body-scroll') !== null) {
      let scrollposition = document.getElementById('#body-scroll').scrollTop;
      var max = document.getElementById('#body-scroll').scrollHeight;
      if (
        scrollposition > max / 2 &&
        this.props.users_voucher_list.length % 10 === 0 &&
        this.state.request
      ) {
        this.setState({ page: (this.state.page += 1), request: false });
        this.props.getComments(this.props.id_post, this.state.page);

        document
          .getElementById('#body-scroll')
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }

  render() {
    let visible = this.props.visible;

    return (
      <Modal visible={visible} width="35%" height="70%" effect="fadeInUp">
        <MuiThemeProvider>
          <div className="header-comments"></div>
          <div className="header-row-comments">
            <div className="label-comments">
              {this.props.total + ' códigos no total'} /{' '}
              {this.props.users_voucher_list.length !== 1
                ? this.props.users_voucher_list.length + ' validados'
                : this.props.users_voucher_list.length + ' validado'}{' '}
            </div>
          </div>
          <div onClick={this.props.closeDialog} className="header-row-exit">
            <img
              style={{
                width: '18px',
                height: '18px',
                marginRight: '20px',
                marginTop: '-15%'
              }}
              src={IcoExit}
              alt="fechar"
            />
          </div>
          <div
            id="#body-scroll"
            onScroll={this.handleScroll}
            className="body-comments"
          >
            {Array.isArray(this.props.users_voucher_list) &&
              this.props.users_voucher_list.map(
                function(voucher) {
                  let hour = moment(voucher.created_at).fromNow();
                  return (
                    <div className="col-comments">
                      <div className="row-comments">
                        {/* <Avatar
                          style={{ marginTop: '-20px' }}
                          src={voucher.user.image.url}
                        ></Avatar> */}
                        <div className="col-row-comments">
                          <div className="user-name-comments">
                            {voucher.user_name}
                          </div>
                          <label className="comments-text">
                            {voucher.user_email}
                          </label>
                        </div>
                        <div className="comments-timer">{hour}</div>
                      </div>
                    </div>
                  );
                }.bind(this)
              )}
          </div>
        </MuiThemeProvider>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () =>
      dispatch({ type: 'SHOW_USERS_LIST_VOUCHER', data: [], visible: false }),
    deleteComments: (value, id, comment) =>
      dispatch(PostApi.deleteComment(id, value)),
    viewUsersVoucher: (voucherId, id, page) => {
      dispatch(CourseApi.viewUsersVoucher(voucherId, id, page));
    }
  };
};
let DialogVouchersContainer = connect(
  (state) => ({
    users_voucher_list: state.course.data
    // id_post: state.course.id_post
  }),
  mapDispatchToProps
)(DialogVouchers);

export default DialogVouchersContainer;
