import React, { Component } from 'react';
import RichTextEditor from '../../../../utils/RichTextEditor/RichTextEditor';
import ToolTipText from '../../toolstips/ToolTipText';

require('../../css/newcanvasform.css');

export class TextModule extends Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
  }

  render() {
    return (
      <div
        onMouseLeave={() => this.setState({ show: false })}
        style={{marginLeft:'2px',minWidth:'90%', marginTop: '10px'}}
        className="row"
      >
        <div
          onMouseOver={() => this.setState({ show: true })}
          data-tip
          data-for={'text_' + this.props.element.id}
          className="col-md-8"
        >
          <RichTextEditor
            onChange={this.props.timer.bind(this, this.props.element.id)}
            data={this.props.element.text}
          />

          <div className="tooltip-remove-text-form">
            <ToolTipText
              showbtn={this.state.show}
              id_canvas={this.props.id_canvas}
              id={'text_' + this.props.element.id}
              elements={this.props.elements}
              setComponentsToState={this.props.setComponentsToState}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TextModule;
