const initialState = {
  list: [],
  editPost: null,
  pagination: 1,
  page: 1,
  listRequestsActive: [],
  searchActive: false
};

export function post(state = initialState, action) {
  switch (action.type) {
    case 'SET_SEARCH_ACTIVE':
      return {
        ...state,
        searchActive: true,
      };
    case 'SET_SEARCH_NOT_ACTIVE':
      return {
        ...state,
        searchActive: false,
      };
    case 'GET_PUBLICATION_LIST':
      return {
        ...state,
        list: action.publication_list,
        pagination: state.pagination + 1,
        page: action.page
      };
    case 'GET_MORE_PUBLICATIONS':
      return {
        ...state,
        list: action.publication_list,
        pagination: state.pagination + 1,
        page: action.page
      };
    case 'RESET_PAGINATION':
      return {
        ...state,
        pagination: 1,
        page: 1
      };
    case 'SET_REQUEST_ACTIVE':
      return{
        ...state,
        listRequestsActive: [...state.listRequestsActive, action.randomIdRequest]
      }
    case 'DELETE_REQUEST_ACTIVE':
      const newListRequestActive = state.listRequestsActive.filter(ids => ids !== action.randomIdRequest);
      return{
        ...state,
        listRequestsActive: newListRequestActive
      }
    default:
      return state;
  }
}
