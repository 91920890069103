import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PostApi from '../../../../Logicas/PostApi';
import CircularProgress from 'material-ui/CircularProgress';
import EditLinkForm from '../dialog/DialogEditLink';
import style from 'react-awesome-modal/lib/style';

require('./tooltip.css');

class ToolTipLink extends Component {
  constructor() {
    super();
    this.state = { class: 'btn-edit-image' };
    this.EditLink = this.EditLink.bind(this);
    this.removeLinkFromState = this.removeLinkFromState.bind(this);
  }
  EditLink() {
    this.setState({ enable_edit: true });

    this.props.EditLinkForm(this.props.id_canvas, this.props.id.split('_')[1]);
  }

  removeLinkFromState() {
    const elements = this.props.elements;
    const newElements = elements.filter(
      (element) => element.id !== Number(this.props.id.split('_')[1])
    );

    this.props.setComponentsToState(newElements);

    this.props.RemoveLink(this.props.id_canvas, this.props.id.split('_')[1]);
  }
  render() {
    return (
      <div>
        <div
          className={
            this.props.showbtn ? 'btn-edit-image-show' : 'btn-edit-image'
          }
          style={{
            marginTop: '-20%',
            position: 'relative',
            top: '-45px',
            left: '30%',
          }}
        >
          <div className="container-tooltip-text">
            {this.props.load_status_delete === true ? (
              <CircularProgress
                style={{ marginLeft: '-20px' }}
                color="#444444"
                size={30}
                thickness={7}
              />
            ) : (
              <div className="container-tooltip-text">
                <label onClick={this.EditLink} className="label-btn-text">
                  Editar link
                </label>
                <label
                  onClick={() => this.removeLinkFromState()}
                  className="label-btn-text"
                >
                  Remover link
                </label>
              </div>
            )}
          </div>
        </div>
        {this.props.dialoglink ? <EditLinkForm /> : undefined}
      </div>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'tooltiplink'
})(ToolTipLink);

const mapDispatchToProps = (dispatch) => {
  return {
    EditLinkForm: (id, idcontent) => {
      dispatch(PostApi.EditLink(id, idcontent));
    },
    RemoveLink: (idcanvas, idtext) => {
      dispatch(PostApi.DeleteCotent(idcanvas, idtext));
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    creatpost: state.createpost,
    initialValues: state.createpost,
    dialoglink: state.dialoglink
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
