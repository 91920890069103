import styled from 'styled-components';
import { style as teamStyle } from '../../../../../style';

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const GridCard = styled.div`
  position: relative;
  width: 196px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  ${defaultTransition};

  &::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    ${defaultTransition}
  }

  &:hover {
    transform: scale(1.15, 1.15);
  }

  &:hover::after {
    opacity: 1;
  }
`;

export const GridCardHeader = styled.div`
  width: 100%;
  height: 110.25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridCardImage = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const GridCardFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &.download-icon svg {
    width: 50%;
    height: 50%;
    margin-right: 0;
  }

  &.link-icon svg {
    width: 40%;
    height: 40%;
    margin-right: 0;
  }
`;

export const GridcardBody = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GridCardTitle = styled.h3`
  width: 100%;
  height: 36px;
  padding: 0 8px;
  font-size: 16px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
`;

export const GridCardLinkFile = styled.div`
  padding: 0 8px;
  margin-bottom: 8px;
  display: inline-flex;
  justify-content: center;
  position: relative;

  &:hover > * {
    cursor: pointer;
  }

  &::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: ${teamStyle.inpuStyle.colorInput};
    transition: width 0.33s ease 0s, left 0.33s ease 0s;
    width: 0;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
`;

export const GridCardType = styled.h5`
  font-size: 14px;
  font-weight: 700;
  color: ${teamStyle.inpuStyle.colorInput};
  text-transform: capitalize;
`;

export const GridCardActions = styled.div`
  width: 100%;
  padding-top: 11.25px;
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid #eee;
`;

export const GridCardActionItem = styled.div`
  display: flex;

  &:hover > * {
    cursor: pointer;
    filter: brightness(0.75);
  }
`;

export const GridCardIcon = styled.img`
  width: 15px;
`;

export const GridCardText = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  text-align: center;
  color: #acacac;
  margin-left: 5px;
  margin-top: 1px;
`;
