import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import GalleryApi from '../../../Logicas/GalleryApi';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  AutoCompleteForm,
  TextFieldForm
} from '../../utils_form/FormComponents';
import { required } from '../../validation/FormValidation';
import { MuiThemeProvider } from 'material-ui/styles';
import GalleryButton from '../Components/GalleryButton';
import TagApi from '../../../Logicas/TagApi';

import * as S from './DialogCreateGallery.style';

class DialogCreateGallery extends Component {
  constructor() {
    super();
    this.formRef = React.createRef();
    this.createGallery = this.createGallery.bind(this);
  }

  componentDidMount() {
    this.props.getTags();
  }

  createGallery({ galleryName, tags }) {
    const { saveGallery, editGallery, initialValues, hideModal } = this.props;

    if (Object.values(initialValues).length) {
      editGallery(initialValues.id, galleryName, tags);
    } else {
      saveGallery(galleryName, tags);
    }
    hideModal();
  }
  render() {
    const {
      hideModal,
      isLoadingTags,
      getTagAutoComplete,
      availableTags,
      handleSubmit,
      showGalleryForm,
      initialValues
    } = this.props;

    return (
      <Modal
        visible={showGalleryForm}
        width="400px"
        height="400px"
        effect="fadeInUp"
      >
        <MuiThemeProvider>
          <S.FormContainer>
            <S.FormBody
              onSubmit={handleSubmit(this.createGallery)}
              ref={this.formRef}
            >
              <div>
                <TextFieldForm
                  name="galleryName"
                  floatingLabelText="Digite o nome da galeria"
                  validations={[required]}
                  fullWidth={true}
                />
                <AutoCompleteForm
                  name="tags"
                  isLoading={isLoadingTags}
                  validations={[required]}
                  label="Segmentação*"
                  getTagAutoComplete={getTagAutoComplete}
                  getAuto={getTagAutoComplete}
                  tags={availableTags}
                />
              </div>
              <S.ButtonsContainer>
                <GalleryButton onClick={hideModal} label="Cancelar" />
                <GalleryButton
                  onClick={() =>
                    this.formRef.current.dispatchEvent(new Event('submit'))
                  }
                  label={
                    !Object.values(initialValues).length
                      ? 'Criar galeria'
                      : 'Salvar edições'
                  }
                />
              </S.ButtonsContainer>
            </S.FormBody>
          </S.FormContainer>
        </MuiThemeProvider>
      </Modal>
    );
  }
}

let DialogCreateGalleryForm = reduxForm({
  form: 'DialogCreateGallery',
  enableReinitialize: true
})(DialogCreateGallery);

const mapDispatchToProps = (dispatch) => ({
  saveGallery: (galleryName, tags) =>
    dispatch(GalleryApi.saveGallery(galleryName, tags)),
  editGallery: (galleryId, galleryName, tags) =>
    dispatch(GalleryApi.editGallery(galleryId, galleryName, tags)),
  getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
  getTags: () => {
    dispatch(TagApi.getTagsUser());
  },
  hideModal: () => {
    dispatch({ type: 'HIDE_GALLERY_FORM' });
  }
});

DialogCreateGalleryForm = connect(
  (state) => ({
    initialValues: state.gallery.editGallery,
    selectedTags: state.gallery.selectedTags,
    isLoadingTags: state.isLoadingtags,
    availableTags: state.tagsparsed,
    showGalleryForm: state.gallery.showGalleryForm
  }),
  mapDispatchToProps
)(DialogCreateGalleryForm);

export default DialogCreateGalleryForm;
