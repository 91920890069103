export function loadstatus(state = { enablesubmit: true }, action) {
  if (action.type === 'SHOW_STATUS') {
    return { message: 'Salvando.....', enablesubmit: false };
  }
  if (action.type === 'HIDE_STATUS') {
    return {
      message: 'Alterações salvas automaticamente como rascunho',
      enablesubmit: true
    };
  }

  return state;
}
