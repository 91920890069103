import React, { Component } from 'react';
import { connect } from 'react-redux';
import {change} from 'redux-form';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import MenuButton from '../MenuButton/MenuButton';
import DialogApi from '../../../../Logicas/DialogApi';

import * as S from './MenuHeader.style';

export class MenuHeader extends Component {
  render() {
    return (
      <MuiThemeProvider>
        <S.TagsComponentHeader>
          <S.TagsComponentHeaderTitle>
            <S.TagsComponentHeaderTitleValue>
              Itens de Menu
            </S.TagsComponentHeaderTitleValue>
          </S.TagsComponentHeaderTitle>
          <S.TagsComponentHeaderButtons>
            <MenuButton label="Novo Item" onClick={this.props.openDialog} />
          </S.TagsComponentHeaderButtons>
        </S.TagsComponentHeader>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.tagReducer,
    alert: state.dialog,
    erroalert: state.erroalert
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openDialog: () => {
      dispatch(change('edititemmenu', 'title', ''));
      dispatch(change('edititemmenu', 'link', ''));
      dispatch(change('edititemmenu', 'canvas_publication_id', ''));
      dispatch(DialogApi.openDialog());
    }
  };
};
const MenuHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuHeader);

export default MenuHeaderContainer;
