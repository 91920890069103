import styled from 'styled-components';
import { style as teamStyle } from '../../../../style';

const defaultFontFamily = 'Rubik, sans-serif';

export const Button = styled.button.attrs({ type: 'button' })`
  width: 162px;
  height: 40px;
  border-radius: 5px;
  color: #ffffff;
  background-color: ${(props) =>
    props.disabled
      ? 'rgb(201, 199, 199)'
      : `${teamStyle.inpuStyle.colorInput}`};
  border: none;
  cursor: pointer;
  font-size: 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const ActionsGroup = styled.div``;

export const UsersHeaderContainer = styled.section`
  margin-top: 24px;
`;

export const MainRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div``;

export const Title = styled.h2`
  font-family: ${defaultFontFamily};
  font-size: 32.3px;
  font-weight: 500;
  text-align: left;
  color: #333333;
`;

export const NewUserContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & ${Button}:first-of-type {
    width: auto;
    padding: 0 16px;
  }

  & ${Button} + ${Button} {
    margin-left: 15px;
  }
`;

export const SecondaryRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & > div + div {
    margin-left: 32px;
  }
`;

export const SearchContainer = styled.div``;

export const DropdownGroup = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:nth-of-type(2) {
    margin-left: 16px;
  }
`;

export const TertiaryRow = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`;

export const ClearFilterButton = styled.div`
  position: relative;
  min-width: 130px;
  text-align: center;

  & + & {
    margin-left: 12px;
  }
`;

export const ClearFilterlabel = styled.button`
  font-family: ${defaultFontFamily};
  font-size: 16px;
  font-weight: 500;
  color: ${teamStyle.inpuStyle.colorInput};
  text-align: center;
  border: none;
  background-color: transparent;
  appearance: none;
`;

export const Underline = styled.span`
  position: absolute;
  top: 28px;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 2px;
  opacity: 0.3;
  background-color: ${teamStyle.inpuStyle.colorInput};
  transform-origin: center;
  transform: translate(-50%, 0) scaleX(0);
  transition: all 0.3s ease;

  ${ClearFilterlabel}:hover ~ & {
    transform: translate(-50%, 0) scaleX(1);
    opacity: 1;
  }
`;
