import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import TagApi from '../../../Logicas/TagApi';
import { formValueSelector } from 'redux-form';
import CourseApi from '../../../Logicas/CourseApi';
import CourseCreateInitial from './course_initial_form/CanvasFormInitial';

class NewCourse extends Component {
  constructor() {
    super();
    this.state = { drag_area: false, tags: [] };
    this.showDragArea = this.showDragArea.bind(this);
    this.closeDragArea = this.closeDragArea.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
  }

  componentWillMount() {
    this.props.getTags();
  }
  showDragArea() {
    this.setState({ drag_area: true });
    setTimeout(() => {
      this.setState({ drag_area: false });
    }, 2000);
  }

  closeDragArea() {
    this.setState({ drag_area: false });
  }

  saveCourse(values) {
    if (values.image !== undefined) {
      this.props.createCourse(values);
    } else {
      this.props.showError();
    }
  }

  render() {
    return <CourseCreateInitial />;
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'createcourse'
})(NewCourse);

const mapDispatchToProps = (dispatch) => {
  return {
    createCourse: (values) => {
      dispatch(CourseApi.createCourse(values));
    },
    showError: () =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: 'É obrigatório adicionar uma imagem' }
      }),
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    }
  };
};
const selector = formValueSelector('createcourse');
InitializeFromStateForm = connect(
  (state) => ({
    alert: state.dialog,
    course_title: selector(state, 'title'),
    course_description: selector(state, 'description'),
    tags_course: selector(state, 'tags_course'),
    couser_score: selector(state, 'score'),
    course_duration: selector(state, 'duration'),
    img_course: selector(state, 'image'),
    level_selected: selector(state, 'level'),
    tags: state.tagsparsed
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
