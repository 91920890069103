import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Snackbar from 'material-ui/Snackbar';
import Jump from 'react-reveal/Jump';
import { reduxForm } from 'redux-form';
import { ButtonLogin } from '../utils/FormsComponents';
import { TextFieldForm } from '../utils_form/FormComponents';
import { required } from '../validation/FormValidation';
import UserApi from '../../Logicas/UserApi';
import { connect } from 'react-redux';
import DialogApi from '../../Logicas/DialogApi';
import { style } from '../../style';
import { getToken, loadReCaptcha, validateRecaptcha } from '../utils/reCaptcha';
import teamSettings from '../utils/TeamsSettings/generalSettings';
import EyeIcon from '../utils/img/IconEye.svg';
import EyeIconOff from '../utils/img/IconEyeOff.svg';

require('./login.css');

const { hasRecaptcha, reCaptchaKey } = teamSettings();

class Login extends Component {
  constructor() {
    super();
    this.state = {
      pwdFieldType: 'password',
      pwdFieldIcon: EyeIconOff
    };
  }

  componentDidMount() {
    if (hasRecaptcha) {
      loadReCaptcha(reCaptchaKey);
    }
  }

  componentWillUnmount() {
    if (hasRecaptcha) {
      window.grecaptcha.reset();
    }
  }

  loginUser = async (values) => {
    let recaptchaSuccess = false;

    const token = await getToken();
    const { success: responseSucces } = await validateRecaptcha(token);

    recaptchaSuccess = responseSucces;

    this.props.loginUser(values, recaptchaSuccess);
  };

  togglePasswordVisibility = () => {
    if (this.state.pwdFieldType === 'password')
      this.setState({ pwdFieldType: 'text', pwdFieldIcon: EyeIcon });
    else this.setState({ pwdFieldType: 'password', pwdFieldIcon: EyeIconOff });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <Jump>
        <body style={{ background: '#ffffff' }} className="container">
          <MuiThemeProvider>
            <form onSubmit={handleSubmit(this.loginUser)}>
              <div className="container-login">
                <div className="form-login">
                  <img
                    style={{
                      width: '50%',
                      objectFit: 'contain',
                      height: '50%'
                    }}
                    src={style.fileUrl.logo}
                    alt="logomarca"
                  />
                  <div>
                    <form className="inside-body-login">
                      <TextFieldForm
                        name="email_user"
                        validations={[required]}
                        id="nameuser"
                        floatingLabelText="Acesso"
                        type="text"
                      />
                      <div className="password-row">
                        <TextFieldForm
                          name="password_user"
                          type={this.state.pwdFieldType}
                          validations={[required]}
                          id="nameuser"
                          floatingLabelText="Senha"
                        />
                        <img
                          src={this.state.pwdFieldIcon}
                          className="toggle-visibility-icon"
                          onClick={this.togglePasswordVisibility}
                          alt='exibir ou ocultar senha'
                        />
                      </div>
                      {hasRecaptcha && (
                        <div
                          class="g-recaptcha"
                          id="widgetLogin"
                          data-sitekey={reCaptchaKey}
                        ></div>
                      )}
                      <ButtonLogin label="Entrar" isLoading={submitting} />
                    </form>
                  </div>
                </div>
              </div>
            </form>
            {this.props.erroalert !== undefined ? (
              <Snackbar
                open={this.props.erroalert.visible}
                message={this.props.erroalert.message}
                autoHideDuration={2000}
              />
            ) : (
              undefined
            )}
            <div />
          </MuiThemeProvider>
        </body>
      </Jump>
    );
  }
}
Login = reduxForm({
  form: 'user_session'
})(Login);

const mapStateToProps = (state) => {
  return {
    user_login: state.crudUser,
    erroalert: state.erroalert
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (value, recaptchaSuccess, resolve, reject) => {
      dispatch(UserApi.logInUser(value, recaptchaSuccess, resolve, reject));
    },
    closeDialogErro: () => {
      dispatch(DialogApi.closeDialogErro());
    }
  };
};
const LoginListContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default LoginListContainer;
