import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { TextFieldDefault, LongTextLink } from '../../../utils/FormsComponents';
import { required, validurl } from '../../../validation/FormValidation';
import IcoLinkConfirm from '../../img/ic-add-link.png';
import PostApi from '../../../../Logicas/PostApi';
import IcoExit from '../../../utils/img/exit-dialog.png';

require('./dialogcanvas.css');

class EditLinkForm extends Component {
  constructor() {
    super();
    this.state = {};
    this.saveLink = this.saveLink.bind(this);
  }

  saveLink(values) {
    this.setState({ visible: false });
    this.props.PathcLink(
      this.props.editpostform.id,
      this.props.initialValues.id,
      values
    );
    this.props.CloseDialog();
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <Modal visible={this.props.dialoglink} width="50%" height="29%">
        <form onSubmit={handleSubmit(this.saveLink)}>
          <div onClick={this.props.CloseDialog} className="body-row-exit">
            <img style={{ height: '12px', width: '12px' }} src={IcoExit} alt="fechar"/>
          </div>
          <div className="form-link-class">
            <Field
              name="title"
              rows={1}
              validate={[required]}
              id="name"
              label="Texto do botão*"
              component={TextFieldDefault}
              type="text"
            />

            <Field
              style={{ width: '70%' }}
              name="link"
              rowsMax={1}
              rows={1}
              validate={[required]}
              id="name"
              label="Endereço do link"
              component={LongTextLink}
              type="text"
            />
            <div className="form-link-btn">
              <button className="img-button-click">
                {' '}
                <img src={IcoLinkConfirm} alt="confirmar"/>{' '}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'editlink'
})(EditLinkForm);

const mapDispatchToProps = (dispatch) => {
  return {
    PathcLink: (id, idcontent, values) => {
      dispatch(PostApi.patchLink(id, idcontent, values));
    },
    CloseDialog: () => {
      dispatch({ type: 'CLOSE_DIALOG_LINK', visible: false });
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.edit_link,
    editpostform: state.editpostform,
    edit_link: state.edit_link,
    dialoglink: state.dialoglink
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
