import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { debounce } from 'lodash';
import { browserHistory } from 'react-router';
import {
  DropDownDefaultById,
  DropDownDefaultOnlyOne,
  TextFieldWithoutForm
} from '../../../utils/FormsComponents';
import { SelectForm } from '../../../utils_form/FormComponents';
import { required } from '../../../validation/FormValidation';
import SectorApi from '../../../../Logicas/SectorApi';
import TagApi from '../../../../Logicas/TagApi';
import UserApi from '../../../../Logicas/UserApi';
import DownloadPostData from '../../../_shared/DownloadPostData/DownloadPostData';
import teamSettings from '../../../utils/TeamsSettings/generalSettings';

import * as S from './UsersHeader.styles';

const { userFilter } = teamSettings();

export class UsersHeader extends Component {
  constructor() {
    super();
    this.state = {
      isTypeFilterActive: false,
      selectedFilter: '',
      searchValue: ''
    };
  }

  quickActionsMap = {
    'Enviar notificações': () => this.props.ShowDialogNotification(),
    'Pontuar usuários': () => this.props.ShowDialogScore(),
    'Atribuir tag': () => this.props.ShowDialogTag(),
    'Editar campos personalizáveis': () =>
      browserHistory.push('/user/customfields'),
    'Definir mensagem de aniversário': () =>
      this.props.showBirthdayMessageModal(),
    'Reenviar e-mail de boas vindas': () => this.props.showResendWelcomeDialog()
  };

  componentDidMount() {
    const { getSectorUser } = this.props;
    getSectorUser();
  }

  resetUsersFilter = () => {
    const { getFilterUsers } = this.props;

    getFilterUsers('Página 1');
  };

  filterUsersByName = debounce((searchValue) => {
    const { getFilterUsers } = this.props;

    getFilterUsers('', '', searchValue);
  }, 1500);

  filterUsersByGender = (searchValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, searchValue);
  };

  filterUsersByActivity = (searchValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, searchValue);
  };

  filterUsersByBirthday = (searchValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, searchValue);
  };

  filterUsersBySector = (searchValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, searchValue);
  };

  filterUserByTags = (tags) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, tags);
  };

  filterUsersByLogin = (searchValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, searchValue);
  };

  render() {
    const {
      resetUsersFilter,
      filterUsersByName,
      filterUsersByGender,
      filterUsersByBirthday,
      filterUsersBySector,
      filterUserByTags,
      filterUsersByLogin,
      filterUsersByActivity
    } = this;
    const { isTypeFilterActive, searchValue, selectedFilter } = this.state;
    const {
      sectors,
      tags,
      tags_selected,
      list_marked,
      unselectAllUsers,
      clearFilterStore
    } = this.props;
    return (
      <S.UsersHeaderContainer className="container">
        <S.MainRow>
          <S.TitleContainer>
            <S.Title>Usuários</S.Title>
          </S.TitleContainer>
          <S.NewUserContainer>
            <S.Button onClick={() => browserHistory.push('/user/create')}>
              Novo usuário
            </S.Button>
          </S.NewUserContainer>
        </S.MainRow>
        <S.SecondaryRow>
          {!isTypeFilterActive && (
            <TextFieldWithoutForm
              value={searchValue}
              onChange={(event, value) =>
                this.setState({ searchValue: value }, () =>
                  filterUsersByName(value)
                )
              }
              floatingLabelText="Busque por usuários"
            />
          )}
          <S.DropdownGroup>
            <div>
              <DropDownDefaultOnlyOne
                multiple={false}
                value={selectedFilter}
                onChange={(event, index, searchValue) =>
                  this.setState({
                    isTypeFilterActive: true,
                    selectedFilter: searchValue
                  })
                }
                array={userFilter}
                floatingLabelText={
                  isTypeFilterActive
                    ? 'Filtrando por:'
                    : 'Filtrar usuários por:'
                }
              />
            </div>
            {selectedFilter === 'Gênero' && (
              <div>
                <DropDownDefaultOnlyOne
                  multiple={false}
                  value={searchValue}
                  onChange={(event, index, searchValue) =>
                    this.setState(
                      { searchValue },
                      filterUsersByGender(searchValue)
                    )
                  }
                  array={[
                    'Mulher cis',
                    'Mulher trans',
                    'Homem cis',
                    'Homem trans',
                    'Não binário',
                    'Prefere não se identificar',
                    'Outros'
                  ]}
                  floatingLabelText="Selecione o Gênero"
                />
              </div>
            )}
            {selectedFilter === 'Mês do Aniversário' && (
              <div>
                <DropDownDefaultOnlyOne
                  multiple={false}
                  value={searchValue}
                  onChange={(event, index, searchValue) =>
                    this.setState(
                      { searchValue },
                      filterUsersByBirthday(searchValue)
                    )
                  }
                  array={[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                  ]}
                  floatingLabelText="Selecione o mês"
                />
              </div>
            )}
            {selectedFilter === 'Setor' && (
              <div>
                <DropDownDefaultById
                  multiple={false}
                  value={searchValue}
                  onChange={(event, index, searchValue) =>
                    this.setState(
                      { searchValue },
                      filterUsersBySector(searchValue)
                    )
                  }
                  array={sectors}
                  floatingLabelText="Selecione o setor"
                />
              </div>
            )}
            {selectedFilter === 'Tags' && (
              <div style={{ width: '255px' }}>
                <SelectForm
                  onChange={(event, tags) => {
                    filterUserByTags(tags);
                  }}
                  elements_selected={tags_selected}
                  multiple={true}
                  fullWidth={true}
                  validations={[required]}
                  elements={tags}
                  name="tags"
                  floatingLabel="Segmentação"
                  label="Defina as tags dos convidados"
                />
              </div>
            )}
            {selectedFilter === 'Login' && (
              <div style={{ width: '255px' }}>
                <DropDownDefaultOnlyOne
                  multiple={false}
                  value={searchValue}
                  onChange={(event, index, searchValue) =>
                    this.setState(
                      { searchValue },
                      filterUsersByLogin(searchValue)
                    )
                  }
                  array={['Já realizaram login', 'Não realizaram login']}
                  floatingLabelText="Status de Login"
                />
              </div>
            )}
            {selectedFilter === 'Atividade' && (
              <div>
                <DropDownDefaultOnlyOne
                  multiple={false}
                  value={searchValue}
                  onChange={(event, index, searchValue) =>
                    this.setState(
                      { searchValue },
                      filterUsersByActivity(searchValue)
                    )
                  }
                  array={[
                    'Consumidor',
                    'Pintor',
                    'Quero me formar pintor',
                    'Balconista',
                    'Arquiteto / Decorador',
                  ]}
                  floatingLabelText="Selecione a Atividade"
                />
              </div>
            )}
          </S.DropdownGroup>
          <S.ActionsGroup>
            <DropDownDefaultOnlyOne
              multiple={false}
              value={selectedFilter}
              onChange={(event, index, actionValue) =>
                this.quickActionsMap[actionValue]()
              }
              array={Object.keys(this.quickActionsMap)}
              floatingLabelText={'Ações rápidas'}
            />
          </S.ActionsGroup>
        </S.SecondaryRow>
        <S.TertiaryRow>
          {isTypeFilterActive && (
            <S.ClearFilterButton>
              <S.ClearFilterlabel
                onClick={() =>
                  this.setState(
                    {
                      isTypeFilterActive: false,
                      selectedFilter: '',
                      searchValue: ''
                    },
                    () => [resetUsersFilter(), clearFilterStore()]
                  )
                }
              >
                Limpar filtros
              </S.ClearFilterlabel>
              <S.Underline />
            </S.ClearFilterButton>
          )}
          {!!list_marked.length && (
            <S.ClearFilterButton>
              <S.ClearFilterlabel onClick={() => unselectAllUsers()}>
                Limpar usuários
              </S.ClearFilterlabel>
              <S.Underline />
            </S.ClearFilterButton>
          )}
          <DownloadPostData kind="user"/>
        </S.TertiaryRow>
      </S.UsersHeaderContainer>
    );
  }
}

UsersHeader = reduxForm({ form: 'UsersHeader' })(UsersHeader);
const selector = formValueSelector('UsersHeader');

const mapDispatchToProps = (dispatch) => {
  return {
    getFilterUsers: (filter_selectd, value_filter, name_user) => {
      dispatch(UserApi.getFilterUsers(filter_selectd, value_filter, name_user));
    },
    getUserPage: (id) => {
      dispatch(UserApi.getUserPage(id));
    },
    ShowDialogScore: () => {
      dispatch({ type: 'OPEN_DIALOG_SCORE', alert: true });
    },
    ShowDialogNotification: () => {
      dispatch({ type: 'OPEN_DIALOG_NOTIFICATION_USERS', alert: true });
    },
    getSectorUser: () => {
      dispatch(SectorApi.getSectorUser());
    },
    clearFilterStore: () => {
      dispatch({ type: 'USER_FILTER_CLEAR' });
    },
    ShowDialogTag: () => {
      dispatch({ type: 'SHOW_MODAL_INSERT_TAG' });
    },
    getTags: () => dispatch(TagApi.getTagsUser()),
    unselectAllUsers: () => {
      dispatch({
        type: 'USER_SELECTED_NONE'
      });
    },
    showBirthdayMessageModal: () => {
      dispatch({
        type: 'BIRTHDAY_MESSAGE_MODAL_SHOW'
      });
    },
    showResendWelcomeDialog: () => {
      dispatch({ type: 'SHOW_RESEND_WELCOME' });
    }
  };
};
const UsersHeaderContainer = connect(
  (state) => ({
    list_marked: state.user.selectedUsers.users,
    sectors: state.sectorReducer.sectorList,
    tags: state.tagsparsed,
    tags_selected: selector(state, 'tags')
  }),
  mapDispatchToProps
)(UsersHeader);
export default UsersHeaderContainer;
