import React, { Component } from 'react';
import IcoPontos from '../utils/img/ic-pontos.svg';
import RemoveItemIco from '../utils/img/ic-excluir.svg';
import EditItemIco from '../utils/img/ic-editar.svg';
require('./cardproduct.css');

class CardItem extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div className="product-card-body">
        <div onClick={this.props.onClickEdit}>
          <div className="card-product-header">
            <img
              className="image-product-card"
              src={this.props.productimage}
              alt="imagem do produto"
            />
            <label className="title-header-card-product">
              {' '}
              {this.props.producttitle.length > 25
                ? this.props.producttitle.slice(0, 22) + '...'
                : this.props.producttitle}
            </label>
            <span className="description-card-product">
              {' '}
              {this.props.productdescription.length > 25
                ? this.props.productdescription.slice(0, 25) + '...'
                : this.props.producttitle}{' '}
            </span>
          </div>
          <div className="row-product-attributes">
            <div className="row-inside-attributes">
              <img src={IcoPontos} alt="ícone pontuação" />
              <div className="label-score-product">
                {this.props.productscore}
              </div>
              <div className="label-score-product">
                {this.props.productTeamName}
              </div>
            </div>
            <label className="amout-products">
              Qtd.:<label>{this.props.productamount}</label>
            </label>
          </div>
        </div>
        <div className="row-actions-card-product">
          <div
            onClick={this.props.onClickDelete}
            className="row-btns-card-modules"
          >
            <img src={RemoveItemIco} alt="ícone excluir" />
            <label className="label-btn-card-course">Excluir</label>
          </div>
          <div
            onClick={this.props.onClickEdit}
            className="row-btns-card-modules"
          >
            <img src={EditItemIco} alt="ícone editar" />
            <label className="label-btn-card-course">Editar</label>
          </div>
        </div>
      </div>
    );
  }
}

export default CardItem;
