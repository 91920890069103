
  export const style={
    inpuStyle:{
      colorInput:"#131313"
    },
    url:{
      urlClient:"https://smartt-bot.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/smart_bot.png"
    },
    CurrencyName:{
      name:"SmarttCoin/SmarttCoins"
    },
    teamName:{
      name:"Sou SmarttBotter"
    },
    hasToken: true,
    hostHeader: "smartt-bot"
  }
