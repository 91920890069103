import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';

import { Body } from '../utils/BodyComponente';
import { required } from '../validation/FormValidation';
import ComponentHeader from '../utils/ComponenteHeader';
import * as ApplicationApi from '../../Logicas/ApplicationApi';

import {
  TextFieldForm,
  LongTextFieldTitle,
  DropZoneImageApp
} from '../utils_form/FormComponents';

require('./Application.css');

class FileCreate extends Component {
  constructor() {
    super();
    this.state = {};
    this.patchApp = this.patchApp.bind(this);
  }

  patchApp(values) {
    this.props.patchAppEdit(values);
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    if (Object.keys(this.props.initialValues).length !== 0) {
      return (
        <form onSubmit={handleSubmit((values) => this.patchApp({ ...values }))}>
          <ComponentHeader
            disabled={submitting}
            tooltip={
              <p>
                Atualizar aplicativo
                <br />
              </p>
            }
            actions={[]}
            name="Editar aplicativo"
            nameaction="Salvar"
            onClick={handleSubmit((values) => this.patchApp({ ...values }))}
          />
          <Body>
            <div className="row">
              <div className="col-md-8">
                <LongTextFieldTitle
                  name="name"
                  validations={[required]}
                  id="name"
                  label="Nome do aplicativo*"
                  type="text"
                />
                <div style={{ marginBottom: '150px' }} className="row">
                  <div className="col-md-6">
                    <TextFieldForm
                      fullWidth={true}
                      name="url"
                      rows={1}
                      validations={[required]}
                      id="name"
                      floatingLabelText="Digite ou cole o link do aplicativo*"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="image-row">
                <Field
                  name="image"
                  disableClick={false}
                  component={DropZoneImageApp}
                />
              </div>
            </div>
          </Body>
        </form>
      );
    } else {
      browserHistory.push('/app/list');
      return null;
    }
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'app_edit'
})(FileCreate);

const mapDispatchToProps = (dispatch) => {
  return {
    patchAppEdit: (values) => {
      dispatch(ApplicationApi.patchAppEdit(values));
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.application.app_edit
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
