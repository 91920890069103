import React, { Component } from 'react';
import { DialogProgressIndeterminated } from '../../../../utils/DailogComponentes';
import {
  TextAreaForm,
  ToogleForm
} from '../../../../utils_form/FormComponents';
import { required } from '../../../../validation/FormValidation';

import IcoRemove from '../../../../utils/img/ic-excluir.svg';
import CardContentQuiz from '../../../../utils/img/card-content-quiz.svg';

import '../CourseLongStyles.css';

export class QuizModule extends Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: this.props.loading.loadingCourse });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      loading: nextProps.loading.loadingCourse
    });
  }

  render() {
    return (
      <div className="container-content-submodule">
        <div className="loading-content">
          {this.props.loading.loadingCourse &&
            this.props.loading.indexContent === this.props.content.id && (
              <DialogProgressIndeterminated />
            )}
        </div>
        <div className="container-content-submodule-quiz">
          <div className="header-content-submodule">
            <div className="header-title-img">
              <img src={CardContentQuiz} alt="Componente de Quiz" />
              <div className="header-content-description">
                <p>CARD QUIZ</p>
                <p>{this.props.content.question_text}</p>
              </div>
            </div>
            <div className="removed-submodule">
              <img
                src={IcoRemove}
                alt="Remover Conteudo"
                onClick={() =>
                  this.props.removedSubModuleAndContent(
                    this.props.content.id,
                    'content'
                  )
                }
              />
            </div>
          </div>
          <div className="container-title-quiz">
            <TextAreaForm
              type="text"
              rowsMax={10}
              multiLine={false}
              validations={[required]}
              onChange={(event) =>
                this.props.patchTextContentAnswers(
                  event.target.value,
                  this.props.index,
                  this.props.indexContent
                )
              }
              value={this.props.content.question_text}
              floatingLabelText="Pergunta"
            />
            <p className="view-characters">
              {this.props.content.question_text &&
                this.props.content.question_text.length <= 255 &&
                `${255 -
                  this.props.content.question_text
                    .length} caracteres restantes.`}
            </p>
            <div
              className={
                this.state.loading
                  ? 'loading loading-container'
                  : 'loading-container'
              }
              style={{
                transform: 'translateY(-65px)',
                width: '480px'
              }}
            ></div>
          </div>
          {this.props.content.answers &&
            this.props.content.answers.map((answersContent, indexAnswers) => {
              return (
                <div
                  className="container-answers-quiz"
                  ref={this.answerRef}
                  key={indexAnswers}
                >
                  <div className="text-answers-quiz">
                    <TextAreaForm
                      type="text"
                      rowsMax={10}
                      validations={[required]}
                      onChange={(event) =>
                        this.props.patchTextContentAnswers(
                          event.target.value,
                          this.props.index,
                          this.props.indexContent,
                          indexAnswers
                        )
                      }
                      value={answersContent.answer_text}
                      floatingLabelText="Alternativa"
                    />
                    <p className="view-characters">
                      {answersContent.answer_text &&
                        answersContent.answer_text.length <= 255 &&
                        `${255 -
                          answersContent.answer_text
                            .length} caracteres restantes.`}
                    </p>
                    <div
                      className={
                        this.state.loading
                          ? 'loading loading-container'
                          : 'loading-container'
                      }
                    ></div>
                  </div>
                  <ToogleForm
                    disabled={answersContent.is_correct}
                    value={answersContent.is_correct}
                    onChange={(event, value) =>
                      this.props.chooseAnswersCorrect(
                        value,
                        this.props.index,
                        this.props.indexContent,
                        indexAnswers
                      )
                    }
                  />
                  <div className="remove-answer">
                    <img
                      src={IcoRemove}
                      alt="Remover Conteudo"
                      onClick={() =>
                        this.props.removeQuizAnswer(
                          this.props.index,
                          indexAnswers
                        )
                      }
                    />
                  </div>
                </div>
              );
            })}
          <div className="add__answer__new--container">
            <span className="add__sign">&#x2B;</span>
            <button
              type="button"
              className="add__answer__new"
              onClick={() => this.props.addQuizAnswer(this.props.index)}
            >
              Adicionar resposta
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default QuizModule;
