import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { v1 as uuidv1 } from 'uuid';
import ComponentHeader from '../utils/ComponenteHeader';
import { Body } from '../utils/BodyComponente';
import TagApi from '../../Logicas/TagApi';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { CheckboxDefault } from '../utils/FormsComponents';
import {
  Table,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import MergeSelectInput from './Components/MergeSelectInput/MergeSelectInput';

require('./tag.css');

class TagMerge extends Component {
  constructor() {
    super();
    this.state = {
      removeTag: false,
      tagMerge: ''
    };
  }

  componentWillMount() {
    const { initialValues } = this.props;
    if (!Object.keys(initialValues).length) {
      browserHistory.push('/tags/list');
      return null;
    }
  }

  componentDidMount() {
    this.props.getTags(true);
  }

  render() {
    if (this.props.initialValues !== undefined) {
      return (
        <div>
          <ComponentHeader
            actions={[]}
            name="Unir tags"
            nameaction="Salvar"
            onClick={this.props.mergeTagInOne.bind(
              this,
              this.props.initialValues,
              this.state.tagMerge,
              this.state.removeTag
            )}
          />
          <Body>
            <div className="row">
              <div className="col-md-2 col-md-offset-1"></div>
              <div className="col-md-offset-2 col-md-4">
                <MergeSelectInput
                  disabled={true}
                  floatingLabelText="Unir a seguinte tag"
                  initialValue={this.props.initialValues.name}
                  fullWidth={true}
                  options={[this.props.initialValues]}
                />
              </div>
              <div className="div-vertical-tags"></div>
              <div className="col-md-offset-2 col-md-4">
                <MergeSelectInput
                  floatingLabelText="Com a seguinte tag"
                  onChange={(event, index, values) =>
                    this.setState({ tagMerge: values }, () => {
                      this.props.getUsersAssociation(values);
                    })
                  }
                  fullWidth={true}
                  options={this.props.tags}
                />
              </div>
              <div className="col-md-3 col-md-offset-2"></div>
            </div>
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8 merge-tags__check-box-label">
                <CheckboxDefault
                  checked={this.state.removeTag}
                  onCheck={() =>
                    this.setState({ removeTag: !this.state.removeTag })
                  }
                />
                <div className="tag-exp">
                  Ao mesclar, desativar tag{' '}
                  <strong>{this.props.initialValues.name}</strong>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: '100px' }}>
              <div className="offset-md-1 col-md-10">
                <label className="label-tag-discleimer">
                  Lista de usuários associados ({' '}
                  {this.props.tagUserAssociation.length} ){' '}
                  <label className="edit-user-div">
                    {' '}
                    Para remover um usuário da listagem, edite sua permissao em{' '}
                  </label>{' '}
                  Editar Usuário{' '}
                </label>
              </div>
            </div>

            <Table height="200px">
              <TableBody displayRowCheckbox={false}>
                <TableRow>
                  <TableHeaderColumn>Nome</TableHeaderColumn>
                  <TableHeaderColumn>Setor</TableHeaderColumn>
                </TableRow>
                {this.props.tagUserAssociation.map((user) => (
                  <TableRow displayRowCheckbox={false} key={uuidv1()}>
                    <TableRowColumn>{user.name}</TableRowColumn>
                    <TableRowColumn>{user.sector.name}</TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Body>
        </div>
      );
    } else {
      return <div>ds</div>;
    }
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'edittag'
})(TagMerge);

const mapDispatchToProps = (dispatch) => {
  return {
    getFilterUsersTag: (value_filter, kind_filter) => {
      dispatch(TagApi.getFilterUsersTag(value_filter, kind_filter));
    },
    getUsersAssociation: (tag) => {
      dispatch(TagApi.getTagUsersAssociation(tag));
    },
    mergeTags: (tag, filter, values) => {
      dispatch(TagApi.mergeTags(tag, filter, values));
    },
    getTags: (withIds) => {
      dispatch(TagApi.getTagsUser(withIds));
    },
    mergeTagInOne: (tagold, tagnew, remove) => {
      dispatch(TagApi.mergeTagInOne(tagold, tagnew, remove));
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.tag.editTag,
    alert: state.dialog,
    list: state.user.user_list,
    tags: state.tagsparsed,
    tagUserAssociation: state.tag.tagUserAssociation
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
