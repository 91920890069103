import styled, { css } from 'styled-components';

export const SearchWrapper = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 2;
`;

export const Button = styled.button`
  position: absolute;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  pointer-events: none;
  font-size: 24px;
  top: 0;
  left: 0;
  height: 40px;
  width: 50px;
  z-index: 2;
  vertical-align: top;

  ${(props) =>
    props.isFocus &&
    css`
      transform: translateX(350px);
    `};

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input.attrs({ type: 'text' })`
  width: ${(props) => (props.isFocus ? '400px' : '50px')};
  height: 40px;
  border: 0;
  padding: 5px;
  background-color: #fff;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  z-index: 2;
  vertical-align: top;

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 0.66;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  height: ${(props) => (props.isFocus ? '280px' : '40px')};
  transform: ${(props) => props.isFocus && 'translateY(120px)'};
  display: flex;
  flex-direction: row-reverse;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  &::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
  }

  &:hover::after,
  &:focus-within::after {
    opacity: 1;
  }

  &:hover ${Input} {
    width: 400px;
  }

  &:hover ${Button} {
    transform: translateX(350px);
  }
`;

export const TagsWrapper = styled.div`
  width: 400px;
  padding-top: 16px;
  position: absolute;
  height: 100%;
  max-height: 240px;
  background-color: #fff;
  transform: translateY(40px);
  z-index: 2;
  visibility: ${(props) => (props.isFocus ? 'visible' : 'hidden')};
`;

export const TagsList = styled.ul`
  height: 100%;
  list-style-type: none;
  padding-inline-start: 0;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
`;

export const TagsListItem = styled.li`
  width: 100%;
  height: 40px;
  padding: 0 16px 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13.7px;
  color: rgb(128, 128, 128);
  font-family: 'Rubik', sans-serif;
  background-color: #fff;
  z-index: 2;
`;

export const TagName = styled.span``;

export const TagEdit = styled.img`
  z-index: 2;

  &:hover {
    cursor: pointer;
  }
`;

export const TagsOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  visibility: ${(props) => (props.isFocus ? 'visible' : 'hidden')};
`;

export const NoResults = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoResultsText = styled.span`
  font-size: 16px;
  color: rgb(128, 128, 128);
  font-family: 'Rubik', sans-serif;
`;
