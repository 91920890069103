import React, { Component } from 'react';
import CheckIcon from './Icons/CheckIcon';

import * as S from './styles';

export class SelectTags extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
  }

  selectTags = (tagId) => {
    let selectedTags = this.props.selectedTags;

    const selectedTag = this.props.availableTags.find(
      (selectedTag) => selectedTag.id === tagId
    );

    const alreadySelected = selectedTags.find(
      (tag) => selectedTag.id === tag.id
    );

    if (alreadySelected) {
      selectedTags = this.props.selectedTags.filter(
        (tag) => tag.id !== selectedTag.id
      );
    } else {
      selectedTags = [...selectedTags, selectedTag];
    }

    this.props.updateSelectedTags(selectedTags);
  };

  render() {
    return (
      <>
        <S.SelectWrapper
          onClick={() => this.setState({ open: true })}
          open={this.state.open}
        >
          <S.Description>
            Clique para selecionar tags de participantes
          </S.Description>
          <S.SelectBody onChange={() => this.props.updateSelectedTags}>
            {this.state.open && (
              <>
                {this.props.availableTags.map((tag) => {
                  return (
                    <S.OptionBody
                      key={tag.id}
                      onClick={() => this.selectTags(tag.id)}
                    >
                      {this.props.selectedTags.find(
                        (selectedTag) => selectedTag.id === tag.id
                      ) && <CheckIcon />}{' '}
                      <S.TagName>{tag.name}</S.TagName>
                    </S.OptionBody>
                  );
                })}
              </>
            )}
          </S.SelectBody>
        </S.SelectWrapper>
        <S.SelectTagsOverlay
          onClick={() => this.setState({ open: false })}
          open={this.state.open}
        />
      </>
    );
  }
}

export default SelectTags;
