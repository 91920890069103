import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from 'material-ui/styles';
import { ToogleForm, TextForm } from '../../utils_form/FormComponents';
import ContentApi from '../../../Logicas/ContentApi';
import DialogApi from '../../../Logicas/DialogApi';
import IconRemove from '../../utils/img/ic-excluir.svg';
import ReactLoading from 'react-loading';
import PubSub from 'pubsub-js';
require('./contentbody.css');

class CreateContentQuiz extends Component {
  constructor() {
    super();
    this.state = { edit: false, render: true, saving: false };
    this.resetEdit = this.resetEdit.bind(this);
    this.resetEdit = this.resetEdit.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
    this.saveText = this.saveText.bind(this);
    this.validForm = this.validForm.bind(this);
  }

  componentDidMount() {
    if (this.props.id_edit !== undefined) {
      this.setState({
        question: this.props.question,
        answer1: this.props.answers[0].iscorrect,
        answertext1: this.props.answers[0].answerstext,

        answertext2: this.props.answers[1].answerstext,
        answer2: this.props.answers[1].iscorrect,

        answertext3: this.props.answers[2].answerstext,
        answer3: this.props.answers[2].iscorrect,

        answertext4: this.props.answers[3].answerstext,
        answer4: this.props.answers[3].iscorrect,
        score: this.props.score
      });
    }
  }

  resetEdit() {
    if (this.props.id_edit === undefined) {
      PubSub.publish('clear_new_elements', true);
    } else {
      this.setState({ title_text: this.props.text_name });
      this.setState({ text: this.props.text });
    }
  }

  saveText() {
    if (this.validForm()) {
      this.setState({ edit: false });
      if (this.props.id_edit === undefined) {
        this.setState({ saving: true });
        this.props.saveContent(
          this.state,
          this.props.module_id,
          this.props.course_id
        );
      } else {
        this.setState({ saving: true });

        this.props.patchContent(
          this.state,
          this.props.id_edit,
          this.props.course_id
        );
      }
      this.props.showLoad(this.props.id_edit);
    } else {
      this.props.showErroValidation('Questão inválida, verifique os campos');
    }
  }

  deleteCard() {
    this.props.deleteContent(this.props.id_edit, this.props.course_id);
  }

  validForm() {
    if (
      this.state.question !== undefined &&
      this.state.question !== '' &&
      this.state.answertext1 !== undefined && this.state.question !== '' &&
      this.state.answertext2 !== undefined && this.state.question !== '' &&
      this.state.answertext3 !== undefined && this.state.question !== '' &&
      this.state.answer4 !== undefined && this.state.question !== '' &&
      this.state.score !== undefined && this.state.score > -1 &&
      (this.state.answer1 ||
        this.state.answer2 ||
        this.state.answer3 ||
        this.state.answer4)
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    if (this.state.render) {
      return (
        <MuiThemeProvider>
          <form className="content-body-display">
            {this.props.load_content_requisition.visible === false ||
            this.props.load_content_requisition.id !== this.props.id_edit ? (
              <React.Fragment>
                <div className="actions-card-course">
                  {this.state.edit === true ||
                  this.props.id_edit === undefined ? (
                    <React.Fragment>
                      <label onClick={this.saveText} className="label-actions">
                        Salvar
                      </label>
                      <label onClick={this.resetEdit} className="label-actions">
                        Cancelar
                      </label>
                    </React.Fragment>
                  ) : (
                    <label
                      onClick={() =>
                        this.setState({
                          text: '',
                          title_text: null,
                          edit: true
                        })
                      }
                      className="label-actions"
                    >
                      Editar
                    </label>
                  )}
                  {this.state.edit === true ? (
                    <img
                      onClick={this.deleteCard}
                      className="img-card-course"
                      src={IconRemove}
                      alt="remover"
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <TextForm
                  onChange={(event) =>
                    event.target.value.length > 50
                      ? this.setState({
                          errorquestion: 'Limite de caracteres atingido'
                        })
                      : this.setState({
                          question: event.target.value,
                          errorquestion: ''
                        })
                  }
                  errorText={this.state.errorquestion}
                  value={this.state.question}
                  hintText="Pergunta"
                />
                <div>
                  <TextForm
                    onChange={(event) =>
                      event.target.value.length > 50
                        ? this.setState({
                            errora: 'Limite de caracteres atingido'
                          })
                        : this.setState({
                            answertext1: event.target.value,
                            errora: ''
                          })
                    }
                    errorText={this.state.errora}
                    value={this.state.answertext1}
                    hintText="a)"
                  />
                  <ToogleForm
                    disabled={this.state.answer1disabled}
                    value={this.state.answer1}
                    onChange={(event, value) => {
                      this.setState({
                        answer1: value,
                        answer2: value === true ? false : null,
                        answer3: value === true ? false : null,
                        answer4: value === true ? false : null,
                        answer2disabled: value,
                        answer3disabled: value,
                        answer4disabled: value
                      });
                    }}
                  />
                </div>
                <div>
                  <TextForm
                    onChange={(event) =>
                      event.target.value.length > 50
                        ? this.setState({
                            errorb: 'Limite de caracteres atingido'
                          })
                        : this.setState({
                            answertext2: event.target.value,
                            errorb: ''
                          })
                    }
                    value={this.state.answertext2}
                    errorText={this.state.errorb}
                    hintText="b)"
                  />
                  <ToogleForm
                    disabled={this.state.answer2disabled}
                    value={this.state.answer2}
                    onChange={(event, value) => {
                      this.setState({
                        answer1: value === true ? false : null,
                        answer2: value,
                        answer3: value === true ? false : null,
                        answer4: value === true ? false : null,
                        answer1disabled: value,
                        answer3disabled: value,
                        answer4disabled: value
                      });
                    }}
                  />
                </div>
                <div>
                  <TextForm
                    onChange={(event) =>
                      event.target.value.length > 50
                        ? this.setState({
                            errorc: 'Limite de caracteres atingido'
                          })
                        : this.setState({
                            answertext3: event.target.value,
                            errorc: ''
                          })
                    }
                    value={this.state.answertext3}
                    errorText={this.state.errorc}
                    hintText="c)"
                  />
                  <ToogleForm
                    disabled={this.state.answer3disabled}
                    value={this.state.answer3}
                    onChange={(event, value) => {
                      this.setState({
                        answer1: value === true ? false : null,
                        answer2: value === true ? false : null,
                        answer3: value,
                        answer4: value === true ? false : null,
                        answer1disabled: value,
                        answer2disabled: value,
                        answer4disabled: value
                      });
                    }}
                  />
                </div>
                <div>
                  <TextForm
                    onChange={(event) =>
                      event.target.value.length > 50
                        ? this.setState({
                            errord: 'Limite de caracteres atingido'
                          })
                        : this.setState({
                            answertext4: event.target.value,
                            errord: ''
                          })
                    }
                    value={this.state.answertext4}
                    hintText="d)"
                    errorText={this.state.errord}
                  />
                  <ToogleForm
                    disabled={this.state.answer4disabled}
                    value={this.state.answer4}
                    onChange={(event, value) => {
                      this.setState({
                        answer1: value === true ? false : null,
                        answer2: value === true ? false : null,
                        answer3: value === true ? false : null,
                        answer4: value,
                        answer1disabled: value,
                        answer2disabled: value,
                        answer3disabled: value
                      });
                    }}
                  />
                  <TextForm
                    onChange={(event) =>
                      this.setState({ score: event.target.value })
                    }
                    value={this.state.score}
                    hintText="Pontuação"
                    type="number"
                  />
                </div>
              </React.Fragment>
            ) : (
              <div className="display-load">
                <div className="display-load">
                  <label className="save-label">Salvando</label>
                  <ReactLoading
                    type="balls"
                    color="#c3c3c3"
                    height={'10%'}
                    width={'10%'}
                  />
                </div>
              </div>
            )}
          </form>
        </MuiThemeProvider>
      );
    } else {
      return (
        <React.Fragment>
          <div />
        </React.Fragment>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveContent: (values, idmodule, idcourse) => {
      dispatch(ContentApi.saveContentQuiz(values, idmodule, idcourse));
    },
    patchContent: (values, idmodule, idcontent, idcourse, indexAnswers) => {
      dispatch(
        ContentApi.PatchContentQuiz(
          values,
          idcontent,
          idmodule,
          idcourse,
          indexAnswers
        )
      );
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    },
    deleteShowDialogUser: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    deleteContent: (values, idmodule) => {
      dispatch(ContentApi.deleteContent(values, idmodule));
    },
    showLoad: (id) => {
      dispatch({ type: 'SUBMITTING_CONTENT', id: id });
    },
    showErroValidation: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: message
        }
      })
  };
};

const mapStateToProps = (state) => {
  return {
    load_content_requisition: state.load_content_requisition
  };
};

const CreateContentQuizContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContentQuiz);

export default CreateContentQuizContainer;
