import React, { Component } from 'react';
import CardCouse from './CardCourse';
import Slide from 'react-reveal/Slide';

require('./courselistcard.css');

export default class CourseListCards extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const teamNames = this.props.cards[0].teamName.split('/');

    return (
      <React.Fragment>
        <div className="row-card-corses-list">
          {Array.isArray(this.props.cards) ? (
            this.props.cards.map(
              function(course, index) {
                return (
                  <CardCouse
                    index={course.id}
                    typeCourse={course.kind}
                    indexmodule={course.title + index}
                    deleteCourse={this.props.deleteCourse}
                    modules={course.module_courses}
                    courseduration={course.duration}
                    courseimage={course.image.url}
                    description={course.description}
                    coursename={course.title}
                    coursescore={course.score}
                    teamName={course.score > 1 ? teamNames[1] : teamNames[0]}
                    tags={course.tags}
                    avaiable={course.available}
                    changeAvaiableCourse={this.props.changeAvaiableCourse}
                    editCourse={this.props.editCourse}
                  />
                );
              }.bind(this)
            )
          ) : (
            <React.Fragment />
          )}
        </div>
      </React.Fragment>
    );
  }
}
