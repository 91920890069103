import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PostApi from '../../../../Logicas/PostApi';
import DialogEditMidia from '../dialog/DialogEditMidia';
import CircularProgress from 'material-ui/CircularProgress';

require('./tooltip.css');

class ToolTipImage extends Component {
  constructor() {
    super();
    this.state = { class: 'btn-edit-image' };
    this.EditImage = this.EditImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }
  EditImage() {
    this.setState({ enable_edit: true });
  }

  removeImage() {
    const elements = this.props.elements;
    const newElements = elements.filter(
      (element) => element.id !== Number(this.props.id.split('_')[1])
    );

    this.props.setComponentsToState(newElements);

    this.props.RemoveImage(this.props.id_canvas, this.props.id.split('_')[1]);
  }
  render() {
    return (
      <div>
        <div
          className={
            this.props.showbtn ? 'btn-edit-image-show' : 'btn-edit-image'
          }
        >
          <div className="container-tooltip">
            <label
              onClick={this.props.showDialogUploadFile.bind(
                this,
                'image',
                this.props.id.split('_')[1]
              )}
              className="label-btn-imag"
            >
              Substituir
            </label>
            {this.props.load_status_delete === true ? (
              <CircularProgress
                style={{ marginLeft: '-20px' }}
                color="#444444"
                size={30}
                thickness={7}
              />
            ) : (
              <label
                onClick={
                  (this.props.RemoveImage.bind(
                    this,
                    this.props.id_canvas,
                    this.props.id.split('_')[1],
                    this.props.index
                  ),
                  () => this.removeImage())
                }
                className="label-btn-imag"
              >
                Remover
              </label>
            )}
          </div>
        </div>
        <DialogEditMidia
          idcanvas={this.props.id_canvas}
          id={this.props.id.split('_')[1]}
          visible={true}
        />
      </div>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'tooltipimage'
})(ToolTipImage);

const mapDispatchToProps = (dispatch) => {
  return {
    EditImagemForm: (id, idcontent) => {
      dispatch(PostApi.PatchContentImage(id, idcontent));
    },
    showDialogUploadFile: (kind, id) => {
      dispatch({
        type: 'SHOW_DIALOG_FILE_MIDIA',
        alert: { visible: true, kind: kind, id: id }
      });
    },
    RemoveImage: (idcanvas, idimage, index) => {
      dispatch(PostApi.DeleteContentImage(idcanvas, idimage, index));
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    creatpost: state.createpost,
    initialValues: state.createpost,
    dialoglink: state.dialogeditmidia,
    load_status_delete: state.load_status_delete
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
