import styled from "styled-components";

export const ContainerSearch = styled.div`
  width: 100%;
  height: 300px;

  display: flex;
  align-items: start;
  justify-content: center;
`

export const ContentSearch = styled.div`
  width: 60%;
`

export const HeaderTable = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #E4E4E5;
  padding: 5px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  >span{
    padding: 0 10px 0;
    font-family: Rubik;
    font-size: 18px;
    color: #4B4E5C;
  }
`

export const BodyTable = styled.div`
  width: 100%;
`

export const ContainerType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .header-title{
    margin-left: 10px;
    margin-top: 25px;
    margin-bottom: 10px;

    display: flex;
    align-items: center;

    span{
      margin-left: 7px;
      font-family: Rubik;
      font-size: 16px;
      color: #4B4E5C;
    }
  }

  .results-not-found{
    font-family: Rubik;
    padding-left: 15px;
    font-size: 14px;
    color: black;
  }
  
`

export const MiniCardCourse = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  cursor: pointer;

  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover{
    background-color: #E4E4E5;
  }

`

export const ContainerImageMiniCard = styled.div`
  width: 10%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  >.image-not-active{
    filter: grayscale(100%);
  }

`

export const ImageMiniCard = styled.img`
  object-fit: cover;
  max-height: 80%;
  max-width: 100%;
`

export const ContainerTexts = styled.div`
  width: 85%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-left: 10px;
  font-family: Rubik;

  span{
    font-size: 11px;
    color: #808080;
  }

`

export const ContainerStatus = styled.div`
  width: 5%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`