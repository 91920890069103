import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { generateUserReport } from '../utils/historyReportGenerator';
import { style } from '../../style';
import './historyStyle.css';

import HistoricalApi from '../../Logicas/HistoricalApi';

import { BodyTable } from '../utils/BodyTable';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableData,
  TableHeadRow
} from '../utils/Table';

import IcoPontos from '../utils/img/ic-pontos.svg';
import not_img from '../utils/img/ico-external.png';

class UserDetails extends Component {
  render() {
    const teamName = style.CurrencyName.name.split('/');
    const { userDetails } = this.props;
    return (
      <div>
        <div className="container">
          <div className="header-child-title">
            <div className="title-historical">
              <p>Histórico de {teamName[1]}</p>
            </div>
          </div>
          <div className="header-section-list">
            <div className="header-title-user-history">
              <img
                className="img-user-history"
                src={userDetails.image ? userDetails.image : not_img}
                alt="Perfil usuario"
              />
              <p className="title-name-user-history">{userDetails.name}</p>
            </div>
            <div className="header-full-score">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() =>
                  this.props.getUserDetailsHistory(
                    userDetails.id,
                    userDetails.name,
                    userDetails.image
                  )
                }
              >
                <path
                  d="M19 8L15 12H18C18 15.31 15.31 18 12 18C10.99 18 10.03 17.75 9.2 17.3L7.74 18.76C8.97 19.54 10.43 20 12 20C16.42 20 20 16.42 20 12H23L19 8ZM6 12C6 8.69 8.69 6 12 6C13.01 6 13.97 6.25 14.8 6.7L16.26 5.24C15.03 4.46 13.57 4 12 4C7.58 4 4 7.58 4 12H1L5 16L9 12H6Z"
                  fill={style.inpuStyle.colorInput}
                />
              </svg>
              <p>{teamName[1]} Totais</p>
              <p>
                {userDetails.score}{' '}
                {userDetails.score > 0 ? teamName[1] : teamName[0]}
              </p>
              <img src={IcoPontos} alt="moeda de pontos" />
              <button
                className="download-report-btn"
                onClick={() => {
                  generateUserReport(userDetails);
                }}
                style={{ backgroundColor: style.inpuStyle.colorInput }}
              >
                <div className="download-report-btn-txt">Baixar histórico</div>
              </button>
            </div>
          </div>
        </div>
        <BodyTable>
          <Table>
            <TableHead>
              <TableHeadRow>Título da pontuação</TableHeadRow>
              <TableHeadRow>Pontuação</TableHeadRow>
              <TableHeadRow>Motivo de pontuação</TableHeadRow>
              <TableHeadRow>Data da pontuação</TableHeadRow>
            </TableHead>

            <TableBody>
              {userDetails.DetailsHistory &&
                userDetails.DetailsHistory.map((detailsUser, index) => (
                  <TableRow className="content-list-history" key={index}>
                    <TableData>
                      <div className="content-user-details-history">
                        {detailsUser.kind === 'gift' ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.25 12.6129V20.1129C3.25 20.8029 3.81125 21.3629 4.5 21.3629H10.75V12.6129H3.25Z"
                              fill={style.inpuStyle.colorInput}
                            />
                            <path
                              d="M20.75 7.61302H16.795C17.0788 7.41927 17.3225 7.22677 17.4925 7.05427C18.5012 6.04052 18.5012 4.39052 17.4925 3.37677C16.5125 2.38927 14.805 2.39177 13.8263 3.37677C13.2838 3.92052 11.8463 6.13427 12.045 7.61302H11.955C12.1525 6.13427 10.715 3.92052 10.1737 3.37677C9.19375 2.39177 7.48625 2.39177 6.5075 3.37677C5.5 4.39052 5.5 6.04052 6.50625 7.05427C6.6775 7.22677 6.92125 7.41927 7.205 7.61302H3.25C2.56125 7.61302 2 8.17427 2 8.86302V10.738C2 11.083 2.28 11.363 2.625 11.363H10.75V8.86302H13.25V11.363H21.375C21.72 11.363 22 11.083 22 10.738V8.86302C22 8.17427 21.44 7.61302 20.75 7.61302ZM10.6763 7.57177C10.6763 7.57177 10.6237 7.61302 10.445 7.61302C9.58125 7.61302 7.9325 6.71552 7.39375 6.17302C6.87 5.64552 6.87 4.78552 7.39375 4.25802C7.6475 4.00302 7.98375 3.86302 8.34125 3.86302C8.6975 3.86302 9.03375 4.00302 9.2875 4.25802C10.13 5.10552 10.9675 7.27052 10.6763 7.57177ZM13.5538 7.61302C13.3763 7.61302 13.3237 7.57302 13.3237 7.57177C13.0325 7.27052 13.87 5.10552 14.7125 4.25802C15.2163 3.74927 16.0975 3.74677 16.6063 4.25802C17.1313 4.78552 17.1313 5.64552 16.6063 6.17302C16.0675 6.71552 14.4187 7.61302 13.5538 7.61302Z"
                              fill={style.inpuStyle.colorInput}
                            />
                            <path
                              d="M13.25 12.6129V21.3629H19.5C20.19 21.3629 20.75 20.8029 20.75 20.1129V12.6129H13.25Z"
                              fill={style.inpuStyle.colorInput}
                            />
                          </svg>
                        ) : detailsUser.kind === 'purchase' ? (
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.01231 10C8.01231 4.24 7.56923 1 12 1C16.4308 1 15.9877 4.24 15.9877 10"
                              stroke={style.inpuStyle.colorInput}
                              stroke-width="2"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.60442 10.4783C3.75787 9.62274 4.50223 9 5.37146 9H18.6285C19.4977 9 20.2421 9.62274 20.3956 10.4783L22.6212 22.8879C22.8186 23.9886 21.9725 25 20.8541 25H3.14583C2.02753 25 1.18138 23.9886 1.3788 22.8879L3.60442 10.4783ZM8.49999 16C9.32842 16 9.99999 15.3284 9.99999 14.5C9.99999 13.6716 9.32842 13 8.49999 13C7.67156 13 6.99999 13.6716 6.99999 14.5C6.99999 15.3284 7.67156 16 8.49999 16ZM17 14.5C17 15.3284 16.3284 16 15.5 16C14.6716 16 14 15.3284 14 14.5C14 13.6716 14.6716 13 15.5 13C16.3284 13 17 13.6716 17 14.5Z"
                              fill={style.inpuStyle.colorInput}
                            />
                          </svg>
                        ) : (
                          <img src={IcoPontos} alt="Foto do titulo" />
                        )}
                        {detailsUser.title && detailsUser.title.length > 30
                          ? `${detailsUser.title.slice(0, 30)}...`
                          : detailsUser.title}
                      </div>
                    </TableData>
                    <TableData>
                      <div
                        className="content-user-details-history"
                        style={{
                          color:
                            detailsUser.kind === 'purchase' ||
                            detailsUser.kind === 'removal'
                              ? style.inpuStyle.colorInput
                              : '#efc75e'
                        }}
                      >
                        {detailsUser.score}
                      </div>
                    </TableData>
                    <TableData>
                      <div>
                        {detailsUser.justification &&
                        detailsUser.justification.length > 30
                          ? `${detailsUser.justification.slice(0, 30)}...`
                          : detailsUser.justification}
                      </div>
                    </TableData>
                    <TableData>
                      <div>
                        {detailsUser.registered_at
                          ? `
                      ${moment
                        .tz(detailsUser.registered_at, 'America/Sao_Paulo')
                        .format('DD/MM/YYYY')}
                        às
                        ${moment
                          .tz(detailsUser.registered_at, 'America/Sao_Paulo')
                          .format('LT')}
                          `
                          : 'Data inexistente.'}
                      </div>
                    </TableData>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </BodyTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.historical_points.userDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetailsHistory: (userId, userName, useImage) => {
      dispatch(HistoricalApi.getUserDetailsHistory(userId, userName, useImage));
    }
  };
};

const UserDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetails);

export default UserDetailsContainer;
