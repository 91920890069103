import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import DialogApi from '../../../Logicas/DialogApi';
import ModuleApi from '../../../Logicas/ModuleApi';
import ComponentHeader from '../../utils/ComponenteHeader';
import { Body } from '../../utils/BodyComponente';
import {
  required,
  number,
  maxLength190
} from '../../validation/FormValidation';
import {
  TextArea,
  LongTextFieldTitle,
  TextFieldForm
} from '../../utils_form/FormComponents';
import PreviewModule from '../../previews/PreviewModule';

class CreateModule extends Component {
  constructor() {
    super();
    this.saveModule = this.saveModule.bind(this);
  }

  saveModule(values) {
    this.props.createModule(values, this.props.initialValues.id);
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    if (this.props.course !== undefined) {
      return (
        <form onSubmit={handleSubmit(this.saveModule)}>
          <ComponentHeader
            disabled={submitting}
            tooltip={
              <p>
                Crie um novo módulo
                <br />
              </p>
            }
            actions={[]}
            name="módulo"
            nameaction="Salvar"
          />
          <Body>
            <div className="row">
              <div className="col-md-8">
                <LongTextFieldTitle
                  name="module_title"
                  validations={[required]}
                  id="name"
                  label="Título do módulo"
                  type="text"
                />
              </div>
              <div className="col-md-2">
                {this.props.course.image !== undefined ? (
                  <PreviewModule
                    title={this.props.course.title}
                    text={this.props.course.description}
                    img={this.props.course.image}
                    tag={this.props.course.tags_course[0]}
                    duration={this.props.course.duration}
                    module_description={this.props.module_description}
                    module_title={this.props.module_title}
                  />
                ) : (
                  undefined
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextArea
                  name="module_description"
                  validations={[required, maxLength190]}
                  id="name"
                  label="Descrição do módulo"
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <TextFieldForm
                  name="module_score"
                  validations={[required, number]}
                  id="name"
                  floatingLabelText="Pontuação ao concluír o módulo"
                  type="number"
                />
              </div>
            </div>
          </Body>
        </form>
      );
    } else {
      return <div>teste</div>;
    }
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'createmodule'
})(CreateModule);

const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    createModule: (values, idcourse) => {
      dispatch(ModuleApi.createModule(values, idcourse));
    }
  };
};
const selector = formValueSelector('createmodule');

InitializeFromStateForm = connect(
  (state) => ({
    alert: state.dialog,
    course: state.course,
    course: state.course,
    initialValues: state.course,
    module_title: selector(state, 'module_title'),
    module_description: selector(state, 'module_description')
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
