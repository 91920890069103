import React, { Component } from 'react';
import { Body } from '../utils/BodyComponente';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import ComponentHeader from '../utils/ComponenteHeader';
import {
  required,
  maxLengthDescription,
  minValue0,
  minValue1,
  parseValue,
  maxLength255
} from '../validation/FormValidation';
import { reduxForm } from 'redux-form';
import {
  SelectForm,
  LongTextFieldTitle,
  TextArea,
  TextFieldForm,
  SelectFormIdStore,
  AutoCompleteForm,
  DropZoneImagePreview
} from '../utils_form/FormComponents';
import TagApi from '../../Logicas/TagApi';
import StoreApi from '../../Logicas/StoreApi';
import ProductApi from '../../Logicas/ProductApi';
import ProductPreview from '../previews/ProductPreview';
import DialogProgressCircular from '../utils/DialogProgressCircular';
import { addImageProcess } from '../utils/addImageProcess';

class CreateProduct extends Component {
  constructor() {
    super();
    this.state = {
      tags: [],
      image: ''
    };
    this.showDragArea = this.showDragArea.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
    this.insertImage = this.insertImage.bind(this);
  }

  componentDidMount() {
    this.props.getTags();
    this.props.getStores();
  }

  updatePublication(values) {
    this.props.editpost(values);
  }

  showDragArea() {
    this.setState({ drag_area: true });
    setTimeout(() => {
      this.setState({ drag_area: false });
    }, 2000);
  }

  closeDragArea() {
    this.setState({ drag_area: false });
  }

  isImg(file){
    return file.type === "image/jpeg" || file.type === "image/png" || 
          file.type === ".jpg" || file.type === ".png" || file.type === ".jpeg" || file.type === "image/gif"; 
  }

  isGif(file){
    return file.type === "image/gif";
  }

  async insertImage(file) {
    const { preview } = file[0];

    if(!this.isImg(file[0]) && !this.isGif(file[0])) return;

    let hasError = false;

    if(this.isImg(file[0])){
      const i = await addImageProcess(URL.createObjectURL(file[0])); 
      if(i.height > 2000 || i.width > 2000) {
        hasError = true;
        this.props.dispatchImageError();
      }
    }

    if(this.isGif(file[0]) && (file[0].size / (1024*1024).toFixed(2)) > 20){
      hasError = true;
      this.props.dispatchGifError();
    }

    if(!hasError)
      this.setState({
        image: preview,
        file
      });
  }

  saveProduct(values) {
    values.image_product = this.state.file;
    if (values.image_product !== undefined && this.state.image !== undefined) {
      this.props.saveProduct(values);
    } else {
      this.props.showError();
    }
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    let score;
    if (this.props.score_product) {
      score = this.props.score_product.replace(/[^0-9]/g, '');
    }
    return (
      <div>
        <form
          onDragOver={this.showDragArea}
          onSubmit={handleSubmit(this.saveProduct)}
        >
          <ComponentHeader
            disabled={submitting}
            actions={[]}
            name="Produtos"
            nameaction="Salvar"
            onClick={handleSubmit(this.saveProduct)}
          />
          <Body>
            <div className="row">
              <div className="col-md-8">
                <LongTextFieldTitle
                  name="name_product"
                  validations={[required]}
                  id="name"
                  label="Nome do produto*"
                  type="text"
                />
              </div>
              <DropZoneImagePreview 
                onDrop={
                  (file) => this.insertImage(file)
                }
              >
                <div className="col-md-4">
                  {
                    <ProductPreview
                      image={this.state.image}
                      kind={this.props.kind_product}
                      score={score}
                      text={this.props.description_product}
                      title={this.props.name_product}
                    />
                  }
                </div>
              </DropZoneImagePreview>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextArea
                  name="description_product"
                  validations={[required, maxLengthDescription]}
                  id="name"
                  label="Descrição*"
                  type="text"
                />
                <p className="view-characters">
                  {this.props.description_product &&
                    this.props.description_product.length <= 1024 &&
                    `${1024 -
                      this.props.description_product
                        .length} caracteres restantes.`}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <AutoCompleteForm
                  name="tags_product"
                  isLoading={this.props.isLoadingTags}
                  validations={[required]}
                  label="Tags"
                  getTagAutoComplete={this.props.getTagAutoComplete}
                  getAuto={this.props.getTagAutoComplete}
                  tags={this.props.tags}
                />
              </div>
              <div className="col-md-4">
                <TextFieldForm
                  name="score_product"
                  validations={[required, minValue0, parseValue]}
                  id="name"
                  floatingLabelText="Valor*"
                  type="number"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <TextFieldForm
                  name="amount_product"
                  validations={[required, minValue1]}
                  id="name"
                  floatingLabelText="Quantidade disponível*"
                  type="number"
                />
              </div>
              <div className="col-md-4">
                <SelectFormIdStore
                  name="store_product"
                  elements={this.props.stores}
                  validations={[required]}
                  id="name"
                  label="Loja*"
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <SelectForm
                  name="kind_product"
                  elements={['Produto Físico', 'Produto Virtual']}
                  validations={[required]}
                  id="name"
                  label="Tipo*"
                  type="text"
                />
              </div>
              <div className="col-md-4">
                <div>
                  <TextFieldForm
                    name="purchase_limit"
                    id="name"
                    floatingLabelText="Limite de resgate*"
                    type="number"
                    validations={[required]}
                    textareaStyle={{ backgroundColor: 'transparent' }}
                  />
                </div>
                <small
                  style={{
                    fontSize: '12px',
                    color: 'rgb(59, 59, 59)',
                    opacity: '0.66'
                  }}
                >
                  Digite 0 (zero) para deixar sem limite
                </small>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div>
                  <TextArea
                    name="default_message_to_send"
                    id="default_message_to_send"
                    label="Mensagem de suporte*"
                    type="text"
                    validations={[required, maxLength255]}
                    textareaStyle={{ backgroundColor: 'transparent' }}
                  />
                  <p className="view-characters">
                    {this.props.default_message_to_send &&
                      this.props.default_message_to_send.length <= 255 &&
                      `${255 -
                        this.props.default_message_to_send
                          .length} caracteres restantes.`}
                  </p>
                </div>
                <small
                  style={{
                    fontSize: '12px',
                    color: 'rgb(59, 59, 59)',
                    opacity: '0.66'
                  }}
                >
                  Informação ou contato para dúvidas sobre o envio
                </small>
              </div>
            </div>
            <DialogProgressCircular />
          </Body>
        </form>
      </div>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'createproduct'
})(CreateProduct);

const mapDispatchToProps = (dispatch) => {
  return {
    saveProduct: (values) => {
      dispatch(ProductApi.saveProduct(values));
    },
    getStores: () => {
      dispatch(StoreApi.getStores());
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    showError: () =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: 'É obrigatório adicionar uma imagem' }
      }),
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
    dispatchGifError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.",
        }
      });
    },
  };
};
const selector = formValueSelector('createproduct');

InitializeFromStateForm = connect(
  (state) => ({
    stores: state.market,
    name_product: selector(state, 'name_product'),
    description_product: selector(state, 'description_product'),
    default_message_to_send: selector(state, 'default_message_to_send'),
    tags_product: selector(state, 'tags_product'),
    score_product: selector(state, 'score_product'),
    amount_product: selector(state, 'amount_product'),
    store_product: selector(state, 'store_product'),
    kind_product: selector(state, 'kind_product'),
    image_product: selector(state, 'image_product'),
    isLoadingTags: state.isLoadingtags,
    tags: state.tagsparsed
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
