import React, { Component } from 'react';
import { ButtonConfirm } from '../../utils/FormsComponents';
import IcoTrash from '../../utils/img/ic-excluir.svg';
import IcoAddress from '../../utils/img/ic-address.svg';
import { DialogUserAddress, DialogYesNo } from '../../utils/DailogComponentes';
import { CheckboxDefaultList } from '../../utils/FormsComponents';
import DialogApi from '../../../Logicas/DialogApi';
import { connect } from 'react-redux';
import ProductApi from '../../../Logicas/ProductApi';
import ReactTooltip from 'react-tooltip';
import StoreApi from '../../../Logicas/StoreApi';
import moment from 'moment-timezone';

import { style } from '../../../style';

require('./historycss.css');

class CardProductHistory extends Component {
  constructor() {
    super();
    this.state = { visible: false };
  }
  componentDidMount() {}

  saveProduct(values) {
    this.props.saveProduct(values);
  }

  render() {
    const {
      setProductToDispatch,
      openDialogProductToDispatch,
      showDialogCancelOrder,
      historyCurrentPage,
      openDialogUserAddress,
      alert_user_address,
      updateDialogUserAndAddress
    } = this.props;
    return (
      <div style={{display:"flex", justifyContent:"center",minWidth:"100%", margin:"auto"}}>
        <div className="body-card" 
          style={
            this.props.hasCheckbox ?
              (this.props.checked ? 
                {backgroundColor: "#EAEAEA", marginBottom:"5px", border:"none", width:"80%",borderRadius:"0", marginLeft:"auto",marginRight:"auto"}
                  :{marginBottom:"5px",border:"none", width:"80%", borderRadius:"0",marginLeft:"auto",marginRight:"auto"})
                    : {width:"80%",marginLeft:"auto",marginRight:"auto"}
          }
        >
          { this.props.hasCheckbox && 
            <div className="card-product-column">
              <CheckboxDefaultList
                checked={this.props.checked}
                onCheck={this.props.onCheck}
              />
            </div>
          }
          <div className="card-product-column">
            <img
              style={{ width: '80px', height: '50px', 'object-fit': 'cover' }}
              src={this.props.img}
              alt="imagem do produto"
            />
          </div>
          <div className="card-product-column" style={{minWidth:"200px"}}>
              <div className="name-product-container">
                <h2 className="name-product-heading" title={this.props.name_product}>
                  {this.props.name_product}
                </h2>
            </div>
          </div>
          <div className="card-product-column" style={this.props.hasCheckbox ? {minWidth:"120px"}:{}}>
            <div>{this.props.amount}</div>
          </div>
          <div className="card-product-column">
            <div>{this.props.user_name}</div>
          </div>
          {(style.teamName.name === 'Pintou Parceria Suvinil' ||
            style.teamName.name === 'sample') && (
            <div className="card-product-column" style={this.props.hasCheckbox ? {minWidth:"80px"}:{}}>
              <img
                className="ico-imag"
                data-tip="Endereço de entrega"
                onClick={() => {
                  updateDialogUserAndAddress(
                    this.props.user_name,
                    this.props.user_address,
                    this.props.user_phoneNumber
                  );
                  openDialogUserAddress();
                }}
                src={IcoAddress}
                alt="visualizar"
              />
            </div>
          )}
          <div className="card-product-column">
            <div>
              {moment
                .tz(this.props.requested_date, 'Europe/Berlin')
                .format('DD/MM/YYYY')}
            </div>
          </div>
          {this.props.send ? (
            <div className="card-product-column" style={this.props.hasCheckbox ? {minWidth:"80px"}:{}}>
              <img
                className="ico-imag"
                data-tip="Deletar compra"
                onClick={() =>
                  showDialogCancelOrder(
                    this.props.user_id,
                    this.props.id_product,
                    this.props.cart_id
                  )
                }
                src={IcoTrash}
                alt="apagar"
              />
              <ReactTooltip className="tooltip" />
            </div>
          ) : null}

          {this.props.dispatchProduct !== undefined && this.props.send && (
            <div className="card-product-column">
              <ButtonConfirm
                isSecondary={true}
                onClick={() => [
                  setProductToDispatch(this.props.cart_id),
                  openDialogProductToDispatch()
                ]}
                label="Confirmar entrega!"
                height="none"
              />
            </div>
          )}
        </div>
        {this.props.alert !== undefined && (
          <DialogYesNo
            yesclick={this.props.deleteBuy.bind(
              this,
              this.props.alert.id_user,
              this.props.alert.id_product,
              this.props.alert.id_sell,
              historyCurrentPage
            )}
            noclick={this.props.closeDialog}
            onClick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message="Deletar solicitação do produto?"
          />
        )}

        {alert_user_address.openDialog && (
          <DialogUserAddress
            yesclick={this.props.closeDialogUserAddress}
            noclick={this.props.closeDialogUserAddress}
            visible={alert_user_address.openDialog}
            user_name={alert_user_address.userName}
            user_address={alert_user_address.userAddress}
            user_phoneNumber={alert_user_address.userPhoneNumber}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.dialog,
    dialog_dispatch: state.dialog_dispatch,
    historyCurrentPage:
      state.storeList.historyList.notDispatchedProducts.pagination.current_page,
    alert_user_address: state.dialog_user_address
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatchProduct: (cart_id, id_user, id_product) => {
      dispatch(StoreApi.dipatchProduct(cart_id, id_user, id_product));
    },
    setProductToDispatch: (cart_id) => {
      dispatch({ type: 'SET_PRODUCT_TO_DISPATCH', payload: { cart_id } });
    },
    openDialogProductToDispatch: () => {
      dispatch({ type: 'DISPATCH_DIALOG_OPEN' });
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    cloaseDialogHistory: () => {
      dispatch({ type: 'CLOSE_DIALOG_DISPATCH', alert: { visible: false } });
    },
    deleteBuy: (id_user, id_product, cart_id, historyCurrentPage) => {
      dispatch(
        ProductApi.cancelBuy(id_user, id_product, cart_id, historyCurrentPage)
      );
    },
    showDialogCancelOrder: (context, id_product, cart_id, tes) => {
      dispatch(DialogApi.deleteAlertBuy(context, id_product, cart_id));
    },
    openDialogUserAddress: () => {
      dispatch(DialogApi.openDialogUserAddress());
    },
    closeDialogUserAddress: () => {
      dispatch(DialogApi.closeDialogUserAddress());
    },
    updateDialogUserAndAddress: (userName, userAddress, userPhoneNumber) => {
      dispatch(
        DialogApi.updateDialogUserAndAddress({
          userName,
          userAddress,
          userPhoneNumber
        })
      );
    }
  };
};
const CardProductHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardProductHistory);

export default CardProductHistoryContainer;
