export default class DialogApi {
  static closeDialog() {
    return (dispatch) => {
      dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
    };
  }

  static closeDialogErro() {
    return (dispatch) => {
      dispatch({ type: 'CLOSE_ERRO_ALERT', alert: { visible: false } });
    };
  }

  static openDialogErro(erro) {
    return (dispatch) => {
      dispatch({ type: 'ERRO_ALERT', alert: { visible: true, message: erro } });
    };
  }

  static openDialog() {
    return (dispatch) => {
      dispatch({ type: 'OPEN_DIALOG', alert: { visible: true } });
    };
  }

  static deleteAlert(idelemente, is_publication, avaiable) {
    return (dispatch) => {
      dispatch({
        type: 'OPEN_DIALOG_YES_NO',
        alert: {
          visible: true,
          id_elemente: idelemente,
          avaiable: avaiable,
          is_publication: is_publication
        }
      });
    };
  }

  static deleteAlertModule(idelemente) {
    return (dispatch) => {
      dispatch({
        type: 'OPEN_DIALOG_YES_NO',
        alert: { module: { visible: true, id_elemente: idelemente } }
      });
    };
  }

  static deleteAlertProductHistory(user_id, id_product, id_sell) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_DIALOG_DISPATCH',
        alert: {
          visible: true,
          dispatch: {
            user_id: user_id,
            id_product: id_product,
            id_sell: id_sell
          }
        }
      });
    };
  }

  static deleteAlertBuy(id_user, id_product, id_sell) {
    return (dispatch) => {
      dispatch({
        type: 'OPEN_DIALOG_YES_NO',
        alert: {
          visible: true,
          id_user: id_user,
          id_product: id_product,
          id_sell: id_sell
        }
      });
    };
  }

  static openDialogUserAddress() {
    return (dispatch) => {
      dispatch({ type: 'OPEN_DIALOG_USER_ADDRESS', alert: true });
    };
  }

  static closeDialogUserAddress() {
    return (dispatch) => {
      dispatch({ type: 'CLOSE_DIALOG_USER_ADDRESS', alert: false });
    };
  }

  static updateDialogUserAndAddress({userName,userAddress,userPhoneNumber}) {
    return (dispatch) => {
      dispatch({ type: 'UPDATE_DIALOG_USER_AND_ADDRESS', payload: {
        userName, userAddress, userPhoneNumber
      } });
    };
  }
}
