import IcoDocDrop from '../../../utils/img/ic-doc_newdoc.png';
import IcoDoc from '../../../utils/img/doc.png';
import IcoJpeg from '../../../utils/img/jpeg.png';
import IcoPdf from '../../../utils/img/pdf.png';
import IcoPpt from '../../../utils/img/ppt.png';
import IcoPrezi from '../../../utils/img/prezi.png';
import IcoXls from '../../../utils/img/xls.png';

const findIcon = (file) => {
  let sourceExtension = '';

  typeof file === 'string'
    ? (sourceExtension = file.split('.').pop())
    : (sourceExtension = file[0].name.split('.').pop());

  switch (sourceExtension) {
    case 'txt':
    case 'odt':
    case 'rtf':
    case 'docx':
    case 'doc':
    case 'html':
      return IcoDoc;
    case 'pdf':
      return IcoPdf;
    case 'xls':
    case 'xlsx':
    case 'xml':
      return IcoXls;
    case 'ppt':
    case 'form':
      return IcoPpt;
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'gif':
      return IcoJpeg;
    case 'prezi':
      return IcoPrezi;
    default:
      return IcoDocDrop;
  }
};

export default findIcon;
