import styled from 'styled-components';
import { style as teamStyle } from '../../../../style';

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const ButtonBody = styled.button`
  height: 40px;
  width: 150px;
  border-radius: 5px;
  background-color: ${teamStyle.inpuStyle.colorInput};
  border: none;
  margin-left: 30px;
  cursor: pointer;
  appearance: none;
  ${defaultTransition};
`;

export const ButtonLabel = styled.span`
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  vertical-align: text-top;
  color: #ffffff;
`;
