import React, { Component } from 'react';
import { DropZoneAvatar, TextFieldDefault } from '../utils/FormsComponents';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
  SelectForm,
  SelectFormId,
  LongTextFieldTitle,
  TextFieldForm,
  ToggleForm,
  AutoCompleteForm,
  ToogleNoReduxForm
} from '../utils_form/FormComponents';
import ComponentHeader from '../utils/ComponenteHeader';
import { Body } from '../utils/BodyComponente';
import TagApi from '../../Logicas/TagApi';
import SectorApi from '../../Logicas/SectorApi';
import UserApi from '../../Logicas/UserApi';
import { Field, reduxForm, change } from 'redux-form';
import { required, validAge, minValue0 } from '../validation/FormValidation';
import { cepMask, dateMask, phoneMask } from '../normalize/Normalize';
import { DialogYesNo } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { style } from '../../style';
import teamSettings from '../utils/TeamsSettings/generalSettings';
const { userTypes, displayGenderField, hasAcademyRole } = teamSettings();

require('./user.css');

class CreateUser extends Component {
  constructor() {
    super();
    this.state = {
      tags: [],
      sectors: [],
      tagsuser: [],
      sendPasswordEmail: false,
      academyRole: false,
      user_roles: '',
      formAddress: {}
    };
    this.saveUser = this.saveUser.bind(this);
    this.handleFormAddress = this.handleFormAddress.bind(this);
  }

  componentWillMount() {
    this.props.getTags();
    this.props.getSectorUser();
    this.props.getStates();
    this.props.setSelectedState({});
    this.props.setSelectedCity({});
  }

  componentDidMount() {
    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let user_role = roles.map(function (role) {
      return role.name;
    })[0];

    this.setState({
      user_roles: user_role
    });
  }

  componentDidUpdate() {
    const newForm = this.state.formAddress;
    const newCity = this.props.listCities !== undefined && this.props.citySelected !== undefined && this.props.citySelected.name_sector !== undefined ?
      this.props.listCities.find(city => city.name_sector === this.props.citySelected.name_sector)
      : [];
    if (newCity !== undefined && newCity.length !== 0) {
      newForm['city'] = newCity.id_sector;
      newForm['cityName'] = newCity.name_sector;
      this.props.setSelectedCity(newCity);
    } else if (this.props.citySelected.name_sector === "")
      this.props.setSelectedCity({ name_sector: "" });

    const newState = this.props.listStates !== undefined && this.props.stateSelected !== undefined && this.props.stateSelected.id_sector !== undefined ?
      this.props.listStates.find(state => state.id === this.props.stateSelected.id)
      : [];
    if (newState !== undefined && newState.length !== 0) {
      newForm['state'] = newState.id_sector;
      this.props.getCities(newState.id_sector);
    }

    this.props.dispatch(change('createuser', 'state', newForm['state']));
    this.props.dispatch(change('createuser', 'city', newForm['city']));
    this.props.dispatch(change('createuser', 'cityName', newForm['cityName']));
  }

  handleFormAddress(index, value) {
    const newForm = this.props.initialValues;

    switch (index) {
      case 'city':
        this.props.setAllowRequestCity(true);
        break;
      case 'state':
        newForm['city'] = '';
        newForm['cityName'] = '';
        this.props.dispatch(change('createuser', 'city', newForm['city']));
        this.props.dispatch(change('createuser', 'cityName', newForm['cityName']));
        this.props.setSelectedCity({ name_sector: "" });
        this.setState({ formAddress: newForm });
        this.props.setAllowRequestCity(false);
        break;
      case 'zip_code':
        if (value.length > 8) return;
        if (value.length === 8) {
          fetch(`https://viacep.com.br/ws/${value}/json/`).then(
            (response) => {
              response.json().then((data) => {
                const { localidade, logradouro, uf, bairro } = data;
                newForm['street'] = logradouro;
                newForm['neighborhood'] = bairro;
                const newState = this.props.listStates !== undefined ?
                  this.props.listStates.find(state => state.id === uf)
                  : [];
                if (newState !== undefined) {
                  newForm['state'] = newState.id_sector;
                  this.props.getCities(newState.id_sector);
                  this.props.setSelectedState(newState);
                  this.props.setSelectedCity({ name_sector: localidade })
                }


                this.setState({ formAddress: newForm });
                this.props.dispatch(change('createuser', 'street', newForm['street']));
                this.props.dispatch(change('createuser', 'state', newForm['state']));
                this.props.dispatch(change('createuser', 'neighborhood', newForm['neighborhood']));
              });
            },
          );
        }
        break;
    }
    newForm[index] = value;
    this.setState({ formAddress: newForm });

  }

  saveUser(values) {
    const { academyRole } = this.state;

    const contacts = [];
    const contactEmail = {
      kind: 'email',
      value: values.contact_email
    };
    const contactPhone = {
      kind: 'phone',
      value: values.contact_phone
    };
    contacts.push(contactEmail);
    contacts.push(contactPhone);

    this.props.listStates.map(state => {
      if (state.id_sector === values.state)
        values.stateName = state.id;
    })

    if (this.props.citySelected.name_sector !== '' && this.state.formAddress['city'] !== '' && this.props.allowRequestCity)
      this.props.saveUser(values, contacts, academyRole);
    else
      this.props.dispatchError();

  }

  render() {
    const { handleSubmit, submitting } = this.props;
    const { sendPasswordEmail, academyRole, user_roles } = this.state;

    return (
      <MuiThemeProvider>
        <form onSubmit={handleSubmit(this.saveUser)}>
          <ComponentHeader
            disabled={submitting}
            tooltip={
              <p>
                Crie um novo usuário
                <br />
              </p>
            }
            actions={[]}
            name="Usuários"
            nameaction="Salvar"
            onClick={handleSubmit(this.saveUser)}
          />
          <Body>
            <div className="row">
              <div className="col-md-7">
                <LongTextFieldTitle
                  name="name_user"
                  type="text"
                  validations={[required]}
                  id="name"
                  label="Nome do Usuário *"
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="img"
                  id="user_image"
                  component={(e) => DropZoneAvatar({ ...e, dispatchImageError: this.props.dispatchImageError })}
                  type="text"
                />
              </div>
            </div>
            <div style={{ marginTop: '-5%' }} className="row">
              <div className="col-md-4">
                <TextFieldForm
                  name="email_user"
                  validations={[required]}
                  id="email_user"
                  floatingLabelText="Login *"
                  type="text"
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="birthday_user"
                  validate={[validAge]}
                  normalize={dateMask}
                  id="user_image"
                  label="Aniversário"
                  component={TextFieldDefault}
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              {(style.teamName.name !== 'Pintou Parceria Suvinil') && (
                <div className="col-md-4">
                  <SelectFormId
                    name="sector_id"
                    id="user_image"
                    validations={[required]}
                    elements={this.props.sectorReducer}
                    label="Setor *"
                  />
                </div>
              )}
              <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column' }}>
                <TextFieldForm
                  type="text"
                  name="complement_sector"
                  id="complement_sector"
                  floatingLabelText="Complemento"
                />
                <span
                  style={{ zIndex: 2, fontSize: '12px', fontFamily: '"Rubik", sans-serif', color: "rgba(0,0,0,0.3)", width: "90%" }}
                >
                  Campo de preenchimento livre, Empresa / Filial / Equipe / Tribo / Área / Cargo / Função
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <TextFieldForm
                  name="score_user"
                  disabled={false}
                  validations={[minValue0]}
                  id="nameuser"
                  floatingLabelText="Pontuação"
                  type="number"
                />
              </div>
              <div className="col-md-4">
                <TextFieldForm
                  name="contact_email"
                  disabled={false}
                  validations={[required]}
                  id="contact_email"
                  floatingLabelText="E-mail *"
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: '10px' }}>
              {displayGenderField && (
                <div className="col-md-4">
                  <SelectForm
                    name="sex_user"
                    multiple={false}
                    id="user_image"
                    label="Gênero"
                    elements={[
                      'Mulher cis',
                      'Mulher trans',
                      'Homem cis',
                      'Homem trans',
                      'Não binário',
                      'Prefere não se identificar',
                      'Outros'
                    ]}
                  />
                </div>
              )}
              {this.props.gender &&
                this.props.gender.includes('Outros') &&
                displayGenderField && (
                  <div className="col-md-4">
                    <TextFieldForm
                      name="custom_gender"
                      id="custom_gender"
                      floatingLabelText="Outros gêneros"
                      type="text"
                    />
                  </div>
                )}
            </div>
            {
              (style.teamName.name === 'Pintou Parceria Suvinil') && (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <Field
                        type="cep"
                        name="zip_code"
                        normalize={cepMask}
                        id="zip_code"
                        floatingLabelText="CEP"
                        component={TextFieldDefault}
                        onChange={(e) => this.handleFormAddress('zip_code', e.target.value.replaceAll("-", ""))}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextFieldForm
                        type="text"
                        name="street"
                        id="street"
                        floatingLabelText="Rua/Av."
                        component={TextFieldDefault}
                        onChange={(e) => this.handleFormAddress('street', e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <TextFieldForm
                        name="number"
                        disabled={false}
                        validations={[minValue0]}
                        id="number"
                        floatingLabelText="Número"
                        type="number"
                        onChange={(e) => this.handleFormAddress('number', e.target.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextFieldForm
                        type="text"
                        name="complement"
                        id="complement"
                        floatingLabelText="Complemento"
                        onChange={(e) => this.handleFormAddress('complement', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <SelectFormId
                        name="state"
                        id="state"
                        validations={[required]}
                        elements={this.props.listStates}
                        label="Estado *"
                        dispatch={this.props.setSelectedState}
                        onChange={(e) => this.handleFormAddress('state', e)}
                      />
                    </div>
                    <div className="col-md-4">
                      <SelectFormId
                        name="city"
                        id="city"
                        validations={[required]}
                        elements={this.props.listCities}
                        label="Cidade *"
                        dispatch={this.props.setSelectedCity}
                        onChange={(e) => this.handleFormAddress('city', e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <TextFieldForm
                        type="text"
                        name="neighborhood"
                        id="neighborhood"
                        floatingLabelText="Bairro"
                        onChange={(e) => this.handleFormAddress('neighborhood', e.target.value)}
                      />
                    </div>
                  </div>
                </>
              )
            }
            <div className="row mt-5">
              <div className="col-md-12">
                <span className="label-tags">Tags e permissões</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <AutoCompleteForm
                  id="tags_user"
                  name="tags_user"
                  isLoading={this.props.isLoadingTags}
                  validations={[required]}
                  label="Tags"
                  getTagAutoComplete={this.props.getTagAutoComplete}
                  getAuto={this.props.getTagAutoComplete}
                  tags={this.props.tags}
                />
              </div>
              <div className="col-md-4">
                <SelectForm
                  elements_selected={this.props.permission_selected}
                  name="permission_user"
                  validations={[required]}
                  multiple={false}
                  id="user_image"
                  elements={
                    this.state.user_roles === 'manager'
                      ? ['Usuário App']
                      : userTypes
                  }
                  label="Permissões"
                  type="text"
                />
                {hasAcademyRole &&
                  this.props.permission_selected === 'Speaker' && (
                    <div className="mt-1">
                      <ToogleNoReduxForm
                        label="Pode visualizar cursos em rascunho"
                        onChange={() =>
                          this.setState({ academyRole: !academyRole })
                        }
                      />
                    </div>
                  )}
              </div>
            </div>

            {this.props.permission !== undefined ? (
              this.props.permission.includes('Speaker') ? (
                <div className="row-speaker-colum">
                  <div className="row">
                    <div className="col-md-4">
                      <TextFieldForm
                        name="score_user_speaker"
                        validations={[required, minValue0]}
                        id="nameuser"
                        floatingLabelText="Valor ao publicar *"
                        type="number"
                      />
                    </div>
                    <div className="col-md-4">
                      <AutoCompleteForm
                        id="tags_user_speaker"
                        name="tags_user_speaker"
                        isLoading={this.props.isLoadingTags}
                        validations={[required]}
                        label="Tags permitidas*"
                        getTagAutoComplete={this.props.getTagAutoComplete}
                        getAuto={this.props.getTagAutoComplete}
                        tags={this.props.tags}
                      />
                    </div>
                  </div>
                  <TextFieldForm
                    name="publications_score"
                    validations={[required, minValue0]}
                    id="nameuser"
                    floatingLabelText="Valor da interação *"
                    type="number"
                  />
                </div>
              ) : (
                undefined
              )
            ) : (
              undefined
            )}
            <div className="row">
              <div className="col-md-4">
                <Field
                  type="text"
                  name="admitted_at"
                  normalize={dateMask}
                  id="admitted_at"
                  floatingLabelText="Data de admissão"
                  component={TextFieldDefault}
                />
              </div>
              <div className="col-md-4">
                <Field
                  type="tel"
                  name="contact_phone"
                  normalize={phoneMask}
                  id="contact_phone"
                  floatingLabelText="Telefone (com DDD)"
                  component={TextFieldDefault}
                />
              </div>
            </div>
            <div style={{ marginTop: '2%' }} className="row">
              <div className="col-md-4 mt-5 pt-3">
                {user_roles === 'manager' ? (
                  'Senha será enviada por e-mail'
                ) : (
                  <ToggleForm
                    name="send_email"
                    label="Enviar senha automática por e-mail?"
                    value={sendPasswordEmail}
                    onChange={() =>
                      this.setState({ sendPasswordEmail: !sendPasswordEmail })
                    }
                  />
                )}
              </div>
              {!sendPasswordEmail && user_roles !== 'manager' && (
                <div className="col-md-4">
                  <TextFieldForm
                    type="text"
                    name="password_user"
                    id="password_user"
                    floatingLabelText="Senha*"
                    validations={[required]}
                  />
                </div>
              )}
            </div>
          </Body>
        </form>
        <DialogYesNo
          yesclick={this.props.restoreUser.bind(
            this,
            this.props.alert.id_elemente
          )}
          noclick={this.props.closeDialog}
          onClick={this.props.closeDialog}
          visible={this.props.alert.visible}
          message="Usuário já cadastrado, deseja reativar?"
        />
      </MuiThemeProvider>
    );
  }
}

// Decorate the form component
let InitializeFromStateForm = reduxForm({
  form: 'createuser'
})(CreateUser);

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: (values, contacts, academyRole) =>
      dispatch(UserApi.saveUser(values, contacts, academyRole)),
    restoreUser: (id) => dispatch(UserApi.restoreUser(id)),
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    getSectorUser: () => {
      dispatch(SectorApi.getSectorUser());
    },
    getStates: () => {
      dispatch(UserApi.getStates());
    },
    getCities: (idState) => {
      dispatch(UserApi.getCities(idState));
    },
    setSelectedState: (element) => {
      dispatch({ type: 'UPDATE_STATE', state: element });
    },
    setSelectedCity: (element) => {
      dispatch({ type: 'UPDATE_CITY', city: element });
    },
    setAllowRequestCity: (allowRequestCity) => {
      dispatch({ type: 'ALLOW_REQUEST_CITY', allowRequestCity });
    },
    dispatchError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: "Preencha a cidade"
        }
      })
    },
    dispatchImageError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: "Não foi possível carregar a imagem",
          submessage: "O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
  }
};
const selector = formValueSelector('createuser');

InitializeFromStateForm = connect(
  (state) => ({
    alert: state.dialog,
    permission: selector(state, 'permission_user'),
    gender: selector(state, 'sex_user'),
    isLoadingTags: state.isLoadingtags,
    tags_selected: selector(state, 'tags_user'),
    permission_selected: selector(state, 'permission_user'),
    tags_user_speaker_selected: selector(state, 'tags_user_speaker'),
    tags_text: selector(state, 'tags_user'),
    tags: state.tagsparsed,
    sectorReducer: state.sectorReducer.sectorList,
    initialValues: {
      'street': '',
      'city': '',
      'state': '',
      'neighborhood': '',
      'cityName': ''
    },
    listStates: state.user.listStates,
    listCities: state.user.listCities,
    stateSelected: state.user.stateSelected,
    citySelected: state.user.citySelected,
    allowRequestCity: state.user.allowRequestCity
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
