const initialState = {
  pendingRequests: 0
};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PENDING_REQUESTS':
      return {
        ...state,
        pendingRequests: action.pendingRequests
      };
    default:
      return state;
  }
};

export default requestReducer;
