import styled from 'styled-components';
import { style as teamStyle } from '../../../../style';

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 0 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputContainer = styled.div`
  max-width: 33%;
  font-family: Rubik, sans-serif;
  text-align: center;
  position: relative;
`;

export const TextInput = styled.input.attrs({
  type: 'number',
  min: '0'
})`
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.3);

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    text-align: center;
  }
`;

export const Underline = styled.span`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 2px;
  opacity: 0.3;
  background-color: ${teamStyle.inpuStyle.colorInput};
  transform-origin: center;
  transform: translate(-50%, 0) scaleX(0);
  transition: all 0.3s ease;

  ${TextInput}:focus ~ & {
    transform: translate(-50%, 0) scaleX(1);
    opacity: 1;
  }
`;
