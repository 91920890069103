import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pulse from 'react-reveal/Pulse';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import {
  required,
  minValue0,
  dateValue,
  maxLength190
} from '../../../validation/FormValidation';

import { style } from '../../../../style';
import TagApi from '../../../../Logicas/TagApi';
import PostApi from '../../../../Logicas/PostApi';
import { MuiThemeProvider } from 'material-ui/styles';

import { TextFieldTitle } from '../../../utils/FormsComponents';
import { DropZoneCover } from '../../../utils_form/FormComponents';
import {
  TextFieldForm,
  DatePickerForm,
  AutoCompleteForm
} from '../../../utils_form/FormComponents';

import EmojiPicker from '../../../utils/EmojiPicker/EmojiPicker';

import IcoNextSubmit from '../../img/ic-next-submit.png';
import IconCancel from '../../../utils/img/ic-cancel.png';

require('./canvasforminitial.css');

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});
class CanvasCreatInitial extends Component {
  constructor() {
    super();
    this.state = {
      expressionTags: [],
      selectionStart: 0,
      title:"",
      score: '0',
      hasDate: false,
    };
    this.saveCanvas = this.saveCanvas.bind(this);
    this.AnimationArrow = this.AnimationArrow.bind(this);
    this.preserveCursor = this.preserveCursor.bind(this);
    this.setCursor = this.setCursor.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.setTitle = this.setTitle.bind(this);
  }

  componentWillMount() {
    this.props.getTags();
  }

  preserveCursor() {
    this.setState({
      selectionStart: document.querySelector('textarea#name').selectionStart
    });
  }

  setCursor() {
    document
      .querySelector('textarea#name')
      .setSelectionRange(this.state.selectionStart, this.state.selectionStart);
  }

  setTitle(newTitle){
    this.setState({title:newTitle});
  }

  handleOnBlur() {
    this.preserveCursor();
  }

  saveCanvas(values) {
    this.props.postCreate(values, this.props.isForm, this.state.expressionTags);
  }
  AnimationArrow() {
    this.setState({ show: false });
  }

  zeroScore = target => {
    if(target.nodeName === 'IMG'){
      this.props.dispatch(change('createcanvasinitial', 'score', '0'));
      this.setState({score:'0'})
    }
  }

  zeroDate = target => {
    if(target.nodeName === 'IMG'){
      this.props.dispatch(change('createcanvasinitial', 'not_score_after', null));
      this.setState({hasDate:false})
    }
  }

  render() {
    const { handleSubmit } = this.props;
    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let roleAdmin = roles.filter(function(role) {
      return role.name === 'admin';
    });
    let roleManager = roles.filter(function(role) {
      return role.name === 'manager';
    });
    const tagspeaker = JSON.parse(localStorage.getItem('tagspeaker'));
    const tags = tagspeaker.map((tag) => tag.name);

    return (
      <div className="container">
        <MuiThemeProvider muiTheme={muiTheme}>
          <form onSubmit={handleSubmit(this.saveCanvas)}>
            <div className="label-canvas">
              Defina algumas informações iniciais
            </div>
            <div className="row justify-content-center">
              <div className="body-form-submit">
                <div className="row">
                  <div className="col-12">
                    <div className="img-create">
                      <div className="cover-img-publish">
                        <Field
                          name="cover_form"
                          disableClick={false}
                          component={(e)=> DropZoneCover({...e, dispatchImageError: this.props.dispatchImageError})}
                        />
                      </div>
                      <div className="img-next-btn col-md-2">
                        <Pulse spy={this.state.show}>
                          <img
                            onMouseLeave={this.AnimationArrow}
                            onMouseOver={() => {
                              this.setState({ show: true });
                            }}
                            onClick={handleSubmit(this.saveCanvas)}
                            className="img-next"
                            src={IcoNextSubmit}
                            alt="próxima etapa"
                            // aria-disabled={}
                          />
                        </Pulse>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="details-publish">
                      <div className="row">
                        <div className="col-md-10">
                          <Field
                            name="title"
                            rows={1}
                            validate={[required, maxLength190]}
                            id="name"
                            label="Dê um título para a publicação*"
                            component={TextFieldTitle}
                            type="text"
                            onBlur={this.handleOnBlur}
                            value={this.state.title}
                            onChange={(event) =>
                              this.setTitle(event.target.value)
                            }
                          />
                          <p className='view-characters-description'>
                            {this.state.title.length > 0 ? 
                                this.state.title.length <=190 ?
                                  (`${190 -
                                    this.state.title.length} caracteres restantes.`)
                                  : (
                                    <span className='text-red'>
                                      Quantidade de caracteres acima do permitido.
                                    </span> 
                                  )
                              :(
                                <span className='text-red'>
                                    O título não pode ser vazio.
                                </span>
                              )}
                          </p>
                        </div>
                        <div className="col-md-2">
                          <div className="emoji-modal__container">
                            <EmojiPicker
                              element="textarea#name"
                              propToChange="title"
                              parentProps={this.props}
                              cursorPosition={this.state.selectionStart}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                        {/* <ComponentSegmentationTags
                          onChange={(tagsSelected) =>
                            this.setState({ expressionTags: tagsSelected })
                          }
                          tags={this.props.tags}
                          showHeader={true}
                        /> */}
                        <div className="col-md-6" style={{minWidth:"200px"}}>
                          <AutoCompleteForm
                            label="Tags"
                            tags={roleAdmin[0] || roleManager[0] ? this.props.tags : tags}
                            name="tags"
                            validations={[required]}
                            isLoading={this.props.isLoadingTags}
                            getAuto={this.props.getTagAutoComplete}
                            getTagAutoComplete={this.props.getTagAutoComplete}
                          />
                        </div>
                        {(roleAdmin[0] || roleManager[0]) ? (
                          <>
                            <div className="col-md-6 pontuation-form">

                              <div className="cancel" >
                                {
                                  this.state.score && this.state.score !== '0' && (
                                    <img src={IconCancel} alt="" onClick={(e) => this.zeroScore(e.target)}/>
                                  )
                                }
                              </div>

                              <div className="container-textField">
                                <TextFieldForm
                                  name="score"
                                  rows={1}
                                  validations={[required, minValue0]}
                                  id="name"
                                  floatingLabelText="Pontuação*"
                                  type="number"
                                  onChange={(e)=>this.setState({score:e.target.value})}
                                />
                                <span className="note-schedule">
                                  Premiação ao interagir com a publicação
                                </span>
                              </div>
                                
                            </div>
                            <div className="col-md-6">
                              
                              <div className="cancel" >
                                {
                                  this.state.hasDate === true && (
                                    <img src={IconCancel} alt="" onClick={(e) => this.zeroDate(e.target)}/>
                                  )
                                }
                              </div>

                              <label className="label-chindren-date" style={{borderBottom:'1px solid #3B3B3B4D'}}>
                                <DatePickerForm
                                  name="not_score_after"
                                  label="Prazo para gamificação"
                                  isCanvasFormInitial={true}
                                  value={this.state.date}
                                  onChange={
                                    ()=>{
                                      if(this.state.hasDate === false) this.setState({hasDate:true});
                                    }
                                  }  
                                />
                              </label>
                              <div style={{width:"600px"}}>
                                <span className="note-schedule">
                                  Até quando o usuário pode ser pontuado pela interação
                                </span>
                              </div>
                          
                            </div>
                          </>
                        ):(
                          <>
                          <div className="pontuation-form">

                          </div>
                          </>
                        )}
                      </div>
                      {this.props.isForm && (
                        <div className="form-choice-send">
                          <label className="label-chindren-date" style={{borderBottom:'1px solid #3B3B3B4D'}}>
                            <DatePickerForm
                              validations={[required, dateValue]}
                              name="end_date"
                              label="Prazo para respostas*"
                              isCanvasFormInitial={true}
                            />
                          </label>
                          <div>
                            <span className="note-schedule">
                              Até quando o formulário aceitará respostas
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="label-canvas-bottom">
              Fique tranquilo, você pode alterar esses dados depois.
            </div>
          </form>
        </MuiThemeProvider>
      </div>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'createcanvasinitial'
})(CanvasCreatInitial);

const mapDispatchToProps = (dispatch) => {
  return {
    setPublication: (kind, value) =>
      dispatch({ type: 'formPost', kind: kind, value: value }),
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    postCreate: (values, isForm, tags) => {
      dispatch(PostApi.saveCanvasInitial(values, isForm, tags));
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    showError: () =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: 'É obrigatório adicionar uma imagem' }
      }),
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
  };
};
const selector = formValueSelector('createcanvasinitial');
InitializeFromStateForm = connect(
  (state) => ({
    creatpost: state.createpost,
    tags_selected: selector(state, 'tags'),
    cover_form: selector(state, 'cover_form'),
    tags: state.tagsparsed,
    isLoadingTags: state.isLoadingtags,
    isForm: state.getTypeForm
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
