import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import UserApi from '../../Logicas/UserApi';
import { connect } from 'react-redux';
import { DialogYesNo } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import TableSelect from '../utils/Tables/TableSelect';
import DialogTable from '../utils/Tables/Dialog/Dialog';
import UsersHeader from './components/UsersHeader/UsersHeader';
import DialogInsertTag from '../utils/Tables/Dialog/DialogTag';
import DialogNotificationUsers from '../utils/Tables/Dialog/DialogNotificationUsers';
import DialogResendToken from '../utils/Tables/Dialog/DialogResendToken';
import DialogBirthdayMessage from '../utils/Tables/Dialog/DialogBirthdayMessage/DialogBirthdayMessage';
import DialogResendWelcome from './dialog/DialogResendWelcome/DialogResendWelcome';
class UserList extends Component {
  constructor() {
    super();
    this.getUserByName = this.getUserByName.bind(this);
  }

  componentWillMount() {
    this.props.getUsers(1);
  }

  componentWillUnmount() {
    this.props.clearUser();
  }

  getUserByName(event) {
    this.props.getUserName(event.target.value);
  }

  redirectToNewUser() {
    browserHistory.push('/user/create');
  }

  render() {
    return (
      <div>
        <MuiThemeProvider className="form-create-publication">
          <UsersHeader />

          <TableSelect
            showDelete={true}
            headers={[
              { name: 'Nome do usuário' },
              { name: 'Aniversário' },
              { name: 'Setor' },
              { name: 'Permissões' },
              { name: 'Pontuação' },
              { name: 'Ações' }
            ]}
            elements={this.props.list}
          />

          <DialogTable />
          <DialogInsertTag />
          <DialogResendToken />
          <DialogNotificationUsers />
          <DialogBirthdayMessage />
          <DialogResendWelcome />

          <DialogYesNo
            yesclick={this.props.deleteUser.bind(
              this,
              this.props.alert.id_elemente
            )}
            noclick={this.props.closeDialog}
            onClick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message="Tem certeza que deseja remover este usuário ?"
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.user.user_list,
    alert: state.dialog,
    pagination: state.pagination
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editUser: (value, id) => {
      dispatch(UserApi.editUser(value));
    },
    getUsers: () => {
      dispatch(UserApi.getUserList());
    },
    deleteShowDialogUser: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deleteUser: (values, id) => {
      dispatch(UserApi.deleteUser(values));
    },
    getUserPage: (id) => {
      dispatch(UserApi.getUserPage(id));
    },
    getUserName: (id) => {
      dispatch(UserApi.getUserName(id));
    },
    clearUser: () => {
      dispatch({ type: 'GET_USER_LIST', user_list: [] });
    }
  };
};
const UserListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList);

export default UserListContainer;
