import React, { Component } from 'react';

import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { browserHistory } from 'react-router';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import DialogApi from '../../Logicas/DialogApi';
import * as ApplicationApi from '../../Logicas/ApplicationApi';

import { BodyTable } from '../utils/BodyTable';
import { FooterPagination } from '../utils/Pagination';
import ComponentHeader from '../utils/ComponenteHeader';
import { DialogYesNo, DialogAlert } from '../utils/DailogComponentes';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEdit
} from '../utils/Table';

import not_img from '../utils/img/ico-external.png';

require('../publication/publicationlist.css');

class ApplicationList extends Component {
  constructor() {
    super();
    this.state = { init: 0, activePage: 1 };
  }

  componentDidMount() {
    this.props.getAppList();
  }

  redirectNewApp() {
    browserHistory.push('/app/create');
  }

  render() {
    if (this.props.appList) {
      return (
        <div>
          <MuiThemeProvider>
            <ComponentHeader
              onClick={this.redirectNewApp}
              tooltip={
                <p>
                  Atualizar publicação
                  <br />
                </p>
              }
              actions={[]}
              name="Aplicativos"
              nameaction="Novo aplicativo"
            />
            <BodyTable>
              <Table>
                <TableHead>
                  <TableHeadRow>Id</TableHeadRow>
                  <TableHeadRow></TableHeadRow>
                  <TableHeadRow>Titulo</TableHeadRow>
                  <TableHeadRow>Link</TableHeadRow>
                  <TableHeadRow>Ações</TableHeadRow>
                </TableHead>
                <TableBody>
                  {this.props.appList &&
                    this.props.appList
                      .slice(this.state.init, this.state.init + 10)
                      .map(
                        function(applicationList, i) {
                          return (
                            <TableRow key={i} onClik={this.props.editUser}>
                              <TableData>{applicationList.id}</TableData>
                              <TableData>
                                <img
                                  src={
                                    applicationList.image.url
                                      ? applicationList.image.url
                                      : not_img
                                  }
                                  style={{
                                    width: '80px',
                                    height: '50px',
                                    'object-fit': 'contain'
                                  }}
                                  alt="imagem da aplicação"
                                />
                              </TableData>
                              <TableData>{applicationList.name}</TableData>
                              <TableData>{applicationList.url}</TableData>
                              <TableData>
                                <RemoveEdit
                                  Delete={this.props.deleteShowDialogApp.bind(
                                    this,
                                    applicationList.id
                                  )}
                                  Edit={this.props.editApp.bind(
                                    this,
                                    applicationList.id
                                  )}
                                />
                              </TableData>
                            </TableRow>
                          );
                        }.bind(this)
                      )}
                </TableBody>
              </Table>
              {this.props.appList.length > 10 && (
                <FooterPagination
                  activePage={this.state.activePage}
                  handlePageChange={(pageNumber) =>
                    this.setState({
                      init: (pageNumber - 1) * 10,
                      activePage: pageNumber
                    })
                  }
                  totalItemsCount={this.props.appList.length}
                />
              )}
              <ReactTooltip className="tooltip" />
            </BodyTable>
            <DialogYesNo
              yesclick={this.props.deleteApp.bind(
                this,
                this.props.alert.id_elemente
              )}
              noclick={this.props.closeDialog}
              onClick={this.props.closeDialog}
              visible={this.props.alert.visible}
              message="Tem certeza que deseja remover este aplicativo ?"
            />
            <DialogAlert
              closeDialog={this.props.closeDialogAlert}
              message="Este aplicativo não pode ser editado"
              visible={this.props.edit_old_publication}
            />
          </MuiThemeProvider>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    appList: state.application.app_list,
    alert: state.dialog
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAppList: async () => {
      await dispatch(ApplicationApi.getAppList());
    },
    deleteShowDialogApp: async (id) => {
      await dispatch(DialogApi.deleteAlert(id));
    },
    editApp: async (id) => {
      await dispatch(ApplicationApi.getEditApp(id));
    },
    deleteApp: async (id) => {
      await dispatch(ApplicationApi.deleteApp(id));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    }
  };
};
const ApplicationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationList);

export default ApplicationListContainer;
