import React, { Component } from 'react';

import * as S from './MenuButton.style';

export class MenuButton extends Component {
  render() {
    return (
      <S.ButtonBody
        className="btn-click"
        onClick={this.props.onClick}
        type="button"
      >
        <S.ButtonLabel>{this.props.label}</S.ButtonLabel>
      </S.ButtonBody>
    );
  }
}

export default MenuButton;
