import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import ImageIcon from '../../utils/img/ic-dragdrop-image.svg';
import {
  DropZoneImagePreview,
  CircularProgressDeterminatedStyled
} from '../../utils_form/FormComponents';
import { MuiThemeProvider } from 'material-ui/styles';
import GalleryApi from '../../../Logicas/GalleryApi';
import { ValidVideo, ValidImageOrGif } from '../../validation/FormValidation';
import DialogProgressCircular from '..//../utils/DialogProgressCircular';
import ToolsButton from '../../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import { ToogleNoReduxForm } from '../../utils_form/FormComponents';
import * as S from './GalleryDialog.styles';
import { canAddFiles } from '../../utils/canAddFiles';

class GalleryDialog extends Component {
  constructor() {
    super();
    this.state = {
      selectedFiles: [],
      sendNotification: false
    };
  }

  sendFiles = () => {
    const { sendFileToGallery, id_gallery } = this.props;
    const { selectedFiles, sendNotification } = this.state;

    selectedFiles.forEach((file) => {
      if (ValidVideo(file.type)) {
        sendFileToGallery(file, id_gallery, 'video', sendNotification);
      } else if (ValidImageOrGif(file.type)) {
        sendFileToGallery(file, id_gallery, 'image', sendNotification);
      }
    });

    this.resetDialog();
  };

  resetDialog = () => {
    this.props.hideDialog();
    this.setState({ selectedFiles: [] });
  };

  removeFile = (filePreview) => {
    const { selectedFiles } = this.state;
    const filteredFiles = selectedFiles.filter(
      (file) => file.preview !== filePreview
    );

    this.setState({ selectedFiles: filteredFiles });
  };

  isImg(file){
    return file.type === "image/jpeg" || file.type === "image/png" || 
          file.type === ".jpg" || file.type === ".png" || file.type === ".jpeg" || file.type === "image/gif"; 
  }

  isGif(file){
    return file.type === "image/gif";
  }

  async setFiles(filesToUpload){
    const { selectedFiles } = this.state;

    const files = filesToUpload.map(file => (this.isImg(file) || this.isGif(file)) ? file : false).filter(value => value !== false);
    if(files.length === 0 ) return;

    const imgFiles = files.filter(file => this.isImg(file));
    const gifFiles = files.filter(file => this.isGif(file));
    // const otherFiles = files.filter(file => !this.isImg(file) && !this.isGif(file));

    // if(otherFiles.length === files.length) this.setState({ selectedFiles: [...selectedFiles, ...files] }) //Without Images e Withou Gifs

    const indexesErrorGifs = []; //Validation to Gifs Errors
    if(gifFiles.length > 0){
      files.map((file, index) => {
        if(this.isGif(file) && (file.size / (1024*1024).toFixed(2)) > 20){
          indexesErrorGifs.push(index);
        }
      }) 
    }
    
    const {canAddAllFiles, indexesError} = imgFiles.length === 0 ? {canAddAllFiles: true, indexesError: []} : await canAddFiles(imgFiles);
    //Validation to Images Errors

    let newFiles = [];
    if(canAddAllFiles && indexesErrorGifs.length === 0) this.setState({ selectedFiles: [...selectedFiles, ...files] })
    else {
      files.map((newFile, index) =>{
        if(!indexesError.includes(index) && !indexesErrorGifs.includes(index)) 
          newFiles.push(newFile);
      })
      this.setState({ selectedFiles: [...selectedFiles, ...newFiles] });
      this.props.hideDialog();
      
      if(!canAddAllFiles) this.props.dispatchImageError(this.props.showDialog);
      if(indexesErrorGifs.length > 0) this.props.dispatchGifError(this.props.showDialog);;
    }
  }

  render() {
    const { hideDialog, progress_upload } = this.props;
    const { selectedFiles } = this.state;
    return (
      <MuiThemeProvider>
        <Modal
          visible={this.props.visible}
          width="800px"
          height="550px"
          effect="fadeInUp"
        >
          <S.CloseButton onClick={() => hideDialog()} />
          {progress_upload > 0 ? (
            <S.BodyDialogGallery>
              <CircularProgressDeterminatedStyled completed={progress_upload} />
              <S.LabelLoad>Enviando...</S.LabelLoad>
            </S.BodyDialogGallery>
          ) : (
            <DropZoneImagePreview
              onDrop={(files) =>
                this.setFiles(files)
              }
            >
              <S.BodyDialogGallery>
                <S.BodyDialogImage src={ImageIcon} alt="Ícone de upload" />
                <S.BodyDialogLabel>
                  Clique aqui ou arraste uma imagem
                </S.BodyDialogLabel>
              </S.BodyDialogGallery>
            </DropZoneImagePreview>
          )}
          <S.PreviewContainer>
            {selectedFiles.map((file) => (
              <S.PreviewItem key={uuidv1()}>
                <S.RemovePreview onClick={() => this.removeFile(file.preview)}>
                  Remover
                </S.RemovePreview>
                <S.PreviewImage src={file.preview} alt={file.name} />
              </S.PreviewItem>
            ))}
          </S.PreviewContainer>
          <S.ActionsContainer>
            <div>
              <ToogleNoReduxForm
                label="Notificar usuários"
                style={{ width: '200px' }}
                onChange={() =>
                  this.setState({
                    sendNotification: !this.state.sendNotification
                  })
                }
              />
            </div>
            <ToolsButton onClick={this.sendFiles} label="Enviar" />
          </S.ActionsContainer>
        </Modal>
        <DialogProgressCircular />
      </MuiThemeProvider>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    progress_upload: state.progress_upload,
    percent: state.percent
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendFileToGallery: (file, id, kind, sendNotification) => {
      dispatch(GalleryApi.sendFileGallery(file, id, kind, sendNotification));
    },
    hideDialog: () => {
      dispatch({ type: 'SHOW_DIALOG_GALLERY', visible: false });
    },
    showDialog: () => {
      dispatch({ type: 'SHOW_DIALOG_GALLERY', visible: true });
    },
    dispatchImageError:(okFunction)=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
          okFunction: okFunction,
        }
      });
    },
    dispatchGifError:(okFunction)=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.",
          okFunction: okFunction,
        }
      });
    },
  };
};
const GalleryDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryDialog);

export default GalleryDialogContainer;
