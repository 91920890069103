import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
  DatePickerForm,
  ToggleForm
} from '../../../../../utils_form/FormComponents';
import DashboardButton from '../DashboardButton/';
import { style as teamStyle } from '../../../../../../style';
import * as DashBoardApi from '../../../../../../Logicas/DashBoardApi';

import * as S from './styles';

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: teamStyle.inpuStyle.colorInput,
    headerColor: teamStyle.inpuStyle.colorInput
  },
  timePicker: {
    pickerHeaderColor: teamStyle.inpuStyle.colorInput,
    selectColor: teamStyle.inpuStyle.colorInput,
    headerColor: teamStyle.inpuStyle.colorInput,
    pickerSelectColor: teamStyle.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: teamStyle.inpuStyle.colorInput }
});

export class DateRange extends Component {
  constructor() {
    super();
    this.state = {
      comparePeriods: false
    };
  }

  handleInteractionsRequests = (values) => {
    if (this.state.comparePeriods) {
      this.props.comparePeriodsInteractions(values);
    } else {
      this.props.getPeriodInteractions(values);
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <S.Container>
          <S.Header>
            <S.TextContainer>
              <S.Title>
                Defina um intervalo para ver interações com publicações
              </S.Title>
              <S.Description>
                Contagem total das interações no mural por dia
              </S.Description>
            </S.TextContainer>
            <S.ComparisonContainer>
              <S.ToggleFormContainer>
                <ToggleForm
                  onChange={() =>
                    this.setState({
                      comparePeriods: !this.state.comparePeriods
                    })
                  }
                />
              </S.ToggleFormContainer>
              <S.Description>Comparar períodos</S.Description>
            </S.ComparisonContainer>
          </S.Header>
          <S.RangeWrapper>
            <S.RangeForm>
              <S.PickerPair comparePeriods={this.state.comparePeriods}>
                <S.PickerWrapper>
                  <DatePickerForm
                    name="first_date_start"
                    label="Data inicial"
                    className="datePicker"
                    minDate={this.props.minDate}
                    selected={this.props.first_date_start}
                  />
                  <DatePickerForm
                    name="first_date_end"
                    label="Data final"
                    className="datePicker"
                    minDate={this.props.minDate}
                    selected={this.props.first_date_end}
                  />
                </S.PickerWrapper>
                {this.state.comparePeriods && (
                  <S.Description>Período 1</S.Description>
                )}
              </S.PickerPair>
              {this.state.comparePeriods && (
                <S.PickerPair comparePeriods={this.state.comparePeriods}>
                  <S.PickerWrapper>
                    <DatePickerForm
                      name="second_date_start"
                      label="Data inicial*"
                      className="datePicker"
                      minDate={this.props.minDate}
                      selected={this.props.second_date_start}
                    />
                    <DatePickerForm
                      name="second_date_end"
                      label="Data final*"
                      className="datePicker"
                      minDate={this.props.minDate}
                      selected={this.props.second_date_end}
                    />
                  </S.PickerWrapper>
                  <S.Description>Período 2</S.Description>
                </S.PickerPair>
              )}
            </S.RangeForm>
          </S.RangeWrapper>
          <S.RangeDescription comparePeriods={this.state.comparePeriods}>
            <DashboardButton
              onClick={handleSubmit((values) =>
                this.handleInteractionsRequests({ ...values })
              )}
              isActive={this.props.loading}
              label={this.props.loading ? 'Carregando...' : 'Analisar'}
            />
            <S.Description highLight>
              Interações de{' '}
              {moment
                .tz(this.props.first_date_start, 'America/Sao_Paulo')
                .format('DD/MM/YYYY')}{' '}
              até{' '}
              {!this.state.comparePeriods ? (
                <>
                  {moment
                    .tz(this.props.first_date_end, 'America/Sao_Paulo')
                    .format('DD/MM/YYYY')}
                </>
              ) : (
                <>
                  {moment
                    .tz(this.props.second_date_end, 'America/Sao_Paulo')
                    .format('DD/MM/YYYY')}
                </>
              )}
            </S.Description>
          </S.RangeDescription>
        </S.Container>
      </MuiThemeProvider>
    );
  }
}

let InitializeDateRangeFromState = reduxForm({ form: 'dateRangeForm' })(
  DateRange
);

const mapDispatchToProps = (dispatch) => {
  return {
    comparePeriodsInteractions: (values) =>
      dispatch(DashBoardApi.comparePeriodsInteractions(values)),
    getPeriodInteractions: (values) =>
      dispatch(DashBoardApi.getPeriodInteractions(values))
  };
};

InitializeDateRangeFromState = connect(
  (state) => ({
    initialValues: {
      first_date_start: state.dashboard.first_date_start,
      first_date_end: state.dashboard.first_date_end,
      second_date_start: state.dashboard.second_date_start,
      second_date_end: state.dashboard.second_date_end
    },
    first_date_start: state.dashboard.first_date_start,
    first_date_end: state.dashboard.first_date_end,
    second_date_start: state.dashboard.second_date_start,
    second_date_end: state.dashboard.second_date_end,
    minDate: state.dashboard.minDate,
    loading: state.dashboard.loading
  }),
  mapDispatchToProps
)(InitializeDateRangeFromState);

export default InitializeDateRangeFromState;
