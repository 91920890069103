import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import { MuiThemeProvider } from 'material-ui/styles';
import { LongTextFieldTitle } from '../../../utils_form/FormComponents';
import DashboardButton from '../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton/';
import ArrowBack from '../../../repository/Components/ArrowBack';
import { required } from '../../../validation/FormValidation';
import FieldPlaceholder from '../FieldPlaceholder/FieldPlaceholder';
import UserApi from '../../../../Logicas/UserApi';

import * as S from './UserCustomFields.styles';

export class UserCustomFields extends Component {
  constructor() {
    super();
    this.state = {
      editActive: false
    };
    this.formRef = React.createRef();
  }
  componentDidMount() {
    const { getCustomFields } = this.props;
    getCustomFields();
  }
  render() {
    const {
      customQuestions,
      editField,
      setQuestionActive,
      handleSubmit,
      updateQuestion,
      isLoading,
      progressIndeterminate
    } = this.props;
    const { editActive } = this.state;
    return (
      <MuiThemeProvider>
        <S.CustomFieldsContainer className="container">
          <div onClick={() => browserHistory.push('/user/list')}>
            <ArrowBack />
          </div>
          <S.Title>Campos personalizáveis</S.Title>
          <S.Main>
            <S.FieldsContainer>
              <S.SubTitle>Selecione um campo abaixo</S.SubTitle>
              <S.CustomFieldList>
                {progressIndeterminate && !customQuestions.length && (
                  <>
                    <FieldPlaceholder height={'60px'} />
                    <FieldPlaceholder height={'60px'} />
                    <FieldPlaceholder height={'60px'} />
                  </>
                )}
                {customQuestions.map((question) => (
                  <S.CustomFieldItem
                    key={question.id}
                    active={question.active}
                    onClick={() =>
                      this.setState({ editActive: true }, () => [
                        editField(question.id),
                        setQuestionActive(question.id)
                      ])
                    }
                  >
                    {question.title}
                  </S.CustomFieldItem>
                ))}
              </S.CustomFieldList>
            </S.FieldsContainer>
            <S.AnswersContainer>
              {editActive && (
                <S.AnswersForm
                  onSubmit={handleSubmit(updateQuestion)}
                  ref={this.formRef}
                >
                  <S.SubTitle>Edite seu campo aqui</S.SubTitle>
                  <LongTextFieldTitle
                    name="title"
                    label="Título"
                    fullWidth={true}
                    disabled={isLoading}
                    validations={[required]}
                  />
                  <LongTextFieldTitle
                    name="placeholder"
                    label="Placeholder"
                    fullWidth={true}
                    disabled={isLoading}
                    validations={[required]}
                  />
                  <DashboardButton
                    disabled={isLoading}
                    label={isLoading ? 'Salvando' : 'Salvar'}
                    onClick={() =>
                      this.formRef.current.dispatchEvent(new Event('submit'))
                    }
                  />
                </S.AnswersForm>
              )}
            </S.AnswersContainer>
          </S.Main>
        </S.CustomFieldsContainer>
      </MuiThemeProvider>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'UserCustomFields',
  enableReinitialize: true
})(UserCustomFields);

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomFields: () => {
      dispatch(UserApi.getCustomFields());
    },
    updateActiveQuestion: (questions) => {
      dispatch(UserApi.updateActiveField(questions));
    },
    updateQuestion: ({ id, title, placeholder }) => {
      dispatch(UserApi.updateQuestion(id, title, placeholder));
    },
    setQuestionActive: (id) => {
      dispatch({
        type: 'CUSTOM_FIELDS_QUESTION_ACTIVE',
        id: id
      });
    },
    editField: (id) => {
      dispatch({
        type: 'CUSTOM_FIELDS_QUESTIONS_EDIT',
        id: id
      });
    }
  };
};

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.user.customFields.editQuestion,
    customQuestions: state.user.customFields.customQuestions,
    usersAnswers: state.user.customFields.usersAnswers,
    editQuestion: state.user.customFields.editQuestion,
    isLoading: state.user.customFields.isLoading,
    progressIndeterminate: state.progress_indeterminate
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
