import React, { Component } from 'react';

import Pagination from 'react-js-pagination';

require('./table.css');
require('./utils.css');

export class FooterPagination extends Component {
  render() {
    return (
      <div className="container">
        <div className="ul-pagination" style={this.props.twoLines && {display:"flex", flexDirection:"column"}}>
          <div className="size-pagination">
            {this.props.activePage * 10 - 9} -{' '}
            {this.props.activePage * 10 <= this.props.totalItemsCount
              ? this.props.activePage * 10
              : this.props.totalItemsCount}{' '}
            de {this.props.totalItemsCount}
          </div>
          <div style={this.props.twoLines && {paddingTop:"5px", paddingBottom: "10px"}}>
            <Pagination
              className="paginationHome"
              activeClass="paginationClass"
              activePage={this.props.activePage}
              itemClass="paginationItem"
              linkClass="paginationLink"
              itemsCountPerPage={10}
              totalItemsCount={this.props.totalItemsCount}
              pageRangeDisplayed={5}
              onChange={this.props.handlePageChange}
              prevPageText={'Anterior'}
              nextPageText={'Próximo'}
            />
          </div>
          
          
        </div>
      </div>
    );
  }
}
