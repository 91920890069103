import styled from 'styled-components';
import { style as profile } from '../../../style';

export const dialog = styled.dialog`
  background-color: ${profile.inpuStyle.colorInput};
`;

export const ListItemsGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 32px auto;
`;
