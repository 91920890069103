import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import moment from 'moment-timezone';
import errorHandler from '../componentes/utils/errorHandler';
import { generatePublicationsSpreadsheet } from '../componentes/utils/historyReportGenerator';

export default class HistoricalApi {
  static getListUsersHistory(page, name) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(
        `historical/users_history?page=${page}${name &&
          `&uid_or_name=${name}`}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'GET_HISTORICAL_LIST',
            historical_list: response.data,
            pageActive: page
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUserDetailsHistory(id, userName, userImage) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(`users/${id}/build_score_history`, Config.headers())
        .then((response) => {
          let user = this.parseUserDetails(
            id,
            userName,
            userImage,
            response.data
          );
          dispatch({
            type: 'GET_USER_DETAILS_HISTORICAL',
            userDetails: user
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getListPublishHistory(page, title, startDate, endDate) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      let dateObject = this.parseDate(startDate, endDate);
      API.get(
        `historical/canvas_publications_history?page=${page}${title &&
          `&title=${title}`}${dateObject &&
          `&start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}`}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'GET_HISTORICAL_LIST',
            historical_list: response.data,
            pageActive: page
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static sendPublicationsReport(kind, email, startDate, endDate) {
    return (dispatch) => {
      dispatch({ type: 'REPORT_REQUEST_START' });
      let dateObject = this.parseDateV2(startDate, endDate);
      API.get(
        `historical/canvas_publications_history_spreadsheet.xlsx?start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}&kind=${kind}&email=${email}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'REPORT_REQUEST_SUCCESS',
            payload: { message: response.data.mesage }
          });
        })
        .catch((err) => {
          dispatch({ type: 'CLOSE_REPORT_EMAIL_MODAL' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static sendHistoricalReport(kind, email, startDate, endDate) {
    return (dispatch) => {
      dispatch({ type: 'HISTORICAL_REQUEST_START' });
      let dateObject = null;

      if (startDate && endDate && kind !== 'users_history.xlsx') {
        dateObject = this.parseDateV2(startDate, endDate);
      }

      const url = dateObject
        ? `historical/${kind}?start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}&email=${email}`
        : `historical/${kind}?email=${email}`;

      API.get(url, Config.headers())
        .then((response) => {
          dispatch({
            type: 'HISTORICAL_REQUEST_SUCCESS',
            payload: { message: response.data.mesage }
          });
        })
        .catch((err) => {
          dispatch({ type: 'CLOSE_HISTORICAL_EMAIL_MODAL' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static sendUsersReport(kind, email, startDate, endDate) {
    return (dispatch) => {
      dispatch({ type: 'REPORT_REQUEST_START' });
      let dateObject = this.parseDateV3(startDate, endDate);
      API.get(
        `historicals/historical/detailed_users.xlsx?start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}&email=${email}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'REPORT_REQUEST_SUCCESS',
            payload: { message: response.data.mesage }
          });
        })
        .catch((err) => {
          dispatch({ type: 'CLOSE_REPORT_EMAIL_MODAL' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static sendAccessReport(kind, email) {
    return (dispatch) => {
      dispatch({ type: 'REPORT_REQUEST_START' });
      API.get(
        `historicals/users/authentications.xlsx?&email=${email}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'REPORT_REQUEST_SUCCESS',
            payload: { message: response.data.mesage }
          });
        })
        .catch((err) => {
          dispatch({ type: 'CLOSE_REPORT_EMAIL_MODAL' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static sendUsageReport(kind, email, startDate, endDate) {
    return (dispatch) => {
      dispatch({ type: 'REPORT_REQUEST_START' });
      let dateObject = this.parseDateV3(startDate, endDate);
      API.get(
        `historicals/users/use.xlsx?start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}&email=${email}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'REPORT_REQUEST_SUCCESS',
            payload: { message: response.data.mesage }
          });
        })
        .catch((err) => {
          dispatch({ type: 'CLOSE_REPORT_EMAIL_MODAL' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getXlsxReport(
    sectionName,
    sectionActive,
    page,
    searchvalue,
    startDate,
    endDate
  ) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const urls = {
        0: 'historical/users_history.xlsx',
        1: 'historical/canvas_publications_history.xlsx',
        2: 'historical/courses_history.xlsx',
        3: 'historical/manual_scorings_history.xlsx'
      };

      const targetUrl = urls[sectionActive] || '';

      const dateObject = this.parseDate(startDate, endDate);

      const reportUrl = `${targetUrl}?page=${page}${searchvalue &&
        `&title=${searchvalue}`}${dateObject &&
        `&start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}`}`;

      API.get(reportUrl, {
        responseType: 'blob',
        headers: Config.headers().headers
      })
        .then((response) => {
          const src = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = src;
          link.setAttribute(
            'download',
            `historico-nozes__${sectionName
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/ /g, '-')
              .toLowerCase()}__${moment().format('L')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();

          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getPublicationsReport = (page = 1, startDate, endDate) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'LOADING_SPREADSHEET_START' });

        const dateObject = this.parseDate(startDate, endDate);

        const { data: singlePublication } = await API.get(
          `historical/canvas_publications_history?page=${page}&per_page=1${dateObject &&
            `&start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}`}`,
          Config.headers()
        );

        const numberOfPublications = singlePublication.size[0];

        const {
          data: { canvas_publications: publicationList }
        } = await API.get(
          `historical/canvas_publications_history?page=${page}&per_page=${numberOfPublications}${dateObject &&
            `&start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}`}`,
          Config.headers()
        );

        const publicationDataList = await Promise.all(
          publicationList.map(async (publication) => {
            const { data: publicationData } = await API.get(
              `canvas_publications/${publication.id}`,
              Config.headers()
            );
            return publicationData;
          })
        );

        generatePublicationsSpreadsheet(publicationDataList);

        dispatch({ type: 'LOADING_SPREADSHEET_FINISH' });
      } catch (error) {
        dispatch({ type: 'LOADING_SPREADSHEET_FINISH' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static getPublishDetailsHistory(id, publishTitle, publishImage, page = 1) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(
        `historical/canvas_publications_history/${id}?page=${page}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'GET_PUBLISH_DETAILS_HISTORICAL',
            pageActive: page,
            publishDetails: {
              publishId: id,
              size: response.data.size,
              publishTitle: publishTitle,
              publishImage: publishImage,
              score: response.data.total_score,
              interactions: response.data.interactions
            }
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {});
    };
  }

  static getListCourseHistory(page, title, startDate, endDate) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      let dateObject = this.parseDate(startDate, endDate);
      API.get(
        `historical/courses_history?page=${page}${title &&
          `&title=${title}`}${dateObject &&
          `&start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}`}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'GET_HISTORICAL_LIST',
            historical_list: response.data,
            pageActive: page
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {});
    };
  }

  static getListMeritHistory(page, name, startDate, endDate) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      let dateObject = this.parseDate(startDate, endDate);
      API.get(
        `historical/manual_scorings_history?page=${page}${name &&
          `&uid_or_name=${name}`}${dateObject &&
          `&start_date=${dateObject.dateStart}&end_date=${dateObject.dateEnd}`}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'GET_HISTORICAL_LIST',
            historical_list: response.data,
            pageActive: page
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {});
    };
  }

  static parseDate(startDate, endDate) {
    let objectDate = { dateStart: '', dateEnd: '', validation: false };

    objectDate.dateEnd = moment
      .tz(endDate, 'Europe/Berlin')
      .format('DD/MM/YYYY');
    objectDate.dateStart = moment
      .tz(startDate, 'Europe/Berlin')
      .format('DD/MM/YYYY');

    if (objectDate.dateStart !== objectDate.dateEnd) {
      objectDate.validation = true;
    }

    return objectDate.validation ? objectDate : '';
  }

  static parseDateV2(startDate, endDate) {
    let objectDate = { dateStart: '', dateEnd: '' };

    objectDate.dateEnd = moment
      .tz(endDate, 'Europe/Berlin')
      .format('DD/MM/YYYY');
    objectDate.dateStart = moment
      .tz(startDate, 'Europe/Berlin')
      .format('DD/MM/YYYY');

    return objectDate;
  }

  static parseDateV3(startDate, endDate) {
    let objectDate = { dateStart: '', dateEnd: '' };

    objectDate.dateEnd = moment
      .tz(endDate, 'Europe/Berlin')
      .format('YYYY-MM-DD');
    objectDate.dateStart = moment
      .tz(startDate, 'Europe/Berlin')
      .format('YYYY-MM-DD');

    return objectDate;
  }

  static parseUserDetails(id, userName, userImage, userDetails) {
    let userHistory = {};
    userHistory.id = id;
    userHistory.name = userName;
    userHistory.image = userImage;
    userHistory.score = userDetails[0].score;
    userHistory.DetailsHistory = [];
    userDetails.map((user) => {
      let userDetailsAux = {};
      switch (user.kind) {
        case 'week_interaction_balance':
          userDetailsAux.title = 'Interação semanal';
          userDetailsAux.kind = user.kind;
          userDetailsAux.score = `+ ${user.score_balance}`;
          userDetailsAux.justification = '-';
          userDetailsAux.registered_at = user.registered_at;
          break;
        case 'course':
          userDetailsAux.title = user.title;
          userDetailsAux.kind = user.kind;
          userDetailsAux.score = `+ ${user.score}`;
          userDetailsAux.justification = user.description;
          userDetailsAux.registered_at = user.registered_at;
          break;
        case 'removal':
          userDetailsAux.title = 'Debito de pontos';
          userDetailsAux.kind = user.kind;
          userDetailsAux.score = `- ${user.score * -1}`;
          userDetailsAux.justification = user.justification;
          userDetailsAux.registered_at = user.registered_at;
          break;
        case 'purchase':
          userDetailsAux.title = user.product_name;
          userDetailsAux.kind = user.kind;
          userDetailsAux.score = `- ${user.product_value}`;
          userDetailsAux.justification = '-';
          userDetailsAux.registered_at = user.registered_at;
          break;
        case 'gift':
          userDetailsAux.title = 'Ganhou pontos';
          userDetailsAux.kind = user.kind;
          userDetailsAux.score = `+ ${user.score}`;
          userDetailsAux.justification = user.justification;
          userDetailsAux.registered_at = user.registered_at;
          break;
        case 'speaker':
          userDetailsAux.title = 'Ganhou pontos';
          userDetailsAux.kind = user.kind;
          userDetailsAux.score = `+ ${user.score}`;
          userDetailsAux.justification = user.justification;
          userDetailsAux.registered_at = user.registered_at;
      }
      if (Object.entries(userDetailsAux).length) {
        userHistory.DetailsHistory.push(userDetailsAux);
      }
    });

    return userHistory;
  }
}
