import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Modal from 'react-awesome-modal';
import { MuiThemeProvider } from 'material-ui/styles';
import SucessImg from '../../../utils/img/ic-usuarios-adicionados@3x.png';
import {
  Table,
  TableBody,
  TableHeaderColumn,
  TableRow
} from 'material-ui/Table';
import ExitDialog from '../../../utils/img/exit-dialog.png';
require('./dialogsucess.css');
export default class DialogSucess extends Component {
  showDragArea() {
    this.setState({ height: '20%', drag: true });
  }
  redirect() {
    browserHistory.push('/user/list');
  }
  render() {
    return (
      <MuiThemeProvider>
        <Modal width="40%" height="70%" visible={this.props.visible}>
          <div className="header-exit">
            <img
              onClick={this.redirect}
              className="img-exit-import"
              src={ExitDialog}
              alt="fechar"
            />
          </div>
          <div className="modal-header-validation">
            <img style={{ width: '190px', height: '139px' }} src={SucessImg} alt="ícone sucesso"/>
            <div className="main-label-import">
              Usuários adicionados com sucesso!
            </div>
            <div className="secondary-label-import">
              Planilha importada! Os usuários foram automaticamente adicionados
              na listagem.
            </div>
            <Table height="200px">
              <TableBody displayRowCheckbox={false}>
                <TableRow>
                  <TableHeaderColumn>
                    <label>Novos usuários</label>
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    {this.props.sucessinfo.number_of_users_added}
                  </TableHeaderColumn>
                </TableRow>
                <TableRow>
                  <TableHeaderColumn>
                    <label>Usuários atualizados</label>
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    {this.props.sucessinfo.number_of_users_updated}
                  </TableHeaderColumn>
                </TableRow>
                <TableRow>
                  <TableHeaderColumn>
                    <label>Usuários com email invalido</label>
                  </TableHeaderColumn>
                  <TableHeaderColumn>
                    {this.props.sucessinfo.users_with_invalid_email.length > 0
                      ? this.props.sucessinfo.users_with_invalid_email.map(
                          function(user) {
                            return `${user}, `;
                          }
                        )
                      : 'Nenhum usuário com email inválido.'}
                  </TableHeaderColumn>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Modal>
      </MuiThemeProvider>
    );
  }
}
