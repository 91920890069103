import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import { Field, reduxForm } from 'redux-form';
import {
  required,
  maxLength190,
  minValue0,
  maxValue100
} from '../../../validation/FormValidation';
import { TextArea } from '../../../utils_form/FormComponents';
import { MuiThemeProvider } from 'material-ui/styles';
import TagApi from '../../../../Logicas/TagApi';
import ButtonDefault, { ButtonDisable } from '../../../utils/FormsComponents';
import Zoom from 'react-reveal/Zoom';
import {
  TextFieldForm,
  AutoCompleteForm,
  DropZoneImageCover,
  ToggleForm
} from '../../../utils_form/FormComponents';
import { formValueSelector } from 'redux-form';
import CourseApi from '../../../../Logicas/CourseApi';
import IcoExit from '../../../utils/img/exit-dialog.png';
import { style } from '../../../../style';

require('./courseinitial.css');

class CourseCreateInitial extends Component {
  constructor() {
    super();
    this.state = {
      expressionTags: [],
      onlineOrPresential: null,
      quiz: null,
      gamification: null,
      score_with: null,
      has_certificate: null
    };
    this.booleansAreNotNull = this.booleansAreNotNull.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
    this.AnimationArrow = this.AnimationArrow.bind(this);
    this.handleOnlinePresential = this.handleOnlinePresential.bind(this);
    this.handleQuiz = this.handleQuiz.bind(this);
    this.handleGamification = this.handleGamification.bind(this);
    this.handleScoreWith = this.handleScoreWith.bind(this);
    this.handleCertificate = this.handleCertificate.bind(this);
  }

  componentWillMount() {
    this.props.getTags();
  }

  booleansAreNotNull(modality){
    if(!this.props.editCourse){
        const {
          onlineOrPresential, gamification, has_certificate, quiz
        } = this.state;
        if(!modality)
          return false;
        else if(modality === 'presential'){
          if(has_certificate === null) return false;
          return true;
        }else{ // modality === 'online'
          if(quiz === null || has_certificate === null) return false;
          else if(quiz === 'Sim'){
            if(gamification === null) return false;
            else return true;
          }
          return true;
        }
    }

    return true;
  }

  saveCourse(values) {
    if (values.image !== undefined) {
      const valuesForRequisition = values;
      // values.has_certificate = this.state.has_certificate;
      // values.gamification = this.state.gamification;
      // values.modality = this.state.onlineOrPresential;
      // values.quiz = this.state.quiz;
      if (this.props.editCourse) {
        this.props.patchCourse(valuesForRequisition);
      } else {
        this.props.createCourse(valuesForRequisition);
      }
    } else {
      this.props.showError();
    }
  }
  AnimationArrow() {
    this.setState({ show: false });
  }

  handleOnlinePresential(e) {
    this.setState({
      onlineOrPresential: e.target.value
    });
  }

  handleQuiz(e) {
    this.setState({
      quiz: e.target.value
    });
  }

  handleGamification(e) {
    this.setState({
      gamification: e.target.value
    });
  }
  handleScoreWith(e) {
    this.setState({
      score_with: e.target.value
    });
  }

  handleCertificate(e) {
    this.setState({
      has_certificate: e.target.value === 'true' ? true : false
    });
  }

  render() {
    const { handleSubmit, initialValues } = this.props;

    let modality =
      this.state.onlineOrPresential ||
      (initialValues && initialValues.modality);

    if(style.teamName.name !== 'Pintou Parceria Suvinil' && style.teamName.name !== 'sample')
      modality = 'online';
      
    let quiz =
      this.state.quiz ||
      (initialValues ? 
        (initialValues.score_for_certificate > 0 ? 'Sim' : 'Não' )
        : null);

    let gamification =
      this.state.gamification ||
      (initialValues ? 
        (initialValues.score_for_certificate > 0 ? 'Sim' : 'Não' )
        : null);

    let has_certificate =
      this.state.has_certificate === null
        ? initialValues && initialValues.has_certificate
        : this.state.has_certificate;

    return (
      <div className="container">
        <MuiThemeProvider>
          <Zoom>
            {this.props.showModal ? (
              <form onSubmit={handleSubmit((values)=>this.saveCourse({...values,quiz, gamification, has_certificate}))}>
                <div className="body-form-initialo-course">
                  <p className="title-name-course">
                    {this.props.title ? this.props.title : 'Titulo do Curso'}
                  </p>
                  <div>
                    <Field
                      name="image"
                      disableClick={false}
                      validations={[required]}
                      component={(e) => DropZoneImageCover({...e,dispatchImageError: this.props.dispatchImageError, dispatchGifError: this.props.dispatchGifError})}
                    />
                  </div>
                  <div className="display-form">
                    <div className="form-input-body">
                      <div className="form-group-input">
                        <TextArea
                          rows={1}
                          rowsMax={1}
                          name="title"
                          label="NOME DO CURSO"
                          validations={[required, maxLength190]}
                          labelsecondari="Escreva o nome de curso"
                        />
                        <p className="view-characters-description">
                          {this.props.title &&
                            this.props.title.length <= 190 &&
                            `${190 -
                              this.props.title.length} caracteres restantes.`}
                        </p>
                      </div>
                      <div className="form-group-input">
                        <TextArea
                          rows={1}
                          rowsMax={1}
                          label="DESCRIÇÃO"
                          name="description"
                          validations={[required, maxLength190]}
                          labelsecondari="Escreva uma breve descrição"
                        />
                        <p className="view-characters-description">
                          {this.props.description &&
                            this.props.description.length <= 190 &&
                            `${190 -
                              this.props.description
                                .length} caracteres restantes.`}
                        </p>
                      </div>
                      {(style.teamName.name === 'Pintou Parceria Suvinil' || style.teamName.name === 'sample') && 
                        !this.props.editCourse && (
                          <div className="form-group-input-row">
                            <div className="form-level">
                              <div className="form-modality w-100">
                                <span class="form-modality-label">
                                  MODALIDADE DO CURSO
                                </span>
                                <div
                                  className="form-modality"
                                  onChange={this.handleOnlinePresential}
                                >
                                  <div>
                                    <Field
                                      name="modality"
                                      component="input"
                                      type="radio"
                                      value="online"
                                      id="online"
                                      checked={modality === 'online'}
                                    />
                                    <label for="online" className="form-label">
                                      Virtual
                                    </label>
                                  </div>
                                  <div>
                                    <Field
                                      name="modality"
                                      component="input"
                                      type="radio"
                                      value="presential"
                                      id="presential"
                                      checked={modality === 'presential'}
                                    />
                                    <label
                                      for="presential"
                                      className="form-label"
                                    >
                                      Presencial
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      )}
                          {modality === 'online' && (
                            <>
                              <div className="form-group-input-row">
                                <div className="form-level">
                                  <div className="form-modality">
                                    <span class="form-modality-label">
                                      REALIZAÇÃO DE QUIZ
                                    </span>
                                    <div
                                      className="form-modality"
                                      onChange={this.handleQuiz}
                                    >
                                      <div>
                                        <input
                                          name="quiz"
                                          component="input"
                                          type="radio"
                                          value="Sim"
                                          id="quiz-sim"
                                          defaultChecked={
                                            quiz === 'Sim'
                                          }
                                        />
                                        <label
                                          for="quiz-sim"
                                          className="form-label"
                                        >
                                          Sim
                                        </label>
                                      </div>
                                      <div>
                                        <input
                                          name="quiz"
                                          component="input"
                                          type="radio"
                                          value="Não"
                                          id="quiz-nao"
                                          defaultChecked={
                                            quiz === 'Não'
                                          }
                                        />
                                        <label
                                          for="quiz-nao"
                                          className="form-label"
                                        >
                                          Não
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {quiz === 'Sim' && (
                                <div className="form-group-input-row">
                                  <div className="form-level">
                                    <div className="form-modality">
                                      <span class="form-modality-label">
                                        PERCENTUAL MÍNIMO PARA GAMIFICAÇÃO
                                      </span>
                                      <div
                                        className="form-modality"
                                        onChange={this.handleGamification}
                                      >
                                        <div>
                                          <input
                                            name="gamification"
                                            component="input"
                                            type="radio"
                                            value="Sim"
                                            id="gamification-sim"
                                            defaultChecked={
                                              gamification === 'Sim'
                                            }
                                          />
                                          <label
                                            for="gamification-sim"
                                            className="form-label"
                                          >
                                            Sim
                                          </label>
                                        </div>
                                        <div>
                                          <input
                                            name="gamification"
                                            component="input"
                                            type="radio"
                                            value="Não"
                                            id="gamification-nao"
                                            defaultChecked={
                                              gamification === 'Não'
                                            }
                                          />
                                          <label
                                            for="gamification-nao"
                                            className="form-label"
                                          >
                                            Não
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                      
                      <div className="form-group-input-row">
                        <div className="form-level">
                          <div className="form-modality">
                            <span class="form-modality-label">
                              DISPONIBILIZAÇÃO DE CERTIFICADO
                            </span>
                            <div
                              className="form-modality"
                              onChange={this.handleCertificate}
                            >
                              <div>
                                <input
                                  name="has_certificate"
                                  component="input"
                                  type="radio"
                                  value={true}
                                  id="has_certificate-sim"
                                  checked={has_certificate === true}
                                />
                                <label
                                  for="has_certificate-sim"
                                  className="form-label"
                                >
                                  Sim
                                </label>
                              </div>
                              <div>
                                <input
                                  name="has_certificate"
                                  component="input"
                                  type="radio"
                                  value={false}
                                  id="has_certificate-nao"
                                  checked={has_certificate === false}
                                />
                                <label
                                  for="has_certificate-nao"
                                  className="form-label"
                                >
                                  Não
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {((
                        modality === 'online') 
                        ) && (
                        <>
                          <p className="form-modality-label form-modality-label--points">
                            PONTUAÇÃO{' '}
                            {quiz === 'Sim'
                              ? has_certificate === true &&
                                gamification === 'Sim'
                                ? '(Quiz para receber certificado e gamificação)'
                                : has_certificate === true
                                ? '(Quiz para receber certificado)'
                                : gamification === 'Sim'
                                ? '(Quiz para receber gamificação)'
                                : '(Quiz)'
                              : '(Por conclusão)'}
                          </p>
                        </>
                      )}

                      <div className="form-modality">
                        {((modality === 'online' &&
                          quiz === 'Sim' &&
                          (gamification === 'Sim' ||
                            has_certificate === true))
                          ) && (
                          <div className={`form-level`}>
                            <div className="form-score-certificate">
                              <TextFieldForm
                                type="number"
                                fullWidth={true}
                                name="score_for_certificate"
                                validations={
                                  !initialValues && [
                                    required,
                                    minValue0,
                                    maxValue100
                                  ]
                                }
                                floatingLabelText="Porcentagem minima"
                                min
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className={`form-level ${
                            gamification === 'Não' ||
                            modality === 'presential'
                              ? 'w-100'
                              : ''
                          }`}
                        >
                          <TextFieldForm
                            name="score"
                            type="number"
                            validations={
                              !initialValues && [required, minValue0]
                            }
                            floatingLabelText="Pontuação para o usuário"
                          />
                        </div>
                      </div>
                      {/* {(!this.props.editCourse || !this.props.initialValues.tags_course) && (
                        <div className='form-group-input-row'>
                          <ComponentSegmentationTags
                            onChange={(tagsSelected) =>
                              this.setState({ expressionTags: tagsSelected })
                            }
                            tags={this.props.tags}
                            showHeader={true}
                          />
                        </div>
                      )} */}
                      <div className="form-group-input-row">
                        <div className="form-score-certificate">
                          <div className="segmentationTag-course">
                            <AutoCompleteForm
                              label="Tags"
                              tags={this.props.tags}
                              name="tags_course"
                              validations={[required]}
                              isLoading={this.props.isLoadingTags}
                              getAuto={this.props.getTagAutoComplete}
                              getTagAutoComplete={this.props.getTagAutoComplete}
                            />
                          </div>
                        </div>
                        {/* <div className="form-has-certificate">
                          <ToggleForm
                            name="has_certificate"
                            label="Tem certificado"
                          />
                        </div> */}
                        {/* )} */}
                      </div>
                      <div className="form-group-input-row">
                        {this.props.valid && this.booleansAreNotNull(modality)? (
                          <ButtonDefault
                            label={
                              this.props.editCourse
                                ? 'Atualizar curso'
                                : 'Criar curso'
                            }
                          />
                        ) : (
                          <ButtonDisable
                            label={
                              this.props.editCourse
                                ? 'Atualizar curso'
                                : 'Criar curso'
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <Modal width="25%" height="20%" visible={true}>
                <div className="container-modal-patch-course">
                  <div className="header-modal-patch-course">
                    <img
                      onClick={this.props.closeDialog}
                      className="btn-exit"
                      src={IcoExit}
                      alt="fechar"
                    />
                  </div>
                  <div className="body-modal-patch-course">
                    <p>Curso Atualizado!</p>
                    <ButtonDefault
                      label="Ok"
                      onClick={this.props.closeDialog}
                    />
                  </div>
                </div>
              </Modal>
            )}
          </Zoom>
        </MuiThemeProvider>
      </div>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'create_course_form'
})(CourseCreateInitial);

const mapDispatchToProps = (dispatch) => {
  return {
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    createCourse: (values, tagsExpressions) => {
      dispatch(CourseApi.createCourseV2(values, tagsExpressions));
    },
    patchCourse: (values) => {
      dispatch(CourseApi.patchCourse(values, 'long'));
    },
    patchCourseExpressionsTags: (values, tagsExpressions) => {
      dispatch(CourseApi.patchCourseExpressionTags(values, tagsExpressions));
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    showError: () =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: 'É obrigatório adicionar uma imagem' }
      }),

    closeDialog: () => dispatch({ type: 'SHOW_MODAL_BIRTHDAY' }),
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
    dispatchGifError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.",
        }
      });
    },
  };
};
const selector = formValueSelector('create_course_form');
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.course.course.id && state.course.course,
    creatpost: state.createpost,
    isLoadingTags: state.isLoadingtags,
    tags_selected: selector(state, 'tags_course'),
    tags: state.tagsparsed,
    image: selector(state, 'image'),
    title: selector(state, 'title'),
    description: selector(state, 'description'),
    has_certificate: selector(state, 'has_certificate'),
    modality: selector(state, 'modality'),
    showModal: state.show_modal_birthday,
    score_with: selector(state, 'score_with')
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
