export function dialog(state = { visible: false, message: '' }, action) {
  if (action.type === 'CLOSE_ALERT') {
    return action.alert;
  }
  if (action.type === 'OPEN_DIALOG_YES_NO') {
    return action.alert;
  }
  if (action.type === 'OPEN_DIALOG') {
    return action.alert;
  }
  if (action.type === 'SHOW_DIALOG_FILE') {
    return action.alert;
  }
  if (action.type === 'CLOSE_DIALOG_FILE') {
    return action.alert;
  }

  return state;
}
