import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';

import UserApi from '../../../../Logicas/UserApi';

import { ButtonDialogTag } from '../../../utils/FormsComponents';

import IcoExit from '../../../utils/img/exit-dialog.png';
import './dialog.css';

class DialogResendToken extends Component {
  render() {
    return (
      <Modal
        visible={this.props.showModal}
        width="30%"
        height="25%"
        effect="fadeInUp"
      >
        <div className="row-exit-schedule">
          <img
            onClick={this.props.hide_token_resend}
            className="btn-exit"
            src={IcoExit}
            alt="fechar"
          />
        </div>
        {this.props.confirmModal ? (
          <div>
            <div className="dialogyesno-body">
              <div className="label-dialog-tag">
                Token reenviado com sucesso!
              </div>
            </div>

            <div className="confirm-btn-resend-token">
              <div className="offset-md-1 col-md-5">
                <ButtonDialogTag
                  onClick={this.props.hide_token_resend}
                  label="OK"
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="dialogyesno-body">
              <div className="label-dialog-tag">
                Tem certeza que deseja reenviar o token de primeiro acesso?
              </div>
            </div>

            <div className="row">
              <div className="offset-md-1 col-md-5">
                <ButtonDialogTag
                  onClick={this.props.hide_token_resend}
                  label="Não"
                />
              </div>
              <div className="col-md-5 ">
                <ButtonDialogTag
                  onClick={() =>
                    this.props.resend_token_send(this.props.idUser)
                  }
                  label="Sim"
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resend_token_send: (idUser) => {
      dispatch(UserApi.resend_token_send(idUser));
    },
    hide_token_resend: () => {
      dispatch(UserApi.hide_token_resend());
    }
  };
};

const mapStateToProps = (state) => {
  return {
    idUser: state.token_resend.idUser,
    showModal: state.token_resend.showModal,
    confirmModal: state.token_resend.confirmModal
  };
};

const DialogResendTokenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogResendToken);

export default DialogResendTokenContainer;
