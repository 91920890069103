import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Player } from 'video-react';
import { browserHistory } from 'react-router';
import GalleryApi from '../../../Logicas/GalleryApi';
import { ListItemsGallery } from '../gallerystile';
import ComponentHeader from '../../utils/ComponenteHeader';
import ArrowBack from '../../repository/Components/ArrowBack';
import GalleryDialog from '../GalleryDialog/GalleryDialog';
import deleteIcon from '../../utils/img/ic-excluir.svg';
import AutoPagination from '../../utils/AutoPagination/Pagination';
import Loader from '../../utils/AutoPagination/Loader';

import * as S from '../Mansory/Mansory.style';
class GalleryListItems extends Component {
  constructor() {
    super();
    this.state = {
      galleryName: ''
    };
  }

  componentDidMount() {
    if (!this.props.galleries.length) {
      browserHistory.push('/galleries');
      return null;
    }

    const { name } = this.props.galleries.find(
      (gallery) => this.props.galleryId === gallery.id
    );
    this.setState({ galleryName: name });
  }

  getGalleryNextPage = () => {
    const {
      getGalleryNextPage,
      galleryId,
      content: {
        pagination: { next_page }
      }
    } = this.props;

    getGalleryNextPage(galleryId, next_page);
  };

  render() {
    return (
      <>
        <ComponentHeader
          onClick={this.props.showDialog}
          actions={[]}
          name={`${this.state.galleryName} (${this.props.total_count} ${
            this.props.total_count > 1 ? 'itens' : 'item'
          })`}
          nameaction="Adicionar mídias +"
        />
        <div onClick={() => browserHistory.push('/galleries')}>
          <ArrowBack />
        </div>
        <ListItemsGallery style={{ width: '970px', margin: '0 auto' }}>
          <S.Mansory>
            {this.props.content.gallery_contents &&
              this.props.content.gallery_contents.map((item) =>
                item.kind === 'image' ? (
                  <S.MansoryItem>
                    <S.MansoryImage src={item.content.url} />
                    <S.DeleteIconContainer>
                      <S.DeleteIcon
                        src={deleteIcon}
                        onClick={this.props.removeItem.bind(
                          this,
                          this.props.galleryId,
                          item.id
                        )}
                      />
                    </S.DeleteIconContainer>
                  </S.MansoryItem>
                ) : (
                  <S.MansoryItem>
                    <S.MansoryVideo>
                      <Player src={item.content.url} />
                    </S.MansoryVideo>
                    <S.DeleteIconContainer>
                      <S.DeleteIcon
                        src={deleteIcon}
                        onClick={this.props.removeItem.bind(
                          this,
                          this.props.galleryId,
                          item.id
                        )}
                      />
                    </S.DeleteIconContainer>
                  </S.MansoryItem>
                )
              )}
          </S.Mansory>
        </ListItemsGallery>

        <GalleryDialog
          id_gallery={this.props.galleryId}
          visible={this.props.dialog_gallery}
        />
        {this.props.content.pagination.next_page && (
          <AutoPagination getMorePublications={this.getGalleryNextPage} />
        )}
        <Loader loading={this.props.progressIndeterminate} />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    galleries: state.gallery.galleriesList,
    content: state.gallery.content,
    total_count: state.gallery.content.pagination.total_count,
    galleryId: state.gallery.galleryId,
    dialog_gallery: state.dialog_gallery,
    progressIndeterminate: state.progress_indeterminate
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showDialog: () => {
      dispatch({ type: 'SHOW_DIALOG_GALLERY', visible: true });
    },
    removeItem: (id, idgallery) =>
      dispatch(GalleryApi.deleteFileGallery(id, idgallery)),
    getGalleryNextPage: (galleryId, page) => {
      dispatch(GalleryApi.getMoreGalleryPhotos(galleryId, page));
    }
  };
};
const GalleryListItemsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryListItems);

export default GalleryListItemsContainer;
