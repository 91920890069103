import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import StoreApi from '../../../Logicas/StoreApi';
import { ButtonDialog } from '../../utils/FormsComponents';
import { LabelLink } from '../../utils/utils_styled';
import { connect } from 'react-redux';
require('./historycss.css');

class DialogDispatchProduct extends Component {
  render() {
    const {
      isModalOpen,
      cartId,
      productToDispatch,
      requesterUser,
      closeDialogHistory,
      dispatchProduct,
      historyCurrentPage,
      filtersInformation,
    } = this.props;

    return (
      <Modal visible={isModalOpen} width="400" height="265" effect="fadeInUp">
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="labe-dialog-cofirm">
              Confirma o envio do produto?
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 my-3">
            <div className="dialog-history--data">
              <h4 className="dialog-history--heading">Produto solicitado:</h4>
              <span className="dialog-history--value">
                {productToDispatch.name}
              </span>
              <h4 className="dialog-history--heading mt-3">Solicitante:</h4>
              <span className="dialog-history--value">
                {requesterUser.name}
              </span>
            </div>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-md-2 offset-md-2">
            <LabelLink onClick={() => closeDialogHistory()}>Cancelar</LabelLink>
          </div>
          <div className="col-md-6">
            <ButtonDialog
              onClick={() =>
                dispatchProduct(
                  cartId,
                  requesterUser.id,
                  productToDispatch.id,
                  historyCurrentPage,
                  filtersInformation
                )
              }
              label="Confirmar"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  cartId: state.storeList.productToDispatch.cartId,
  productToDispatch: state.storeList.productToDispatch.productData,
  requesterUser: state.storeList.productToDispatch.userData,
  isModalOpen: state.storeList.productToDispatch.isModalOpen,
  historyCurrentPage:
    state.storeList.historyList.notDispatchedProducts.pagination.current_page
});

const mapDispoatchToProps = (dispatch) => ({
  dispatchProduct: (cartId, userId, productId, historyCurrentPage, filtersInformation) => {
    dispatch(
      StoreApi.dipatchProduct(cartId, userId, productId, historyCurrentPage, filtersInformation)
    );
  },
  closeDialogHistory: () => {
    dispatch({ type: 'DISPATCH_DIALOG_CLOSE' });
  }
});

const DialogDispatchProductContainer = connect(
  mapStateToProps,
  mapDispoatchToProps
)(DialogDispatchProduct);

export default DialogDispatchProductContainer;
