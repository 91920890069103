import React, { Component } from 'react';

import * as S from './styles';

export class index extends Component {
  constructor() {
    super();
    this.state = {
      messages:[
        "Enviando",
        "Processando",
        "Quase lá"
      ],
      messageActual:0
    };

    this.handleMessage = this.handleMessage.bind(this);
    this.verifyMessageActual = this.verifyMessageActual.bind(this);
  }
  handleMessage(newMessage){
    this.setState({messageActual:newMessage});
  }

  verifyMessageActual(){
    if(this.state.messageActual < 2) this.handleMessage(this.state.messageActual + 1);
    else this.handleMessage(0);
  }

  render() {
    setTimeout(this.verifyMessageActual, 5000);
    return (
      <S.LoaderWrapper loading={this.props.loading} style={this.props.style}>
        <S.ElementWrapper>
          {
            this.props.isDialogProgressCircular ? (
              <S.Text>{this.state.messages[this.state.messageActual]}</S.Text>
            ):(
              <S.Text>{this.props.message ? this.props.message: "Carregando..."}</S.Text>
            )
          }
        </S.ElementWrapper>
        <S.ElementWrapper>
          <S.Circle />
          <S.Circle />
          <S.Circle />
        </S.ElementWrapper>
      </S.LoaderWrapper>
    );
  }
}

export default index;
