import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SectorButton from '../SectorButton/SectorButton';
import SectorSearch from '../SectorSearch/SectorSearch';

import * as S from './SectorHeader.style';

export class SectorHeader extends Component {
  render() {
    return (
      <MuiThemeProvider>
        <S.SectorComponentHeader>
          <S.SectorComponentHeaderTitle>
            <S.SectorComponentHeaderTitleValue>
              Setor
            </S.SectorComponentHeaderTitleValue>
          </S.SectorComponentHeaderTitle>
          <S.SectorComponentHeaderButtons>
            <SectorSearch
              setPatchSector={this.props.setPatchSector}
              editSector={this.props.editSector}
              deleteSector={this.props.deleteSector}
            />
            <SectorButton
              label="Novo setor"
              onClick={this.props.setPatchSector}
            />
          </S.SectorComponentHeaderButtons>
        </S.SectorComponentHeader>
      </MuiThemeProvider>
    );
  }
}

export default SectorHeader;
