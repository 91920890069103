import React, { Component } from 'react';
import DragDrop from '../utils/img/ic-dragdrop-image.svg';
import 'video-react/dist/video-react.css';
import IcoContent from '../utils/img/ic-conte-do.svg';
require('./previewcontent.css');

class PreviewContentImage extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="preview-content">
        <div className="row">
          <div className="col-md-12 preview-content-img">
            <img className="img-default-simple" src={IcoContent} alt="ícone conteúdo"/>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="preview-content-title ">
              <div className="col-md-12">{this.props.title}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 preview-content-img">
              {this.props.image === undefined ? (
                <img className="img-default-content" src={DragDrop} alt="ícone imagem"/>
              ) : this.props.image[0] === undefined ? (
                <img
                  className="preview-content-img"
                  src={this.props.image.url}
                  alt="imagem de prévia"
                />
              ) : (
                <img
                  className="preview-content-img"
                  src={this.props.image[0].preview}
                  alt="imagem de prévia"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PreviewContentImage;
