import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { required } from '../validation/FormValidation';
import * as ApplicationApi from '../../Logicas/ApplicationApi';

import ComponentHeader from '../utils/ComponenteHeader';
import { Body } from '../utils/BodyComponente';
import {
  TextFieldForm,
  LongTextFieldTitle,
  DropZoneImage
} from '../utils_form/FormComponents';

require('./Application.css');

class FileCreate extends Component {
  constructor() {
    super();
    this.state = {};
    this.saveApp = this.saveApp.bind(this);
  }

  saveApp(values) {
    this.props.saveAppCreate(values);
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.saveApp)}>
        <ComponentHeader
          disabled={submitting}
          tooltip={
            <p>
              Atualizar aplicativo
              <br />
            </p>
          }
          actions={[]}
          name="Novo aplicativo"
          nameaction="Salvar"
          onClick={handleSubmit(this.saveApp)}
        />
        <Body>
          <div className="row">
            <div className="col-md-8">
              <LongTextFieldTitle
                name="name"
                validations={[required]}
                id="name"
                label="Nome do aplicativo*"
                type="text"
              />
              <div style={{ marginBottom: '150px' }} className="row">
                <div className="col-md-6">
                  <TextFieldForm
                    fullWidth={true}
                    name="url"
                    rows={1}
                    validations={[required]}
                    id="name"
                    floatingLabelText="Digite ou cole o link do aplicativo*"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="image-row">
              <Field
                name="image"
                disableClick={false}
                component={DropZoneImage}
              />
            </div>
          </div>
        </Body>
      </form>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'createapp'
})(FileCreate);

const mapDispatchToProps = (dispatch) => {
  return {
    saveAppCreate: async (values) => {
      await dispatch(ApplicationApi.saveAppCreate(values));
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({}),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
