import React, { Component } from 'react';
import ButtonDefault, {
  TextFieldDefault,
  LongTextField,
  TextFieldNumberInput,
  AlternativeToggleCourse
} from '../../utils/FormsComponents';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../validation/FormValidation';
import ContentPreviewQuiz from '../../previews/PreviewConteudoQuiz';
import ContentApi from '../../../Logicas/ContentApi';
import { BodyContent } from '../../utils/BodyContent';

class EditContentQuiz extends Component {
  constructor() {
    super();
    this.state = {};
    this.saveContentText = this.saveContentText.bind(this);
  }

  componentDidMount() {
    this.props.enableBtn();
  }

  saveContentText(values) {
    if (this.state.answer1) {
      values.answers_correct = 1;
    } else if (this.state.answer2) {
      values.answers_correct = 2;
    } else if (this.state.answer3) {
      values.answers_correct = 3;
    }
    if (this.state.answer4) {
      values.answers_correct = 4;
    }

    this.props.saveContent(values, this.props.idmodule);
  }

  render() {
    const labelalternative = {
      height: '12px',
      'font-family': 'Rubik',
      'font-size': '12px',
      'font-weight': '500',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'left',
      color: '#e20914',
      'margin-top': '40px'
    };
    const { handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.saveContentText)}>
        <BodyContent>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="questiontext"
                rows={1}
                validate={[required]}
                id="name"
                label="Pergunta*"
                component={LongTextField}
              />
            </div>
            <div
              style={{ marginTop: '50px', marginLeft: '70px;' }}
              className="col-md-4"
            >
              <ContentPreviewQuiz
                question={this.props.quiz}
                answer1={this.props.answer1}
                answer2={this.props.answer2}
                answer3={this.props.answer3}
                answer4={this.props.answer4}
                title={this.props.title}
                text={this.props.text}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Field
                name="score"
                validate={[required]}
                id="name"
                label="Pontuação*"
                component={TextFieldNumberInput}
              />
            </div>
          </div>
          <div className="row">
            <div style={labelalternative} className="col-md-1">
              a)
            </div>
            <div className="col-md-4">
              <Field
                name="answer1"
                validate={[required]}
                id="name"
                label="Opção 1*"
                component={TextFieldDefault}
              />
            </div>
            <div className="col-md-2" style={{ marginTop: '45px' }}>
              {this.props.initialValues !== undefined ? (
                <AlternativeToggleCourse
                  defaultToggled={this.props.initialValues.answer1_correct}
                  disabled={this.state.disable1}
                  onToggle={(target, values, index) => {
                    if (values) {
                      this.setState({
                        answer1: values,
                        answer2: false,
                        answer3: false,
                        answer4: false,
                        disable1: false,
                        disable2: true,
                        disable4: true,
                        disable3: true
                      });
                    } else {
                      this.setState({
                        answer1: values,
                        answer2: false,
                        answer2: false,
                        answer4: false,
                        disable1: false,
                        disable2: false,
                        disable4: false,
                        disable3: false
                      });
                    }
                  }}
                />
              ) : (
                undefined
              )}
            </div>
          </div>
          <div className="row">
            <div style={labelalternative} className="col-md-1">
              b)
            </div>
            <div className="col-md-4">
              <Field
                name="answer2"
                validate={[required]}
                id="name"
                label="Opção 2*"
                component={TextFieldDefault}
              />
            </div>
            <div className="col-md-2" style={{ marginTop: '45px' }}>
              {this.props.initialValues !== undefined ? (
                <AlternativeToggleCourse
                  defaultToggled={this.props.initialValues.answer2_correct}
                  disabled={this.state.disable2}
                  onToggle={(target, values, index) => {
                    if (values) {
                      this.setState({
                        answer2: values,
                        answer1: false,
                        answer3: false,
                        answer4: false,
                        disable1: true,
                        disable2: false,
                        disable4: true,
                        disable3: true
                      });
                    } else {
                      this.setState({
                        answer2: values,
                        answer1: false,
                        answer2: false,
                        answer4: false,
                        disable1: false,
                        disable2: false,
                        disable4: false,
                        disable3: false
                      });
                    }
                  }}
                />
              ) : (
                undefined
              )}
            </div>
          </div>
          <div className="row">
            <div style={labelalternative} className="col-md-1">
              c)
            </div>
            <div className="col-md-4">
              <Field
                name="answer3"
                validate={[required]}
                id="name"
                label="Opção 3*"
                value="dsdsdsd"
                component={TextFieldDefault}
              />
            </div>
            <div className="col-md-2" style={{ marginTop: '45px' }}>
              {this.props.initialValues !== undefined ? (
                <AlternativeToggleCourse
                  defaultToggled={this.props.initialValues.answer3_correct}
                  disabled={this.state.disable3}
                  onToggle={(target, values, index) => {
                    if (values) {
                      this.setState({
                        answer3: values,
                        answer1: false,
                        answer2: false,
                        answer4: false,
                        disable1: true,
                        disable2: true,
                        disable4: true,
                        disable3: false
                      });
                    } else {
                      this.setState({
                        answer3: values,
                        answer1: false,
                        answer2: false,
                        answer4: false,
                        disable1: true,
                        disable2: false,
                        disable4: false,
                        disable3: false
                      });
                    }
                  }}
                />
              ) : (
                undefined
              )}
            </div>
          </div>
          <div className="row">
            <div style={labelalternative} className="col-md-1">
              d)
            </div>
            <div className="col-md-4">
              <Field
                name="answer4"
                validate={[required]}
                id="name"
                label="Opção 4*"
                component={TextFieldDefault}
              />
            </div>
            <div className="col-md-2" style={{ marginTop: '45px' }}>
              {this.props.initialValues !== undefined ? (
                <AlternativeToggleCourse
                  defaultToggled={this.props.initialValues.answer4_correct}
                  disabled={this.state.disable4}
                  onToggle={(target, values, index) => {
                    if (values) {
                      this.setState({
                        answer4: values,
                        answer1: false,
                        answer2: false,
                        answer3: false,
                        disable1: true,
                        disable2: true,
                        disable4: false,
                        disable3: true
                      });
                    } else {
                      this.setState({
                        answer4: values,
                        answer1: false,
                        answer2: false,
                        answer3: false,
                        disable1: false,
                        disable2: false,
                        disable4: false,
                        disable3: false
                      });
                    }
                  }}
                />
              ) : (
                undefined
              )}
            </div>
          </div>
          <div className="col-md-3">
            <ButtonDefault label="Salvar" />
          </div>
        </BodyContent>
      </form>
    );
  }
}
let InitializeFromStateForm = reduxForm({})(EditContentQuiz);

const mapDispatchToProps = (dispatch) => {
  return {
    saveContent: (values, idmodule) => {
      dispatch(ContentApi.saveContentQuiz(values, idmodule));
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    }
  };
};
const selector = formValueSelector('editcontentquiz');

InitializeFromStateForm = connect(
  (state, props) => ({
    alert: state.dialog,
    initialValues: state.content.data,
    answer1: selector(state, 'answer1'),
    answer2: selector(state, 'answer2'),
    answer3: selector(state, 'answer3'),
    answer4: selector(state, 'answer4'),

    answer1_correct: selector(state, 'answer1_correct'),
    answer2_correct: selector(state, 'answer2_correct'),
    answer3_correct: selector(state, 'answer3_correct'),
    answer4_correct: selector(state, 'answer4_correct'),
    form: 'form' + '/' + props.content.id,
    quiz: selector(state, 'questiontext')
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
