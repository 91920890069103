import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeaderColumn
} from 'material-ui/Table';
import moment from 'moment';
import PlaceHolder from '../PlaceHolder/PlaceHolder';

require('./productlistrigth.css');

class ProductListRight extends Component {
  render() {
    return (
      <div className="body-card-product-list-rigth">
        <div>
          <div className="header-publication-list">
            <div className="header-publication-list-description">
              <label className="main-label-header-publication-list">
                {this.props.label}
              </label>
              <label className="secondary-label-header-publication-list">
                {this.props.description}
              </label>
            </div>
          </div>
          {this.props.list.length === 0 ? (
            <PlaceHolder />
          ) : (
            <Table height="350px">
              <TableBody displayRowCheckbox={false}>
                <TableRow displayRowCheckbox={false}>
                  <TableHeaderColumn displayRowCheckbox={false}>
                    Produto
                  </TableHeaderColumn>
                  <TableHeaderColumn displayRowCheckbox={false}>
                    Quantidade
                  </TableHeaderColumn>
                  <TableHeaderColumn displayRowCheckbox={false}>
                    Atualizado em
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    displayRowCheckbox={false}
                  ></TableHeaderColumn>
                </TableRow>
                {Array.isArray(this.props.list)
                  ? this.props.list.map(
                      function(product) {
                        let hour = moment(product.created_at).fromNow();
                        return (
                          <TableRow displayBorder={false}>
                            <TableRowColumn>
                              <label className="label-title-list">
                                {product.name}
                              </label>
                            </TableRowColumn>
                            <TableRowColumn>
                              <label className="label-title-secondary">
                                {0}
                              </label>
                            </TableRowColumn>
                            <TableRowColumn>
                              <label className="label-title-secondary">
                                {hour}
                              </label>
                            </TableRowColumn>
                            <TableRowColumn>
                              <label
                                onClick={this.props.editProduct.bind(
                                  this,
                                  product.id
                                )}
                                className="label-title-secondary-action"
                              >
                                Editar
                              </label>
                            </TableRowColumn>
                          </TableRow>
                        );
                      }.bind(this)
                    )
                  : null}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    );
  }
}
export default ProductListRight;
