import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import SectorApi from '../../Logicas/SectorApi';
import DialogApi from '../../Logicas/DialogApi';

import ButtonDefault from '../utils/FormsComponents';

import IcoExit from '../utils/img/exit-dialog.png';
import ImgPlaceHolder from '../utils/img/place_holder_user.png';
import '../tag/tag.css';

class DeleteSector extends Component {
  constructor() {
    super();
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.props.closeDialogSave();
    this.props.closeDialogConfirm();
  }

  render() {
    const { confirmSave, showModal } = this.props;
    return (
      <Modal visible={!showModal} width="35%" height="50%" effect="fadeInUp">
        <div className="container-modal-schedule">
          <div className="exit-modal-draw">
            <img
              onClick={this.closeDialog}
              className="btn-exit"
              src={IcoExit}
              alt="fechar"
            />
          </div>
          <div className="body-draw-confirm">
            {confirmSave ? (
              <div className="body-button-sector-removed">
                <div className="label-dialog-tag">Setor Removido!</div>
                <div className="body-button-sector-removed">
                  <ButtonDefault
                    onClick={() => this.closeDialog()}
                    label="Ok"
                  />
                </div>
              </div>
            ) : (
              <div className="removed-sector-body">
                <div className="label-dialog-tag">
                  {this.props.associated_users.length > 0 ? (
                    <div className="body-list-container-sector">
                      Este Setor contem usuários associados, não é permitido
                      exclusão.
                      <ul className="body-list-sector-users">
                        {this.props.associated_users.map(function(userWinner) {
                          return (
                            <li className="user-winners-list">
                              <img
                                className="img-user-winner"
                                src={
                                  userWinner.image.url
                                    ? userWinner.image.url
                                    : ImgPlaceHolder
                                }
                                alt="perfil-user"
                              />
                              {userWinner.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    'Tem certeza que deseja remover este setor?'
                  )}
                </div>
                <div className="body-button-sector-removed">
                  {this.props.associated_users.length > 0 ? (
                    <div>
                      <ButtonDefault
                        label="OK"
                        onClick={() => this.closeDialog()}
                      />
                    </div>
                  ) : (
                    <div>
                      <ButtonDefault
                        onClick={() => this.closeDialog()}
                        label="Não"
                      />
                      <ButtonDefault
                        onClick={() =>
                          this.props.deleteSectorUser(this.props.sectorId)
                        }
                        label="Sim"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    confirmSave: state.dialog.visible,
    showModal: state.show_modal_birthday
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSectorUser: (id) => {
      dispatch(SectorApi.deleteSectorUser(id));
    },
    closeDialogConfirm: () => {
      dispatch(DialogApi.closeDialog());
    },
    closeDialogSave: () => {
      dispatch({ type: 'SHOW_MODAL_BIRTHDAY' });
    }
  };
};

const SaveSectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSector);

export default SaveSectorContainer;
