import styled from 'styled-components';
import { style as teamStyle } from '../../../../style';

const defaultFontFamily = 'Rubik, sans-serif';

const defaultTransition = {
  transition: 'all 0.15s'
};

export const CustomFieldsContainer = styled.section`
  margin-top: 24px;
  max-height: 100vh;

  & > :first-child > * {
    margin-left: 0;
  }
`;

export const Title = styled.h2`
  font-family: ${defaultFontFamily};
  font-size: 32px;
  font-weight: 500;
  text-align: left;
  color: #333333;
`;

export const SubTitle = styled.h3`
  font-family: ${defaultFontFamily};
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: #333333;
`;

export const Main = styled.main`
  display: flex;
`;

export const FieldsContainer = styled.div`
  width: 40%;
`;

export const CustomFieldList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

export const CustomFieldItem = styled.li`
  width: 100%;
  padding: 16px 32px;
  margin: 0;
  border-radius: 4px;
  color: ${(props) =>
    props.active ? 'rgb(255, 255, 255);' : 'rgb(33, 37, 41)'};
  background-color: ${(props) =>
    props.active ? `${teamStyle.inpuStyle.colorInput}` : 'rgb(244, 246, 251)'};
  cursor: pointer;
  ${defaultTransition};

  &:hover {
    box-shadow: inset 0px 0px 0px 2px ${teamStyle.inpuStyle.colorInput};
  }

  & + & {
    margin-top: 16px;
  }
`;

export const AnswersContainer = styled.div`
  width: 60%;
  padding: 0 48px;
  ${defaultTransition};
`;

export const AnswersForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }

  & > :last-child {
    align-self: flex-end;
  }
`;

export const AnswersList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

export const AnswerItem = styled.li`
  width: 100%;
  padding: 16px 32px;
  margin: 0;
  border-radius: 4px;
  background-color: rgb(244, 246, 251);
  & + & {
    margin-top: 16px;
  }
`;

export const UserName = styled.h4`
  font-family: ${defaultFontFamily};
  font-size: 18px;
  font-weight: 500;
`;

export const UserAnswer = styled.h5`
  font-family: ${defaultFontFamily};
  font-size: 14px;
  font-weight: 400;
`;
