import React, {Component} from 'react';
import { Chip } from 'material-ui';
import RemoveIcon from '../utils/img/ic-excluir.svg'
import EditIcon from '../utils/img/ic-editar.svg'
require('./eventrow.css')

export default class EventRow extends Component {

  render() {

      return (
        <div className='container'>
          <div className='body-event-row' >
            <div className='row-header-event' >
              <label className='title-event-row' >{this.props.event.title.slice(0,20)+'...'}</label>
              <div className='body-event-img'>
                <img  onClick={this.props.editEvent.bind(this,this.props.event.id)} className='img-remove-event'  src={EditIcon} alt="editar"/>
                <img onClick={this.props.deleteShowDialogEvent.bind(this,this.props.event.id)} className='img-edit' src={RemoveIcon} alt="apagar"/>
              </div>
            </div>
            <p className='description-event-row' >{this.props.event.description}  </p>
          </div>
          <div className='tag-event-row-content' >
            {
              Array.isArray(this.props.event.tags)?(
                this.props.event.tags.map(function(tag,i){
                  if(i<3){
                  return(<Chip style={{marginRight:'5px'}} ><label className='tag-event-row' >{ tag.name.length>10?(tag.name.slice(0,10)+'...'):(tag.name)}</label></Chip>)
                  }
                })
              ):(null)
            }
          </div>
         <div className='divisori-event-row'></div>
        </div>
      );
  }
}

