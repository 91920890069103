import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { DialogProgressIndeterminated } from '../../../../utils/DailogComponentes';
import { NewDropZoneFileForm } from '../../../../utils_form/FormComponents';
import fileTypeFilter from '../../../../utils/fileTypeFilter';
import ExtensionsModal from '../../../../repository/Components/ExtensionsModal/ExtensionsModal';

import IcoRemove from '../../../../utils/img/ic-excluir.svg';
import CardContentFile from '../../../../utils/img/card-content-file.svg';

import '../CourseLongStyles.css';

export class FileModule extends Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };

    this.addFile = this.addFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: this.props.loading.loadingCourse});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      loading: nextProps.loading.loadingCourse
    });
  }

  checkInvalidFileType = (files) => {
    let invalidFile = false;

    files.forEach((file) => {
      if (!fileTypeFilter(file.type)) {
        invalidFile = true;
      }
    });

    return invalidFile;
  };
  
  addFile(event, newFiles, value) {
    const newConvertedFiles = [];

    if (this.checkInvalidFileType(newFiles)) {
      return;
    }

    newFiles.map((file) => {
      file.name_file = file.name;
      newConvertedFiles.push(file);
    });

    this.props.patchFileContent(this.props.index, this.props.indexContent, newConvertedFiles);
  }

  removeFile(key, fileId) {
    this.props.removeFileContent(this.props.index, this.props.indexContent, fileId)
  }

  render() {

    return (
      <div className="container-content-submodule">
        <div className="loading-content">
          {this.props.loading.loadingCourse &&
            this.props.loading.indexContent === this.props.content.id && (
              <DialogProgressIndeterminated />
            )}
        </div>
        <div className="container-content-submodule-quiz">
            <div className="header-content-submodule">
                <div className="header-title-img">
                    <img src={CardContentFile} alt="Componente de File" />
                    <div className="header-content-description">
                        <p>ARQUIVOS</p>
                    </div>
                </div>
                <div className="removed-submodule">
                    <img
                        src={IcoRemove}
                        alt="Remover Conteudo"
                        onClick={() =>
                        this.props.removedSubModuleAndContent(
                            this.props.content.id,
                            'content'
                        )
                        }
                    />
                </div>
            </div>
            <div className='content-submodule-file content-submodule-file2'>
                <div className="body-send-file body-send-file2">
                    <div className="send-file send-file2">
                      <Field
                        onDrop={this.onDrop}
                        showAlert={this.props.showErroAlert}
                        name="file"
                        onChange={this.addFile}
                        component={(e)=> NewDropZoneFileForm({...e, dispatchImageError: this.props.dispatchImageError})}
                      />
                    </div>
                    <div className="list-send-file list-send-file2">
                      <div
                        style={{display:'flex', fontSize: '18px'}}
                      >
                        <p className="title-list-file title-list-file2">Lista de arquivos</p> 
                        <ExtensionsModal style={{marginTop:'15px', height:'15px', width:'15px'}}/>
                      </div>
                      <ul className="body-list-file body-list-file2">
                        {this.props.content.content_files &&
                          this.props.content.content_files.map((file, key) => {
                            return (
                              <li className="file-selected file-selected2">
                                <div className="file-name file-name2" title={file.name_file}>
                                  {file.name_file.length > 15
                                    ? `${file.name_file.slice(0, 15)} ...`
                                    : file.name_file}
                                </div>
                                <div>
                                  <img
                                    type="button"
                                    src={IcoRemove}
                                    onClick={() => this.removeFile(key, file.id)}
                                    alt="apagar"
                                  />
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

let InitializeFromStateForm = reduxForm({
    form: 'createfile'
})(FileModule);

const mapDispatchToProps = (dispatch) => {
    return {
    //   saveFile: (values, files, idfolder, sendType, notify_users) => {
    //     dispatch(
    //       RepositorieApi.saveFile(values, files, idfolder, sendType, notify_users)
    //     );
    //   },
      showErroAlert: (message) =>
        dispatch({
          type: 'OPEN_ERROR',
          alert: { visible: true, message: message }
        }),
      dispatchImageError:()=>{
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message:"Não foi possível carregar a imagem",
            submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
          }
        });
      },
    };
  };
InitializeFromStateForm = connect(
  () => ({
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
// export default FileModule;
