import React, { Component } from 'react';
import IcoDown from '.././utils/ic-down.png';
import IcoUp from '.././utils/ic-up.png';

require('./headerinteraction.css');

class HeaderComparison extends Component {
  render() {
    if (this.props.interaction) {
      return (
        <div>
          <div className="header-interaction-row-display">
            <div className="element-header-interaction">
              <label className="main-label-interaction">
                <img
                  className="ic-porc-interaction"
                  src={
                    this.props.interaction.amount_difference > 0
                      ? IcoUp
                      : IcoDown
                  }
                  alt="ícone comparação"
                ></img>
                {this.props.interaction.amount_difference}
              </label>
              <label className="secondary-label-interaction">
                ENGAJAMENTO TOTAL
              </label>
              {this.props.interaction.percentage > 0 ? (
                <div className="row-porc">
                  <label className="porc-label-positive">
                    +{parseInt(this.props.interaction.percentage)}%
                  </label>
                </div>
              ) : (
                <div className="row-porc">
                  <label className="porc-label-negative">
                    {parseInt(this.props.interaction.percentage)}%
                  </label>
                </div>
              )}
            </div>
            <div className="border-right-element"></div>
            <div className="element-header-interaction">
              <label className="main-label-interaction">
                <img
                  className="ic-porc-interaction"
                  src={this.props.views.amount_difference > 0 ? IcoUp : IcoDown}
                  alt="ícone comparação"
                ></img>
                {this.props.views.amount_difference}
              </label>
              <label className="secondary-label-interaction">
                VISUALIZAÇÕES
              </label>
              {this.props.views.percentage > 0 ? (
                <div className="row-porc">
                  <label className="porc-label-positive">
                    +{parseInt(this.props.views.percentage)}%
                  </label>
                </div>
              ) : (
                <div className="row-porc">
                  <label className="porc-label-negative">
                    {parseInt(this.props.views.percentage)}%
                  </label>
                </div>
              )}
            </div>
            <div className="border-right-element"></div>
            <div className="element-header-interaction">
              <label className="main-label-interaction">
                <img
                  className="ic-porc-interaction"
                  src={
                    this.props.comments.amount_difference > 0 ? IcoUp : IcoDown
                  }
                  alt="ícone comparação"
                ></img>
                {this.props.comments.amount_difference}
              </label>
              <label className="secondary-label-interaction">COMENTÁRIOS</label>
              {this.props.comments.percentage > 0 ? (
                <div className="row-porc">
                  <label className="porc-label-positive">
                    +{parseInt(this.props.comments.percentage)}%
                  </label>
                </div>
              ) : (
                <div className="row-porc">
                  <label className="porc-label-negative">
                    {parseInt(this.props.comments.percentage)}%
                  </label>
                </div>
              )}
            </div>
            <div className="border-right-element"></div>
            <div className="element-header-interaction">
              <label className="main-label-interaction">
                <img
                  className="ic-porc-interaction"
                  src={this.props.likes.amount_difference > 0 ? IcoUp : IcoDown}
                  alt="ícone comparação"
                ></img>
                {this.props.likes.amount_difference}
              </label>
              <label className="secondary-label-interaction">CURTIDAS</label>
              {this.props.likes.percentage > 0 ? (
                <div className="row-porc">
                  <label className="porc-label-positive">
                    +{parseInt(this.props.likes.percentage)}%
                  </label>
                </div>
              ) : (
                <div className="row-porc">
                  <label className="porc-label-negative">
                    {parseInt(this.props.likes.percentage)}%
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default HeaderComparison;
