import API from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import { browserHistory } from 'react-router';
import imageTypeFilter from '../componentes/utils/imageTypeFilter';
import { style } from '../style';

const parseApp = (appEdit) => {
  let app = {};
  app.id = appEdit.id;
  app.name = appEdit.name;
  app.url = appEdit.url;
  app.image = appEdit.image.url;

  return app;
};

const isValidImage = (values) => {
  let image = {
    isInvalid: false,
  };

  if (values.image[0].name !== undefined) {
    image.name = values.image[0].name;
    image.isInvalid = !imageTypeFilter.includes(values.image[0].type);

    if (image.name.length > 30) {
      image.name = `${image.name.substring(0, 15)}(...)${image.name.slice(
        image.name.length - 15
      )}`;
    }
  }

  if (image.isInvalid) {
    return image;
  }
  return values;
};

export const getAppList = () => async (dispatch) => {
  try {
    const { data: apps } = await API.get('/external_links', Config.headers());

    dispatch({
      type: 'GET_APP_LIST',
      app_list: apps.reverse(),
    });
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: error.response.data.errors[0],
      },
    });
  }
};

export const saveAppCreate = (values) => async (dispatch) => {
  const invalidImage = await isValidImage(values);
  if (invalidImage.isInvalid) {
    dispatch({
      type: 'HIDE_STATUS',
      enablesubmit: true,
    });
    dispatch({
      type: 'END_LOAD',
    });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: `Imagem em formato não suportado: ${invalidImage.name}`,
      },
    });
    return;
  }

  const formData = new FormData();
  formData.append('name', values.name);
  if (values.image) {
    formData.append('image', values.image[0]);
  }
  formData.append('url', values.url);

  try {
    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

    await API.post('/external_links', formData, Config.headers());

    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });

    browserHistory.push('/app/list');
  } catch (error) {
    if (error.response.statusCode === 401) {
      browserHistory.push('./');
    } else {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: error.response.data.errors[0],
        },
      });
    }
  }
};

export const deleteApp = (id) => async (dispatch) => {
  try {
    let API_CLIENT = API;

    if (style.teamName.name === 'youseed') {
      API_CLIENT = APIDelete;
    }

    await API_CLIENT.delete(`external_links/${id}`, Config.headers());

    dispatch({
      type: 'CLOSE_ALERT',
      alert: {
        visible: false,
      },
    });
    dispatch({
      type: 'NO_SUBMITTING',
    });
    dispatch(getAppList());
  } catch (error) {
    if (error.response.statusCode === 401) {
      browserHistory.push('./');
    } else {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: error.response.data.errors[0],
        },
      });
    }
  }
};

export const patchAppEdit = (values) => async (dispatch) => {
  const invalidImage = await isValidImage(values);
  if (invalidImage.isInvalid) {
    dispatch({
      type: 'HIDE_STATUS',
      enablesubmit: true,
    });
    dispatch({
      type: 'END_LOAD',
    });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: `Imagem em formato não suportado: ${invalidImage.name}`,
      },
    });
    return;
  }

  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('image', values.image[0]);
  formData.append('url', values.url);

  try {
    await API.patch(`external_links/${values.id}`, formData, Config.headers());

    browserHistory.push('/app/list');
  } catch (error) {
    if (error.response.statusCode === 401) {
      browserHistory.push('./');
    } else {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: error.response.data.errors[0],
        },
      });
    }
  }
};

export const getEditApp = (id) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
    const { data: app } = await API.get(
      `external_links/${id}`,
      Config.headers()
    );

    dispatch({
      type: 'GET_EDIT_APP',
      app_edit: parseApp(app),
    });
    dispatch({
      type: 'NO_SUBMITTING',
    });
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
    browserHistory.push('/app/edit');
  } catch (error) {}
};
