import styled from 'styled-components';
import { style as teamStyle } from '../../../../../../style';

const defaultFont = 'Rubik, sans-serif';

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const SpecialPostContainer = styled.div`
  height: 100%;
  padding: 64px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SpecialPostTextContainer = styled.div``;

export const SpecialPostTitle = styled.h2`
  font-family: ${defaultFont};
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: #333333;
`;

export const SpecialPostDescription = styled.p`
  font-family: ${defaultFont};
  font-size: 16px;
  padding: 2px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.55;
`;

export const SpecialPostButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const SpecialPostButtonBody = styled.button.attrs({ type: 'button' })`
  height: 40px;
  width: 150px;
  border-radius: 5px;
  background-color: ${teamStyle.inpuStyle.colorInput};
  border: none;
  margin-left: 15px;
  cursor: pointer;
  appearance: none;
  ${defaultTransition};

  & + & {
    margin-left: 16px;
  }
`;

export const SpecialPostButtonLabel = styled.span`
  font-family: ${defaultFont};
  font-size: 15px;
  text-align: center;
  vertical-align: text-top;
  color: #ffffff;
`;
