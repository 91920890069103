export function dialog_score(state = false, action) {
  if (action.type === 'OPEN_DIALOG_SCORE') {
    return action.alert;
  }

  if (action.type === 'CLOSE_DIALOG_SCORE') {
    return action.alert;
  }

  return state;
}
