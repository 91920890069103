import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

export class BodyTable extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const bodyclass = {
      'border-radius': '10px',
      padding: this.props.isDispatchProducts ? '0' : '20px',
      'padding-top': this.props.isDispatchProducts ? '0' : '',
      'background-color': '#ffffff',
      'box-shadow': this.props.isDispatchProducts ? 'none': '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
      'margin-top': '10px',
      'margin-bottom': '10px'
    };
    return (
      <MuiThemeProvider>
        <div className="container" style={this.props.isDispatchProducts ? {minWidth:"100%",padding:"0"}:{}}>
            <div style={bodyclass}>{this.props.children}</div>
        </div>
      </MuiThemeProvider>
    );
  }
}
