import styled from 'styled-components';

export const ModalContentBody = styled.div`
  padding: 32px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ModalForm = styled.form`
  width: 80%;
`;

export const CloseButton = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const ModalTitle = styled.h2`
  font-family: Rubik;
  font-size: 18.5px;
  font-weight: 500;
  color: #3b3b3b;
`;
