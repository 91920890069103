import styled, { keyframes } from 'styled-components';
import { style as teamStyle } from '../../../../style';

const loaderAnimation = keyframes`
  0%{
      left: -45%;
  }
  100%{
      left: 100%;
  }
`;

export const hexToRgbA = (hex, alpha) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const FieldPlaceholderContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${({ height }) => height};
  background-color: rgb(244, 246, 251);
  z-index: 44;
  overflow: hidden;
  border-radius: 4px;

  & + & {
    margin-top: 16px;
  }
`;

export const FieldPlaceholderLoader = styled.div`
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    ${hexToRgbA(teamStyle.inpuStyle.colorInput, 0.05)},
    ${hexToRgbA(teamStyle.inpuStyle.colorInput, 0.3)},
    ${hexToRgbA(teamStyle.inpuStyle.colorInput, 0.6)},
    ${hexToRgbA(teamStyle.inpuStyle.colorInput, 0.3)},
    ${hexToRgbA(teamStyle.inpuStyle.colorInput, 0.05)}
  );
  animation: ${loaderAnimation} 1s infinite;
  z-index: 10;
`;
