import styled from 'styled-components';
import { style as teamStyle } from '../../../../style';

const fontFamily = { fontFamily: 'Rubik, sans-serif' };

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const ToolsComponentHeader = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
`;

export const ToolsComponentHeaderTitle = styled.div``;

export const ToolsComponentHeaderTitleValue = styled.h1`
  ${fontFamily};
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
  padding: 20px;
`;

export const ToolsContentTitleWrapper = styled.div`
  background-color: #eee;
`;

export const ToolsContentTitle = styled.h2`
  padding: 32px 0;
`;

export const ToolsComponentHeaderButtons = styled.div`
  .btn-click:hover {
    font-size: inherit;
  }
`;

export const FormContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FormTitle = styled.h2`
  ${fontFamily};
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
  padding: 20px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
`;
