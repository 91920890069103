import styled from 'styled-components';
import { style } from '../../style';

export const ContainerDrop = styled.div`
  background-color: #F4F6FB;
  width: 500px;
  height: 270px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p{
    color: rgba(125, 134, 161, 1);
    font-size: 16px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    max-width:342px;
  }

`

export const DropHover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  >p{
    font-size: 20px !important;
  }
  &:hover {
    cursor: pointer;
    p{
      color: ${style.inpuStyle.colorInput} !important;
    }
    svg > path{
      fill: ${style.inpuStyle.colorInput} !important;
    }
  }
`
