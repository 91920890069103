import React, { Component } from 'react';
import Pulse from 'react-reveal/Pulse';
import { connect } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import WrapPublicationDash from './PublicationDash/WrapPublicationDash';
import HeaderController from './HeaderControlle/HeaderController';
import WrapUserDash from './UserDash/WrapUserDash';
import WrapStoreDash from './StoreDash/WrapProductDash';
import WrapCourseDash from './CourseDash/WrapCourseDash';
import StoreApi from '../../../Logicas/StoreApi';
import * as DashBoardApi from '../../../Logicas/DashBoardApi';
import { style } from '../../../style';
import teamSettings from '../../utils/TeamsSettings/generalSettings';
var Spinner = require('react-spinkit');
require('./controllpainel.css');
require('./homedash.css');

const { hasCourses } = teamSettings();
class HomeDash extends Component {
  constructor() {
    super();
    this.state = { kind_list_user: 'Usuários em destaque', users_show: [] };
  }

  componentWillMount() {
    this.getTabContent('PUBLICAÇÕES');
  }

  getTabContent = (tabName) => {
    this.props.getTabContent(
      tabName,
      this.props.first_date_start,
      this.props.first_date_end
    );
  };

  render() {
    if (!this.props.progress_indeterminate) {
      return (
        <div className="container">
          <MuiThemeProvider>
            <HeaderController
              getTabContent={this.getTabContent}
              dashboardTab={this.props.dashboardTab}
              actions={[
                'PUBLICAÇÕES',
                'USUÁRIOS',
                'LOJA',
                `${hasCourses.value.toUpperCase()}`
              ]}
            />
            {this.props.dashboardTab === 'PUBLICAÇÕES' && (
              <div>
                <Pulse>
                  <WrapPublicationDash
                    get_by_filter={this.props.getDashBoardValues}
                    more_popular_graphic={
                      this.props.publications.more_popular_graphic
                    }
                    more_popular_publication={
                      this.props.publications.more_popular_publication
                    }
                    tag_use={this.props.publications.tags_with_more_use}
                    views={this.props.publications.number_of_visualizations}
                    commets={this.props.publications.number_of_comments}
                    likes={this.props.publications.number_of_Likes}
                    interaction={this.props.publications.number_of_interactions}
                    getDashBoardValues={this.props.getDashBoardValues}
                  />
                </Pulse>
              </div>
            )}
            {this.props.dashboardTab === 'USUÁRIOS' && (
              <Pulse>
                <WrapUserDash
                  user_no_interaction={this.props.users.less_active_users}
                  user_with_interaction={this.props.users.most_active_users}
                  user_speaker={this.props.users.most_influential_speaker}
                  users_devices={this.props.users.users_devices}
                  users_value={{
                    users_length: this.props.users.get_users_length,
                    users_length_admin: this.props.users.users_length_admin,
                    users_logged: this.props.users.users_logged,
                    users_length_speaker: this.props.users.users_length_speaker
                  }}
                />
              </Pulse>
            )}
            {this.props.dashboardTab === 'LOJA' && (
              <Pulse>
                <WrapStoreDash
                  products_rescued={this.props.store.products_ordered}
                  products_without_stock_list={
                    this.props.store.products_without_stock_list
                  }
                  new_products={this.props.store.new_products}
                  delivered_products={this.props.store.delivered_products}
                  products_without_stock={
                    this.props.store.products_without_stock
                  }
                  product_length={this.props.store.product_length}
                  editProduct={this.props.editProduct}
                />
              </Pulse>
            )}
            {this.props.dashboardTab ===
              `${hasCourses.value.toUpperCase()}` && (
              <Pulse>
                <WrapCourseDash
                  course_length={this.props.courses.course_length}
                  submodule_lenght={this.props.courses.submodule_lenght}
                  courses_finished={this.props.courses.courses_finished}
                  module_length={this.props.courses.module_length}
                />
              </Pulse>
            )}
          </MuiThemeProvider>
        </div>
      );
    } else {
      return (
        <div className="load-graphic ">
          <Spinner name="cube-grid" color={style.inpuStyle.colorInput} />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    progress_indeterminate: state.progress_indeterminate,
    alert: state.dialog,
    publications: state.dashboard.publications,
    users: state.dashboard.users,
    store: state.dashboard.store,
    courses: state.dashboard.courses,
    dashboardTab: state.dashboard.dashboardTab,
    first_date_start: state.dashboard.first_date_start,
    first_date_end: state.dashboard.first_date_end
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTabContent: (dashboardTab, first_date_start, first_date_end) => {
      dispatch(
        DashBoardApi.getTabContent(
          dashboardTab,
          first_date_start,
          first_date_end
        )
      );
    },
    editProduct: (id, value) => {
      dispatch(StoreApi.editProduct(id));
    }
  };
};
const HomeListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeDash);

export default HomeListContainer;
