import styled from 'styled-components';

const fontFamily = { fontFamily: 'Rubik, sans-serif' };

export const ToolsComponentHeader = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
`;

export const ToolsComponentHeaderTitle = styled.div``;

export const ToolsComponentHeaderTitleValue = styled.h1`
  ${fontFamily};
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
  padding: 20px;
`;

export const ToolsComponentHeaderButtons = styled.div`
  .btn-click:hover {
    font-size: inherit;
  }
`;

export const ToolsContentBody = styled.div``;

export const ToolsContentBodySection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &:not(:first-of-type) {
    margin-top: 48px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 40px auto;
  min-width: 540px;
`;

export const Header = styled.div`
  border-bottom: 0.5px solid #ccd2e0;
  padding: 11.5px;
  display: flex;
`;

export const HeaderText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: #7d86a1;
  width: ${({ width }) => width};
`;

export const Ul = styled.ul`
  padding-left: 11.5px;
  list-style: none;
`;

export const Li = styled.li`
  margin: 0;
  padding: 21px 0;
  border-bottom: 0.5px solid #ccd2e0;
  list-style: none;
`;

export const LiText = styled.span`
  width: ${({ width }) => width};
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  color: #2f313d;
`;

export const Name = styled.span`
  color: #bdbdbd;
`;
