import styled, { css, keyframes } from 'styled-components';
import { style as teamStyle } from '../../../../../../style';

const defaultTransition = {
  transition: 'transform 0.33s'
};

const rotateBorder = keyframes`
    0% {
      background-position: left top, right bottom, left bottom, right   top;
    }
    100% {
      background-position: left 15px top, right 15px bottom , left bottom 15px , right   top 15px;
    }
`;

export const ButtonBody = styled.button.attrs({ type: 'button' })`
  height: 40px;
  width: 150px;
  margin: ${(props) => props.withoutMargin
    ? '0'
    : '32px 0'};

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: ${(props) =>
    !props.bothDatesRequired && !props.isActive
      ? '2px solid rgb(201, 199, 199)'
      : props.bothDatesRequired && !props.isActive
      ? `2px solid ${teamStyle.inpuStyle.colorInput}`
      : 'none'};
    
  border-radius: 5px;
  appearance: none;
  position: relative;
  overflow: hidden;
  ${defaultTransition};

  ${(props) =>
    props.isActive &&
    css`
      background-image: linear-gradient(
          90deg,
          ${teamStyle.inpuStyle.colorInput} 50%,
          transparent 50%
        ),
        linear-gradient(
          90deg,
          ${teamStyle.inpuStyle.colorInput} 50%,
          transparent 50%
        ),
        linear-gradient(
          0deg,
          ${teamStyle.inpuStyle.colorInput} 50%,
          transparent 50%
        ),
        linear-gradient(
          0deg,
          ${teamStyle.inpuStyle.colorInput} 50%,
          transparent 50%
        );
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
      background-position: left top, right bottom, left bottom, right top;
      animation: ${rotateBorder} 1s infinite linear;
    `}

  cursor: ${(props) =>
    props.isActive || !props.bothDatesRequired
      ? 'default !important'
      : 'pointer'};
`;

export const ButtonLabel = styled.span`
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: text-top;
  color: ${(props) =>
    !props.bothDatesRequired
      ? 'rgb(201, 199, 199)'
      : `${teamStyle.inpuStyle.colorInput}`};
`;
