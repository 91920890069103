import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import { Button } from '../../../user/components/UsersHeader/UsersHeader.styles';
import IcoExit from '../../../utils/img/exit-dialog.png';
import UserApi from '../../../../Logicas/UserApi';

import * as S from './DialogResendWelcome.styles';
import { WarningText } from '../../../publication/dialog/DialogReportEmail/DialogReportEmail.styles';

export class ResendWelcome extends Component {
  componentWillMount() {
    this.props.getNotLoggedUsersCount();
  }

  render() {
    const {
      showResendDialog,
      closeResendDialog,
      requesting_resend,
      resend_success,
      users_count,
      resendWelcomeEmails
    } = this.props;
    return (
      <Modal
        effect="fadeInUp"
        width="500px"
        height="340px"
        visible={showResendDialog}
      >
        <S.CloseButton src={IcoExit} onClick={() => closeResendDialog()} />
        <S.ModalContentBody>
          {!resend_success ? (
            <>
              <S.ModalTitle>Reenviar e-mail de boas vindas</S.ModalTitle>
              <S.ModalText>
                Esta ação reenviará o e-mail de boas vindas<br/>com informações
                sobre a plataforma e instruções<br/>de acesso à todos os usuários
                cadastrados<br/>que ainda não efetuaram login.
                <br />
                <br />
                Atualmente <WarningText>{users_count}</WarningText> usuários não
                realizaram login.
              </S.ModalText>
              {!requesting_resend ? (
                <Button onClick={resendWelcomeEmails}>Solicitar reenvio</Button>
              ) : (
                <Button>Solicitando...</Button>
              )}
            </>
          ) : (
            <>
              <S.ModalTitle>Reenvio solicitado com sucesso!</S.ModalTitle>
              <S.ModalText>
                Os e-mails de boas vindas serão reenviados <br /> para os
                usuários que não realizaram login.
              </S.ModalText>
              <Button onClick={closeResendDialog}>OK</Button>
            </>
          )}
        </S.ModalContentBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  showResendDialog: state.dialog_resend_welcome.visible,
  requesting_resend: state.dialog_resend_welcome.requesting_resend,
  resend_success: state.dialog_resend_welcome.resend_success,
  users_count: state.dialog_resend_welcome.users_count
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeResendDialog: () =>
      dispatch({
        type: 'CLOSE_RESEND_WELCOME'
      }),
    resendWelcomeEmails: () => dispatch(UserApi.resendWelcomeEmails()),
    getNotLoggedUsersCount: () => dispatch(UserApi.getNotLoggedUsersCount())
  };
};

const DialogResendWelcome = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendWelcome);

export default DialogResendWelcome;
