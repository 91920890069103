import styled from 'styled-components';
import { style as profile } from '../../style';

export const dialog = styled.dialog`
  background-color: ${profile.inpuStyle.colorInput};
`;
export const ButtonConfirm = styled.button`
  width: 110px;
  height: 40px;
  border-radius: 7px;
  background-image: linear-gradient(
    to right,
    ${profile.inpuStyle.colorInput},
    ${profile.inpuStyle.colorInput}
  );
  border: none;
  margin-top: ${(props) => (props.withoutMarginTop ? '25px' : '50px')};
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  &:hover ${ButtonConfirm} {
    cursor: pointer;
  }
`;

export const BodyDialog = styled.div`
  border-radius: 5px;
  height: 100%;
  border-top: 8px solid ${profile.inpuStyle.colorInput};
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .span-user-name, .span-user-address,.span-user-phoneNumber{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 90%;

    display: flex;
    align-items: center;
    text-align: center;

    color: #7B7B7B;
  }
`;

export const BorderTop = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 8px solid ${profile.inpuStyle.colorInput};
  margin-top: -2.5%;
  margin-right: -2.5%;
  margin-left: -2.5%;
  border-radius: 5px;
`;

export const LabelLoad = styled.span`
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  position: absolute;
  width: 7% !important;
`;
