import React, { Component } from 'react';

import * as S from './SectorButton.style';

export class SectorButton extends Component {
  render() {
    return (
      <S.ButtonBody
        className="btn-click"
        onClick={this.props.onClick}
        type="button"
      >
        <S.ButtonLabel>{this.props.label}</S.ButtonLabel>
      </S.ButtonBody>
    );
  }
}

export default SectorButton;
