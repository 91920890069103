import React, { Component } from 'react';
import { Avatar } from 'material-ui';
require('./userspeaker.css');
class UserSpeaker extends Component {
  render() {
    return (
      <div className="speaker-card-body">
        <div className="speaker-display-row">
          <Avatar src={this.props.user.image && this.props.user.image.url} />
          <div className="speaker-row">
            <label className="label-name-speaker">{this.props.user.name}</label>
            <label className="speaker-label">speaker mais influente</label>
          </div>
        </div>
      </div>
    );
  }
}
export default UserSpeaker;
