import React, { Component } from 'react';

require('./headerinteraction.css');

class HeaderUser extends Component {
  render() {
    return (
      <div>
        <div className="header-interaction-row-display">
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.users_value.users_length}
            </label>
            <label className="secondary-label-interaction">
              USUÁRIOS NO TOTAL
            </label>
          </div>
          <div className="border-right-element"></div>
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.users_value.users_logged}
            </label>
            <label className="secondary-label-interaction">
              ACESSOS REALIZADOS
            </label>
          </div>
          <div className="border-right-element"></div>
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.users_value.users_length_speaker}
            </label>
            <label className="secondary-label-interaction">SPEAKERS</label>
          </div>
          <div className="border-right-element"></div>
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.users_value.users_length_admin}
            </label>
            <label className="secondary-label-interaction">
              ADMINISTRADORES
            </label>
          </div>
        </div>
      </div>
    );
  }
}
export default HeaderUser;
