import React, { Component } from 'react';

require('./headerinteraction.css');

class HeaderCourse extends Component {
  render() {
    return (
      <div>
        <div className="header-interaction-row-display">
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.course_length}
            </label>
            <label className="secondary-label-interaction">CURSOS</label>
          </div>
          <div className="border-right-element"></div>
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.submodule_lenght}
            </label>
            <label className="secondary-label-interaction">CONTÉUDOS</label>
          </div>
          <div className="border-right-element"></div>
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.module_length}
            </label>
            <label className="secondary-label-interaction">MÓDULOS</label>
          </div>
        </div>
      </div>
    );
  }
}
export default HeaderCourse;
