import React, { Component } from 'react';
import ButtonDefault, {
  DropDownSingle,
  TextFieldDefault,
  LongTextField,
  TextFieldNumberInput
} from '../../utils/FormsComponents';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../validation/FormValidation';
import { MuiThemeProvider } from 'material-ui/styles';
import DialogApi from '../../../Logicas/DialogApi';
import PreviewContentText from '../../previews/PreviewContentText';
import ContentApi from '../../../Logicas/ContentApi';

class EditContentText extends Component {
  constructor() {
    super();

    this.saveContent = this.saveContent.bind(this);
  }

  componentDidMount() {
    this.props.enableBtn();
  }

  saveContent(values) {
    this.props.patchContentText(
      values,
      this.props.initialValues.id,
      this.props.module.id
    );
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <MuiThemeProvider>
        <form
          formKey={
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15)
          }
          onSubmit={handleSubmit(this.saveContent)}
        >
          <div className="row">
            <div className="col-md-7"></div>
            <div className="col-md-4">
              <PreviewContentText
                title={this.props.content.title}
                text={this.props.content.textcontent}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Field
                name="title"
                validate={[required]}
                id="name"
                label="Título"
                component={TextFieldDefault}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="textcontent"
                rows={3}
                validate={[required]}
                id="name"
                label="Texto"
                component={LongTextField}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Field
                name="score"
                validate={[required]}
                id="name"
                label="Pontuação"
                component={TextFieldNumberInput}
              />
            </div>
          </div>
          <div className="col-md-3">
            <ButtonDefault label="Salvar" />
          </div>
        </form>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    patchContentText: (values, idcontent, idmodule) => {
      dispatch(ContentApi.patchContentText(values, idcontent, idmodule));
    },
    setContentEdit: (content) => {
      dispatch(ContentApi.editContentForm(content));
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    }
  };
};

let InitializeFromStateForm = reduxForm({
  form: 'random(),'
})(EditContentText);
var nameform = '';

InitializeFromStateForm = connect(
  (state, props) => ({
    alert: state.dialog,
    initialValues: props.content,
    module: state.module,
    form: 'form' + '/' + props.content.id
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
