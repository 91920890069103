import React, { Component } from 'react';
import Modal from 'react-awesome-modal';

import * as S from './ExtensionsModal.styles';

export class ToolTip extends Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false
    };
  }
  render() {
    const { isModalOpen } = this.state;
    return (
      <>
        <S.ToolTipBody
          data-tooltip="Clique para ver a lista de arquivos permitidos"
          onClick={() => this.setState({ isModalOpen: true })}
          style={this.props.style}
        >
          ?
        </S.ToolTipBody>
        <Modal visible={isModalOpen} width="700" height="400" effect="fadeInUp">
          <S.ModalContent data-testid="tootltipModal">
            {isModalOpen && (
              <S.ModalTitle>
                É possível adicionar arquivos com as seguintes extensões:
              </S.ModalTitle>
            )}
            <S.ModalList>
              <S.ModalListItem>.txt</S.ModalListItem>
              <S.ModalListItem>.odt</S.ModalListItem>
              <S.ModalListItem>.rtf</S.ModalListItem>
              <S.ModalListItem>.docx</S.ModalListItem>
              <S.ModalListItem>.doc</S.ModalListItem>
              <S.ModalListItem>.xls</S.ModalListItem>
              <S.ModalListItem>.xlsx</S.ModalListItem>
              <S.ModalListItem>.xlsm</S.ModalListItem>
              <S.ModalListItem>.csv</S.ModalListItem>
              <S.ModalListItem>.xml</S.ModalListItem>
              <S.ModalListItem>.ppt</S.ModalListItem>
              <S.ModalListItem>.pptx</S.ModalListItem>
              <S.ModalListItem>.form</S.ModalListItem>
              <S.ModalListItem>.prezi</S.ModalListItem>
              <S.ModalListItem>.html</S.ModalListItem>
              <S.ModalListItem>.png</S.ModalListItem>
              <S.ModalListItem>.gif</S.ModalListItem>
              <S.ModalListItem>.jpeg</S.ModalListItem>
              <S.ModalListItem>.rtf</S.ModalListItem>
              <S.ModalListItem>.pdf</S.ModalListItem>
              <S.ModalListItem>.odt</S.ModalListItem>
              <S.ModalListItem>.ods</S.ModalListItem>
              <S.ModalListItem>.odp</S.ModalListItem>
            </S.ModalList>
            <S.ButtonBody onClick={() => this.setState({ isModalOpen: false })}>
              <S.ButtonLabel>Fechar</S.ButtonLabel>
            </S.ButtonBody>
          </S.ModalContent>
        </Modal>
      </>
    );
  }
}

export default ToolTip;
