export function alert_term_use(
  state = { visible: false, message: '' },
  action
) {
  if (action.type === 'OPEN_DIALOG_TERM') {
    return action.alert;
  }
  if (action.type === 'CLOSE_DIALOG_TERM') {
    return action.alert;
  }

  return state;
}
