import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { debounce } from 'lodash';
import PubSub from 'pubsub-js';

import TextModule from '../canvas/components/forms/Modules/TextModule';
import ImageModule from '../canvas/components/forms/Modules/ImageModule';
import VideoModule from '../canvas/components/forms/Modules/VideoModule';
import CarouselModule from '../canvas/components/forms/Modules/CarouselModule';
import SurveyModule from '../canvas/components/forms/Modules/SurveyModule';
import QuizModule from '../canvas/components/forms/Modules/QuizModule';
import OpenQuestionModule from '../canvas/components/forms/Modules/OpenQuestionModule';
import { AddFormContent } from './AddFormContent';
import {AddPostContent} from './AddPostContent';
import ModalCanvasMidia from '../canvas/components/dialog/DialogMidia';
import DrawModal from '../canvas/components/forms/CanvasComponents/DrawModal/DrawModal';

import { style } from '../../style';
import PostApi from '../../Logicas/PostApi';
import * as FormApiAsync from '../../Logicas/FormApiAsync';
import * as PostApiAsync from '../../Logicas/PostApiAsync';
import ToolTipLink from '../canvas/components/toolstips/TooltipLink';

import styled from 'styled-components';

import { ReactComponent as IconInfo } from './icons/ic-info.svg';
import { ReactComponent as IconText } from './icons/add_text.svg';
import { ReactComponent as IconVideo } from './icons/add_video.svg';
import { ReactComponent as IconImages } from './icons/add_images.svg';
import { ReactComponent as IconImage } from './icons/add_image.svg';
import { ReactComponent as IconLink } from './icons/add_link.svg';
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
import { ReactComponent as IconOpenQuestion } from './icons/open-question.svg';
import { ReactComponent as IconRadioButton } from './icons/radio-button.svg';
import { ReactComponent as IconCheckbox } from './icons/checkbox.svg';

import './createOrEdit.css';

const Link = styled.a`
  background-color: rgb(244, 246, 251);
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 5px;
  transition: all .3s ease-out;

  word-wrap: break-word;
  
  p{
    text-decoration: none;
    color: ${style.inpuStyle.colorInput};
    font-size: 18px;
    line-height: 24px;
    margin: 0;
  }

  svg path{
    stroke: #808080;
  }

  &:hover{
    text-decoration: none;
    background-color: ${style.inpuStyle.colorInput};
    p{
      color: white;
    }

    svg path{
      stroke: white;
    }

  }
`;

const InfoAboutCover = styled.span`
    margin: 15px auto 40px;
    
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'Rubik';
    font-size: 14px;
    color: ${style.inpuStyle.colorInput};

    >svg{
      margin-right: 10px;
      path{
        stroke: ${style.inpuStyle.colorInput};
      }
    }
`;

const RaffleConguration = styled.div`
  font-weight: 500;
  margin: 40px auto 10px;
  padding: 16px 32px;
  gap: 8px;

  background: #F4F6FB;
  color: #808080;
  border-radius: 6px;

  &:hover{
    cursor: pointer;
    background-color:${style.inpuStyle.colorInput};
    color:white;
  }
`


const DragHandle = sortableHandle(() => (
  <svg
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
      fill="#BDBDBD"
    />
  </svg>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul style={{ marginTop: '10px', padding:"0" }}>{children}</ul>;
});

class PostContents extends Component {
  constructor() {
    super();
    this.state = {
      elements: [],
      items: [],
      timer: 0,
      fixedMenu: false,
      showinitialcomponente: false,
      detailsPostDraw: 'Post',
      showBtnCarousel: false,
      selectionStart: 0,
      showToolTipText: false
    };
    this.InsertComponentText = this.InsertComponentText.bind(this);
    this.setComponentsToState = this.setComponentsToState.bind(this);
    this.InsertComponentImage = this.InsertComponentImage.bind(this);
    this.InsertComponentVideo = this.InsertComponentVideo.bind(this);
    this.InsertComponentCarousel = this.InsertComponentCarousel.bind(this);
    this.InsertComponentLink = this.InsertComponentLink.bind(this);
    this.InsertContentQuestion = this.InsertContentQuestion.bind(this);
    this.timer = this.timer.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.contaQuestions = this.contaQuestions.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.arrayMoveMutate = this.arrayMoveMutate.bind(this);
    this.arrayMove = this.arrayMove.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  componentWillMount() {
    const { getTags, editpostform } = this.props;
    this.setState({ elements: editpostform.elements });

    // if (
    //   /post\/form\/edit/.test(window.location.href) ||
    //   /post\/form\/create/.test(window.location.href)
    // ) {
    //   editForm(this.props);
    // }
  }

  timer(id, event, target) {
    const elements = [...this.state.elements];

    const currentText = elements.find((element) => element.id === id);
    currentText.text = target.getData();
    const currentTextValue = currentText.text;

    const currentTextIndex = elements.findIndex((element) => element.id === id);
    elements[currentTextIndex].text = currentTextValue;

    this.setState({ elements });
    this.delayedQuery(currentText, currentTextValue);
  }

  delayedQuery = debounce((currentText, currentTextValue) => {
    this.props.saveContentEdit(
      this.props.editpostform.id,
      currentTextValue,
      currentText.id
    );
  }, 1000);

  contaQuestions = debounce((idContent, value, idPatch, typeQuestion) => {
    this.props.saveContentEditQuestions(
      idContent,
      value,
      idPatch,
      typeQuestion
    );
  }, 1000);

  contaOptionsV2 = (value, idContent, idQuestion, type) => {
    let aux = this.state.elements;
    if (value.length <= 255) {
      const element = aux.find((element) => element.id === idContent);
      const content = element.content_survey.find(
        (content) => content.id === idQuestion
      );
      aux[aux.indexOf(element)].content_survey[
        element.content_survey.indexOf(content)
      ].title = value;

      if (value && value.length > 0) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.props.saveContentEditOptions(
            this.props.editpostform.id,
            value,
            idContent,
            idQuestion,
            type
          );
        }, 1500);
      }

      this.setState({
        elements: aux
      });
    }
  };

  componentDidMount() {
    window.onscroll = () => this.handleScroll();
    PubSub.subscribe(
      're-render-canvas',
      function(render) {
        this.setState({ elements_preview: [] });
      }.bind(this)
    );
    if (this.props.editpostform.elements !== undefined) {
      if (this.props.editpostform.elements.length > 0) {
        this.setState({ showinitialcomponente: false });
      }
    }
  }
  handleScroll() {
    if (document.documentElement.scrollTop > 250) {
      this.setState({ fixedMenu: true });
    } else {
      this.setState({ fixedMenu: false, showComponents: true });
    }
  }

  setComponentsToState(elements) {
    this.setState({ elements });
  }

  removeQuestion = async (idContent, idOption, type, length) => {
    switch (type) {
      case 'survey':
      case 'quiz':
        if (length > 2) {
          await this.props.deleteOptionQuestion(idContent, idOption, type);
        } else {
          const elements = [...this.state.elements];
          const newElements = elements.filter(
            (element) => element.id !== idContent
          );

          this.setState({ elements: newElements });

          await this.props.deleteQuestionContent(
            this.props.editpostform.id,
            idContent,
            type
          );
        }
        break;
      case 'open_question':
        const elements = [...this.state.elements];
        const newElements = elements.filter(
          (element) => element.id !== idOption
        );

        this.setState({ elements: newElements });

        await this.props.deleteQuestionContent(idContent, idOption, type);
    }
  };

  SortableItem = sortableElement(({ element, index }) => {
    switch (element.kind) {
      case 'text':
        return (
          <div style={{ display: 'flex', marginTop: '50px', width:"142%",minWidth: "450px" }}>
            <div style={{ height: '25px', cursor: 'pointer' }}>
              <DragHandle />
            </div>
            <div style={{width: '100%'}}>
              <span style={{marginLeft: '20px', color:'#808080', fontFamily: 'Rubik', fontSize: '14px'}}>
                <IconText style={{marginRight: '10px'}}/>
                Texto
              </span>
              <TextModule
                key={element.id}
                element={element}
                timer={this.timer}
                id_canvas={this.props.editpostform.id}
                elements={this.state.elements}
                setComponentsToState={this.setComponentsToState}
              />
            </div>
            
          </div>
        );
      case 'image':
        return (
          <div style={{ display: 'flex', marginTop: '50px', width:"160%" }}>
            <div style={{ height: '25px', cursor: 'pointer' }}>
              <DragHandle />
            </div>
            <div style={{width:'100%'}}>
              <span style={{marginLeft: '20px', color:'#808080', fontFamily: 'Rubik', fontSize: '14px'}}>
                <IconImage style={{marginRight: '10px'}}/>
                Imagem
              </span>
              <ImageModule
                key={element.id}
                element={element}
                id_canvas={this.props.editpostform.id}
                elements={this.state.elements}
                setComponentsToState={this.setComponentsToState}
              />
            </div>
          </div>
        );
      case 'video':
        if (element.canvas_content_videos.length > 0) {
          return (
            <div style={{ display: 'flex', marginTop: '50px', width:"160%"}}>
              <div style={{ height: '25px', cursor: 'pointer' }}>
                <DragHandle />
              </div>
              <div style={{width:'100%'}}>
                <span style={{marginLeft: '20px', color:'#808080', fontFamily: 'Rubik', fontSize: '14px'}}>
                  <IconVideo style={{marginRight: '10px'}}/>
                  Vídeo
                </span>
                <VideoModule
                  key={element.id}
                  element={element}
                  id_canvas={this.props.editpostform.id}
                  elements={this.state.elements}
                  setComponentsToState={this.setComponentsToState}
                />
              </div>
            </div>
          );
        }
      case 'carousel':
        if (element.canvas_content_images.length > 0) {
          return (
            <div style={{ display: 'flex', marginTop: '50px', width:"160%" }}>
              <div style={{ height: '25px', cursor: 'pointer' }}>
                <DragHandle />
              </div>
              <div style={{width:'100%'}}>
                <span style={{marginLeft: '20px', color:'#808080', fontFamily: 'Rubik', fontSize: '14px'}}>
                  <IconImages style={{marginRight: '10px'}}/>
                  Imagens
                </span>
                <CarouselModule
                  key={element.id}
                  element={element}
                  elements={this.state.elements}
                  id_canvas={this.props.editpostform.id}
                  link={element.link}
                  setComponentsToState={this.setComponentsToState}
                />
              </div>
            </div>
          );
        }
      case 'link':
        const show = {};
        const hide = {};
        show['showbtnlink' + element.id] = true;
        hide['showbtnlink' + element.id] = false;
        return (
          <div style={{ display: 'flex', marginTop: '50px', width:"160%" }}>
            <div style={{ height: '25px', cursor: 'pointer' }}>
              <DragHandle />
            </div>
            <div 
              style={{width: '150%'}}
              key={element.id}
            >
              <span style={{marginLeft: '20px', color:'#808080', fontFamily: 'Rubik', fontSize: '14px'}}>
                <IconLink style={{marginRight: '10px'}}/>
                Link
              </span>
              <div
                style={{
                  marginLeft: '10px',
                  marginTop: '10px',
                  minWidth: '510px',
                }}
                onMouseLeave={() => this.setState(hide)}
              >
                <div
                  style={{ marginLeft: '5px', width:'58%', boxShadow:'none'}}
                  className="btn-add-component"
                >
                  <Link
                    href={`${element.link}`}  
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseOver={() => this.setState(show)}
                  >
                    <p>
                      {element.title.length > 30
                        ? element.title.slice(0, 30) + '...'
                        : element.title}
                    </p>
                    <ArrowRight />
                  </Link>

                  <ToolTipLink
                    showbtn={this.state['showbtnlink' + element.id]}
                    index={this.state.index}
                    id_canvas={this.props.editpostform.id}
                    id={'link_' + element.id}
                    link={element.link}
                    elements={this.state.elements}
                    setComponentsToState={this.setComponentsToState}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 'survey':
        return (
          <div style={{ display: 'flex', marginTop: '50px', width:"85%"}}>
            <div style={{ height: '25px', cursor: 'pointer' }}>
              <DragHandle />
            </div>
            <div style={{width: '100%'}}>
              <span style={{marginLeft: '20px', color:'#808080', fontFamily: 'Rubik', fontSize: '14px', display: 'flex', alignItems: 'center'}}>
                <IconRadioButton style={{marginRight: '10px', width: '20px'}}/>
                Seleção única
              </span>
              <SurveyModule
                key={element.id}
                element={element}
                elements={this.state.elements}
                editPostFormId={this.props.editpostform.id}
                style={style}
                contaOptions={this.contaOptionsV2}
                contaQuestions={this.contaQuestions}
                removeQuestion={this.removeQuestion}
                saveOptionQuestion={this.props.saveOptionQuestion}
                setComponentsToState={this.setComponentsToState}
              />
            </div>
          </div>
        );
      case 'quiz':
        return (
          <div style={{ display: 'flex', marginTop: '50px', width:"85%" }}>
            <div style={{ height: '25px', cursor: 'pointer' }}>
              <DragHandle />
            </div>
            <div style={{width: '100%'}}>
              <span style={{marginLeft: '20px', color:'#808080', fontFamily: 'Rubik', fontSize: '14px', display: 'flex', alignItems: 'center'}}>
                <IconCheckbox style={{marginRight: '10px', width: '20px'}}/>
                Seleção múltipla
              </span>
              <QuizModule
                key={element.id}
                element={element}
                editPostFormId={this.props.editpostform.id}
                style={style}
                contaOptions={this.contaOptionsV2}
                contaQuestions={this.contaQuestions}
                removeQuestion={this.removeQuestion}
                saveOptionQuestion={this.props.saveOptionQuestion}
              />
            </div>
          </div>
        );
      case 'open_question':
        return (
          <div style={{ display: 'flex', marginTop: '50px', width:"85%"}}>
            <div style={{ height: '25px', cursor: 'pointer' }}>
              <DragHandle />
            </div>
            <div style={{width: '100%'}}>
              <span style={{marginLeft: '20px', marginTop:'5px', color:'#808080', fontFamily: 'Rubik', fontSize: '14px', display: 'flex', alignItems: 'center'}}>
                <IconOpenQuestion style={{marginRight: '10px', width: '15px'}}/>
                Resposta aberta
              </span>
              <OpenQuestionModule
                key={element.id}
                element={element}
                editPostFormId={this.props.editpostform.id}
                style={style}
                contaQuestions={this.contaQuestions}
                removeQuestion={this.removeQuestion}
              />
            </div>
          </div>
        );
      default:
        return (
          <div style={{ display: 'flex', marginTop: '0' }}>
            <div
              style={{ height: '0', cursor: 'pointer', visibility: 'hidden' }}
            >
              <DragHandle />
            </div>
          </div>
        );
    }
  });

  arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };

  arrayMove = (array, from, to) => {
    array = array.slice();
    this.arrayMoveMutate(array, from, to);

    return array;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const array_move = this.arrayMove(this.state.elements, oldIndex, newIndex);

    this.setState({ elements: array_move });

    const contents_position = [];
    let queue = 0;
    array_move.map((content) => {
      contents_position.push({ canvas_content_id: content.id, queue });
      queue++;
    });
    const contents_position_object = {
      contents_position
    };
    this.props.sortContent(
      this.props.editpostform.id,
      contents_position_object
    );
  };

  InsertContentQuestion = async (type) => {
    await this.props.saveContentQuestion(
      this.props.editpostform.id,
      this.props.editpostform,
      true,
      type
    );

    switch (type) {
      case 'survey':
      case 'quiz':
        const idPublish = this.props.editpostform.id;
        const idContent = this.props.editpostform.elements.slice(-1)[0].id;

        await this.props.saveOptionQuestion(idPublish, idContent);

        setTimeout(async () => {
          const idContent = this.props.editpostform.elements.slice(-1)[0].id;

          await this.props.saveOptionQuestion(idPublish, idContent);
        }, 300);
        break;

      case 'open_question':
        setTimeout(async () => {
          const idContent = this.props.editpostform.elements.slice(-1)[0].id;
          const idPublish = this.props.editpostform.id;

          await this.props.saveOptionQuestion(idPublish, idContent);
        }, 300);
        break;
    }

    this.setState({ elements: this.props.editpostform.elements });

    setTimeout(
      function() {
        this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }.bind(this),
      300
    );
  };

  async InsertComponentText(initial) {
    const elements = [...this.state.elements];

    await this.props.saveContentText(this.props.editpostform.id, '', true);

    const newElement = this.props.editpostform.elements.slice(-1)[0];
    elements.push(newElement);

    this.setState({ elements, showinitialcomponente: false });

    setTimeout(
      function() {
        this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }.bind(this),
      900
    );
  }

  async InsertComponentVideo(e) {
    const elements = [...this.state.elements];

    const response = await this.props.saveContentVideoV2(this.props.editpostform.id, e, style.teamName.name);

    if(response){
      const newElement = this.props.editpostform.elements.slice(-1)[0];
      elements.push(newElement);

      this.setState({ elements, showinitialcomponente: false });

      setTimeout(
        function() {
          this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }.bind(this),
        900
      );
    }
  }

  async InsertComponentImage(e) {
    const elements = [...this.state.elements];

    const response = await this.props.saveContentImage(this.props.editpostform.id, e, 'image');

    if(response){
      const newElement = this.props.editpostform.elements.slice(-1)[0];
      elements.push(newElement);
  
      this.setState({ elements, showinitialcomponente: false });
  
      setTimeout(
        function() {
          this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }.bind(this),
        900
      );
    }

  }

  async InsertComponentLink(e) {
    const elements = [...this.state.elements];

    await this.props.saveContentLink(this.props.editpostform.id, e, 'link');

    const newElement = this.props.editpostform.elements.slice(-1)[0];
    elements.push(newElement);

    this.setState({ elements, showinitialcomponente: false });

    setTimeout(
      function() {
        this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }.bind(this),
      900
    );
  }

  async InsertComponentCarousel(e) {
    const elements = [...this.state.elements];

    const response = await this.props.saveContentCarousel(
      this.props.editpostform.id,
      e,
      'carousel'
    );

    if(response){
      const newElement = this.props.editpostform.elements.slice(-1)[0];
      elements.push(newElement);
  
      this.setState({ elements, showinitialcomponente: false });
  
      setTimeout(
        function() {
          this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }.bind(this),
        900
      );
    }

  }

  render() {
    
    const isForm = /post\/form\/edit/.test(window.location.href) || /post\/form\/create/.test(window.location.href);
    const isRaffle = this.props.isDrawType;

    if (this.props.editpostform.elements !== undefined) {
      return (
        <>

          {!isRaffle && 
            <InfoAboutCover>
              <IconInfo/>
              Se o primeiro conteúdo for uma imagem ou vídeo, será exibido como capa
            </InfoAboutCover>
          }

          <AddPostContent
            InsertComponentText={this.InsertComponentText}
            InsertComponentLink={this.InsertComponentLink}
            showDialogUploadFile={this.props.showDialogUploadFile}
          />

          {isForm && <AddFormContent InsertContentQuestion={this.InsertContentQuestion}/>}

          {isRaffle && 
            <RaffleConguration onClick={() => this.props.openDrawModal()}>
              Configurações do sorteio
            </RaffleConguration>
          }

          <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
            {this.state.elements.map((element, index) => (
              <this.SortableItem index={index} element={element} />
            ))}
          </SortableContainer>

          <div ref={(ref) => (this.body = ref)} />

          <ModalCanvasMidia
            InsertComponentLink={this.InsertComponentLink}
            closeDialogUploadFile={this.props.closeDialogUploadFile}
            InsertComponentCarousel={this.InsertComponentCarousel}
            InsertComponentVideo={this.InsertComponentVideo}
            InsertComponentImage={this.InsertComponentImage}
            alert={this.props.alert}
          />
          <DrawModal/>
        </>
      );
    }
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'post_content',
  enableReinitialize: true
})(PostContents);

const mapDispatchToProps = (dispatch) => {
  return {
    saveContentQuestion: async (id, value, edit, type) => {
      await dispatch(FormApiAsync.saveContentQuestion(id, value, edit, type));
    },
    saveOptionQuestion: async (idPublish, idContent) => {
      await dispatch(PostApi.saveOptionQuestion(idPublish, idContent));
    },
    deleteOptionQuestion: (idcontent, idOption, type) => {
      dispatch(PostApi.deleteOptionQuestion(idcontent, idOption, type));
    },
    deleteQuestionContent: (idPublish, idContent, type) => {
      dispatch(PostApi.deleteQuestionContent(idPublish, idContent, type));
    },
    sortContent: (id_post, contents_position) => {
      dispatch(PostApi.sortContent(id_post, contents_position));
    },
    saveContentEditOptions: async (id, value, idContent, IdOption, type) => {
      await dispatch(
        FormApiAsync.saveContentEditOptions(
          id,
          value,
          idContent,
          IdOption,
          type
        )
      );
    },
    saveContentEditQuestions: async (id, values, idpatch, type) => {
      await dispatch(
        FormApiAsync.saveContentEditQuestions(id, values, idpatch, type)
      );
    },
    saveContentEdit: (id, values, idpatch) => {
      dispatch(PostApi.saveContentEdit(id, values, idpatch));
    },
    saveContentText: async (id, value) => {
      await dispatch(PostApiAsync.saveContentText(id, value));
    },
    saveContentVideoV2: async (id, value, teamName) => {
      const response = await dispatch(PostApiAsync.saveContentVideoV2(id, value, teamName));
      return response;
    },
    saveContentImage: async (id, values, kind) => {
      const response = await dispatch(PostApiAsync.saveContentImage(id, values, kind));
      return response;
    },
    saveContentLink: async (id, link, title) => {
      await dispatch(PostApiAsync.saveContentLink(id, link, title));
    },
    saveContentCarousel: async (id, value) => {
      const response = await dispatch(PostApiAsync.saveContentCarousel(id, value));
      return response;
    },
    showDialogUploadFile: (kind) => {
      dispatch({
        type: 'SHOW_DIALOG_FILE',
        alert: { visible: true, kind: kind }
      });
    },
    closeDialogUploadFile: () => {
      dispatch({ type: 'CLOSE_DIALOG_FILE', alert: { visible: false } });
    },
    openDrawModal: () => {
      dispatch({ type: 'DRAW_MODAL_OPEN' });
    },
  };
};
const selector = formValueSelector('post_content');
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.editpostform,
    editpostform: state.editpostform,
    alert: state.dialog,
    isDrawType: state.draw.isDrawType,
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
