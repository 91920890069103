import React, { Component } from 'react';

import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';

import IcoExit from '../utils/img/exit-dialog.png';
import ImgPlaceHolder from '../utils/img/place_holder_user.png';

import './drawDetails.css';

class DetailsDraw extends Component {
  constructor() {
    super();
    this.state = { dateNow: new Date() };
  }
  render() {
    const { viewDrawDetails, showDrawResults } = this.props;
    return (
      <Modal
        visible={showDrawResults}
        width="40%"
        height="auto"
        effect="fadeInUp"
      >
        <div className="container-draw-details">
          <div className="body-draw-details">
            <header className="header-draw-details">Sorteio</header>
            <img
              onClick={this.props.hideDrawResults}
              className="btn-exit"
              src={IcoExit}
              alt="fechar"
            />
          </div>
          <div className="info-draw-details">
            <div className="info-details">
              <p>
                <span>Participantes: </span>
                {Date(viewDrawDetails.date_draw) <= Date(this.state.dateNow) ? (
                  viewDrawDetails.winners &&
                  viewDrawDetails.winners.length > 0 ? (
                    viewDrawDetails.winners.length
                  ) : (
                    'Não há sorteados.'
                  )
                ) : (
                  'Não realizado.'
                )}
              </p>
            </div>
            <div className="info-details">
              <p>
                <span>Regras do sorteio: </span>
                {viewDrawDetails.rulesParsed}
              </p>
            </div>
            <div className="info-details">
              <p>
                <span>Data da realização: </span>
                  {
                    new Intl.DateTimeFormat('pt-BR', {
                      dateStyle: "short",
                      timeStyle: "short",
                    })
                    .format(viewDrawDetails.date_draw)
                    .slice(0,10)
                  }
              </p>
            </div>
            <div className="info-details">
              <p>
                <span>Horário: </span>
                {viewDrawDetails.date_draw.getHours() <= 9 ? '0' + viewDrawDetails.date_draw.getHours() : viewDrawDetails.date_draw.getHours()}
                h
                {viewDrawDetails.date_draw.getMinutes() <= 9 ? '0' + viewDrawDetails.date_draw.getMinutes() : viewDrawDetails.date_draw.getMinutes()}
              </p>
            </div>

            <div className="info-details">
              {viewDrawDetails.winners &&
                viewDrawDetails.winners.length > 0 && (
                  <p>
                    <span>Ganhadores: </span>
                    {Date(viewDrawDetails.date_draw) <= Date(this.state.dateNow) ? (
                      <ul className="body-user-winners-list">
                        {viewDrawDetails.winners.map(function(userWinner, index) {
                          return (
                            <li className="user-winners-list">
                              {index+1}º {' - '} {userWinner.name}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      'Não realizado.'
                    )}
                  </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    viewDrawDetails: state.draw.viewDrawDetails,
    showDrawResults: state.draw.showDrawResults
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideDrawResults: () => {
      dispatch({ type: 'DRAW_RESULTS_HIDE' });
    }
  };
};

const DetailsDrawContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsDraw);

export default DetailsDrawContainer;
