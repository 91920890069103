import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import ImageIcon from '../../img/ic-imagem_3x.png';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Dropzone from 'react-dropzone';
import VideoIcon from '../../img/ic-video.png';
import CarouselIcon from '../../img/ic-carousel.png';
import { TextFieldDefault, LongTextLink } from '../../../utils/FormsComponents';
import { required, validurl } from '../../../validation/FormValidation';
import IcoLinkConfirm from '../../img/ic-add-link.png';
import IcoExit from '../../../utils/img/exit-dialog.png';
require('./dialogcanvas.css');
const formats = [
  '.webm',
  '.mkv',
  '.flv',
  '.vob',
  '.ogv',
  '.ogg',
  '.drc',
  '.gif',
  '.gifv',
  '.mng',
  '.avi',
  '.mov',
  '.qt',
  '.wmv',
  '.yuv',
  '.rm',
  '.rmvb',
  '.asf',
  '.amv',
  '.mp4',
  '.m4p',
  '.m4v',
  '.mpg',
  '.mp2',
  '.mpeg',
  '.mpe',
  '.mpg',
  '.mpeg',
  '.m2v',
  '.m4v',
  '.svi',
  '.3gp',
  '.3g2',
  '.mxf',
  '.roq',
  '.nsv',
  '.flv',
  '.f4v',
  '.f4p',
  '.mov',
  '.MOV',
  '.f4a',
  '.f4b',
  'video/mp4',
  'video/quicktime'
];

class ModalCanvasMidia extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.saveLink = this.saveLink.bind(this);
  }
  closeDialog() {}

  handleChange(e) {
    if (e[0] !== undefined && e !== null) {
      try {
        if (e.length === 1) {
          if (formats.includes(e[0].type)) {
            this.props.InsertComponentVideo(e);
            this.props.closeDialogUploadFile();
            this.props.setPublication('video', e);
          } else {
            this.props.InsertComponentImage(e);
            this.props.closeDialogUploadFile();
            this.props.setPublication('image', e);
          }
        } else if (e.length > 1 && e.length <= 6) {
          this.props.InsertComponentCarousel(e);
          this.props.closeDialogUploadFile();
          this.props.setPublication('carousel', e);
        } else {
          this.setState({ erro: 'O limite de imagens permitida é de 6' });
          setTimeout(
            function() {
              this.setState({ erro: undefined });
            }.bind(this),
            3000
          );
        }
      } catch (error) {}
    }
  }

  saveLink(values) {
    this.props.addLink(values.name_button, values.link_button);
    this.props.setPublication('link', values);
    this.props.InsertComponentLink(values);
    this.props.closeDialogUploadFile();
  }
  render() {
    const { handleSubmit } = this.props;
    if (this.props.alert.kind !== 'link') {
      return (
        <Modal visible={this.props.alert.visible} width="50%" height="50%">
          <div
            onClick={this.props.closeDialogUploadFile}
            className="body-row-exit"
          >
            <img style={{ height: '12px', width: '12px' }} src={IcoExit} alt="fechar"/>
          </div>
          <div
            style={{ width: '100%', height: '90%' }}
            className="dropzone-class"
          >
            {this.state.erro === undefined ? (
              <Dropzone
                className="dropzone-class-border"
                onDrop={this.handleChange}
              >
                {this.props.alert.kind === 'image' ? (
                  <div className="dropzone-class">
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={ImageIcon}
                      alt="ícone de imagem"
                    />
                    <div className="label-dialog-main">
                      <label className="label-dialog-main-select">
                        Clique aqui
                      </label>{' '}
                      ou arraste uma imagem para adicionar um conteúdo Imagem
                    </div>
                  </div>
                ) : (
                  undefined
                )}
                {this.props.alert.kind === 'video' ? (
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={VideoIcon}
                        alt="ícone de vídeo"
                      />
                    </div>
                    <div style={{ marginTop: '10px' }} className="col-md-8">
                      <div className="label-dialog-main">
                        <label className="label-dialog-main-select">
                          Clique aqui
                        </label>{' '}
                        ou arraste um vídeo para adicionar um conteúdo Vídeo
                      </div>
                    </div>
                  </div>
                ) : (
                  undefined
                )}
                {this.props.alert.kind === 'carousel' ? (
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={CarouselIcon}
                        alt="ícone de carrossel"
                      />
                    </div>
                    <div style={{ marginTop: '10px' }} className="col-md-8">
                      <div className="label-dialog-main">
                        <label className="label-dialog-main-select">
                          Clique aqui
                        </label>{' '}
                        ou arraste ao menos duas e até seis imagens para criar
                        um carousel
                      </div>
                    </div>
                  </div>
                ) : (
                  undefined
                )}
              </Dropzone>
            ) : (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="erro-images">
                      Limite de imagens excedido, máximo de seis imagens
                      permitido
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal visible={true} width="50%" height="29%">
          <form onSubmit={handleSubmit(this.saveLink)}>
            <div
              onClick={this.props.closeDialogUploadFile}
              className="body-row-exit"
            >
              <img style={{ height: '12px', width: '12px' }} src={IcoExit} alt="fechar"/>
            </div>
            <div className="form-link-class">
              <Field
                name="name_button"
                rows={1}
                validate={[required]}
                id="name"
                label="Texto do botão*"
                component={TextFieldDefault}
                type="text"
              />

              <Field
                style={{ width: '70%' }}
                name="link_button"
                rowsMax={1}
                rows={1}
                validate={[required]}
                id="name"
                label="https://*"
                component={LongTextLink}
                type="text"
              />
              <div className="form-link-btn">
                <button className="img-button-click">
                  {' '}
                  <img src={IcoLinkConfirm} alt="confirmar"/>{' '}
                </button>
              </div>
            </div>
          </form>
        </Modal>
      );
    }
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'dialogmidia'
})(ModalCanvasMidia);

const mapDispatchToProps = (dispatch) => {
  return {
    sendFiles: (files) => {
      dispatch({ type: 'UPLOAD_FILES', files: files });
    },
    addLink: (link, name) => {
      dispatch({ type: 'ADD_LINK', link: { name: name, link: link } });
    },
    setPublication: (kind, value) =>
      dispatch({ type: 'formPost', kind: kind, value: value }),
    closeDialogUploadFile: () => {
      dispatch({ type: 'CLOSE_DIALOG_FILE', alert: { visible: false } });
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({}),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
