import React, { Component } from 'react';
import { connect } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TagsButton from '../TagsButton/TagsButton';
import TagsSearch from '../TagsSearch/TagsSearch';
import DialogApi from '../../../../Logicas/DialogApi';

import * as S from './TagsHeader.style';

export class TagsHeader extends Component {
  render() {
    return (
      <MuiThemeProvider>
        <S.TagsComponentHeader>
          <S.TagsComponentHeaderTitle>
            <S.TagsComponentHeaderTitleValue>
              Tag
            </S.TagsComponentHeaderTitleValue>
          </S.TagsComponentHeaderTitle>
          <S.TagsComponentHeaderButtons>
            <TagsSearch tagsList={this.props.list} />
            <TagsButton label="Nova Tag" onClick={this.props.openDialog} />
          </S.TagsComponentHeaderButtons>
        </S.TagsComponentHeader>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.tagReducer,
    alert: state.dialog,
    erroalert: state.erroalert
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openDialog: () => {
      dispatch(DialogApi.openDialog());
    }
  };
};
const TagsHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsHeader);

export default TagsHeaderContainer;
