import styled from 'styled-components';
import { style as teamSettings } from '../../../../style';

export const ToolTipBody = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: ${teamSettings.inpuStyle.colorInput};
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: flex;
    align-items: center;
    top: 95px;
    transform: translateX(-50%) translateY(-100%) scale(0);
    opacity: 0;
    transition: 0.15s all;
  }

  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    transform: scale(1);
    opacity: 1;
  }

  &::before {
    content: attr(data-tooltip);
    background-color: ${teamSettings.inpuStyle.colorInput};
    color: #ffffff;
    padding: 8px 12px;
    border-radius: 5px;
    width: max-content;
    max-width: 100%;
  }

  &::after {
    content: '';
    border: 10px solid transparent;
    border-top-color: ${teamSettings.inpuStyle.colorInput};
    top: 130px;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled.h2`
  font-size: 24px;
  margin-top: 20px;
  font-weight: 500;
  color: #403f4c;
  margin-bottom: 16px;
  font-family: Rubik;
`;

export const ModalList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 66%;
  width: 66%;
  margin-bottom: 12px;
  padding-inline-start: 64px;
`;

export const ModalListItem = styled.li`
  width: calc(33% - 24px);
  word-wrap: break-word;

  &::before {
    content: '•';
    color: ${teamSettings.inpuStyle.colorInput};
    font-weight: bold;
    display: inline-block;
    width: 8px;
    margin-right: 8px;
  }

  &:not(:nth-of-type(3n + 3)) {
    margin-right: 24px;
  }

  &:nth-of-type(n + 3) {
    margin-top: 4px;
  }
`;

export const ButtonBody = styled.button.attrs({ type: 'button' })`
  height: 40px;
  width: 150px;
  border-radius: 5px;
  background-color: ${teamSettings.inpuStyle.colorInput};
  border: none;
  margin-left: 15px;
  cursor: pointer;
  appearance: none;
`;

export const ButtonLabel = styled.span`
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  text-align: center;
  vertical-align: text-top;
  color: #ffffff;
`;
