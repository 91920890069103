import React, { Component } from 'react';
import IcoContent from '../utils/img/ic-conte-do.svg';
require('./previewcontent.css');

class PreviewContentText extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    if (this.props.img === undefined) {
      return (
        <div className="preview-content">
          <div className="row">
            <div className="col-md-12 preview-content-img">
              <img className="img-default-simple" src={IcoContent} alt="ícone conteúdo"/>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="preview-content-title ">
                <div className="col-md-12">{this.props.title}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <textarea
                  disabled
                  className="text-area-content"
                  value={this.props.text}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default PreviewContentText;
