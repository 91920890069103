import styled from 'styled-components';
import { style as teamAttributes } from '../../../style';

export const ToastBody = styled.div`
  visibility: ${({ progress }) =>
    progress ? 'visible' : 'hidden'};
  min-width: 250px;
  margin-left: -125px;
  background-color: ${teamAttributes.inpuStyle.colorInput};
  font-size: 18px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  right: 10%;
  bottom: ${({ progress }) =>
    progress ? '30px' : '-32px'};
  opacity: ${({ progress }) => (progress ? 1 : 0)};
  transition: all 0.35s;
`;

export const ToastMessage = styled.span``;
