import styled from 'styled-components';
import { style as teamsAttributes } from '../../../../../style';

export const LabelBody = styled.div`
  height: 26px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #403f4c;
  cursor: pointer;
  border-bottom: ${({ active }) =>
    active && `2.1px ${teamsAttributes.inpuStyle.colorInput} solid`};

  &:hover {
    border-bottom: 2.1px ${teamsAttributes.inpuStyle.colorInput} solid;
  }
`;
