import { addImageProcess } from "./addImageProcess";

export function canAddFiles(filesToUpload){
    let cont = 0;
    let conts = [];
    return new Promise(async (resolve, reject) => {
      filesToUpload.map(
        async (newFile, index) => {
          const i = await addImageProcess(URL.createObjectURL(newFile)); 
          if(i) cont += 1;

          if(i.height > 2000 || i.width > 2000){
            conts.push(index);
          } 

          if(cont === filesToUpload.length){
            if(conts.length === 0) resolve({canAddAllFiles: true, indexesError: []});
            else resolve({canAddAllFiles: false, indexesError: conts});
          }
        }
      )
    })

  }