import styled from "styled-components";
import { style } from '../../../style';

export const ContainerSearch = styled.div`
  display: flex;
  grid-area: search;
  position: relative;
  flex: 1;
  align-items: center;
  min-width: 400px;

  > svg{
    position: absolute;
  }

  .magnifier{
    left: 10px;
    margin-bottom: 10px;
    opacity: 0.8;
  }
`

export const InputSearch = styled.input`
    font-family: Rubik;
    height: 40px;
    border: none;
    width: 100%;

    border: 1px solid #E4E4E5;
    padding: 10px 10px 10px 40px;
    font-size: 16px;
    margin-bottom: 10px;

    color: #808080;

    &::placeholder{
      color: #808080;
    }

    &:focus {
      border: none;
      outline-style: solid;
      outline-color: ${style.inpuStyle.colorInput};
      outline-width: 1px;
    }

    &:hover {
      border: ${(props) => props.isDisabled ? '1px solid #E4E4E5' : 'none'};
      outline-style: solid;
      outline-color: ${style.inpuStyle.colorInput};
      outline-width: ${(props) => props.isDisabled ? '0px' : '1px'};
    }
    
    "@media (max-width: 767px)"{
      margin: 0;
      width: 300px;
    }
`

