import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData
} from '../utils/Table';
import { BodyTable } from '../utils/BodyTable';
import { connect } from 'react-redux';
import TagApi from '../../Logicas/TagApi';
import { Toggle } from 'material-ui';
import IcoEdit from '../utils/img/ic-editar.svg';
import { FooterPagination } from '../utils/Pagination';
import DialogTagForm from '../utils/DailogComponentes';
import { DialogAlert } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import TagsHeader from './Components/TagsHeader/TagsHeader';
require('./tag.css');

class TagList extends Component {
  constructor() {
    super();
    this.state = {
      init: 0,
      activePage: 1
    };
  }

  componentWillMount() {
    this.props.getTags(1);
  }

  render() {
    const styles = {
      block: {
        maxWidth: 250
      },
      toggle: {
        marginBottom: 16
      },
      thumbOff: {
        backgroundColor: '#ffcccc'
      },
      trackOff: {
        backgroundColor: '#ff9d9d'
      },
      thumbSwitched: {
        backgroundColor: '#1bab60'
      },
      trackSwitched: {
        backgroundColor: '#79dba9'
      },
      labelStyle: {
        color: 'red'
      }
    };
    const {
      tagMergeModalOpen: { isOpen, message },
      closeMergeModal
    } = this.props;
    return (
      <div>
        <TagsHeader />
        <BodyTable>
          <Table>
            <TableHead>
              <TableHeadRow>
                <label>Id</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Tag</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Nome exibido</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Usuários Associados</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Status</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Ação</label>
              </TableHeadRow>
            </TableHead>

            <TableBody>
              {Array.isArray(this.props.list)
                ? this.props.list
                    .slice(this.state.init, this.state.init + 10)
                    .map(
                      function(tag, index) {
                        return (
                          <TableRow value={index}>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>{tag.id}</div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {tag.name}
                                <br />
                                {tag.description !== 'undefined'
                                  ? tag.description
                                  : undefined}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {'#' + tag.name}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {tag.users_tag}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                <Toggle
                                  thumbStyle={styles.thumbOff}
                                  trackStyle={styles.trackOff}
                                  thumbSwitchedStyle={styles.thumbSwitched}
                                  trackSwitchedStyle={styles.trackSwitched}
                                  labelStyle={styles.labelStyle}
                                  defaultToggled={tag.valide}
                                  onToggle={(event, index, values) =>
                                    this.props.setTagValide(tag.id, tag.valide)
                                  }
                                />
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                <img
                                  onClick={() => {
                                    this.props.getTagEdit(tag.id);
                                  }}
                                  className="img-edit"
                                  src={IcoEdit}
                                  alt="Ícone de edição"
                                />
                              </div>
                            </TableData>
                          </TableRow>
                        );
                      }.bind(this)
                    )
                : undefined}
            </TableBody>
          </Table>
          <FooterPagination
            activePage={this.state.activePage}
            totalItemsCount={this.props.list.length}
            handlePageChange={(pageNumber) =>
              this.setState({
                init: (pageNumber - 1) * 10,
                activePage: pageNumber
              })
            }
          />
        </BodyTable>
        <DialogTagForm
          visible={this.props.alert.visible}
          createTag={this.props.createTag}
        />
        <DialogAlert
          closeDialog={this.props.closeDialog}
          message={this.props.erroalert.message}
          visible={this.props.erroalert.visible}
          createTag={this.props.createTag}
        />
        <DialogAlert
          closeDialog={closeMergeModal}
          visible={isOpen}
          message={message}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.tag.tagList,
    alert: state.dialog,
    pagination: state.pagination,
    erroalert: state.erroalert,
    tagMergeModalOpen: state.tag.tagMergeModalOpen
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTags: (page) => {
      dispatch(TagApi.getTags(page, '', 'all'));
    },
    setTagValide: (id, valide) => {
      dispatch(TagApi.setTagAvaiable(id, valide));
    },
    getTagEdit: (id) => {
      dispatch(TagApi.getTagEdit(id));
    },
    createTag: (values) => {
      dispatch(TagApi.createTag(values));
    },
    openDialog: () => {
      dispatch(DialogApi.openDialog());
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    closeMergeModal: () => {
      dispatch({ type: 'TAG_MERGE_MODAL_CLOSE' });
    }
  };
};
const TagListContainer = connect(mapStateToProps, mapDispatchToProps)(TagList);

export default TagListContainer;
