import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import ButtonDefault from '../utils/FormsComponents';
import { BodyTable } from '../utils/BodyTable'
import HeaderCalendarList from '../headers/HeaderCalendar/HeaderList'
import CalendarApi from '../../Logicas/CalendarApi'
import { Table, TableHead, TableBody, TableRow, TableHeadRow, TableData, RemoveEdit } from '../utils/Table'
import {DialogYesNo,DialogAlert} from '../utils/DailogComponentes'
import DialogApi from '../../Logicas/DialogApi'
import { FooterPagination } from '../utils/Pagination';
import moment from 'moment'
require('./eventlist.css')
class EventList extends Component {

  constructor() {
    super()
    this.state={
      init: 0,
      activePage: 1,
      month_ini:moment(Date.new).format('L')
    }
  }
  componentWillMount() {
    this.props.getThisMonthEvents()
  }

  render() {
    let today=new Date()
    return (
      <div  >
        <HeaderCalendarList
          month_in_list={
            this.props.event_list.length > 0
            ? (this.props.event_list[0].date_event)
            : (today)
          }
          getNextMonth={this.props.getNextMonth}
          getPreviousMonth={this.props.getPreviousMonth}
        />
        <BodyTable>
          {
            Array.isArray(this.props.event_list) && (
              <Table>
                <TableHead>
                  <TableHeadRow style={{width:'300px'}} >
                    <label className='header-table-names' >Título e descrição</label>
                  </TableHeadRow>
                  <TableHeadRow>
                    <label className='header-table-names' >Criador</label>
                  </TableHeadRow>
                  <TableHeadRow>
                    <label className='header-table-names' >Segmentação</label>
                  </TableHeadRow>
                  <TableHeadRow>
                    <label className='header-table-names' >Data e hora</label>
                  </TableHeadRow>
                  <TableHeadRow>
                    <label className='header-table-names' >Local</label>
                  </TableHeadRow>
                  <TableHeadRow>
                    <label className='header-table-names' >Participantes</label>
                  </TableHeadRow>
                  <TableHeadRow>

                  </TableHeadRow>
                </TableHead>
                <TableBody>
                  {this.props.event_list
                      .slice(this.state.init, this.state.init + 10)
                      .map(function(event) {
                      return (
                        <TableRow>
                          <TableData>
                            <div className='first-colum'>
                              <div className='title-table' >
                                {event.title}
                              </div>
                              <div className='description-table' >
                                {event.description}
                              </div>
                            </div>
                          </TableData>
                          <TableData >
                            <div className='description-table'> {event.user.name}</div>
                          </TableData>
                          {!!event.tags.length ? (
                            <TableData>
                              <div className='description-table'>  {event.tags[0].name}</div>
                            </TableData>
                          ) : (
                            <TableData>
                              <div className='description-table'>  Não definido</div>
                            </TableData>
                          )}
                          <TableData>
                            <div className='description-table'>
                              <div className='timer-row' >
                                <div>
                                  {moment(event.date_event).format('L')}
                                </div>
                                {!event.fixed && (
                                  <div>
                                    {moment.tz(event.hour_event,'Antarctica/Troll').format('LT')}-{moment.tz(event.hour_event_end,'Antarctica/Troll').format('LT')}
                                  </div>
                                )}
                              </div>
                            </div>
                          </TableData>
                          <TableData>
                            <div className='description-table'>
                              {event.local || 'Online'}
                            </div>
                          </TableData>
                          <TableData>
                            <div className='description-table'>
                              {event.users_association}
                            </div>
                          </TableData>
                            {!event.fixed && (
                              <TableData>
                                  <RemoveEdit edit={true}  Edit={this.props.editEvent.bind(this, event.id)}  Delete={this.props.deleteShowDialogEvent.bind(this, event.id)} />
                              </TableData>
                            )}
                        </TableRow>
                      );
                    }.bind(this))
                  }
                </TableBody>
              </Table>
            )
          }
          <FooterPagination
              activePage={this.state.activePage}
              handlePageChange={pageNumber =>
                this.setState({
                  init: (pageNumber - 1) * 10,
                  activePage: pageNumber
                })
              }
              totalItemsCount={this.props.event_list.length}
            />

        </BodyTable>
        <DialogYesNo yesclick={this.props.deleteEvent.bind(this, this.props.alert.id_elemente, this.props.alert.is_publication)} noclick={this.props.closeDialog} onClick={this.props.closeDialog} visible={this.props.alert.visible} message='Remover este evento ?' />
        <DialogAlert closeDialog={this.props.closeDialogAlert} visible={this.props.edit_old_publication} />
        {this.props.showModal !== true && (
          <Modal fadeInUp width="30%" height="25%" visible={true}>
            <div className="body-comfirm-event">
              <label className="label-comfirm-text">
                <p>Evento criado!</p>
              </label>
              <label className="label-comfirm-button">
                <ButtonDefault
                  onClick={() => this.props.closeDialogEventConfirm()}
                  label="OK"
                />
              </label>
            </div>
          </Modal>
        )}
      </div>
    );

  }
}

const mapStateToProps = state => {
  return {
    event_list: state.eventsReducer.eventsList,
    alert: state.dialog,
    showModal: state.show_modal_birthday
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getThisMonthEvents: (data) => dispatch(CalendarApi.getThisMonthEvents(data)),
    deleteEvent:(event_,id)=>dispatch(CalendarApi.deleteEvent(event_)),
    editEvent:(event_,id)=>dispatch(CalendarApi.getEventFromList(event_)),
    getNextDay:(event_,date)=>dispatch(CalendarApi.getNextDay(date)),
    getPreviousDay:(event_,date)=>dispatch(CalendarApi.getPreviousDay(date)),
    closeDialog: () => { dispatch(DialogApi.closeDialog()) },
    deleteShowDialogEvent:(id,is_publication)=>{dispatch(DialogApi.deleteAlert(id,is_publication))},
    getEventByName:(name)=>{dispatch(CalendarApi.getEventByName(name))},
    getNextMonth: (teste,nextmonth) => dispatch(CalendarApi.getThisMonthEvents(teste,true)),
    getPreviousMonth: (teste,previuesmonth) => dispatch(CalendarApi.getThisMonthEvents(teste)),
    closeDialogEventConfirm: () => dispatch({ type: 'SHOW_MODAL_BIRTHDAY' })
  }
}
const EventListContainer = connect(mapStateToProps, mapDispatchToProps)(EventList)

export default EventListContainer;
