import React, { Component } from 'react';
import IcoDuration from '../utils/img/ic-dura-o.svg';
import ImgDrop from '../utils/img/img-drag-drop-preview.png';
import 'video-react/dist/video-react.css';
require('./previews.css');

class CoursePreview extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="preview-publication">
        <div className="row">
          <div className="col-md-12">
            {this.props.img !== undefined ? (
              this.props.img[0] !== undefined ? (
                this.props.img[0].preview === undefined ? (
                  <img className="preview-corse-img" src={this.props.img} alt="imagem de prévia"/>
                ) : (
                  <img
                    className="preview-corse-img"
                    src={this.props.img[0].preview}
                    alt="imagem de prévia"
                  />
                )
              ) : (
                <img className="preview-corse-img-preview" src={ImgDrop} alt="imagem de prévia"/>
              )
            ) : (
              <img className="preview-corse-img-preview" src={ImgDrop} alt="imagem de prévia"/>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="preview-publication-division"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="preview-publication-title ">
              <div className="col-md-12">{this.props.title}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <textarea
                className="text-area-preview-course"
                value={this.props.text}
              >
                {' '}
              </textarea>
            </div>
          </div>
        </div>
        <ul className="est-curso-desc">
          <li>
            <img src={IcoDuration} alt="ícone duração"/>
          </li>
          <li>
            <label className="text-duration">{this.props.estimativa}</label>
          </li>

          {this.props.tags != undefined ? (
            <li className="tag-text">{this.props.tags[0]}</li>
          ) : (
            undefined
          )}
        </ul>
      </div>
    );
  }
}
export default CoursePreview;
