import moment from 'moment';
import API from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import errorHandler from '../componentes/utils/errorHandler';
import PubSub from 'pubsub-js';
import DialogApi from './DialogApi';
import { reset } from 'redux-form';
import { browserHistory } from 'react-router';
import UserApi from './UserApi';
import { parseTagsObject } from './ParseTags';
import { doesContainHttp } from '../componentes/validation/FormValidation';
import { style } from '../style';

export default class CalendarApi {
  static getTodayEvents(date) {
    if (date instanceof Date === false) {
      date = new Date();
    }

    return (dispatch) => {
      API.get(
        'calendars/?origin=web&dateini=' + date + '&' + 'dateend=' + date,
        Config.headers()
      )
        .then(
          function (response) {
            dispatch({ type: 'EVENT_RESET' });
            dispatch({
              type: 'EVENT_LIST',
              events: response.data.events,
            });
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static createNewEvent = () => {
    return (dispatch) => {
      dispatch({ type: 'EVENT_RESET' });
      browserHistory.push('/calendar/new');
    };
  };

  static getTodayEventsList(date) {
    if (date instanceof Date === false) {
      date = new Date();
    }

    return (dispatch) => {
      API.get(
        'calendars/?origin=web&dateini=' + date + '&' + 'dateend=' + date,
        Config.headers()
      )
        .then(
          function (response) {
            dispatch({ type: 'EVENT_LIST', events: response.data.events });
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static getThisMonthEvents(data, get_next) {
    if (data === undefined) {
      var this_month = moment(Date.new).startOf('month').format('L');
      var next_month = moment(Date.new).endOf('month').format('L');
    } else {
      if (get_next) {
        var this_month = moment(data).endOf('month').format('L');
        var next_month = moment(data)
          .add(1, 'month')
          .endOf('month')
          .format('L');
      } else {
        var this_month = moment(data)
          .subtract(1, 'month')
          .startOf('month')
          .format('L');
        var next_month = moment(data).startOf('month').format('L');
      }
    }

    return (dispatch) => {
      API.get(
        'calendars/?origin=web&dateini=' +
          this_month +
          '&' +
          'dateend=' +
          next_month,
        Config.headers()
      )
        .then(
          function (response) {
            dispatch({ type: 'EVENT_LIST', events: response.data.events });

            if (data !== undefined && response.data.events.length === 0) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: `Nenhum evento encontrado ${
                    get_next ? 'para o próximo mês' : 'para o mês anterior'
                  } `,
                },
              });
              dispatch(this.getThisMonthEvents());
            }
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static getEvents(date) {
    if (date instanceof Date === false) {
      date = new Date();
    }
    return (dispatch) => {
      API.get(
        'calendars/?origin=web&dateini=' + date + '&' + 'dateend=' + date,
        Config.headers()
      )
        .then(
          function (response) {
            if (response.data.events.length === 0) {
              PubSub.publish('render-calendar', true);
            }
            dispatch({
              type: 'EVENT_LIST',
              events: response.data.events,
            });
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static getEventByName(name) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('calendars/?origin=web&filter=' + name, Config.headers())
        .then(
          function (response) {
            dispatch({
              type: 'EVENT_LIST',
              events: response.data.events,
            });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static saveEvent(values, isOnline, notifyUsers, when_notify, tagsExpressions) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('date_event', values.event_date);
      formData.append('hour_event', values.event_hour_start);
      formData.append('hour_event_end', values.event_hour_finish);
      formData.append('description', values.event_description);
      formData.append('title', values.event_name);
      formData.append('local', values.event_local);
      if (values.holiday) {
        formData.append('kind', 'holiday');
      } else {
        formData.append('kind', 'common');
      }
      if (isOnline) {
        if (!doesContainHttp(values.link)) {
          values.link = `https://${values.link}`;
        }
        formData.append('link', values.link);
        formData.append('local', '');
      } else {
        formData.append('local', values.event_local);
        formData.append('link', '');
      }
      if (Array.isArray(values.event_tags)) {
        values.event_tags.map(function (tag) {
          formData.append('tags[]', tag.name);
        });
      }

      const whenNotifyConvert =  {
        "Uma hora antes": "in_day",
        "Um dia antes": "one_day_earlier",
        "Uma semana antes": "a_week_before",
      }

      if(notifyUsers){
        if(when_notify === "Ao criar o evento")
          formData.append('notify_immediately',true);
        else {
          formData.append('notify_immediately',false);
          formData.append('when_notify',whenNotifyConvert[when_notify]);
        }

      }else{
        formData.append('notify_immediately',false);
      }

      API.post('calendars/', formData, Config.headers())
        .then(
          function (response) {
            dispatch(this.getTodayEvents(values.event_date));
            dispatch(reset('new_event'));
            dispatch({ type: 'USER_TAG_ASSOCIATION_RESET' });
            dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
            browserHistory.push('/calendar');
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static patchEvent(values, isOnline) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('date_event', values.event_date);
      formData.append('hour_event', values.event_hour_start);
      formData.append('hour_event_end', values.event_hour_finish);
      formData.append('description', values.event_description);
      formData.append('title', values.event_name);
      if (values.holiday) {
        formData.append('kind', 'holiday');
      } else {
        formData.append('kind', 'common');
      }
      if (isOnline) {
        if (!doesContainHttp(values.link)) {
          values.link = `https://${values.link}`;
        }
        formData.append('link', values.link);
        formData.append('local', '');
      } else {
        formData.append('local', values.event_local);
        formData.append('link', '');
      }
      if (Array.isArray(values.event_tags)) {
        values.event_tags.map(function (tag) {
          formData.append('tags[]', tag.name);
        });
      }
      API.patch('calendars/' + values.event_id, formData, Config.headers())
        .then(
          function (response) {
            dispatch(this.getTodayEvents(values.event_date));
            browserHistory.push('/calendar');
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static patchEventExpressionTags(values, tagsExpressions) {
    return (dispatch) => {
      const tagsObjectParse = parseTagsObject(tagsExpressions);
      const objectSaveCanvas = {
        title: values.event_name,
        local: values.event_local,
        expression: tagsObjectParse,
        date_event: values.event_date,
        hour_event: values.event_hour_start,
        description: values.event_description,
        hour_event_end: values.event_hour_finish,
      };
      const objectToJson = JSON.stringify(objectSaveCanvas);
      API.post(`calendars/${values.event_id}`, objectToJson, {
        headers: Object.assign(Config.headers().headers, {
          'content-type': 'application/json',
        }),
      })
        .then((response) => {
          dispatch(this.getTodayEvents(values.event_date));
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err),
            },
          });
        });
    };
  }

  static getNextDay(day) {
    day.setDate(day.getDate() + 1);
    return (dispatch) => {
      API.get(
        'calendars/?origin=web&dateini=' + day + '&' + 'dateend=' + day,
        Config.headers()
      )
        .then(
          function (response) {
            if (response.data.events.length === 0) {
              PubSub.publish('render-calendar', true);
            }
            dispatch({
              type: 'EVENT_LIST',
              events: response.data.events,
            });
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static getPreviousDay(day) {
    if (day === undefined) {
      day = new Date();
    }
    day.setDate(day.getDate() - 1);
    return (dispatch) => {
      API.get(
        'calendars/?origin=web&dateini=' + day + '&' + 'dateend=' + day,
        Config.headers()
      )
        .then(
          function (response) {
            if (response.data.events.length === 0) {
              PubSub.publish('render-calendar', true);
            }
            dispatch({
              type: 'EVENT_LIST',
              events: response.data.events,
            });
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static deleteEvent(id) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete('calendars/' + id, Config.headers())
        .then(
          function (response) {
            dispatch(DialogApi.closeDialog());
            dispatch(this.getThisMonthEvents());
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static getEvent(id) {
    return (dispatch) => {
      API.get('calendars/' + id, Config.headers())
        .then(
          function (response) {
            dispatch({
              type: 'EVENT_EDIT',
              event_: this.parseResponse(response.data),
            });
            dispatch(UserApi.getUserTagsAssociation(response.data.tags));
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static getEventFromList(id) {
    return (dispatch) => {
      API.get('calendars/' + id, Config.headers())
        .then(
          function (response) {
            const newEvent = this.parseResponse(response.data);
            dispatch({
              type: 'EVENT_EDIT',
              event_: newEvent,
            });
            dispatch({
              type: 'GET_TAG_PARSED_WITH_IDS',
              tags_with_ids: newEvent.tags_with_ids
            });
            browserHistory.push('/calendar/new');
          }.bind(this)
        )
        .catch(
          function (err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err),
              },
            });
          }.bind(this)
        );
    };
  }

  static updateSelectedTags = (selectedTags) => {
    return (dispatch) => {
      dispatch({ type: 'EVENT_TAGS_UPDATE', eventTags: selectedTags });
    };
  };

  static parseResponse(response) {
    try {
      var data = {};
      data.event_id = response.id;
      data.event_name = response.title;
      data.event_description = response.description;
      data.event_date = new Date(this.parseDate(response.date_event));
      data.event_hour_start = this.parseTime(response.hour_event);
      data.event_hour_finish = this.parseTime(response.hour_event_end);
      data.event_date_preview = new Date(this.parseDate(response.date_event));
      data.event_local = response.local ? response.local : '';
      data.users_association = response.users_association;
      data.holiday = response.kind === 'holiday' && response.kind;
      data.link = response.link ? response.link : '';
      data.event_tags = response.tags.map(function (tag) {
        let tags = {};
        tags.label = `#${tag.name}`;
        tags.name = tag.name;
        tags.value = tag.name;

        return tags;
      });
      data.tags_with_ids = response.tags.map(function (tag) {
        let tags = {};
        tags.id = tag.id;
        tags.value = tag.name;
        tags.name = tag.name;

        return tags;
      });
    } catch (e) {}

    return data;
  }

  static parseTime(date) {
    if (date !== undefined && date !== '') {
      var teste = date.slice(11, 16);
      return teste;
    }
  }

  static parseDate(date) {
    var year = date.slice(0, 4);
    var month = date.slice(5, 7);
    var day = date.slice(8, 10);
    var date = month + '/' + day + '/' + year;
    return date;
  }
}
