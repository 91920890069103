import React, { Component } from 'react';
import { required } from '../validation/FormValidation';

import { DropDownDefaultOnlyOne } from '../utils/FormsComponents';
import DialogGeneral from '../canvas/components/dialog/DialogGeneral';

import iconSum from '../canvas/img/icon-sum.svg';
import iconSubtraction from '../canvas/img/icon-subtraction.svg';
import iconIntersection from '../canvas/img/icon-intersection.svg';

import { style } from '../../style';

import './componentSegmentationTagsStyle.css';

export class ComponentSegmentationTags extends Component {
  constructor() {
    super();
    this.state = {
      expressionTags: [],
      expressionsArray: [
        { id: 0, value: 'addition', image: iconSum, text: 'Adição' },
        {
          id: 1,
          value: 'subtraction',
          image: iconSubtraction,
          text: 'Subtração'
        },
        {
          id: 2,
          value: 'intersection',
          image: iconIntersection,
          text: 'Interseção'
        }
      ]
    };
  }

  componentWillMount() {
    if (this.props.tagsExpression) {
      this.setState({ expressionTags: this.props.tagsExpression }, () =>
        this.props.onChange(this.props.tagsExpression)
      );
    } else {
      this.insertComponentExpressionTag();
    }
  }

  insertComponentExpressionTag = () => {
    let elementsExpression = this.state.expressionTags;

    elementsExpression.push({
      valueTag: '',
      valueExpression: {
        text: 'Adição',
        image: iconSum,
        value: 'addition'
      }
    });

    this.setState({ expressionTags: elementsExpression }, () =>
      this.props.onChange(elementsExpression)
    );
  };

  removeComponentExpressionTag = (indexTag) => {
    let elementsExpression = this.state.expressionTags;
    elementsExpression.splice(indexTag, 1);
    this.setState({ expressionTags: elementsExpression }, () =>
      this.props.onChange(elementsExpression)
    );
  };

  setValueTag = (index, event, target, value) => {
    let elementsExpression = this.state.expressionTags;
    elementsExpression[index].valueTag = value;

    this.setState({ expressionTags: elementsExpression });
  };

  setValueExpression = (index, event, target, value) => {
    let elementsExpression = this.state.expressionTags;
    this.state.expressionsArray.map((expression) => {
      if (expression.value === value) {
        return (
          (elementsExpression[index].valueExpression.value = expression.value),
          (elementsExpression[index].valueExpression.image = expression.image),
          (elementsExpression[index].valueExpression.text = expression.text)
        );
      }
    });
    this.setState({ expressionTags: elementsExpression });
  };

  openDialogGeneral = () => {
    this.setState((state) => ({
      modalVisibility: !state.modalVisibility
    }));
  };

  render() {
    return (
      <div className="container-segmentation-tag">
        {this.props.showHeader && (
          <header className="header-segmentation-tag">
            <h2>Segmentação</h2>
            <button
              className="segmentation-btn"
              onClick={this.openDialogGeneral}
              style={{
                backgroundColor: style.inpuStyle.colorInput
              }}
              type="button"
            >
              ?
            </button>
          </header>
        )}
        <div className="row">
          <div className="container-selection-tags">
            {this.state.expressionTags &&
              this.state.expressionTags.map((expressionTag, index) => {
                return (
                  <div className="selection-tags-canvas-publish" key={index}>
                    <DropDownDefaultOnlyOne
                      multiple={false}
                      value={expressionTag.valueTag}
                      onChange={this.setValueTag.bind(this, index)}
                      array={this.props.tags}
                      floatingLabelText="Escreva uma tag...*"
                    />
                    {this.state.expressionTags.length === index + 1 ? (
                      <button
                        className="add-tag-btn-segmentation"
                        onClick={this.insertComponentExpressionTag}
                        type="button"
                        style={{
                          backgroundColor: style.inpuStyle.colorInput
                        }}
                      >
                        Adicionar
                      </button>
                    ) : (
                      <div className="removed-tag-and-expression">
                        <button
                          className="remove-tag-btn-segmentation"
                          onClick={() =>
                            this.removeComponentExpressionTag(index)
                          }
                          type="button"
                          style={{ color: style.inpuStyle.colorInput }}
                        >
                          X
                        </button>
                        <DropDownDefaultOnlyOne
                          multiple={false}
                          value={expressionTag.valueExpression.value}
                          onChange={this.setValueExpression.bind(this, index)}
                          elementsObject={this.state.expressionsArray}
                          style={{
                            width: '30px',
                            margin: '0px 8px'
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <DialogGeneral
          visible={this.state.modalVisibility}
          handler={this.openDialogGeneral}
        />
      </div>
    );
  }
}
