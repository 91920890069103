import styled from 'styled-components';
import { style as teamStyle } from '../../../style';

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const FormContainer = styled.div`
  width: 400px;
  height: 400px;
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

export const FormBody = styled.form`
  height: 100%;
  width: 80%;
  margin-top: 16px;
  display: inline-flex;
  flex-flow: column;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
`;

export const ButtonBody = styled.button`
  height: 40px;
  width: 150px;
  border-radius: 5px;
  background-color: ${teamStyle.inpuStyle.colorInput};
  border: none;
  margin-left: 15px;
  cursor: pointer;
  appearance: none;
  ${defaultTransition};
`;

export const ButtonLabel = styled.span`
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  vertical-align: text-top;
  color: #ffffff;
`;
