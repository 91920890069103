import React, { Component } from 'react';

import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import Avatar from 'material-ui/Avatar';

import { RemoveEdit } from '../Table';
import UserApi from '../../../Logicas/UserApi';
import { FooterPagination } from '../Pagination';
import DialogApi from '../../../Logicas/DialogApi';
import { CheckboxDefaultList } from '../FormsComponents';

import Icorder from './img/ic-order-by.png';
import ImgPlaceHolder from '../../utils/img/place_holder_user.png';
require('./tableselect.css');
class TableSelect extends Component {
  constructor() {
    super();
    this.state = {
      users_selected: [],
      invalid: false,
      order: '',
      orderFilter: true,
      user_roles: ''
    };
    this.CheckAll = this.CheckAll.bind(this);
  }
  CheckAll(check, value) {
    if (this.props.elements.users) {
      this.setState({ check_all: value });
      for (var i = 0; i < this.props.elements.users.length; i++) {
        this.props.insertUserMarked(this.props.elements.users[i], value);
      }
      PubSub.publish('btn-dialog', true);
    }
  }

  CheckAllOf(check, value) {
    this.setState({ check_all: value });
    for (var i = 0; i < this.props.elements.users.length; i++) {
      var check = {};
      check['check_' + i] = value;
      this.setState(check);
    }
  }
  checkedUser(ob, user) {
    var marked = false;

    for (var i = this.props.list_marked.length; i > 0; --i) {
      if (user.id === this.props.list_marked[i - 1].id) {
        marked = true;
      }
    }

    return marked;
  }
  componentDidMount() {
    PubSub.subscribe(
      'clear-list-marked',
      function(render) {
        this.CheckAll(null, false);
        this.forceUpdate();
      }.bind(this)
    );

    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let user_role = roles.map(function(role) {
      return role.name;
    })[0];

    this.setState({
      user_roles: user_role
    });
  }
  componentWillUnmount() {
    const { unselectAllUsers } = this.props;
    this.CheckAll(null, false);
    unselectAllUsers();
  }

  deleteUser(id) {
    this.CheckAll(null, false);
    this.props.deleteShowDialogUser(id);
  }

  getUserPageOrder(page) {
    const {
      userFilter: { selectedFilter, filterValue, nameSearch },
      getFilterUsers
    } = this.props;
    const { order } = this.state;
    getFilterUsers(selectedFilter, filterValue, nameSearch, page, order);
  }

  getUserOrder() {
    const {
      userFilter: { selectedFilter, filterValue, nameSearch },
      getFilterUsers,
      pagination
    } = this.props;
    const { order, orderFilter } = this.state;
    if (orderFilter) {
      this.setState({ order: 'desc' }, () => {
        getFilterUsers(
          selectedFilter,
          filterValue,
          nameSearch,
          pagination,
          order
        );
      });
      this.setState({ orderFilter: false });
    } else {
      this.setState({ order: 'asc' }, () => {
        getFilterUsers(
          selectedFilter,
          filterValue,
          nameSearch,
          pagination,
          order
        );
      });
      this.setState({ orderFilter: true });
    }
  }

  render() {
    const { toggleSelectedUser, selectAllUsers } = this.props;

    return (
      <React.Fragment>
        <div className="container">
          <table class="table-select">
            {this.props.showDelete === true ? (
              <tr className="th-head">
                <th className="main-checkbox">
                  <CheckboxDefaultList
                    checked={true}
                    onCheck={() => selectAllUsers()}
                  />
                </th>
                <th></th>
                {Array.isArray(this.props.headers)
                  ? this.props.headers.map(
                      function(header, i) {
                        return (
                          <th
                            className={
                              i === this.state.header
                                ? 'labels-headers-marked'
                                : 'labels-headers'
                            }
                          >
                            <div
                              onClick={() => {
                                if (header.name === 'Pontuação') {
                                  this.setState({ header: i }, () =>
                                    this.getUserOrder()
                                  );
                                } else {
                                  this.setState(
                                    { header: i, order: '', orderFilter: true },
                                    () => {
                                      this.getUserPageOrder();
                                    }
                                  );
                                }
                              }}
                              className="select-item-header"
                            >
                              {i === this.props.headers.length ? (
                                <div style={{ textAlign: 'center' }}>
                                  {' '}
                                  {header.name}
                                </div>
                              ) : (
                                header.name
                              )}
                            </div>
                          </th>
                        );
                      }.bind(this)
                    )
                  : undefined}
              </tr>
            ) : null}

            <tbody>
              {Array.isArray(this.props.elements.users)
                ? this.props.elements.users.map(
                    function(element, i) {
                      var check = {};
                      check['check_' + i] = false;
                      return (
                        <tr className="th-row" key={element.id}>
                          <td>
                            <CheckboxDefaultList
                              checked={this.checkedUser(this, element)}
                              markedall={this.state.markedall}
                              onCheck={() => toggleSelectedUser(element)}
                            />
                          </td>
                          <td>
                            <Avatar
                              src={
                                element.image !== null
                                  ? element.image
                                  : ImgPlaceHolder
                              }
                            />
                          </td>
                          <td>
                            <div>
                              <label style={{ fontWeight: 'bold' }}>
                                {element.name}
                              </label>
                              <label className="email">{element.email}</label>
                            </div>
                          </td>
                          <td style={{ opacity: '0.5' }}>{element.birthday}</td>
                          <td style={{ opacity: '0.5' }}>{element.sector}</td>
                          <td style={{ opacity: '0.5' }}>{element.roles}</td>
                          <td style={{ opacity: '0.5' }}>
                            <div style={{ textAlign: 'center' }}>
                              {' '}
                              {element.score}
                            </div>
                          </td>
                          {(this.props.showDelete === true &&
                            this.state.user_roles === 'manager' &&
                            element.roles === 'Usuário App') ||
                          this.state.user_roles !== 'manager' ? (
                            <td>
                              <RemoveEdit
                                Delete={this.deleteUser.bind(this, element.id)}
                                Edit={this.props.editUser.bind(
                                  this,
                                  element.id
                                )}
                                sendToken={this.props.token_resend.bind(
                                  this,
                                  element.id
                                )}
                              />
                            </td>
                          ) : null}
                        </tr>
                      );
                    }.bind(this)
                  )
                : undefined}
            </tbody>
          </table>
        </div>
        <div className="container">
          {this.props.elements.pages > 0 && (
            <FooterPagination
              activePage={this.props.pagination}
              totalItemsCount={this.props.elements.size}
              handlePageChange={this.getUserPageOrder.bind(this)}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    error_alert: state.alert_erro_geral,
    list_marked: state.user.selectedUsers.users,
    userList: state.user.user_list.users,
    alert: state.dialog,
    pagination: state.pagination,
    userFilter: state.user.userFilter
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteUser: (values, id) => {
      dispatch(UserApi.deleteUser(values));
    },
    insertUserMarked: (user, checked) =>
      dispatch(UserApi.insertUserToEditScore(user, checked)),
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    getUserPage: (page, order) => {
      dispatch(UserApi.getUserPage(page, order));
    },
    deleteShowDialogUser: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    editUser: (value, id) => {
      dispatch(UserApi.editUser(value));
    },
    checkAllUsers: () => {
      dispatch({ type: 'CHECK_ALL' });
    },
    checkAllUsersFalse: () => {
      dispatch({ type: 'CHECK_ALL_NOT' });
    },
    getFilterUsers: (filter_selectd, value_filter, name_user, page, order) => {
      dispatch(
        UserApi.getFilterUsers(
          filter_selectd,
          value_filter,
          name_user,
          page,
          order
        )
      );
    },
    token_resend: (idUser) => {
      dispatch(UserApi.token_resend(idUser));
    },
    toggleSelectedUser: (selectedUser) => {
      dispatch({
        type: 'USER_SELECTED_TOGGLE',
        selectedUser
      });
    },
    selectAllUsers: () => {
      dispatch({
        type: 'USER_SELECTED_ALL'
      });
    },
    unselectAllUsers: () => {
      dispatch({
        type: 'USER_SELECTED_NONE'
      });
    }
  };
};

const TableSelectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TableSelect);
export default TableSelectContainer;
