import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import PostApi from '../../Logicas/PostApi';

import ReactTooltip from 'react-tooltip';
import { browserHistory } from 'react-router';
import { BodyTable } from '../utils/BodyTable';
import DialogApi from '../../Logicas/DialogApi';
import ComponentHeader from '../utils/ComponenteHeader';
import DownloadPostData from '../_shared/DownloadPostData/DownloadPostData';
import { DialogYesNo, DialogAlert } from '../utils/DailogComponentes';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEdit
} from '../utils/Table';
import Pagination from '../utils/AutoPagination/Pagination';
import Loader from '../utils/AutoPagination/Loader';
import { FooterPagination } from '../utils/Pagination';

require('../utils/PaginationSurvey.css');
class Survey extends Component {
  constructor() {
    super();
    this.state = {
      init: 0,
      activePage: 1
    };
    this.showComments = this.showComments.bind(this);
    this.showLikes = this.showLikes.bind(this);
    this.redirectNewPost = this.redirectNewPost.bind(this);
  }

  componentWillMount() {
    this.props.list.length === 0 && this.props.getSurveyList(1);
  }

  getMoreSurveys = (page) => {
    const { getSurveyList } = this.props;
    getSurveyList(page, true);
  };

  redirectNewPost() {
    browserHistory.push('/post/survey/create');
  }

  showComments(publicationId) {
    this.setState({ comments: true });
    this.props.getComments(publicationId);
  }

  showLikes(publicationId) {
    this.setState({ comments: true });
    this.props.getLikes(publicationId);
  }

  render() {
    const {listRequestsActive} = this.props;
    if (this.props.list) {
      return (
        <div>
          <ComponentHeader
            onClick={this.redirectNewPost}
            actions={[
              {
                name: 'Formulários',
                action: () => this.props.getPostList('form')
              },
              {
                name: 'Publicações',
                action: () => this.props.getPostList('publication')
              },
              { name: 'Sorteios', action: () => this.props.getPostList('draw', 'raffle') },
              { name: 'Rascunhos', action: this.props.getDraftPublication }
            ]}
            name="Enquete"
            nameaction="Nova enquete"
            enablesubmit={listRequestsActive.length > 0}
          />
          <DownloadPostData kind="publication" />
          <BodyTable>
            <Table>
              <TableHead>
                <TableHeadRow>Pergunta</TableHeadRow>
                <TableHeadRow>Alcance</TableHeadRow>
                <TableHeadRow>Respostas</TableHeadRow>
                <TableHeadRow>Data</TableHeadRow>
                <TableHeadRow>Ações</TableHeadRow>
              </TableHead>
              <TableBody>
                {this.props.list !== undefined &&
                  this.props.list.map(
                    function(survey, i) {
                      return (
                        <TableRow key={i} onClik={this.props.editUser}>
                          <TableData>{survey.title}</TableData>
                          <TableData>{survey.range}</TableData>
                          <TableData>{survey.votes_amount}</TableData>
                          <TableData>
                            {moment
                              .tz(survey.created_at, 'Europe/Berlin')
                              .format('DD/MM/YYYY')}
                          </TableData>
                          <TableData>
                            <RemoveEdit
                              Delete={this.props.deleteShowDialogPublication.bind(
                                this,
                                survey.canvas_publication_id,
                                survey.title
                              )}
                              edit={false}
                              View={this.props.getViewSurvery.bind(
                                this,
                                survey.content_id,
                                survey.canvas_publication_id
                              )}
                              enablesubmit={listRequestsActive.length > 0}
                            />
                          </TableData>
                        </TableRow>
                      );
                    }.bind(this)
                  )}
              </TableBody>
            </Table>
            {this.props.pagination.next_page && (
              <footer className="footer-pagination">
                <FooterPagination
                  activePage={this.props.pagination.current_page}
                  totalItemsCount={this.props.pagination.total_count}
                  handlePageChange={(pageNumber) =>
                    this.getMoreSurveys(pageNumber)
                  }
                />
              </footer>
            )}
            <ReactTooltip className="tooltip" />
          </BodyTable>
          <DialogYesNo
            yesclick={this.props.deleteSurvey.bind(
              this,
              this.props.alert.id_elemente,
              this.props.alert.is_publication
            )}
            noclick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message="Tem certeza que deseja remover esta publicação ?"
          />
          <DialogAlert
            closeDialog={this.props.closeDialogAlert}
            message="Esta publicação não pode ser editada"
            visible={this.props.edit_old_publication}
          />
          <Loader loading={this.props.loading} />
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.survery.surveyList.survey_content_list,
    pagination: state.survery.surveyList.pagination,
    alert: state.dialog,
    loading: state.progress_indeterminate,
    listRequestsActive: state.post.listRequestsActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSurveyList: (page, getMoreSurveys) => {
      dispatch(PostApi.getSurveyList(page, getMoreSurveys));
    },
    getPostList: (kind, raffle = '') => {
      dispatch(PostApi.getPostListV2(kind, raffle));
    },
    deleteShowDialogPublication: (id, is_publication) => {
      dispatch(DialogApi.deleteAlert(id, is_publication));
    },
    getViewSurvery: (id, publication_id) => {
      dispatch(PostApi.getViewSurvery(id, publication_id));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deleteSurvey: (id) => {
      dispatch(PostApi.deleteSurvey(id));
    },
    closeDialogAlert: () => {
      dispatch({ type: 'CLOSE_ALERT_NO_EDIT', alert: false });
    },
    getDraftPublication: () => {
      dispatch(PostApi.getPostListDraft());
    },
  };
};
const SurveyContainer = connect(mapStateToProps, mapDispatchToProps)(Survey);

export default SurveyContainer;
