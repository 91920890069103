import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';

import PostApi from '../../../../Logicas/PostApi';
import { MuiThemeProvider } from 'material-ui/styles';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import { style } from '../../../../style';
import {
  required,
  minValue1,
  dateValue
} from '../../../validation/FormValidation';
import {
  DatePickerForm,
  TextFieldForm
} from '../../../utils_form/FormComponents';
import ButtonDefault, {
  ButtonDisable,
  DropDownDefaultOnlyOne
} from '../../../utils/FormsComponents';

import IcoExit from '../../../utils/img/exit-dialog.png';

require('../css/sweepstakes.css');

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

class Sweepstakes extends Component {
  constructor() {
    super();
    this.state = { typeRules: '' };
    this.saveDrawSend = this.saveDrawSend.bind(this);
  }

  saveDrawSend(values) {
    this.props.saveDraw(
      this.props.idPublish,
      values,
      this.state.typeRules,
      this.props.drawDetails.id
    );
  }

  componentWillMount() {
    PubSub.subscribe(
      'render-draw',
      function(render) {
        this.forceUpdate();
      }.bind(this)
    );
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.props.idPublish) {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div className="container-sweepstakes">
            <div>
              <header className="header-sweepstakes">
                <h3 className="title-header-sweepstakes">
                  Defina as regras do sorteio
                </h3>
                <p>
                  {this.props.registeredDraw &&
                    'Já existe um sorteio cadastrado!'}
                </p>
                <div className="button-header-sweepstakes">
                  {this.state.typeRules.length > 0 ? (
                    <ButtonDefault
                      onClick={handleSubmit(this.saveDrawSend)}
                      label="Salvar"
                    />
                  ) : (
                    <ButtonDisable label="Salvar" />
                  )}
                </div>
              </header>
              <div className="body-sweepstakes">
                <form className="form-sweepstakes">
                  <label className="date-sweepstakes">
                    <DatePickerForm
                      validations={[required, dateValue]}
                      name="date_draw"
                      label="Data Prevista do Sorteio*"
                    />
                  </label>
                  <label className="qtd-sweepstakes">
                    <TextFieldForm
                      name="qtd_people"
                      disabled={false}
                      validations={[required, minValue1]}
                      floatingLabelText="Quantidade de ganhadores*"
                      type="number"
                    />
                  </label>
                  <label className="rules-sweepstakes">
                    <DropDownDefaultOnlyOne
                      name="rules"
                      multiple={false}
                      value={this.state.typeRules}
                      onChange={(event, index, value) =>
                        this.setState({ typeRules: value })
                      }
                      validations={[required]}
                      array={['Curtir', 'Comentar', 'Curtir e Comentar']}
                      floatingLabelText="Regras do sorteio:*"
                    />
                  </label>
                </form>
              </div>
            </div>
          </div>
          <Modal
            visible={this.props.showModal}
            width="40%"
            height="35%"
            effect="fadeInUp"
          >
            <div className="container-modal-schedule">
              <div className="exit-modal-draw">
                <img
                  onClick={this.props.closeDialogOk}
                  className="btn-exit"
                  src={IcoExit}
                  alt="fechar"
                />
              </div>
              <div className="body-draw-confirm">
                <label className="label-title">
                  <p>Sorteio salvo!</p>
                </label>
                <label className="label-confirm-button">
                  <ButtonDefault
                    label="OK"
                    onClick={this.props.closeDialogOk}
                  />
                </label>
              </div>
            </div>
          </Modal>
        </MuiThemeProvider>
      );
    }
  }
}
let DrawSweepstakes = reduxForm({ form: 'sweepstakes' })(Sweepstakes);

const mapStateToProps = (state) => {
  return {
    drawDetails: state.draw.drawDetails,
    registeredDraw: state.draw.registeredDraw,
    showModal: state.show_modal_insert_users_tag
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveDraw: (idPublish, values, typeRules, id) => {
      dispatch(PostApi.saveDraw(idPublish, values, typeRules, id));
    },
    closeDialogOk: () => {
      dispatch({ type: 'HIDE_MODAL_INSERT_TAG' });
    }
  };
};

DrawSweepstakes = connect(mapStateToProps, mapDispatchToProps)(DrawSweepstakes);

export default DrawSweepstakes;
