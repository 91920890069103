import React, { Component } from 'react';

import PubSub from 'pubsub-js';
import Chip from 'material-ui/Chip';
import { connect } from 'react-redux';
import Avatar from 'material-ui/Avatar';
import Modal from 'react-responsive-modal';
import MenuItem from 'material-ui/MenuItem';
import { Field, reduxForm } from 'redux-form';
import { formValueSelector } from 'redux-form';
import { SelectField } from 'redux-form-material-ui';

import TagApi from '../../../../Logicas/TagApi';
import UserApi from '../../../../Logicas/UserApi';
import { required } from '../../../validation/FormValidation';

import ButtonDefault, {
  ButtonDisable,
  TextFieldDefault,
} from '../../../utils/FormsComponents';
import {AutoCompleteForm} from '../../../utils_form/FormComponents';

import IcoExit from '../../../utils/img/exit-dialog.png';
import ImgPlaceHolder from '../../../utils/img/place_holder_user.png';
require('./dialog.css');
require('../form/formscoreusers.css');
class DialogInsertTag extends Component {
  constructor() {
    super();
    this.state = {};
    this.removerUser = this.removerUser.bind(this);
    this.sendGift = this.sendGift.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  removerUser(user) {
    this.props.removeUserMarked(user);
    this.forceUpdate();
  }

  sendGift(values) {
    var users_ids = [];
    users_ids = this.props.list_marked.map(function(user) {
      return user.id;
    });
    let tagsParsed = [];
    this.props.tags_select.map(tag => tagsParsed.push(tag.value));
    this.props.updateUserTags(users_ids, tagsParsed);
    PubSub.publish('clear-list-marked', true);
  }

  closeDialog(values) {
    values.score = '';
    values.justify = '';
    this.props.closeDialogTag();
    PubSub.publish('clear-list-marked', true);
  }

  componentWillMount() {
    this.props.getTags();
  }

  render() {
    const { handleSubmit } = this.props;
    let chipbody = {
      marginBottom: '5px',
      marginTop: '5px',
      marginRight: '5px',
      paddingLeft: '0px'
    };

    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let roleAdmin = roles.filter(function(role) {
      return role.name === 'admin';
    });
    let roleManager = roles.filter(function(role) {
      return role.name === 'manager';
    });
    const tagspeaker = JSON.parse(localStorage.getItem('tagspeaker'));
    const tags = tagspeaker.map((tag) => tag.name);

    return (
      <div className="container">
        <Modal
          classNames={{ width: '500px' }}
          closeIconSvgPath={IcoExit}
          center={true}
          open={this.props.visible}
        >
          {this.props.showModal === true && (
            <div className="body-form-tag-insert">
              <div className="row-users">
                {this.props.check_all_users !== true && (
                  <label className="label-users-length">
                    Atribuir{' '}
                    {this.props.list_marked.length > 1
                      ? 'aos usuários'
                      : ' ao usuário'}{' '}
                    ({this.props.list_marked.length})
                  </label>
                )}
                <img
                  onClick={this.closeDialog}
                  className="btn-exit"
                  src={IcoExit}
                  alt="Ícone para fechar modal"
                />
              </div>
              <div className="display-chips">
                {this.props.check_all_users === true &&
                this.props.userFilter !== false ? (
                  <div className="label-alert">
                    Todos os usuários filtrados por{' '}
                    {this.props.userFilter.selectedFilter ===
                    'Mês do Aniversário'
                      ? 'aniversário em '
                      : this.props.userFilter.selectedFilter.toLowerCase()}{' '}
                    {this.props.userFilter.filterValue === 'M' ||
                    this.props.userFilter.filterValue === 'F'
                      ? this.props.userFilter.filterValue === 'M'
                        ? ' masculino'
                        : ' feminino'
                      : this.props.userFilter.filterValue}{' '}
                    serão afetados.
                  </div>
                ) : (
                  Array.isArray(this.props.list_marked) &&
                  this.props.list_marked.map(
                    function(user) {
                      return (
                        <Chip backgroundColor="#efefef" style={chipbody}>
                          <Avatar
                            src={user.image ? user.image : ImgPlaceHolder}
                          />
                          {user.name}
                          <img
                            onClick={() => this.props.toggleSelectedUser(user)}
                            className="btn-remove-chip"
                            src={IcoExit}
                            alt="Ícone para remover usuário da listagem"
                          />
                        </Chip>
                      );
                    }.bind(this)
                  )
                )}
              </div>
              <form className="form-to-score">
                <AutoCompleteForm
                  label="Tags*"
                  tags={roleAdmin[0] || roleManager[0] ? this.props.tags : tags}
                  name="tags_select"
                  validations={[required]}
                  isLoading={this.props.isLoadingTags}
                  getAuto={this.props.getTagAutoComplete}
                  getTagAutoComplete={this.props.getTagAutoComplete}
                  // onChange={this.updateSelectedTags}
                />
                <div style={{ width: '80%' }}>
                  <Field
                    disabled={true}
                    name="user_responsible"
                    id="name"
                    label="Responsável"
                    component={TextFieldDefault}
                    type="text"
                  />
                </div>
              </form>
              {this.props.list_marked.length > 0 && this.props.valid ? (
                <div className="submit-btn">
                  <ButtonDefault
                    onClick={handleSubmit(this.sendGift)}
                    label={
                      this.props.tags_select
                        ? this.props.tags_select.length > 1
                          ? 'Atribuir tags'
                          : 'Atribuir tag'
                        : ''
                    }
                  />
                </div>
              ) : (
                <div className="submit-btn">
                  <ButtonDisable label="Atribuir tag" />
                </div>
              )}
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
let DialogContainer = reduxForm({
  form: 'insert_tag'
})(DialogInsertTag);
const selector = formValueSelector('insert_tag');
const mapStateToProps = (state) => {
  return {
    list_marked: state.user.selectedUsers.users,
    initialValues: { user_responsible: localStorage.getItem('UserName') },
    showModal: state.show_modal_insert_users_tag,
    check_all_users: state.check_all_users,
    userFilter: state.user.userFilter,
    visible: state.show_modal_insert_users_tag,
    tags: state.tagsparsed,
    tags_select: selector(state, 'tags_select')
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeDialogTag: () => dispatch({ type: 'HIDE_MODAL_INSERT_TAG' }),
    removeUserMarked: (user, checked) =>
      dispatch(UserApi.insertUserToEditScore(user, false)),
    updateUserTags: (user, tags) =>
      dispatch(UserApi.updateUserTags(user, tags)),
    resetDialog: () => dispatch({ type: 'SHOW_MODAL_BIRTHDAY' }),
    clearFilterStore: () => dispatch({ type: 'CLEAR_FILTER_USER' }),
    getTags: () => dispatch(TagApi.getTagsUser()),
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    toggleSelectedUser: (selectedUser) => {
      dispatch({
        type: 'USER_SELECTED_TOGGLE',
        selectedUser
      });
    }
  };
};
DialogContainer = connect(mapStateToProps, mapDispatchToProps)(DialogContainer);
export default DialogContainer;
