import API, {API_V2} from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import { browserHistory } from 'react-router';
import errorHandler from '../componentes/utils/errorHandler';

export default class NotificationApi {
  static getNotifications(page) {
    return (dispatch) => {
      API.get('push_notifications/?page=' + page, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'GET_NOTIFICATION_LIST',
              notification_list: response.data
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static sendNotification(users, title, description) {
    return (dispatch) => {
      var formdata = new FormData();
      users.map(user =>{
        formdata.append('users_ids[]', user.user ? user.user.id : user.id);
      })
      formdata.append('title', title);
      formdata.append('description', description);
      API_V2.post('notifications/shoot', formdata, Config.headers())
        .then(
          function(response) {
            dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'SHOW_MODAL_BIRTHDAY' });
            dispatch({ type: 'CLOSE_DIALOG_NOTIFICATION_USERS', alert: false });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static resendCertificate(idCourse, user) {
    return (dispatch) => {
      var formdata = new FormData();
      formdata.append('users_ids[]', user);
      API.post(
        `coursers/${idCourse}/send_certificate`,
        formdata,
        Config.headers()
      )
        .then(
          function() {
            dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'SHOW_MODAL_BIRTHDAY' });
            dispatch({ type: 'CLOSE_DIALOG_NOTIFICATION_USERS', alert: false });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static closeDialogNotification() {
    return (dispatch) => {
      dispatch({ type: 'SHOW_MODAL_BIRTHDAY' });
      dispatch({ type: 'CLOSE_DIALOG_NOTIFICATION_USERS', alert: false });
    };
  }
}
