import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import NewEventForm from './NewEventForm';
import CalendarApi from '../../Logicas/CalendarApi';
import Zoom from 'react-reveal/Zoom';

class Calendar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container">
        <Zoom>
          {this.props.eventsList ? (
            <NewEventForm
              getUserTagsAssociation={this.props.getUserTagsAssociation}
            />
          ) : (
            <div></div>
          )}
        </Zoom>
      </div>
    );
  }
}
Calendar = reduxForm({ form: 'new_event_calendar' })(Calendar);
const mapStateToProps = (state) => {
  return {
    eventsList: state.eventsReducer.eventsList
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTodayEvents: () => {
      dispatch(CalendarApi.getTodayEvents(new Date()));
    }
  };
};
const CalendarListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);

export default CalendarListContainer;
