import React, { Component } from 'react';

import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import { browserHistory } from 'react-router';

import UserApi from '../../../Logicas/UserApi';
import ModuleApi from '../../../Logicas/ModuleApi';
import DialogApi from '../../../Logicas/DialogApi';
import CourseApi from '../../../Logicas/CourseApi';
import NotificationApi from '../../../Logicas/NotificationApi';

import FormModule from '../modules/FormModule';
import CreateCardsCourse from './CreateCardsCourse';
import CardDetailsCourse from './CardDetailsCourse';
import TableCourseDetails from './TableCourseDetails';
import CreateContentText from '../contents/NewContentText';
import CreateContentQuiz from '../contents/NewContentQuiz';
import ComponentHeader from '../../utils/ComponenteHeader';
import { required } from '../../validation/FormValidation';
import { DialogAlert } from '../../utils/DailogComponentes';
import CreateContentVideo from '../contents/NewContentVideo';
import CreateContentImage from '../contents/NewContentImage';
import ButtonDefault, { ButtonDisable } from '../../utils/FormsComponents';

import IcoExit from '../../utils/img/exit-dialog.png';

required('./carddetailcourse.css');

class CourseModules extends Component {
  constructor() {
    super();
    this.state = { elements: [], message: '' };
    this.createModule = this.createModule.bind(this);
    this.handleInputMessage = this.handleInputMessage.bind(this);
  }

  componentDidMount() {
    if (!this.props.course.id) {
      this.props.reloadedCourse(localStorage.getItem('IdCourseEdit'));
    }
    PubSub.subscribe(
      'add_elements',
      function(element, ids) {
        let elements = this.state.elements;
        elements.push(
          <CreateCardsCourse
            module_id={ids.module_id}
            course_id={ids.course_id}
            id={Math.random()}
          />
        );
        this.setState({ elements: elements });
      }.bind(this)
    );
    PubSub.subscribe(
      'move_scroll',
      function(element, ids) {
        this.moveScrollToEnd();
      }.bind(this)
    );
  }
  createModule() {
    this.props.createModule(
      this.props.course.id,
      this.props.course.module_courses.length
    );
  }

  handleInputMessage(event) {
    this.setState({ message: event.target.value });
  }

  editCourseForm() {
    browserHistory.push('/course/edit');
  }

  moveScrollToEnd = () =>
    document
      .getElementById('list-cards-rows')
      .scrollTo(document.getElementById('list-cards-rows').scrollWidth, 0);

  render() {
    return (
      <div>
        {this.props.course && this.props.course.id && (
          <div>
            <ComponentHeader
              actions={[
                {
                  name: 'Editar Curso',
                  action: this.editCourseForm
                }
              ]}
              name="Cursos"
              tooltip={<p> Adicionar módulos </p>}
              nameaction="Criar novo módulo"
              onClick={this.createModule}
            />
            <div className="">
              <div className="container">
                <CardDetailsCourse
                  editModule={this.props.editModule}
                  alert={this.props.alert}
                  deleteModule={this.props.deleteModule}
                  closeDialog={this.props.closeDialog}
                  deleteShowDialogModule={this.props.deleteShowDialogModule}
                  course={this.props.course}
                />

                {this.props.course.module_courses && (
                  <React.Fragment>
                    {this.props.course.module_courses.map(
                      function(module) {
                        return (
                          <React.Fragment>
                            <div className="module-row">
                              <FormModule module={module} />
                            </div>

                            <div
                              id="list-cards-rows"
                              className="list-cards-rows"
                            >
                              <div id="body-scroll-cards" className="row-cards">
                                {module.content.map(
                                  function(content) {
                                    switch (content.kindcontent) {
                                      case 'image':
                                        return (
                                          <CreateContentImage
                                            id_edit={content.id}
                                            img_name={content.title}
                                            img_file={content.image.url}
                                            course_id={module.course_id}
                                            modulse_id={module.id}
                                          />
                                        );
                                      case 'video':
                                        return (
                                          <CreateContentVideo
                                            id_edit={content.id}
                                            video_name={content.title}
                                            video_file={content.video.url}
                                            module_id={module.id}
                                            course_id={this.props.course.id}
                                          />
                                        );
                                      case 'text':
                                        return (
                                          <CreateContentText
                                            id_edit={content.id}
                                            text_name={content.title}
                                            text={content.textcontent}
                                            module_id={module.id}
                                            course_id={this.props.course.id}
                                          />
                                        );
                                      case 'quiz':
                                        if (content.answer.length > 0)
                                          return (
                                            <CreateContentQuiz
                                              id_edit={content.id}
                                              question={content.questiontext}
                                              score={content.score}
                                              answers={content.answer}
                                              module_id={module.id}
                                              course_id={this.props.course.id}
                                            />
                                          );
                                    }
                                  }.bind(this)
                                )}
                                <div onClick={this.moveScrollToEnd}>
                                  <CreateCardsCourse
                                    module_id={module.id}
                                    id={0}
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      }.bind(this)
                    )}
                    <div className="card-details-body">
                      <div className="header-details">
                        <p className="title-header-details">Usuarios</p>
                      </div>
                      <div className="body-details">
                        <div className="header-title-course">
                          <ul className="body-details-first">
                            <li>
                              <div
                                className={
                                  this.props.headerName === 'Fazendo'
                                    ? 'menu-item-details active'
                                    : 'menu-item-details'
                                }
                                onClick={() =>
                                  this.props.getHeaderCourseDetails('Fazendo')
                                }
                              >
                                Fazendo
                              </div>
                            </li>
                            <li>
                              <div
                                className={
                                  this.props.headerName === 'Concluiram'
                                    ? 'menu-item-details active'
                                    : 'menu-item-details'
                                }
                                onClick={() =>
                                  this.props.getHeaderCourseDetails(
                                    'Concluiram'
                                  )
                                }
                              >
                                Concluíram
                              </div>
                            </li>
                            <li>
                              <div
                                className={
                                  this.props.headerName === 'Não Começaram'
                                    ? 'menu-item-details active'
                                    : 'menu-item-details'
                                }
                                onClick={() =>
                                  this.props.getHeaderCourseDetails(
                                    'Não Começaram'
                                  )
                                }
                              >
                                Não Começaram
                              </div>
                            </li>
                          </ul>
                          <div className="send-invitation-course">
                            {this.props.headerName === 'Não Começaram' && (
                              <button
                                type="button"
                                className="btn-send-msg"
                                onClick={() => this.props.showDialogMsg()}
                              >
                                <p>ENVIAR MENSAGEM</p>
                              </button>
                            )}
                          </div>
                        </div>
                        <div>
                          <TableCourseDetails elements={this.props.course} />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <DialogAlert
              closeDialog={this.props.closeDialogErro.bind(this)}
              message={this.props.erroalert.message}
              visible={this.props.erroalert.visible}
            />
            <Modal width="50%" height="45%" visible={this.props.visible}>
              <div className="send-notification-course">
                <div className="notification-img-close">
                  <img
                    onClick={() => this.props.closeDialogNotification()}
                    className="btn-exit"
                    src={IcoExit}
                    alt="fechar"
                  />
                </div>
                {this.props.showModal ? (
                  <div>
                    <header className="notification-title">
                      Enviar notificação
                    </header>
                    <div className="notification-body-msg">
                      <p className="notificaiton-msg-description">Mensagem</p>
                      <input
                        className="msg-notification-course-input"
                        type="text"
                        placeholder="Seu curso está te esperando!"
                        value={this.state.message}
                        onChange={this.handleInputMessage}
                        validate={[required]}
                      />
                    </div>
                    <div className="notification-btn-msg">
                      {this.state.message.length > 0 ? (
                        <ButtonDefault
                          onClick={() =>
                            this.props.sendNotification(
                              this.props.course.users_not_started,
                              this.state.message
                            )
                          }
                          label="Enviar"
                        />
                      ) : (
                        <ButtonDisable label="Enviar" />
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <header className="notification-title">
                      Mensagem enviada!
                    </header>
                    <div className="notification-btn-msg">
                      <ButtonDefault
                        onClick={() => this.props.closeDialogNotification()}
                        label="OK"
                      />
                    </div>
                  </div>
                )}
              </div>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    course: state.course.course,
    alert: state.dialog,
    pagination: state.pagination,
    erroalert: state.erroalert,
    list: state.crudUser,
    headerName: state.getHeaderCourseDetails,
    showModal: state.show_modal_birthday,
    visible: state.dialog_notification_users
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteModule: (id, idcourse) => {
      dispatch(ModuleApi.deleteModule(id, idcourse));
    },
    editModule: (id) => {
      dispatch(ModuleApi.getModuleEdit(id));
    },
    deleteCourse: (id) => {
      dispatch(CourseApi.deleteCourse(id));
    },
    deleteShowDialogModule: (values) => {
      dispatch(DialogApi.deleteAlertModule(values));
    },
    deleteShowDialogCourse: (values) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    closeDialogErro: () => {
      dispatch(DialogApi.closeDialogErro());
    },
    getUsers: () => {
      dispatch(UserApi.getUserList());
    },
    getHeaderCourseDetails: (titleName) => {
      dispatch(CourseApi.getHeaderCourseDetails(titleName));
    },
    createModule: (courseid, module_size) =>
      dispatch(
        ModuleApi.createModule(
          {
            module_title: `Módulo ${module_size + 1}`,
            module_score: 0,
            module_description: `módulo ${module_size + 1}`,
            idcourse: courseid
          },
          courseid
        )
      ),
    showDialogMsg: () =>
      dispatch({ type: 'OPEN_DIALOG_NOTIFICATION_USERS', alert: true }),
    sendNotification: (users, title, description) => {
      dispatch(NotificationApi.sendNotification(users, title, description));
    },
    closeDialogNotification: () =>
      dispatch(NotificationApi.closeDialogNotification()),
    reloadedCourse: (id) => {
      dispatch(CourseApi.reloadedCourse(id));
    }
  };
};
const CourseEditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseModules);

export default CourseEditContainer;
