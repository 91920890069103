import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import StoreApi from './StoreApi';
import { browserHistory } from 'react-router';
import DialogApi from './DialogApi';
import errorHandler from '../componentes/utils/errorHandler';
import { ValidImage } from '../componentes/validation/FormValidation';

export default class ProductApi {
  static patchProduct(values) {
    return (dispatch) => {
      var product = this.buildFormData(values);
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };
      API.patch('products/' + values.id, product, CONFI)
        .then(
          function(response) {
            dispatch(StoreApi.getProducts(1));
            browserHistory.push('/store/products');
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'END_LOAD' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static cancelBuy(user_id, product_id, buy_id, historyCurrentPage) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('number_sell', buy_id);
      formData.append('product_id', product_id);
      formData.append('user_id', user_id);
      API.post('products/cancel_buy', formData, Config.headers())
        .then(
          function(response) {
            dispatch(StoreApi.getNotDispatchedProductList(historyCurrentPage));
            dispatch(DialogApi.closeDialog());
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static saveProduct(values) {
    return (dispatch) => {
      if (!ValidImage(values.image_product[0].type)) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message:
              'Imagem em formato inválido. Envia uma imagem em formato .jpg ou .png'
          }
        });
        return;
      }

      var product = this.buildFormData(values);
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };
      API.post('products/', product, CONFI)
        .then(
          function(response) {
            dispatch(StoreApi.getProducts(1));
            browserHistory.push('/store/products');
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'END_LOAD' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static buildFormData(values) {
    var formData = new FormData();
    formData.append('name', values.name_product);
    if (Array.isArray(values.image_product)) {
      formData.append('image', values.image_product[0]);
    }
    formData.append('description', values.description_product);
    formData.append('valuescore', values.score_product);
    formData.append('amount', values.amount_product);
    formData.append('kind', values.kind_product);
    if (Array.isArray(values.tags_product)) {
      values.tags_product.map(function(tag, i) {
        formData.append('tags[]', tag.value);
      });
    }
    formData.append('store_id', values.store_product);
    formData.append('purchase_limit', values.purchase_limit);
    formData.append('default_message_to_send', values.default_message_to_send);
    return formData;
  }
}
