import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeaderColumn
} from 'material-ui/Table';
import PlaceHolder from '../PlaceHolder/PlaceHolder';
import moment from 'moment';

require('./productlist.css');

class ProductList extends Component {
  render() {
    return (
      <div className="publication-list-dash">
        <div>
          <div className="header-publication-list">
            <div className="header-publication-list-description">
              <label className="main-label-header-publication-list">
                {this.props.label}
              </label>
              <label className="secondary-label-header-publication-list">
                {this.props.description}
              </label>
            </div>
          </div>
          {this.props.list.length === 0 ? (
            <PlaceHolder />
          ) : (
            <Table displayRowCheckbox={false} height="300px">
              <TableBody displayRowCheckbox={false}>
                <TableRow displayRowCheckbox={false}>
                  <TableHeaderColumn displayRowCheckbox={false}>
                    Produto
                  </TableHeaderColumn>
                  <TableHeaderColumn displayRowCheckbox={false}>
                    Quantidade
                  </TableHeaderColumn>
                  <TableHeaderColumn displayRowCheckbox={false}>
                    Solicitante
                  </TableHeaderColumn>
                  <TableHeaderColumn displayRowCheckbox={false}>
                    Solicitado em
                  </TableHeaderColumn>
                  <TableHeaderColumn displayRowCheckbox={false}>
                    Entregue em
                  </TableHeaderColumn>
                </TableRow>
                {Array.isArray(this.props.list)
                  ? this.props.list.map(function(product) {
                      let hour = moment(product.date_request).fromNow();
                      return (
                        <TableRow displayBorder={false}>
                          <TableRowColumn>
                            <label className="label-title-list">
                              {product.product_name}
                            </label>
                          </TableRowColumn>
                          <TableRowColumn>
                            <label className="label-title-list">
                              {product.product_amout}
                            </label>
                          </TableRowColumn>
                          <TableRowColumn>
                            <label className="label-title-list">
                              {product.user_name}
                            </label>
                          </TableRowColumn>
                          <TableRowColumn>
                            <label className="label-title-list">{hour}</label>
                          </TableRowColumn>
                          <TableRowColumn>
                            <label className="label-title-list">
                              {product.date_delivery === undefined
                                ? 'Aguardando'
                                : moment(product.date_delivery).fromNow()}
                            </label>
                          </TableRowColumn>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    );
  }
}
export default ProductList;
