import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { SelectField, MenuItem } from 'material-ui';

export class MergeSelectInput extends Component {
  constructor() {
    super();
    this.state = {
      selectedValue: ''
    };
  }
  componentDidMount() {
    const { initialValue } = this.props;
    if (initialValue) {
      this.setState({ selectedValue: initialValue });
    }
  }
  render() {
    const { options } = this.props;
    return (
      <SelectField
        floatingLabelText={this.props.floatingLabelText}
        onChange={this.props.onChange}
        value={this.state.selectedValue}
        disabled={this.props.disabled}
        fullWidth={this.props.fullWidth}
      >
        {options.map((option) => {
          const { name } = option;
          return (
            <MenuItem
              key={uuidv1()}
              value={name}
              primaryText={name}
              onClick={() => this.setState({ selectedValue: name })}
            />
          );
        })}
      </SelectField>
    );
  }
}

export default MergeSelectInput;
