import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from 'material-ui/styles';
import { DropZoneVideoForm, TextForm } from '../../utils_form/FormComponents';
import ContentApi from '../../../Logicas/ContentApi';
import DialogApi from '../../../Logicas/DialogApi';
import IconRemove from '../../utils/img/ic-excluir.svg';
import { Player } from 'video-react';
import ReactLoading from 'react-loading';
import PubSub from 'pubsub-js';
require('./contentbody.css');

class CreateContentVideo extends Component {
  constructor() {
    super();
    this.state = { edit: false, render: true };
    this.setVideo = this.setVideo.bind(this);
    this.resetEdit = this.resetEdit.bind(this);
    this.saveVideo = this.saveVideo.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
  }

  componentDidMount() {
    this.setState({ img_name: this.props.video_name });
    this.setState({ video: this.props.video_file });
  }

  resetEdit() {
    if (this.props.id_edit === undefined) {
      PubSub.publish('clear_new_elements', true);
    } else {
      this.setState({ img_name: this.props.video_name, edit: false });
      this.setState({ image: this.props.video_file });
    }
  }

  saveVideo() {
    if (this.state.img_name !== undefined && this.state.video !== undefined) {
      this.setState({ edit: false });
      if (this.props.id_edit !== undefined) {
        this.props.showLoad(this.props.id_edit);
        this.props.patchContent(
          { title_card: this.state.img_name, img_file: this.state.video },
          this.props.id_edit,
          this.props.course_id
        );
      } else {
        this.props.showLoad(this.props.id_edit);
        this.props.saveContent(
          { title_card: this.state.img_name, img_file: this.state.video },
          this.props.module_id,
          this.props.course_id
        );
      }
    } else {
      this.props.showErroValidation(
        'Título do video e o video são obrigatórios'
      );
    }
  }

  deleteCard() {
    this.props.deleteContent(this.props.id_edit, this.props.course_id);
  }

  setVideo(file) {
    this.setState({ video: file });
  }

  render() {
    if (this.state.render) {
      return (
        <MuiThemeProvider>
          <form className="content-body-display">
            {this.props.load_content_requisition.visible === false ||
            this.props.load_content_requisition.id !== this.props.id_edit ? (
              <React.Fragment>
                <div className="actions-card-course">
                  {this.state.edit === true ||
                  this.props.id_edit === undefined ? (
                    <React.Fragment>
                      <label onClick={this.saveVideo} className="label-actions">
                        Salvar
                      </label>
                      <label onClick={this.resetEdit} className="label-actions">
                        Cancelar
                      </label>
                    </React.Fragment>
                  ) : (
                    <label
                      onClick={() => this.setState({ video: null, edit: true })}
                      className="label-actions"
                    >
                      Editar
                    </label>
                  )}
                  {this.state.edit === true ? (
                    <img
                      onClick={this.deleteCard}
                      className="img-card-course"
                      src={IconRemove}
                      alt="remover"
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <TextForm
                  onChange={(event) =>
                    this.setState({ img_name: event.target.value })
                  }
                  value={this.state.img_name}
                />
                <DropZoneVideoForm
                  video={this.state.video}
                  onDrop={this.setVideo}
                />
                {this.state.video !== undefined ? (
                  <Player
                    alt="..."
                    aspectRatio="16:9"
                    src={
                      Array.isArray(this.state.video)
                        ? this.state.video[0].preview
                        : this.state.video
                    }
                  />
                ) : null}
              </React.Fragment>
            ) : (
              <div className="display-load">
                <label className="save-label">Salvando</label>
                <ReactLoading
                  type="balls"
                  color="#c3c3c3"
                  height={'10%'}
                  width={'10%'}
                />
              </div>
            )}
          </form>
        </MuiThemeProvider>
      );
    } else {
      return <div />;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveContent: (values, idmodule, idcourse) => {
      dispatch(ContentApi.saveContentVideo(values, idmodule, idcourse));
    },
    patchContent: (values, idmodule, idcontent) => {
      dispatch(ContentApi.patchContentVideo(values, idmodule, idcontent));
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    },
    deleteShowDialogUser: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    deleteContent: (values, idmodule) => {
      dispatch(ContentApi.deleteContent(values, idmodule));
    },
    showLoad: (id) => {
      dispatch({ type: 'SUBMITTING_CONTENT', id: id });
    },
    showErroValidation: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: message
        }
      })
  };
};

const mapStateToProps = (state) => {
  return {
    load_content_requisition: state.load_content_requisition
  };
};

const ContentVideoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContentVideo);

export default ContentVideoContainer;
