export default class Config {
  static headers(store) {
    let userRole = JSON.parse(localStorage.getItem('UserRoles'));

    var config = {
      headers: {
        'content-type': 'multipart/form-data',
        'access-token': localStorage.getItem('token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
        'resource-type': localStorage.getItem('resource-type'),
        'resource-origin': userRole[0].name
      }
    };

    return config;
  }

  static headersJson(store) {
    var config = {
      headers: {
        'content-type': 'application/json',
        'access-token': localStorage.getItem('token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
        'resource-type': localStorage.getItem('resource-type')
      }
    };

    return config;
  }
}
