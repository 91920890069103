import React, { Component } from 'react';
import { connect } from 'react-redux';

import { style } from '../../../style';
import HistoricalApi from '../../../Logicas/HistoricalApi';

import { BodyTable } from '../../utils/BodyTable';
import { FooterPagination } from '../../utils/Pagination';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableData,
  TableHeadRow
} from '../../utils/Table';

import IcoPontos from '../../utils/img/ic-pontos.svg';
import not_img from '../../utils/img/ico-external.png';

class ComponentListCourse extends Component {
  render() {
    const teamName = style.CurrencyName.name.split('/');
    return (
      <BodyTable>
        <Table>
          <TableHead>
            <TableHeadRow>Publicação</TableHeadRow>
            <TableHeadRow>{teamName[1]} / Finalização</TableHeadRow>
            <TableHeadRow>{teamName[1]} (Totais)</TableHeadRow>
          </TableHead>

          <TableBody>
            {this.props.listCourse.courses &&
              this.props.listCourse.courses.map((listCourse, index) => {
                return (
                  <TableRow className="content-list-history" key={index}>
                    <TableData>
                      <img
                        id="img-history-publish"
                        src={
                          listCourse.image.url ? listCourse.image.url : not_img
                        }
                        alt="Capa da Publicação"
                      />
                      {listCourse.title && listCourse.title.length > 40
                        ? `${listCourse.title.slice(0, 40)}...`
                        : listCourse.title}
                    </TableData>
                    <TableData>
                      <div className="text-color-historical">
                        {listCourse.score_by_finish}
                      </div>
                    </TableData>
                    <TableData>
                      <div className="text-color-historical text-edit-historical-img">
                        <strong>{listCourse.total_score}</strong>
                        <img src={IcoPontos} alt="moeda de pontos" />
                      </div>
                    </TableData>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {this.props.listCourse.size &&
          this.props.listCourse.size[1] > 0 &&
          this.props.listCourse.courses && (
            <FooterPagination
              activePage={this.props.pageActive}
              totalItemsCount={this.props.listCourse.size[0]}
              handlePageChange={this.props.getListCourseHistory.bind(
                this,
                this.props.filterTitle,
                this.props.startDate,
                this.props.endDate
              )}
            />
          )}
      </BodyTable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageActive: state.historical_points.pageActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListCourseHistory: (title, startDate, endDate, page) => {
      dispatch(
        HistoricalApi.getListCourseHistory(page, title, startDate, endDate)
      );
    }
  };
};

const ComponentListCourseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentListCourse);

export default ComponentListCourseContainer;
