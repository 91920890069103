const initialState = {
  formDetails: {},
  modalContent: {
    answerTitle: '',
    usersWhoVoted: [],
    content_survey: [], 
    survey:{
      users_who_voted:[]
    }, 
    content:{}
  },
  searchUsers: {
    searchValue: '',
    foundUsers: []
  }
};

export function form(state = initialState, action) {
  switch (action.type) {
    case 'GET_VIEW_FORM':
      return { ...state, formDetails: action.formDetails };
    case 'GET_USERS_VOTERS':
      const {
        modalContent: { usersWhoVoted }
      } = action;
      return {
        ...state,
        modalContent: action.modalContent,
        searchUsers: { foundUsers: usersWhoVoted }
      };
    case 'SEARCH_USERS_VOTERS':
      const { searchValue } = action;

      const foundUsers = state.modalContent.usersWhoVoted.filter((user) =>
        user.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      return { ...state, searchUsers: { searchValue, foundUsers } };
    case 'RESET_USERS_VOTERS':
      return {
        ...state,
        modalContent: { 
          answerTitle: '', 
          usersWhoVoted: [] , 
          content_survey: [], 
          survey:{
            users_who_voted:[]
          }, 
          content:{}
      },
        searchUsers: {
          searchValue: '',
          foundUsers: []
        }
      };
    default:
      return state;
  }
}
