import React, { useState, useEffect } from "react";

import { ReactComponent as IconSearch } from "./img/iconSearch.svg";

import * as S from "./SearchComponentStyle";

const SearchComponent = ({ functionSearch, setActiveSearch, setNotActiveSearch, course_search_active, clearSearch }) => {
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    let delaySearchString;
    if (searchString.trim() !== "") {
      setActiveSearch();
      delaySearchString = setTimeout(() => {
        functionSearch(searchString.trim());
      }, 500);
    } else if (clearSearch !== undefined){
      delaySearchString = setTimeout(() => {
        clearSearch();
      }, 500);
    }
    return () => clearTimeout(delaySearchString);
  }, [searchString]);

  document.body.addEventListener('click', (event) => {
    const courseSearchPreview = document.getElementById("ContainerOutPreviewSearch");
    const searchComponent = document.getElementById("ContainerSearchComponent");

    if(
      course_search_active && courseSearchPreview !== null 
      && !courseSearchPreview.contains(event.target) 
      && !searchComponent.contains(event.target)
    ){
      setNotActiveSearch();
    }
  });

  return (
    <S.ContainerSearch onFocus={searchString.trim() !== "" ? setActiveSearch : null} id="ContainerSearchComponent">
      <IconSearch className="magnifier" />
      <S.InputSearch
        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
        placeholder="Pesquise por título, descrição ou conteúdo"
        disabled={functionSearch === null}
        isDisabled={functionSearch === null}
      />
    </S.ContainerSearch>
  );
};

export default SearchComponent;
