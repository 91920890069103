const defaultState = {
  historical_list: [],
  pageActive: 1,
  userDetails: {},
  publishDetails: {},
  loadingSpreadsheet: false
};

export function historical_points(state = defaultState, action) {
  switch (action.type) {
    case 'GET_HISTORICAL_LIST':
      return {
        ...state,
        historical_list: action.historical_list,
        pageActive: action.pageActive
      };
    case 'GET_USER_DETAILS_HISTORICAL':
      return {
        ...state,
        userDetails: action.userDetails
      };
    case 'GET_PUBLISH_DETAILS_HISTORICAL':
      return {
        ...state,
        publishDetails: action.publishDetails,
        pageActive: action.pageActive
      };
    case 'LOADING_SPREADSHEET_START':
      return {
        ...state,
        loadingSpreadsheet: true
      };
    case 'LOADING_SPREADSHEET_FINISH':
      return {
        ...state,
        loadingSpreadsheet: false
      };
    default:
      return state;
  }
  return state;
}
