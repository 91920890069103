import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Modal from 'react-awesome-modal';
import { Button } from '../../../../user/components/UsersHeader/UsersHeader.styles';
import { TextFieldForm } from '../../../../utils_form/FormComponents';
import { LongTextField } from '../../../FormsComponents';
import {
  required,
  maxLengthTitle
} from '../../../../validation/FormValidation';
import IcoExit from '../../../../utils/img/exit-dialog.png';
import UserApi from '../../../../../Logicas/UserApi';

import * as S from './DialogBirthdayMessage.styles';

export class DialogBirthdayMessage extends Component {
  componentDidMount() {
    const { getBirthdayMessage } = this.props;
    getBirthdayMessage();
  }

  saveBirthdayMessage = ({
    id: messageId,
    title: messageTitle,
    message: messageBody
  }) => {
    const { updateBirthdayMessage, registerBirthdayMessage } = this.props;

    if (!messageId) {
      registerBirthdayMessage(messageTitle, messageBody);
    } else {
      updateBirthdayMessage(messageId, messageTitle, messageBody);
    }
  };
  render() {
    const {
      showBirthdayMessageModal,
      closeBirthdayMessageModal,
      updatingBirthdayMessage,
      handleSubmit
    } = this.props;
    return (
      <Modal
        effect="fadeInUp"
        width="520px"
        height="400px"
        visible={showBirthdayMessageModal}
      >
        <S.CloseButton
          src={IcoExit}
          onClick={() => closeBirthdayMessageModal()}
        />
        <S.ModalContentBody>
          {!updatingBirthdayMessage ? (
            <>
              <S.ModalTitle>Definir mensagem de aniversário</S.ModalTitle>
              <S.ModalForm onSubmit={handleSubmit(this.saveBirthdayMessage)}>
                <TextFieldForm
                  name="title"
                  floatingLabelText="Título"
                  validations={[required, maxLengthTitle]}
                  fullWidth
                />
                <div>
                  <Field
                    name="message"
                    validate={[required, maxLengthTitle]}
                    id="message"
                    label="Mensagem"
                    component={LongTextField}
                    type="text"
                  />
                  <p className="view-characters">
                    {this.props.currentMessage &&
                      `${this.props.currentMessage.length}/170.`}
                  </p>
                </div>
              </S.ModalForm>
              <Button
                disabled={!this.props.valid}
                onClick={handleSubmit(this.saveBirthdayMessage)}
              >
                Salvar
              </Button>
            </>
          ) : (
            <>
              <div></div>
              <S.ModalTitle>Salvando mensagem...</S.ModalTitle>
              <div></div>
            </>
          )}
        </S.ModalContentBody>
      </Modal>
    );
  }
}

DialogBirthdayMessage = reduxForm({
  form: 'dialogBirthdayMessageForm',
  enableReinitialize: true
})(DialogBirthdayMessage);

const selector = formValueSelector('dialogBirthdayMessageForm');

const mapStateToProps = (state) => ({
  showBirthdayMessageModal: state.user.birthdayMessage.showBirthdayMessageModal,
  updatingBirthdayMessage: state.user.birthdayMessage.updatingBirthdayMessage,
  initialValues: state.user.birthdayMessage.birthdayMessageBody,
  currentMessage: selector(state, 'message')
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeBirthdayMessageModal: () =>
      dispatch({
        type: 'BIRTHDAY_MESSAGE_MODAL_HIDE'
      }),
    registerBirthdayMessage: (messageTitle, messageBody) => {
      dispatch(UserApi.registerBirthdayMessage(messageTitle, messageBody));
    },
    updateBirthdayMessage: (messageId, messageTitle, messageBody) => {
      dispatch(
        UserApi.updateBirthdayMessage(messageId, messageTitle, messageBody)
      );
    },
    getBirthdayMessage: () => {
      dispatch(UserApi.getBirthdayMessage());
    }
  };
};

const DialogBirthdayMessageForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogBirthdayMessage);

export default DialogBirthdayMessageForm;
