const initialState = {
  publications: {},
  users: {},
  store: {
    product_length: 0,
    products_ordered: [],
    products_without_stock: []
  },
  courses: {},
  dashboardTab: 'PUBLICAÇÕES',
  first_date_start: new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  ),
  first_date_end: new Date(Date.now()),
  second_date_start: new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 2,
    new Date().getDate()
  ),
  second_date_end: new Date(Date.now()),
  minDate: new Date('2018-01-01T00:00:00'),
  loading: false,
  periodsComparison: false,
  loggedUserData: {
    userImage: '',
    userName: '',
    userId: '',
    userSector: '',
    userEmail: '',
    userScore: ''
  }
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DASHBOARD_PUBLICATIONS':
      return {
        ...state,
        publications: action.publications
      };
    case 'GET_DASHBOARD_USERS':
      return {
        ...state,
        users: action.users
      };
    case 'GET_DASHBOARD_STORE':
      return {
        ...state,
        store: action.store
      };
    case 'GET_DASHBOARD_COURSES':
      return {
        ...state,
        courses: action.courses
      };
    case 'CHANGE_DASHBOARD_TAB':
      return {
        ...state,
        dashboardTab: action.dashboardTab
      };
    case 'UPDATE_DATES':
      return {
        ...state,
        first_date_start: action.first_date_start,
        first_date_end: action.first_date_end,
        second_date_start: action.second_date_start,
        second_date_end: action.second_date_end
      };
    case 'LOADING_DASHBOARD_START':
      return {
        ...state,
        loading: true
      };
    case 'LOADING_DASHBOARD_END':
      return {
        ...state,
        loading: false
      };
    case 'PERIODS_COMPARISON_TRUE':
      return {
        ...state,
        periodsComparison: true
      };
    case 'PERIODS_COMPARISON_FALSE':
      return {
        ...state,
        periodsComparison: false
      };
    case 'SET_LOGGED_USER_DATA':
      return {
        ...state,
        loggedUserData: action.payload
      };
    default:
      return state;
  }
};

export default dashboardReducer;
