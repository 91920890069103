const roleAdmin = () => {
  const roles = JSON.parse(localStorage.getItem('UserRoles'));
  let roleAdmin = '';
  if (roles) {
    let roleName = roles.filter(function(role) {
      return role.name === 'admin';
    });
    if (roleName && roleName.length > 0) {
      roleAdmin = '&filter=web';
    }
  }

  return roleAdmin;
};

export default roleAdmin;
