import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';

import ModuleApi from '../../../../Logicas/ModuleApi';

import IcoRemove from '../../../utils/img/ic-excluir.svg';

import './CourseLongStyles.css';

class ListModules extends Component {
  constructor() {
    super();
    this.state = {};
    this.removedModule = this.removedModule.bind(this);
  }

  removedModule(idModule, idCourse) {
    if (this.props.listModulesCourse.length > 1) {
      this.props.deleteModule(idModule, idCourse);
    }
  }

  render() {
    return (
      <div className="container-list-modules">
        {this.props.listModulesCourse &&
          this.props.listModulesCourse.map((module, index) => {
            return (
              <div
                key={uuidv1()}
                className={`card-module ${this.props.moduleActive === index &&
                  'card-select'}`}
                onClick={() => this.props.sendSelectModule(index)}
              >
                <div>
                  <p className="card-title-module">
                    {index + 1}.{' '}
                    {module.name && module.name.length > 30
                      ? `${module.name.slice(0, 30)}...`
                      : module.name}
                  </p>
                  <p className="card-description-module">
                    {module.description && module.description.length > 38
                      ? `${module.description.slice(0, 38)}...`
                      : module.description}
                  </p>
                </div>

                <div className="removed-submodule">
                  <img
                    src={IcoRemove}
                    alt="Remover submódulo"
                    onClick={() =>
                      this.removedModule(module.id, module.course_id)
                    }
                  />
                </div>
              </div>
            );
          })}
        <div
          className="new-module-btn"
          onClick={() =>
            this.props.createModule(
              this.props.idCourseModules,
              this.props.listModulesCourse.length
            )
          }
        >
          <p>
            <strong>+</strong>
          </p>
          <p>NOVO MÓDULO</p>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendSelectModule: (moduleActive) => {
      dispatch({
        type: 'SELECT_MODULE',
        moduleActive: moduleActive
      });
    },
    createModule: (courseid, module_size, typeCourse) => {
      dispatch(
        ModuleApi.createModule(
          {
            module_title: `Módulo ${module_size + 1}`,
            module_score: 0,
            module_description: `Descrição Modulo ${module_size + 1}`,
            idcourse: courseid
          },
          courseid
        )
      );
    },
    deleteModule: (idModule, idCourse) => {
      dispatch(ModuleApi.deleteModule(idModule, idCourse, 'long'));
    }
  };
};

const ListModulesContainer = connect(null, mapDispatchToProps)(ListModules);

export default ListModulesContainer;
