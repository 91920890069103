const initialState = {
  images: [],
  videos: [],
  carousel: [],
  links: [],
  texts: [],
  showScheduleModal: false,
  schedulePublicationSuccess: false,
  patchingPost: false,
  enable_notification: false,
  send_push: null,
  statusRequest: "",
};

export function editpostform(state = initialState, action) {
  switch (action.type) {
    case 'formPostEdit':
      switch (action.kind) {
        case 'canvas':
          state.elements = [];
          state.elementsPreview = [];
          state.elementsQuestions = {
            survey: [],
            quiz: [],
            open_question: []
          };
          state.questionOnly = [];
          state.questionMultiple = [];
          state.texts = [];
          state.title = action.canvas.title;
          state.score = action.canvas.score;
          state.tags = action.canvas.tags;
          state.id = action.canvas.id;
          state.images = action.canvas.images;
          state.videos = action.canvas.videos;
          state.carousel = action.canvas.carousel;
          state.end_date = action.canvas.end_date;
          state.links = action.canvas.links;
          state.texts = action.canvas.texts;
          state.special_post = action.canvas.special_post;
          state.fixed_post = action.canvas.fixed_post;
          state.likes_enabled = action.canvas.likes_enabled;
          state.comments_enabled = action.canvas.comments_enabled;
          state.min_size_comments = action.canvas.min_size_comments;
          state.min_comments_enabled = action.canvas.min_size_comments > 1;
          state.modal_min_comments_is_visible = false;
          state.min_size_form = action.canvas.min_size_comments;
          state.interaction_message = action.canvas.interaction_message;
          state.publish_in = action.canvas.publish_in;
          state.published_at = action.canvas.published_at;
          state.expiration_in = action.canvas.expiration_in;
          state.not_score_after = action.canvas.not_score_after;
          state.user = action.canvas.user;
          if (action.canvas.tagsExpression) {
            state.tagsExpression = action.canvas.tagsExpression;
          }
          if (Array.isArray(action.canvas.elements)) {
            action.canvas.elements.map(function(element) {
              state.elements.push(element);
            });
          }
          if (Array.isArray(action.canvas.textsid)) {
            state.texts.map(function(text) {
              state['text_' + text.id] = text.text;
            });
          }
          state.patchingPost = initialState.patchingPost;
          return state;
        case 'score':
          state.score = action.value.target.value;
          return state;
        case 'tags':
          state.tags = action.value;
          return state;
        case 'link':
          if (state.link === undefined) {
            state.link = [];
          }

          if (action.editlink !== undefined) {
            state.elements.map(function(link) {
              if (link.id === action.id) {
                link.title = action.value.title;
                link.link = action.value.link;
              }
            });
          }
          if (action.editlink === undefined) {
            state.link.push(action.value);
            state.elements.push(action.value);
          }
          return state;
        case 'image':
          if (state.image === undefined) {
            state.image = [];
          }
          if (action.editimage !== undefined) {
            state.elements.map(function(image) {
              if (image.id === action.id) {
                image.canvas_content_images = [];
                image.canvas_content_images.push(
                  action.value.canvas_content_images[0]
                );
              }
            });
          }

          if (action.delete !== undefined) {
            var newElements = [];
            state.elements.forEach((image) => {
              if (image.id !== action.id) {
                if (image !== undefined) {
                  newElements.push(image);
                }
              }
            });

            return {
              ...state,
              elements:newElements
            }
          }

          if (action.editimage === undefined) {
            state.image = state.image;
            state.elements.push(action.value);
            state.image.push(action.value);
          }
          return state;
        case 'video':
          if (state.video === undefined) {
            state.video = [];
          }
          if (action.editvideo !== undefined) {
            state.elements.map(function(video) {
              if (video.id === action.id) {
                video.canvas_content_videos = [];
                video.canvas_content_videos.push(
                  action.value.canvas_content_videos[0]
                );
              }
            });
          }

          if (action.delete !== undefined) {
            var elements = [];
            state.elements.forEach((video) => {
              if (video.id !== action.id) {
                if (video !== undefined) {
                  elements.push(video);
                }
              }
            });
            state.elements = [];
            state.elements = elements;
          }

          if (action.editvideo === undefined) {
            state.video.push(action.value);
            state.elements.push(action.value);
          }

          return state;
        case 'carousel':
          if (state.carousel === undefined) {
            state.carousel = [];
          }

          if (action.deletecarousel && !action.idCarousel) {
            var elements = [];
            elements = state.elements.map(function(carousel) {
              if (carousel.id === action.id) {
                elements = carousel.canvas_content_images.map(function(
                  image,
                  i
                ) {
                  if (i !== action.index) {
                    return image;
                  }
                });
                carousel.canvas_content_images = [];
                elements.map(function(element) {
                  if (element !== undefined) {
                    carousel.canvas_content_images.push(element);
                  }
                });
              }
            });
          }

          if(action.idCarousel){
            var new_elements = [];
            state.elements.map( element => {
              if(element.id !== action.idCarousel) new_elements.push(element);
            })
            state.elements = new_elements;
          }

          if (action.addcarousel) {
            state.elements.map(function(carousel) {
              if (carousel.id === action.id) {
                var index = action.value.canvas_content_images.length - 1;
                carousel.canvas_content_images.push(
                  action.value.canvas_content_images[index]
                );
              }
            });
          }

          if (
            action.deletecarousel === undefined &&
            action.addcarousel !== true
          ) {
            state.carousel.push(action.value);
            state.elements.push(action.value);
          }
          return state;
        case 'text':
          if (state.texts === undefined) {
            state.texts = [];
          }

          if (action.delete !== undefined) {
            let elements = [];
            state.elements.map(function(text) {
              if (text.id !== action.id) {
                if (text) {
                  elements.push(text);
                }
              }
            });

            let elementsText = [];
            state.texts.map(function(text) {
              if (text.id !== action.id) {
                if (text) {
                  elementsText.push(text);
                }
              }
            });

            return {
              ...state,
              texts: elementsText,
              elements: elements,
            };

          }
          if (action.edittext) {
            state.elements.push(action.value);
            if (action.insertText) {
              state.insertText = true;
            }
            state.texts.push(action.value);
            if (action.insertText) {
              state.insertText = true;
            }
            state.texts.push(action.value);
  
          }
          return state;

        case 'survey':
          if (action.deleteOption) {
            const { elements } = state;
            elements.forEach((element) => {
              if (element.content_survey !== null) {
                let answers = element.content_survey;
                answers = answers.filter(
                  (answer) => answer.id !== action.idOption
                );
                element.content_survey = answers;
              }
            });
            return {
              ...state,
              elements: elements
            };
          }

          if (action.deleteContent) {
            const { elements } = state;
            return {
              ...state,
              elements: elements.filter(
                (element) => element.id !== action.idContent
              )
            };
          }

          if (action.edittext) {
            state.elements.push(action.value);

            if (action.insertText) {
              state.insertText = true;
            }
          }

          if (action.patchTextValue) {
            state.elements.map(function(question) {
              if (question.id === action.value.id) {
                question.text = action.value.text;
              }
            });
          }
          if (action.createOptionQuestion) {
            const { elements } = state;
            elements.forEach((element) => {
              if (
                element.content_survey !== null &&
                element.id === action.value.id
              ) {
                const answers = element.content_survey;
                const doesNotContain = Boolean(
                  answers.findIndex(
                    (answer) =>
                      answer.id ===
                      action.value.content_survey[
                        action.value.content_survey.length - 1
                      ].id
                  )
                );
                if (doesNotContain === true) {
                  element.content_survey.push(
                    action.value.content_survey[
                      action.value.content_survey.length - 1
                    ]
                  );
                }
              }
            });
            return {
              ...state,
              elements: elements
            };
          }

          if (action.patchOptionValue) {
            state.elements.map(function(question) {
              if (question && question.id === action.value.id) {
                question.content_survey.forEach(function(option, idContent) {
                  if (option.id === action.idOption) {
                    option.title = action.value.content_survey[idContent].title;
                  }
                });
              }
            });
          }

          return state;

        case 'quiz':
          if (action.deleteOption) {
            const { elements } = state;
            elements.forEach((element) => {
              if (element.content_survey !== null) {
                let answers = element.content_survey;
                answers = answers.filter(
                  (answer) => answer.id !== action.idOption
                );
                element.content_survey = answers;
              }
            });
            return {
              ...state,
              elements: elements
            };
          }

          if (action.deleteContent) {
            const { elements } = state;
            return {
              ...state,
              elements: elements.filter(
                (element) => element.id !== action.idContent
              )
            };
          }

          if (action.edittext) {
            state.elements.push(action.value);

            if (action.insertText) {
              state.insertText = true;
            }
          }

          if (action.patchTextValue) {
            state.elements.map(function(question) {
              if (question.id === action.value.id) {
                question.text = action.value.text;
              }
            });
          }

          if (action.createOptionQuestion) {
            const { elements } = state;
            elements.forEach((element) => {
              if (
                element.content_survey !== null &&
                element.id === action.value.id
              ) {
                const answers = element.content_survey;
                const doesNotContain = Boolean(
                  answers.findIndex(
                    (answer) =>
                      answer.id ===
                      action.value.content_survey[
                        action.value.content_survey.length - 1
                      ].id
                  )
                );
                if (doesNotContain === true) {
                  element.content_survey.push(
                    action.value.content_survey[
                      action.value.content_survey.length - 1
                    ]
                  );
                }
              }
            });
            return {
              ...state,
              elements: elements
            };
          }

          if (action.patchOptionValue) {
            state.elements.map(function(question) {
              if (question && question.id === action.value.id) {
                question.content_survey.map(function(option, idContent) {
                  if (option.id === action.idOption) {
                    option.title = action.value.content_survey[idContent].title;
                  }
                });
              }
            });
          }

          return state;

        case 'open_question':
          if (action.deleteContent) {
            const { elements } = state;
            return {
              ...state,
              elements: elements.filter(
                (element) => element.id !== action.idContent
              )
            };
          }

          if (action.edittext) {
            state.elements.push(action.value);

            if (action.insertText) {
              state.insertText = true;
            }
          }

          if (action.patchTextValue) {
            state.elements.map(function(question) {
              if (question.id === action.value.id) {
                question.text = action.value.text;
              }
            });
          }
          return state;
      };
    
    case 'UPDATE_ELEMENTS_PUBLICATION':
      return{
        ...state,
        elements: action.elements,
      }

    case 'UPDATE_ELEMENTS_QUEUE':
      return {
        ...state,
        elements: action.elements.sort(
          (a, b) => a.queue - b.queue
        )
      }
    case 'UPDATE_STATUS_REQUEST':
      return {
        ...state,
        statusRequest: action.message
      };

    case 'SCHEDULE_MODAL_SHOW':
      return {
        ...state,
        showScheduleModal: true
      };

    case 'SCHEDULE_MODAL_HIDE':
      return {
        ...state,
        showScheduleModal: false
      };

    case 'SCHEDULE_PUBLICATION_SUCCESS':
      return {
        ...state,
        schedulePublicationSuccess: true
      };

    case 'SCHEDULE_PUBLICATION_RESET':
      return {
        ...state,
        schedulePublicationSuccess: false
      };
    case 'PATCHING_POST_START':
      return {
        ...state,
        patchingPost: true
      };
    case 'PATCHING_POST_FINISH':
      return {
        ...state,
        patchingPost: false
      };

    case 'ENABLE_NOTIFICATION_SHOW':
      return {
        ...state,
        enable_notification: true
      };

    case 'ENABLE_NOTIFICATION_HIDDEN':
      return {
        ...state,
        enable_notification: false
      };

    case 'SET_TRUE_SEND_PUSH':
      return {
        ...state,
        send_push: true
      };

    case 'SET_NULL_SEND_PUSH':
      return {
        ...state,
        send_push: null
      };

    case 'SET_MIN_COMMENTS_ENABLED':
      const newVisible = !state.min_comments_enabled;
      return {
        ...state,
        min_comments_enabled: !state.min_comments_enabled,
        modal_min_comments_is_visible: newVisible,
      };

    case 'OPEN_MINIMUM_COMMENTS_MODAL':
      return {
          ...state,
          modal_min_comments_is_visible: true,
      };
    case 'CLOSE_MINIMUM_COMMENTS_MODAL':
      return {
          ...state,
          modal_min_comments_is_visible: false,
      };

    case 'UPDATE_MIN_SIZE_FORM':
      return {
          ...state,
          min_size_form: action.payload
      };

    default:
      return state;
  }
}
