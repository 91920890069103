import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import ButtonDefault from '../../../utils/FormsComponents';
import {
  TextFieldForm,
  LongTextFieldTitle,
  SelectForm
} from '../../../utils_form/FormComponents';
import { DialogYesNo } from '../../../utils/DailogComponentes';
import {
  required,
  ValidImage,
  doesContainHttp
} from '../../../validation/FormValidation';
import * as SectionApi from '../../../../Logicas/SectionApi';
import DropZoneImage from '../../ToolsList/Components/DropZoneTools/DropZoneImage';

import * as S from './LinkCreate.styles';
import { addImageProcess } from '../../../utils/addImageProcess';

export class ToolsCreate extends Component {
  constructor() {
    super();
    this.state = {
      sectionNames: [],
      sectionIds: [],
      selectedSectorId: '',
      file: [{ preview: null }],
      showModal: false,
      formValues: {}
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { sectionNameList } = this.props;

    if (!sectionNameList.length) {
      browserHistory.push('/tools/list');
      return null;
    }
  }

  setSectorId = (filedValue) => {
    delete filedValue.preventDefault;
    const name = Object.values(filedValue).join('');

    const selectedSector = this.props.sectionList.find(
      (section) => name === section.name
    );

    this.setState({ selectedSectorId: selectedSector.id });
  };

  validateLinkUrl = (formValues) => {
    if (!doesContainHttp(formValues.url)) {
      this.setState({ showModal: true, formValues });
    } else {
      this.saveTool(formValues);
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  async updateFile(file) {
    if (!ValidImage(file[0].type)) {
      this.props.showErroAlert(
        'Arquivo não suportado. Envie apenas imagens PNG ou JPG/JPEG'
      );
      return;
    }
    const i = await addImageProcess(URL.createObjectURL(file[0])); 
    if(i.height > 2000 || i.width > 2000) 
      this.props.dispatchImageError();
    else
      this.setState({ file });
  }

  saveTool = (formValues) => {
    const { saveToolCreate } = this.props;
    const { selectedSectorId } = this.state;

    if (this.state.file[0].preview !== null) {
      formValues.image = this.state.file;
    }

    saveToolCreate(formValues, selectedSectorId);
  };

  render() {
    const { handleSubmit, sectionNameList } = this.props;
    const { showModal, formValues } = this.state;

    return (
      <>
        <MuiThemeProvider>
          <S.ToolsComponentHeader>
            <S.ToolsComponentHeaderTitle>
              <S.ToolsComponentHeaderTitleValue>
                Novo link
              </S.ToolsComponentHeaderTitleValue>
            </S.ToolsComponentHeaderTitle>
            <S.ToolsComponentHeaderButtons>
              <ButtonDefault
                label="Salvar"
                onClick={() =>
                  this.formRef.current.dispatchEvent(new Event('submit'))
                }
              />
            </S.ToolsComponentHeaderButtons>
          </S.ToolsComponentHeader>
          <S.FormContainer>
            <form
              onSubmit={handleSubmit(this.validateLinkUrl)}
              ref={this.formRef}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6">
                        <LongTextFieldTitle
                          fullWidth={true}
                          name="name"
                          fullWidth={true}
                          validations={[required]}
                          id="name"
                          label="Nome do link*"
                          floatingLabelText="Digite ou cole o nome do recurso*"
                          type="text"
                        />
                      </div>
                      <div className="col-md-6">
                        <SelectForm
                          name="section"
                          multiple={false}
                          label="Selecione uma seção"
                          validations={[required]}
                          elements={sectionNameList}
                          onChange={(e) => this.setSectorId(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <TextFieldForm
                        fullWidth={true}
                        name="url"
                        rows={1}
                        validations={[required]}
                        id="name"
                        floatingLabelText="Digite ou cole o link do recurso, com http:// ou https://*"
                        type="text"
                      />
                    </div>
                  </div>
                  <S.DropZone className="col md-4">
                    <S.DropZoneActions>
                      <S.DropZoneField>
                        <DropZoneImage
                          onDrop={(file) => this.updateFile(file)}
                          file={this.state.file}
                          name="image"
                        />
                      </S.DropZoneField>
                      <S.DropZoneText>
                        Clique ou arraste imagens para aqui
                      </S.DropZoneText>
                    </S.DropZoneActions>
                  </S.DropZone>
                </div>
              </div>
            </form>
          </S.FormContainer>
          <DialogYesNo
            noclick={() => this.closeModal()}
            yesclick={() => this.saveTool(formValues)}
            message="Será adicionado https:// ao início do link, tudo bem?"
            visible={showModal}
          />
        </MuiThemeProvider>
      </>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'createtool'
})(ToolsCreate);

const mapStateToProps = (state) => {
  return {
    initialValues: {
      kind: 'url'
    },
    sectionList: state.section.sectionList,
    sectionNameList: state.section.sectionNameList,
    alert: state.dialog
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getToolsList: async () => {
      await dispatch(SectionApi.getSectionList());
    },
    saveToolCreate: async (formValues, selectedSectorId) => {
      await dispatch(SectionApi.saveToolCreate(formValues, selectedSectorId));
    },
    showErroAlert: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: message }
      }),
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
  };
};
InitializeFromStateForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
