import React, { Component } from 'react';
import HeaderCourse from '../HeaderInteraction/HeaderCourse';
import CourseList from './CourseList';

class WrapCourseDash extends Component {
  render() {
    return (
      <div>
        <HeaderCourse
          module_length={this.props.module_length}
          submodule_lenght={this.props.submodule_lenght}
          course_length={this.props.course_length}
        />
        <div className="display-row-home-dash">
          <CourseList
            label="Usuários que concluíram os cursos recentemente"
            description=""
            courses_finished={this.props.courses_finished}
          />
        </div>
      </div>
    );
  }
}
export default WrapCourseDash;
