import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PostApi from '../../../../Logicas/PostApi';
import DialogEditMidia from '../dialog/DialogEditMidia';
import CircularProgress from 'material-ui/CircularProgress';

require('./tooltip.css');

class ToolTipCarousel extends Component {
  constructor() {
    super();
    this.state = {};
    this.EditImage = this.EditImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.removeCarousel = this.removeCarousel.bind(this);
  }
  EditImage() {
    this.setState({ enable_edit: true });
  }

  removeImage() {
    this.props.RemoveImage(
      this.props.id_canvas,
      this.props.id.split('_')[1],
      this.props.currentSlide
    );
  }

  removeCarousel() {
    const elements = this.props.elements;
    const newElements = elements.filter(
      (element) => element.id !== Number(this.props.id.split('_')[1])
    );

    this.props.setComponentsToState(newElements);

    this.props.RemoveCarousel(
      this.props.id_canvas,
      this.props.id.split('_')[1]
    );
  }

  render() {
    return (
      <div>
        <div
          className={
            this.props.showbtn
              ? 'btn-edit-image-show-carousel'
              : 'btn-edit-image'
          }
        >
          <div className="container-tooltip-carousel">
            <label
              onClick={this.props.showDialogUploadFile.bind(
                this,
                'carousel',
                this.props.id.split('_')[1]
              )}
              className="label-btn-imag"
            >
              Adicionar imagem
            </label>
            {this.props.element.canvas_content_images.length === 1 ? (
              <label
                onClick={() => this.removeCarousel()}
                className="label-btn-imag"
              >
                Remover carousel
              </label>
            ) : (
              <label
                onClick={() => this.removeImage()}
                className="label-btn-imag"
              >
                Remover imagem
              </label>
            )}
            {this.props.load_status_delete === true ? (
              <CircularProgress
                style={{ marginLeft: '-20px' }}
                color="#444444"
                size={30}
                thickness={7}
              />
            ) : this.props.element.canvas_content_images.length > 1 ? (
              <label
                onClick={() => this.removeCarousel()}
                className="label-btn-imag"
              >
                Remover carousel
              </label>
            ) : null}
          </div>
        </div>
        <DialogEditMidia
          idcanvas={this.props.id_canvas}
          id={this.props.id.split('_')[1]}
          visible={true}
        />
      </div>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'tooltipimage'
})(ToolTipCarousel);

const mapDispatchToProps = (dispatch) => {
  return {
    EditImagemForm: (id, idcontent) => {
      dispatch(PostApi.PatchContentImage(id, idcontent));
    },
    showDialogUploadFile: (kind, id) => {
      dispatch({
        type: 'SHOW_DIALOG_FILE_MIDIA',
        alert: { visible: true, kind: kind, id: id }
      });
    },
    RemoveImage: (idcanvas, idimage, index) => {
      dispatch(PostApi.DeleteContentImage(idcanvas, idimage, index, ''));
    },
    RemoveCarousel: (idCanvas, idContent) => {
      dispatch(PostApi.DeleteCotentCarousel(idCanvas, idContent));
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    creatpost: state.createpost,
    initialValues: state.createpost,
    dialoglink: state.dialogeditmidia,
    load_status_delete: state.load_status_delete
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
