const initialState = {
  tagList: [],
  editTag: {},
  tagUserAssociation: [],
  mergeTagsValue: '',
  tagMergeModalOpen: {
    isOpen: false,
    message: ''
  },
  loadingTags: false
};

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GETTAG':
      return action.tags;
    case 'GET_LIST_TAG':
      return { ...state, tagList: action.tags };
    case 'GET_EDIT_TAG':
      return { ...state, editTag: action.tag };
    case 'TAG_USERS_ASSOCIATION':
      return { ...state, tagUserAssociation: action.tagUserAssociation };
    case 'TAG_USERS_ASSOCIATION_RESET':
      return { ...state, tagUserAssociation: [] };
    case 'MERGE_TAGS_VALUE_SET':
      return {
        ...state,
        mergeTagsValue: action.mergeTagsValue
      };
    case 'MERGE_TAGS_VALUE_RESET':
      return {
        ...state,
        mergeTagsValue: ''
      };
    case 'LOADING_TAGS_START':
      return {
        ...state,
        loadingTags: true
      };
    case 'LOADING_TAGS_FINISH':
      return {
        ...state,
        loadingTags: false
      };
    case 'TAG_MERGE_MODAL_OPEN':
      const {
        tagMergeModalOpen: { message }
      } = action;
      return {
        ...state,
        tagMergeModalOpen: {
          isOpen: true,
          message
        }
      };
    case 'TAG_MERGE_MODAL_CLOSE':
      return {
        ...state,
        tagMergeModalOpen: {
          isOpen: false,
          message: ''
        }
      };
    default:
      return state;
  }
};

export default tagReducer;
