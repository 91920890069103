import React, { Component } from 'react';
import { connect } from 'react-redux';
import TagApi from '../../../../Logicas/TagApi';
import SearchIcon from './SearchIcon';
import IcoEdit from '../../../utils/img/ic-editar.svg';

import * as S from './TagsSearch.style';

export class TagsSearch extends Component {
  constructor() {
    super();
    this.state = {
      filteredTags: [],
      isFocus: false
    };
  }

  filterTags = (tagName) => {
    const tagsList = this.props.list;
    let filteredTags = [];

    filteredTags = tagsList.filter((tag) => {
      return tag.name.toLowerCase().search(tagName) !== -1;
    });

    this.setState({ filteredTags });
  };

  setFocusTrue = () => {
    this.setState({ filteredTags: this.props.list });
    this.setState({ isFocus: true });
  };

  setFocusFalse = () => {
    this.setState({ isFocus: false });
  };

  render() {
    return (
      <>
        <S.InputWrapper isFocus={this.state.isFocus}>
          <S.Input
            onChange={(e) => this.filterTags(e.target.value)}
            onFocus={this.setFocusTrue}
            isFocus={this.state.isFocus}
            placeholder="Busque pelo nome da tag"
          />
          <S.Button isFocus={this.state.isFocus}>
            <SearchIcon />
          </S.Button>
          <S.TagsWrapper isFocus={this.state.isFocus}>
            <S.TagsList>
              {this.props.loadingTags && (
                <S.NoResults>
                  <S.NoResultsText>Carregando tags...</S.NoResultsText>
                </S.NoResults>
              )}
              {this.state.filteredTags.length > 0 ? (
                this.state.filteredTags.map((tag) => (
                  <S.TagsListItem>
                    <S.TagName>{tag.name}</S.TagName>
                    <S.TagEdit
                      src={IcoEdit}
                      alt="Editar tag"
                      onClick={() => {
                        this.props.getTagEdit(tag.id);
                      }}
                    />
                  </S.TagsListItem>
                ))
              ) : (
                <S.NoResults>
                  <S.NoResultsText>Sem resultados</S.NoResultsText>
                </S.NoResults>
              )}
            </S.TagsList>
          </S.TagsWrapper>
        </S.InputWrapper>
        <S.TagsOverlay
          isFocus={this.state.isFocus}
          onClick={this.setFocusFalse}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.tag.tagList,
    alert: state.dialog,
    erroalert: state.erroalert,
    loadingTags: state.tag.loadingTags
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTagEdit: (id) => {
      dispatch(TagApi.getTagEdit(id));
    }
  };
};
const TagsSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsSearch);

export default TagsSearchContainer;
