const defaultState = {
  idUser: 0,
  showModal: false,
  confirmModal: false
};

export function token_resend(state = defaultState, action) {
  switch (action.type) {
    case 'SHOW_TOKEN_RESEND':
      return {
        ...state,
        idUser: action.idUser,
        showModal: action.showModal
      };
    case 'SHOW_CONFIRM_RESEND':
      return {
        ...state,
        confirmModal: action.confirmModal
      };
    case 'HIDE_CONFIRM_RESEND':
      return {
        ...state,
        idUser: action.idUser,
        showModal: action.showModal,
        confirmModal: action.confirmModal
      };
    default:
      return state;
  }
}
