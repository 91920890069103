export function dialog_notification_users(state = false, action) {
  if (action.type === 'OPEN_DIALOG_NOTIFICATION_USERS') {
    return action.alert;
  }

  if (action.type === 'CLOSE_DIALOG_NOTIFICATION_USERS') {
    return action.alert;
  }

  return state;
}
