import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { SelectField, MenuItem } from 'material-ui';

export class SelectInput extends Component {
  constructor() {
    super();
    this.state = {
      selectedValue: ''
    };
  }
  render() {
    const { options } = this.props;
    return (
      <SelectField
        floatingLabelText={this.props.floatingLabelText}
        onChange={this.props.onChange}
        value={this.state.selectedValue}
      >
        {options.map((option) => (
          <MenuItem
            key={uuidv1()}
            value={option}
            primaryText={option}
            onClick={() => this.setState({ selectedValue: option })}
          />
        ))}
      </SelectField>
    );
  }
}

export default SelectInput;
