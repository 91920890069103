import moment from 'moment';
import API, { API_V2 } from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import { browserHistory } from 'react-router';
import { style } from '../style';
import errorHandler from '../componentes/utils/errorHandler';
import Axios from 'axios';
import config from '../componentes/restservice/base.config';

export default class StoreApi {
  static getProducts(page) {
    return (dispatch, getState) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      dispatch({ type: 'LOADING_PRODUCTS_START' });
      API.get(`products?page=1&origin=web`, Config.headers())
        .then((response) => {
          if (response.data && response.data.length > 0) {
            API.get(
              `products?page=${page}&per_page=10&origin=web`,
              Config.headers()
            )
              .then((response) => {
                response = this.parseProductList(
                  response.data,
                  style.CurrencyName.name
                );
                dispatch({
                  type: 'GET_STORE',
                  products: response.product,
                  page: page
                });
                dispatch({ type: 'LOADING_PRODUCTS_END' });
                dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
              })
              .catch((err) => {
                dispatch({
                  type: 'OPEN_ERROR',
                  alert: {
                    visible: true,
                    message: errorHandler(err)
                  }
                });
                dispatch({
                  type: 'REQUEST_ERROR_GET',
                  payload: true
                });
                dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
                dispatch({ type: 'LOADING_PRODUCTS_END' });
              });
          } else {
            dispatch({
              type: 'GET_STORE',
              products: [],
              page: 1
            });
            dispatch({ type: 'LOADING_PRODUCTS_END' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }
        })

        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
          dispatch({
            type: 'REQUEST_ERROR_GET',
            payload: true
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({ type: 'LOADING_PRODUCTS_END' });
        });
    };
  }

  static getInitialProductList() {
    return async (dispatch) => {
      dispatch({ type: 'LOADING_PRODUCTS_START' });

      await dispatch(this.getDispatchedProductList(1));
      await dispatch(this.getNotDispatchedProductList(1));

      dispatch({ type: 'LOADING_PRODUCTS_END' });
    };
  }

  static getDispatchedProductList(
    page = 1,
    name_product = '',
    startDate,
    endDate
  ) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const api = Axios.create({
          baseURL: config.apiurl
        });

        api
          .get(
            `products/cart_products?category=despatched&source=admin&name_product=${name_product}&page=${page}` +
              (startDate && endDate
                ? `&start_date=${startDate}&end_date=${endDate}`
                : ''),
            Config.headers()
          )
          .then(({ data }) => {
            dispatch({
              type: 'GET_PRODUCTS_DISPATCHED',
              payload: data
            });
          });

        dispatch({
          type: 'GET_PAGE',
          page: page
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  }

  static getNotDispatchedProductList(
    page = 1,
    name_product = '',
    startDate,
    endDate
  ) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const api = Axios.create({
          baseURL: config.apiurl
        });

        api
          .get(
            `products/cart_products?category=not_despatched&source=admin&name_product=${name_product}&page=${page}` +
              (startDate && endDate
                ? `&start_date=${startDate}&end_date=${endDate}`
                : ''),
            Config.headers()
          )
          .then(({ data }) => {
            dispatch({
              type: 'GET_PRODUCTS_NOT_DISPATCHED',
              payload: data
            });
          });

        dispatch({
          type: 'GET_PAGE',
          page: page
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  }

  static getProductsHistoryPage(name) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('products/cart_products?name_product=' + name, Config.headers())
        .then(function(response) {
          dispatch({ type: 'GET_PRODUCTS_DISPACHT', products: response.data });
          browserHistory.push('/product/history');
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static dipatchProduct(cart_id, iduser, idproduct, historyCurrentPage, filtersInformation) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('idproduct', idproduct);
      formData.append('iduser', iduser);
      formData.append('sellnumber', cart_id);
      API.post('products/dispatch_product', formData, Config.headers())
        .then(
          function(response) {
            dispatch(this.getNotDispatchedProductList(
              filtersInformation.page,
              filtersInformation.name_product,
              filtersInformation.startDate,
              filtersInformation.endDate,
            ));
            dispatch({
              type: 'DISPATCH_DIALOG_CLOSE'
            });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static dispatchProducts(purchase_products, filtersInformation){
    return (dispatch) => {
      API_V2.post('products/dispatch_product', {purchase_products: purchase_products}, Config.headersJson())
        .then(
          function(response) {
            dispatch(this.getNotDispatchedProductList(
              filtersInformation.page,
              filtersInformation.name_product,
              filtersInformation.startDate,
              filtersInformation.endDate,
            ));
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static editProduct(id) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('products/' + id, Config.headers())
        .then(
          function(response) {
            var product = this.parseProductResponse(response.data);
            browserHistory.push('/store/edit');
            dispatch(this.getStores(1));
            dispatch({ type: 'EDIT_PRODUCT', product: product });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static deleteProduct(id) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete('products/' + id, Config.headers())
        .then(
          function(response) {
            dispatch(this.getProducts(1));
            dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static parseObject(arrays) {
    var arraysname = [];
    if (arrays.length > 0) {
      arrays.map(function(arraysindex, index) {
        return arraysname.push({
          name_sector: arraysindex.name,
          id_sector: arraysindex.id
        });
      });
    }
    return arraysname;
  }

  static parseProductResponse(product) {
    var product_parser = {};
    product_parser.id = product.id;
    product_parser.store_product = product.store_id;
    product_parser.name_product = product.name;
    product_parser.amount_product = product.amount;
    product_parser.description_product = product.description;
    product_parser.image_product = product.image.url;
    product_parser.kind_product = product.kind;
    product_parser.tags_product = [];
    product.tags.map(function(tag, i) {
      return product_parser.tags_product.push({
        value: tag.name,
        label: `#${tag.name}`
      });
    });
    product_parser.score_product = product.valuescore;
    product_parser.purchase_limit = product.purchase_limit;
    product_parser.default_message_to_send = product.default_message_to_send;

    return product_parser;
  }

  static getStores() {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('stores/?origin=web', Config.headers())
        .then(function(response) {
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'GET_MARKET', stores: response.data });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(function(err) {
          if (err.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          } else {
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: { visible: true, message: err.message }
          });
        });
    };
  }

  static parseStores(store) {
    var store = {};
    var arrays = [];
    store.map(function(store) {
      store.id_store = store.id;
      store.name_store = store.name;
      arrays.push(store);
    });
    return arrays;
  }

  static parseProductList(products, teamName) {
    let productArray = { product: [] };
    if (products.length > 0) {
      products.map(function(element, index) {
        let productList = {};
        productList.id = element.id;
        productList.store_id = element.store_id;
        productList.name = element.name;
        productList.image = element.image;
        productList.description = element.description;
        productList.valuescore = element.valuescore;
        productList.teamName = teamName;
        productList.amount = element.amount;
        productList.kind = element.kind;
        productList.video = element.video;
        productList.tags = element.tags;
        productList.size = element.size;

        productArray.product.push(productList);
      });
    }
    return productArray;
  }

  static getSolicitationsReport(startDate, endDate, getDispatchedProducts) {
    return async (dispatch) => {
      try {
        const isDispatched = getDispatchedProducts
          ? 'despatched'
          : 'not_despatched';

        const parsedStartDate = moment(startDate).format('YYYY-MM-DD');
        const parsedEndDate = moment(endDate).format('YYYY-MM-DD');

        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data } = await API.get(
          `historical/purchase_histories.xlsx?category=${isDispatched}&dateini=${parsedStartDate}&dateend=${parsedEndDate}`,
          {
            responseType: 'blob',
            headers: Config.headers().headers
          }
        );

        const src = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = src;
        link.setAttribute(
          'download',
          `historico-solicitacoes-${
            getDispatchedProducts ? 'aprovadas' : 'nao-aprovadas'
          }.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  }

  static sendSolicitationsReport(kind, email, startDate, endDate) {
    return (dispatch) => {
      dispatch({ type: 'REPORT_REQUEST_START' });

      const parsedStartDate = moment(startDate).format('YYYY-MM-DD');
      const parsedEndDate = moment(endDate).format('YYYY-MM-DD');

      API.get(
        `historical/purchase_histories.xlsx?category=${kind}&email=${email}&dateini=${parsedStartDate}&dateend=${parsedEndDate}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'REPORT_REQUEST_SUCCESS',
            payload: { message: response.data.mesage }
          });
        })
        .catch((err) => {
          dispatch({ type: 'CLOSE_REPORT_EMAIL_MODAL' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }
}
