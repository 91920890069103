import React from 'react';
import {
  DatePicker,
  TextField,
  SelectField,
  Toggle as ToggleRedux
} from 'redux-form-material-ui';
import { TimePicker, MenuItem, Toggle } from 'material-ui';
import moment from 'moment';
import Select from 'react-select';
import ImageDrop from '../utils/img/ic-dragdrop-image.svg';
import SendCover from '../../componentes/canvas/img/ic-send-cover.svg';
import 'moment/locale/pt-br';
import { Field } from 'redux-form';
import areIntlLocalesSupported from 'intl-locales-supported';
import Dropzone from 'react-dropzone';
import { Player } from 'video-react';
import IcoAddFile from '../utils/img/add_file.svg';
import IcoDoc from '../utils/img/doc.png';
import IcoDocDrop from '../utils/img/ic-doc_newdoc.png';
import IcoJpeg from '../utils/img/jpeg.png';
import IcoPdf from '../utils/img/pdf.png';
import IcoPpt from '../utils/img/ppt.png';
import IcoPrezi from '../utils/img/prezi.png';
import IcoXls from '../utils/img/xls.png';
import { ReactComponent as AddIcon } from '../utils/img/ic-add.svg';
import CoverIcon from '../utils/img/img-cover.svg';
import { ReactComponent as ArrowIco } from '../utils/img/arrow.svg';
import { ReactComponent as CloseIco } from '../utils/img/ic-close.svg';
import { style } from '../../style';
import CircularProgress from 'material-ui/CircularProgress';
import { useState } from 'react';
import { addImageProcess } from '../utils/addImageProcess';
import { canAddFiles } from '../utils/canAddFiles';
import { ContainerDrop, DropHover, DropPreview } from './FomComponents';

require('./css_componentes.css');

moment.locale('pt-BR');

let DateTimeFormat;

if (areIntlLocalesSupported(['fr', 'fa-IR'])) {
  DateTimeFormat = global.Intl.DateTimeFormat;
} else {
  const IntlPolyfill = require('intl');
  DateTimeFormat = IntlPolyfill.DateTimeFormat;
  require('intl/locale-data/jsonp/fr');
  require('intl/locale-data/jsonp/fa-IR');
}

export class DatePickerForm extends DatePicker {
  render() {
    return (
      <Field
        disabled={this.props.disabled}
        name={this.props.name}
        hintText={this.props.label}
        floatingLabelText={this.props.label}
        component={DatePicker}
        id={this.props.id ? this.props.id : ""}
        underlineStyle={{ display: 'none' }}
        validate={this.props.validations}
        container="inline"
        onChange={this.props.onChange}
        locale="pt"
        cancelLabel="Sair"
        defaultDate={this.props.defaultDate}
        okLabel="Selecionar"
        DateTimeFormat={DateTimeFormat}
        selected={this.props.selected}
        minDate={this.props.minDate ? this.props.minDate : new Date()}
        className={this.props.className}
        textFieldStyle={{
          'font-family': this.props.isCanvasFormInitial ? 'Roboto, sans-serif':'Rubik',
          'font-family': 'arial',
          'font-size':  this.props.isCanvasFormInitial || this.props.isHeaderCanvasEdit || this.props.isSurveyCreate 
            || this.props.isDrawModal ? '16px' : '18px',
          'font-weight': '300',
          'font-style': 'normal',
          'font-stretch': 'normal',
          'line-height': 'normal',
          'letter-spacing': '-0.1px',
          'text-align': 'center',
          color: '#333333',
          width: this.props.isCanvasFormInitial || this.props.isHeaderCanvasEdit? '260px' 
            : this.props.isSurveyCreate? '185px' 
              : this.props.isDrawModal ? '240px' : '150px',
          cursor: 'pointer'
        }}
        formatDate={
          new Intl.DateTimeFormat('pt-BR', {
            day: 'numeric',
            month: 'long'
          }).format
        }
      />
    );
  }
}

export class TimePickerForm extends TimePicker {
  render() {
    return (
      <TimePicker
        hintText={this.props.label}
        component={TimePicker}
        underlineStyle={{ display: 'none' }}
        container="inline"
        validate={this.props.validations}
        format="24hr"
        locale="pt"
        cancelLabel="Sair"
        okLabel="Selecionar"
        underlineFocusStyle={{
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        textFieldStyle={{
          'font-family': 'Rubik',
          'font-size': '18px',
          'font-weight': '500',
          'font-style': 'normal',
          'font-stretch': 'normal',
          'line-height': 'normal',
          'letter-spacing': '-0.1px',
          'text-align': 'center',
          color: '#333333'
        }}
      />
    );
  }
}

export class LongTextFieldTitle extends TextField {
  render() {
    return (
      <Field
        name={this.props.name}
        textareaStyle={{
          fontWeight: 'bold',
          'font-family': 'Rubik',
          color: '#3b3b3b',
          fontSize: '25px'
        }}
        rows={this.props.rows}
        rowsMax={5}
        hintText={this.props.label}
        fullWidth={
          this.props.fullWidth === undefined ? false : this.props.fullWidth
        }
        floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
        multiLine={true}
        onChange={
          this.props.onChange !== undefined ? this.props.onChange : null
        }
        floatingLabelText={this.props.floatingLabelText}
        hintStyle={{ color: '#b6b6b6', fontSize: '25px', fontWeight: 'bold' }}
        underlineFocusStyle={{
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        validate={this.props.validations}
        id={this.props.label}
        component={TextField}
        type="text"
      />
    );
  }
}

export class TextArea extends TextField {
  render() {
    return (
      <Field
        name={this.props.name}
        textareaStyle={
          this.props.textareaStyle === undefined
            ? {
                fontWeight: 'bold',
                'font-family': 'Rubik',
                'font-size': '18px',
                'font-weight': '500',
                'font-style': 'normal',
                'font-stretch': 'normal',
                'line-height': 'normal',
                'letter-spacing': '-0.1px',
                'text-align': 'left',
                color: '#333333'
              }
            : this.props.textareaStyle
        }
        rows={this.props.rows}
        rowsMax={5}
        validate={this.props.validations}
        floatingLabelText={this.props.label}
        floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
        fullWidth={true}
        floatingLabelFixed={true}
        multiLine={true}
        hintText={this.props.labelsecondari}
        floatingLabel
        hintStyle={{
          color: '#b6b6b6',
          fontSize: '16px',
          fontWeight: 'bold'
        }}
        underlineFocusStyle={{
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        component={TextField}
        type="text"
      />
    );
  }
}

export class TextFieldForm extends TextField {
  render() {
    return (
      <Field
        className={this.props.className}
        name={this.props.name}
        autocomplete={this.props.autocomplete}
        inputStyle={
          this.props.textareaStyle === undefined
            ? {
                backgroundColor: 'none !important',
                'font-family': 'Rubik',
                'font-size': '16px',
                'font-weight': '500',
                'font-style': 'normal',
                'font-stretch': 'normal',
                'line-height': 'normal',
                'letter-spacing': '-0.1px',
                'text-align': 'left',
                'min-width': '200px',
                color: '#333333'
              }
            : this.props.textareaStyle
        }
        onChange={this.props.onChange}
        type={this.props.type}
        floatingLabelFixed={this.props.floatingLabelFixed}
        floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
        hintText={this.props.hint}
        validate={this.props.validations}
        disabled={this.props.disabled}
        fullWidth={
          this.props.fullWidth === undefined ? false : this.props.fullWidth
        }
        floatingLabelText={this.props.floatingLabelText}
        hintStyle={{ color: '#b6b6b6', fontSize: '16px' }}
        underlineFocusStyle={{
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        style={this.props.style}
        id={this.props.label}
        component={TextField}
      />
    );
  }
}

export class SelectForm extends SelectField {
  render() {
    let selected = [];

    if (this.props.elements_selected !== undefined) {
      selected = this.props.elements_selected;
    }

    return (
      <Field
        name={this.props.name}
        inputStyle={{
          'font-family': 'Rubik',
          'font-size': '16px',
          'font-weight': '500',
          'font-style': 'normal',
          'font-stretch': 'normal',
          'line-height': 'normal',
          'letter-spacing': '-0.1px',
          'text-align': 'left',
          color: '#333333',
          width: '100%'
        }}
        hintText={this.props.label}
        fullWidth={true}
        onChange={this.props.onChange}
        multiple={this.props.multiple}
        floatingLabelText={this.props.label}
        hintStyle={{ color: '#b6b6b6', fontSize: '22px' }}
        underlineFocusStyle={{
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        selectedMenuItemStyle={{
          color: style.inpuStyle.colorInput,
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold',
          zIndex: '5000000'
        }}
        validate={this.props.validations}
        iconStyle={{ display: 'none' }}
        id={this.props.label}
        component={SelectField}
      >
        {Array.isArray(this.props.elements)
          ? this.props.elements.map(
              function(element) {
                return (
                  <MenuItem
                    insetChildren={true}
                    label={element}
                    value={element}
                    checked={
                      this.props.elements && selected.indexOf(element) > -1
                    }
                    primaryText={element}
                  />
                );
              }.bind(this)
            )
          : null}

        {Array.isArray(this.props.elementsName)
          ? this.props.elementsName.map(
              function(element) {
                return (
                  <MenuItem
                    insetChildren={true}
                    label={element.name}
                    value={element}
                    checked={selected.find(
                      (selected_element) => selected_element.id === element.id
                    )}
                    primaryText={element.name}
                  />
                );
              }.bind(this)
            )
          : null}
      </Field>
    );
  }
}

export class TimerForm extends TextField {
  render() {
    return (
      <Field
        name={this.props.name}
        style={{
          'font-family': 'Rubik',
          'font-size': '16px',
          'font-weight': '500',
          'font-style': 'normal',
          'font-stretch': 'normal',
          'line-height': 'normal',
          'letter-spacing': '-0.1px',
          'text-align': 'left',
          color: '#333333',
          width: '100px'
        }}
        type={this.props.type}
        floatingLabelFixed={this.props.floatingLabelFixed}
        floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
        hintText={this.props.hint}
        placeholder=""
        fullWidth={false}
        underlineStyle={{ display: 'none' }}
        floatingLabelText={this.props.floatingLabelText}
        hintStyle={{ color: '#b6b6b6', fontSize: '16px' }}
        underlineFocusStyle={{
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        validate={this.props.validations}
        id={this.props.label}
        step={this.props.step}
        component={TextField}
      />
    );
  }
}

export class SelectFormId extends SelectField {
  render() {
    let selected = [];

    if (this.props.elements_selected !== undefined) {
      selected = this.props.elements_selected;
    }
    return (
      <Field
        name={this.props.name}
        inputStyle={{
          'font-family': 'Rubik',
          'font-size': '16px',
          'font-weight': '500',
          'font-style': 'normal',
          'font-stretch': 'normal',
          'line-height': 'normal',
          'letter-spacing': '-0.1px',
          'text-align': 'left',
          color: '#333333'
        }}
        hintText={this.props.label}
        fullWidth={this.props.fullWidth}
        onChange={this.props.onChange}
        multiple={this.props.multiple}
        floatingLabelText={this.props.label}
        hintStyle={{ color: '#b6b6b6', fontSize: '22px' }}
        underlineFocusStyle={{
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        selectedMenuItemStyle={{
          color: style.inpuStyle.colorInput,
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        validate={this.props.validations}
        iconStyle={{ display: 'none' }}
        id={this.props.label}
        component={SelectField}
      >
        {Array.isArray(this.props.elements)
          ? this.props.elements.map(
              function(element) {
                return (
                  <MenuItem
                    insetChildren={true}
                    label={element.name_sector}
                    value={element.id_sector}
                    checked={
                      this.props.elements &&
                      selected.indexOf(element.id_sector) > -1
                    }
                    primaryText={element.name_sector}
                    onClick={this.props.dispatch !== undefined ? ()=>this.props.dispatch(element):()=>{}}
                  />
                );
              }.bind(this)
            )
          : null}
      </Field>
    );
  }
}

export class SelectFormIdStore extends SelectField {
  render() {
    let selected = [];

    if (this.props.elements_selected !== undefined) {
      selected = this.props.elements_selected;
    }
    return (
      <Field
        name={this.props.name}
        inputStyle={{
          'font-family': 'Rubik',
          'font-size': '16px',
          'font-weight': '500',
          'font-style': 'normal',
          'font-stretch': 'normal',
          'line-height': 'normal',
          'letter-spacing': '-0.1px',
          'text-align': 'left',
          color: '#333333'
        }}
        hintText={this.props.label}
        fullWidth={true}
        onChange={this.props.onChange}
        multiple={this.props.multiple}
        floatingLabelText={this.props.label}
        hintStyle={{ color: '#b6b6b6', fontSize: '22px' }}
        underlineFocusStyle={{
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        selectedMenuItemStyle={{
          color: style.inpuStyle.colorInput,
          borderColor: style.inpuStyle.colorInput,
          fontWeight: 'bold'
        }}
        validate={this.props.validations}
        iconStyle={{ display: 'none' }}
        id={this.props.label}
        component={SelectField}
      >
        {Array.isArray(this.props.elements)
          ? this.props.elements.map(
              function(element) {
                return (
                  <MenuItem
                    insetChildren={true}
                    label={element.name}
                    value={element.id}
                    checked={
                      this.props.elements && selected.indexOf(element.name) > -1
                    }
                    primaryText={element.name}
                  />
                );
              }.bind(this)
            )
          : null}
      </Field>
    );
  }
}

export class ToggleForm extends Toggle {
  render() {
    const styles = {
      block: {
        maxWidth: 250
      },
      toggle: {
        marginBottom: 16
      },
      thumbOff: {
        backgroundColor: '#fff',
        top: '4.7px',
        left: 3,
        height: 13,
        width: 13
      },
      trackOff: {
        backgroundColor: '#7D86A1',
        height: 15
      },
      thumbSwitched: {
        backgroundColor: '#fff',
        top: '4.7px',
        left: '97%',
        height: 13,
        width: 13
      },
      trackSwitched: {
        backgroundColor: style.inpuStyle.colorInput,
        height: 15
      },
      labelStyle: {
        'font-family': 'Rubik',
        'font-size': '16px',
        'font-weight': '500',
        'font-style': 'normal',
        'font-stretch': 'normal',
        'line-height': 'normal',
        'letter-spacing': '-0.1px',
        'text-align': 'left',
        'min-height': '43px',
        'min-width':  this.props.name === "min_comments_enabled" ? "100px" : "",
        'padding-top': this.props.label === "Curtidas" || this.props.label === "Comentários" ? "10px" : "0",
        opacity: '0.53'
      }
    };

    var toggle = document.getElementById(`id-toggle-${this.props.name}`);
    if(toggle){
      toggle.parentElement.lastChild.style.flexDirection = "column";
      toggle.parentElement.lastChild.lastChild.style.marginLeft = "0px";
    }
      
    
    return (
      <Field
        id={`id-toggle-${this.props.name}`}
        thumbStyle={styles.thumbOff}
        trackStyle={styles.trackOff}
        label={this.props.label}
        name={this.props.name}
        thumbSwitchedStyle={styles.thumbSwitched}
        trackSwitchedStyle={styles.trackSwitched}
        labelStyle={styles.labelStyle}
        onChange={this.props.onChange}
        component={ToggleRedux}
        checked={this.props.checked}
      />
    );
  }
}

export const DropZoneImage = (field) => {
  const files = field.input.value;
  return (
    <div>
      <Dropzone
        name={field.name}
        style={{ border: 'none' }}
        disableClick={field.disableClick}
        onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
      >
        <img
          style={{
            width: '150px',
            height: '150px',
            marginTop: '10px'
          }}
          alt="..."
          src={files[0] !== undefined ? files[0].preview : ImageDrop}
          className="dropZoneImage"
        />
        <br />
      </Dropzone>
    </div>
  );
};

export const DropZoneImageCover = (field) => {
  const files = field.input.value;

  function isImg(file){
    return file.type === "image/jpeg" || file.type === "image/png" || 
          file.type === ".jpg" || file.type === ".png" || file.type === ".jpeg" || file.type === "image/gif"; 
  }

  function isGif(file){
    return file.type === "image/gif";
  }


  async function setImage(filesToUpload){

    if(!isImg(filesToUpload[0]) && !isGif(filesToUpload[0])) return;

    let hasError = false;

    if(isImg(filesToUpload[0])){
      const i = await addImageProcess(URL.createObjectURL(filesToUpload[0])); 
      if(i.height > 2000 || i.width > 2000) {
        hasError = true;
        field.dispatchImageError();
      }
    }

    if(isGif(filesToUpload[0]) && (filesToUpload[0].size / (1024*1024).toFixed(2)) > 20){
      hasError = true;
      field.dispatchGifError();
    }

    if(!hasError)
      field.input.onChange(filesToUpload);
  }

  return (
    <div className="image-cover-container">
      <Dropzone
        name={field.name}
        style={{ border: 'none' }}
        disableClick={field.disableClick}
        onDrop={(filesToUpload, e) => setImage(filesToUpload)}
      >
        {files[0] || files ? (
          <div className="image-cover">
            {files[0].preview ? (
              <img alt="Capa curso padrão" src={files[0].preview} />
            ) : (
              <img alt="Capa curso padrão" src={files} />
            )}
          </div>
        ) : (
          <div className="image-cover-default">
            <img alt="Capa curso escolhida" src={CoverIcon} />
            <p>Adicionar capa</p>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export const DropZoneCover = (field) => {
  const files = field.input.value;
  let type = '';
  if (files && files.length > 0) {
    type = files[0].type.split('/')[0];
  }

  function isImg(file){
    return file.type === "image/jpeg" || file.type === "image/png" || 
          file.type === ".jpg" || file.type === ".png" || file.type === ".jpeg" || file.type === "image/gif";
  }

  async function setFiles(filesToUpload){
    const imgFiles = filesToUpload.filter(file => isImg(file));
    const otherFiles = filesToUpload.filter(file => !isImg(file));

    if(otherFiles.length === filesToUpload.length) field.input.onChange(filesToUpload);
    
    const {canAddAllFiles, indexesError} = await canAddFiles(imgFiles);
    let newFiles = [];
    if(canAddAllFiles) field.input.onChange(filesToUpload);
    else {
      field.dispatchImageError();
      filesToUpload.map((newFile, index) =>{
        if(!indexesError.includes(index)) newFiles.push(newFile);
      })
      newFiles.concat(otherFiles);
      field.input.onChange(newFiles);
    }
  }

  return (
    <>
      <div className="drop-preview">
        <Dropzone
          name={field.name}
          style={{ border: 'none', height: '50px' }}
          disableClick={field.disableClick}
          onDrop={(filesToUpload, e) => setFiles(filesToUpload)}
        >
          <p>Adicionar capa</p>
        </Dropzone>
      </div>
      <div className="preview-cover">
        {type === 'video' ? (
          <div className="preview-cover-movie">
            <Player src={files[0] ? files[0].preview : SendCover} />
          </div>
        ) : (
          <img alt="..." src={files[0] ? files[0].preview : SendCover} />
        )}
      </div>
    </>
  );
};

export const NewDropZoneCover = (field) => {
  const files = field.input.value;
  let type = '';
  if (files && files.length > 0) {
    type = files[0].type.split('/')[0];
  }

  function isImg(file){
    return file.type === "image/jpeg" || file.type === "image/png" || 
      file.type === ".jpg" || file.type === ".png" || file.type === ".jpeg" || file.type === "image/gif"; 
  }

  function isGif(file){
    return file.type === "image/gif";
  }

  async function setFiles(filesToUpload){
    const imgFiles = filesToUpload.filter(file => isImg(file));
    const gifFiles = filesToUpload.filter(file => isGif(file));
    const otherFiles = filesToUpload.filter(file => !isImg(file) && !isGif(file));

    if(otherFiles.length === filesToUpload.length) { //Without Images e Withou Gifs
      field.input.onChange(filesToUpload);
      return;
    }

    const indexesErrorGifs = []; //Validation to Gifs Errors
    if(gifFiles.length > 0){
      filesToUpload.map((file, index) => {
        if(isGif(file) && (file.size / (1024*1024).toFixed(2)) > 20){
          indexesErrorGifs.push(index);
        }
      }) 
    }
    
    const {canAddAllFiles, indexesError} = imgFiles.length === 0 ? {canAddAllFiles: true, indexesError: []} : await canAddFiles(imgFiles);
    //Validation to Images Errors
  
    let newFiles = [];
    if(canAddAllFiles && indexesErrorGifs.length === 0) field.input.onChange(filesToUpload); //0 Errors => Image e Gif
    else {
      if(!canAddAllFiles) field.dispatchImageError();
      if(indexesErrorGifs.length > 0) field.dispatchGifError();

      filesToUpload.map((newFile, index) =>{
        if(!indexesError.includes(index) && !indexesErrorGifs.includes(index)) newFiles.push(newFile);
      })
      newFiles.concat(otherFiles);
      field.input.onChange(newFiles);
    }
  }

  return (
    <ContainerDrop>
        <Dropzone
          name={field.name}
          style={{ border: 'none', height: '100%', display:"flex", flexDirection:"column", 
                  justifyContent:"center", alignItems:"center", width:"100%" }}
          disableClick={field.disableClick}
          onDrop={(filesToUpload, e) => setFiles(filesToUpload)}
        >
          {!files[0] && 
            <DropHover>
              <AddIcon/>
              <p>Adicionar capa</p>
            </DropHover>
          }

          {files[0] ?
            <div className="preview-cover">
              {type === 'video' ? (
                <div className="preview-cover-movie">
                  <Player src={files[0] ? files[0].preview : SendCover} />
                </div>
              ) : (
                <img alt="..." src={files[0] ? files[0].preview : SendCover} />
              )}
            </div>
            : <div className="info-body-cover">
                <p>Clique no ícone para selecionar uma imagem (.jpg ou .png) ou vídeo (.mp4) ou arraste o arquivo.</p>
                <p style={{fontSize: "12px", maxWidth:"350px"}}>Resolução recomendada para imagens: 1000 x 800 px Tamanho máximo para vídeos: 200 MB <br></br> Tamanho máximo para gifs: 20 MB</p>
              </div>
          }
        </Dropzone>
    </ContainerDrop>
  );
};

export const DropZoneImageForm = (props) => {
  return (
    <Dropzone style={{}} onChange={props.onChange} onDrop={props.onDrop}>
      <img
        alt="..."
        style={{ objectFit: 'contain', width: '150px', height: '150px' }}
        src={
          typeof props.image === 'string'
            ? props.image
            : Array.isArray(props.image)
            ? props.image[0].preview
            : ImageDrop
        }
      />
    </Dropzone>
  );
};

export const DropZoneImagePreview = (props) => {
  return (
    <Dropzone style={{}} onChange={props.onChange} onDrop={props.onDrop}>
      {props.children}
    </Dropzone>
  );
};

export const DropZoneVideoForm = (props) => {
  return (
    <Dropzone style={{}} onChange={props.onChange} onDrop={props.onDrop}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Player
          alt="..."
          aspectRatio="16:9"
          src={
            typeof props.video === 'string'
              ? props.video
              : Array.isArray(props.video)
              ? props.video[0].preview
              : ImageDrop
          }
        />
      </div>
    </Dropzone>
  );
};

export const NewDropZoneFileForm = (field) => {
  let image = IcoDocDrop;
  if (
    field.input.value !== undefined &&
    field.input.value !== null &&
    Array.isArray(field.input.value) && 
    field.input.value.length > 0
  ) {
    switch (field.input.value[0].type) {
      case '.txt':
        image = IcoDoc;
        break;
      case '.odt':
        image = IcoDoc;
        break;
      case 'application/vnd.oasis.opendocument.text':
        image = IcoDoc;
        break;
      case 'text/plain':
        image = IcoDoc;
        break;
      case '.rtf':
        image = IcoDoc;
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        image = IcoDoc;
        break;
      case '.docx':
        image = IcoDoc;
        break;
      case '.doc':
        image = IcoDoc;
        break;
      case 'application/msword':
        image = IcoDoc;
        break;
      case 'text/html':
        image = IcoDoc;
        break;
      case 'application/rtf':
        image = IcoDoc;
        break;

      case 'application/pdf':
        image = IcoPdf;
        break;

      case 'application/vnd.ms-excel':
        image = IcoXls;
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        image = IcoXls;
        break;
      case 'application/vnd.oasis.opendocument.spreadsheet':
        image = IcoXls;
        break;
      case '.xls':
        image = IcoXls;
        break;
      case '.xml':
        image = IcoXls;
        break;
      case 'text/csv':
        image = IcoXls;
        break;
      case 'application/vnd.ms-excel.sheet.macroenabled.12':
        image = IcoXls;
        break;
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        image = IcoXls;
        break;
      case '.ppt':
        image = IcoPpt;
        break;
      case '.pptx':
        image = IcoPpt;
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        image = IcoPpt;
        break;
      case 'application/vnd.ms-powerpoint':
        image = IcoPpt;
        break;
      case 'application/vnd.oasis.opendocument.presentation':
        image = IcoPpt;
        break;

      case 'image/png':
        image = IcoJpeg;
        break;
      case 'image/jpeg':
        image = IcoJpeg;
        break;
      case 'image/gif':
        image = IcoJpeg;
        break;

      case '.form':
        image = IcoPpt;

        break;

      case '.prezi':
        image = IcoPrezi;

        break;
      default:
        field.edit !== true &&
          field.showAlert(
            `Arquivo ${field.input.value[0].name} tem formato inválido`
          );

        break;
    }
  }

  function isImg(file){
    return file.type === "image/jpeg" || file.type === "image/png" || 
          file.type === ".jpg" || file.type === ".png" || file.type === ".jpeg"; 
  }

  async function setFiles(filesToUpload){
    const imgFiles = filesToUpload.filter(file => isImg(file));
    const otherFiles = filesToUpload.filter(file => !isImg(file));

    if(otherFiles.length === filesToUpload.length) {
      field.input.onChange(filesToUpload);
      return;
    }

    const {canAddAllFiles, indexesError} = await canAddFiles(imgFiles);

    let newFiles = [];
    if(canAddAllFiles) field.input.onChange(filesToUpload);
    else {
      field.dispatchImageError();
      filesToUpload.map((newFile, index) =>{
        if(!indexesError.includes(index)) newFiles.push(newFile);
      })
      newFiles.concat(otherFiles);
      field.input.onChange(newFiles);
    }
  }

  return (
    <div className="drop-zone-area" 
      style={{width:"100%", height:"100%", cursor: "default",
        display:"flex", alignItems: "center", justifyContent:"center"}}
    >
      <Dropzone
        name={field.name}
        onDrop={(filesToUpload, e) => setFiles(filesToUpload)}
        style={{width:"80%",height:"30%",cursor: "pointer"}}
      >
        <div style={{width:"100%",height:"100%",display:"flex", alignItems:"center", justifyContent:"center"}}>
          <img
            src={IcoAddFile}
            alt="ícone adicionar imagem"
            style={field.isFull ? { marginRight:"-20px" } : {marginRight:"10px"}}
          />

          <span
            style={{
              "font-size": "18px",
              color: "black",
              opacity: "0.65",
              "line-height": "24px",
              "font-family": "SofiaPro",
              textAlign: "center",
              maxWidth: field.isFull ? "60%" : "80%"
            }}
          >Clique aqui ou arraste um arquivo para adicioná-lo</span>

        </div>
      </Dropzone>
    </div>
  );
};

export const DropZoneFileForm = (field) => {
  let image = IcoDocDrop;
  if (
    field.input.value !== undefined &&
    field.input.value !== null &&
    Array.isArray(field.input.value) && 
    field.input.value.length > 0
  ) {
    switch (field.input.value[0].type) {
      case '.txt':
        image = IcoDoc;
        break;
      case '.odt':
        image = IcoDoc;
        break;
      case 'application/vnd.oasis.opendocument.text':
        image = IcoDoc;
        break;
      case 'text/plain':
        image = IcoDoc;
        break;
      case '.rtf':
        image = IcoDoc;
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        image = IcoDoc;
        break;
      case '.docx':
        image = IcoDoc;
        break;
      case '.doc':
        image = IcoDoc;
        break;
      case 'application/msword':
        image = IcoDoc;
        break;
      case 'text/html':
        image = IcoDoc;
        break;
      case 'application/rtf':
        image = IcoDoc;
        break;

      case 'application/pdf':
        image = IcoPdf;
        break;

      case 'application/vnd.ms-excel':
        image = IcoXls;
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        image = IcoXls;
        break;
      case 'application/vnd.oasis.opendocument.spreadsheet':
        image = IcoXls;
        break;
      case '.xls':
        image = IcoXls;
        break;
      case '.xml':
        image = IcoXls;
        break;
      case 'text/csv':
        image = IcoXls;
        break;
      case 'application/vnd.ms-excel.sheet.macroenabled.12':
        image = IcoXls;
        break;
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        image = IcoXls;
        break;
      case '.ppt':
        image = IcoPpt;
        break;
      case '.pptx':
        image = IcoPpt;
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        image = IcoPpt;
        break;
      case 'application/vnd.ms-powerpoint':
        image = IcoPpt;
        break;
      case 'application/vnd.oasis.opendocument.presentation':
        image = IcoPpt;
        break;

      case 'image/png':
        image = IcoJpeg;
        break;
      case 'image/jpeg':
        image = IcoJpeg;
        break;
      case 'image/gif':
        image = IcoJpeg;
        break;

      case '.form':
        image = IcoPpt;

        break;

      case '.prezi':
        image = IcoPrezi;

        break;
      default:
        field.edit !== true &&
          field.showAlert(
            `Arquivo ${field.input.value[0].name} tem formato inválido`
          );

        break;
    }
  }

  function isImg(file){
    return file.type === "image/jpeg" || file.type === "image/png" || 
          file.type === ".jpg" || file.type === ".png" || file.type === ".jpeg" || file.type === "image/gif"; 
  }

  function isGif(file){
    return file.type === "image/gif";
  }

  async function setFiles(filesToUpload){

    const imgFiles = filesToUpload.filter(file => isImg(file));
    const gifFiles = filesToUpload.filter(file => isGif(file));
    const otherFiles = filesToUpload.filter(file => !isImg(file) && !isGif(file));

    if(otherFiles.length === filesToUpload.length) { //Without Images e Withou Gifs
      field.input.onChange(filesToUpload);
      return; 
    }

    const indexesErrorGifs = []; //Validation to Gifs Errors
    if(gifFiles.length > 0){
      filesToUpload.map((file, index) => {
        if(isGif(file) && (file.size / (1024*1024).toFixed(2)) > 20){
          indexesErrorGifs.push(index);
        }
      }) 
    }
    
    const {canAddAllFiles, indexesError} = imgFiles.length === 0 ? {canAddAllFiles: true, indexesError: []} : await canAddFiles(imgFiles);
    //Validation to Images Errors

    let newFiles = [];
    if(canAddAllFiles && indexesErrorGifs.length === 0) field.input.onChange(filesToUpload); //0 Errors => Image e Gif
    else {
      if(!canAddAllFiles) field.dispatchImageError();
      if(indexesErrorGifs.length > 0) field.dispatchGifError();

      filesToUpload.map((newFile, index) =>{
        if(!indexesError.includes(index) && !indexesErrorGifs.includes(index)) newFiles.push(newFile);
      })
      newFiles.concat(otherFiles);
      field.input.onChange(newFiles);
    }
  }

  return (
    <div className="drop-zone-area">
      <Dropzone
        name={field.name}
        onDrop={(filesToUpload, e) => setFiles(filesToUpload)}
        style={{}}
      >
        <div style={{ marginLeft: '40%' }} className="col-md-2">
          <img
            style={{
              width: '243px',
              height: '251px',
              position: 'absolute'
            }}
            src={image}
            className="drop-zone-icon"
            alt="ícone adicionar imagem"
          />
        </div>
      </Dropzone>
    </div>
  );
};

export const DropZoneImageApp = (field) => {
  let image = undefined;

  typeof field.input.value === 'string'
    ? (image = field.input.value)
    : (image = field.input.value[0].preview);

  return (
    <div>
      <Dropzone
        name={field.name}
        style={{ border: 'none' }}
        disableClick={field.disableClick}
        onDrop={(filesToUpload, e) => field.input.onChange(filesToUpload)}
      >
        <img
          style={{
            width: '150px',
            height: '150px',
            marginTop: '10px'
          }}
          alt="..."
          src={image !== undefined ? image : ImageDrop}
        />
        <br />
      </Dropzone>
    </div>
  );
};

export const TextForm = (props) => {
  return (
    <TextField
      inputStyle={{
        backgroundColor: 'none !important',
        'font-family': "Rubik'",
        'font-size': '16px',
        'font-weight': '500',
        'font-style': 'normal',
        'font-stretch': 'normal',
        'line-height': 'normal',
        'letter-spacing': '-0.1px',
        'text-align': 'center',
        color: '#333333'
      }}
      onChange={props.onChange}
      value={props.value}
      type={props.type}
      floatingLabelFixed={props.floatingLabelFixed}
      floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
      validate={props.validations}
      disabled={props.disabled}
      rowsMax={props.rowsMax ? props.rowsMax : 1}
      errorText={props.errorText}
      multiLine={true}
      fullWidth={props.fullWidth ? props.fullWidth : false}
      underlineFocusStyle={{
        borderColor: style.inpuStyle.colorInput,
        fontWeight: 'bold'
      }}
      hintText={props.hintText}
    />
  );
};

export const TextAreaForm = (props) => {
  return (
    <TextField
      textareaStyle={{
        fontWeight: 'bold',
        'font-family': 'Rubik',
        'font-size': '18px',
        'font-weight': '500',
        'font-style': 'normal',
        'font-stretch': 'normal',
        'line-height': 'normal',
        'letter-spacing': '-0.1px',
        'text-align': 'left',
        color: '#333333'
      }}
      rows={props.rows}
      rowsMax={props.rowsMax}
      validate={props.validations}
      floatingLabelText={props.label}
      floatingLabelFocusStyle={{ color: style.inpuStyle.colorInput }}
      fullWidth={true}
      floatingLabelFixed={true}
      onChange={props.onChange}
      errorText={props.errorText}
      multiLine={props.multiLine}
      value={props.value}
      hintText={props.hintText}
      floatingLabel
      hintStyle={{
        bottom: '50%',
        color: '#b6b6b6',
        fontSize: '16px',
        fontWeight: 'bold'
      }}
      underlineFocusStyle={
        props.showBorder === undefined
          ? { borderColor: style.inpuStyle.colorInput, fontWeight: 'bold' }
          : { borderBottom: 'none' }
      }
      type="text"
      disabled={props.disabled}
      style={props.style}
    />
  );
};

export const CircularProgressDeterminatedStyled = (props) => {
  return (
    <CircularProgress
      mode="determinate"
      value={props.completed}
      size={200}
      thickness={20}
      color={style.inpuStyle.colorInput}
    ></CircularProgress>
  );
};

export const CircularProgressIndeterminate = () => {
  return (
    <CircularProgress
      size={200}
      thickness={20}
      color={style.inpuStyle.colorInput}
    ></CircularProgress>
  );
};

export const ToogleForm = (props) => {
  const styles = {
    block: {
      maxWidth: 250
    },
    toggle: {
      marginBottom: 16
    },
    thumbOff: {
      backgroundColor: '#ffcccc'
    },
    trackOff: {
      backgroundColor: '#ff9d9d'
    },
    thumbSwitched: {
      backgroundColor: '#03aa2d'
    },
    trackSwitched: {
      backgroundColor: '#03aa2d'
    },
    labelStyle: {
      'font-family': 'Rubik',
      'font-size': '16px',
      'font-weight': '500',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': '-0.1px',
      'text-align': 'left',
      opacity: '0.53'
    }
  };
  return (
    <Toggle
      style={{ width: '50px' }}
      thumbStyle={styles.thumbOff}
      trackStyle={styles.trackOff}
      defaultToggled={props.value}
      label={props.label}
      name={props.name}
      disabled={props.disabled}
      onToggle={props.onChange}
      thumbSwitchedStyle={styles.thumbSwitched}
      trackSwitchedStyle={styles.trackSwitched}
      labelStyle={styles.labelStyle}
    />
  );
};

export const ToogleNoReduxForm = (props) => {
  const styles = {
    block: {
      maxWidth: 250
    },
    toggle: {
      marginBottom: 16
    },
    thumbOff: {
      backgroundColor: '#ffcccc'
    },
    trackOff: {
      backgroundColor: '#ff9d9d'
    },
    thumbSwitched: {
      backgroundColor: '#03aa2d'
    },
    trackSwitched: {
      backgroundColor: '#03aa2d'
    },
    labelStyle: {
      'font-family': 'Rubik',
      'font-size': '16px',
      'font-weight': '500',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': '-0.1px',
      'text-align': 'left',
      opacity: '0.53'
    }
  };
  return (
    <Toggle
      style={props.style}
      thumbStyle={styles.thumbOff}
      trackStyle={styles.trackOff}
      defaultToggled={props.value}
      label={props.label}
      name={props.name}
      disabled={props.disabled}
      onToggle={props.onChange}
      thumbSwitchedStyle={styles.thumbSwitched}
      trackSwitchedStyle={styles.trackSwitched}
      labelStyle={styles.labelStyle}
    />
  );
};

const RenderSelectInput = ({
  input,
  initial,
  meta: { touched, error },
  options,
  name,
  id,
  getTagAutoComplete,
  isMulti = true,
  customControlStyles,
  label,
  isLoading,
  updateChange
}) => {
  const [isActive,setIsActive] = useState(false);

  function activeBySearch(e){
    if(e.length > 0) setIsActive(true);
  }

  function verifyOnFocus(e){
    if(e.relatedTarget == document.getElementById(`clear-indicator-new-${id}`))
      input.onChange([]);
  }

  function verifyOnBlur(e){
    if(e.relatedTarget === null || 
        (
          e.relatedTarget != document.getElementById(`react-select-input-${id}`) 
        )

    )
      setIsActive(false);
  }

  const DropdownIndicator = () => {
    return (
      <div
        style={{
          width: "15px",
          height: "12px",
          marginLeft: "15px"
        }}
      >
      </div>
    )
  }

  return (
    <div style={{display:"flex", width:"92%"}}>
      <div style={{width:"100%"}}>
        <Select
          {...input}
          id={id}
          inputId={`react-select-input-${id}`}
          className="react-select-container"
          name={name}
          options={options}
          isLoading={isLoading}
          loadingMessage={() => 'Buscando tags...'}
          noOptionsMessage={() => 'Nenhuma tag encontrada'}
          placeholder={label}
          isMulti={isMulti}
          theme={(theme) => ({
            ...theme,
            fontSize: '24px',
            fontWeight: 'bold',
            position: 'absolute',
            width:"100%",
            colors: {
              ...theme.colors,
              text: style.inpuStyle.colorInput,
              primary25: '#c9c7c7',
              primary: style.inpuStyle.colorInput
            }
          })}
          styles={customControlStyles}
          value={input.value}
          onInputChange={(e)=>{
            activeBySearch(e);
            // getTagAutoComplete(e);
          }}
          onChange={(value) => input.onChange(value)}
          onFocus={(e) => verifyOnFocus(e) }
          onBlur={()=>setIsActive(false)}
          menuIsOpen={isActive}
          components={{
            DropdownIndicator,
            ClearIndicator: () => {
              return(
                <div
                  id={`clear-indicator-new-${id}`}
                  className="clear-indicator-new"
                  style={isActive ? {visibility: "hidden"}: {}}
                  onClick={()=> input.onChange([])}
                  title="Limpar tudo"
                >
                  <CloseIco width="23px" height="23px"/>
                </div>
              )
            } 
          }}
        />
        {touched && error && <span className="error-label-input">{error}</span>}
      </div>
      <div
        tabindex="1"
        className="dropdown-indicator-new"
        onClick={()=>setIsActive(!isActive)} 
        onBlur={(e)=>{verifyOnBlur(e)}}
      >
        <ArrowIco/>
      </div>
    </div>

  );
}

export const AutoCompleteForm = (props) => {
  let tag = [];
  const customStyles = {
    control: () => ({
      display: 'flex',
      alignItems: 'center',
      opacity: '0.8',
      border: 0,
      'border-bottom': props.isEmpty ? '1px solid red' : props.isNewEditPost ? '1px solid #dee1e8' : 'none',
      height: '30px',
      width: '100%',
      'margin-left': '-8px',
      'margin-top': '30px',
      'margin-bottom': '15px',
      color: '#3b3b3b',
      height: 'auto',
      'font-size': '16px',
      color: 'red',
      background: 'transparent',
      '&:hover': {
        boxShadow: 'none'
      }
    }),
    multiValueLabel: (base) => ({
      ...base,
      'font-size': '10px'
    }),
    menu: () => ({
      backgroundColor: 'white',
      boxShadow: '1px 2px 6px #3b3b3b', // should be changed as material-ui
      position: 'absolute',
      left: 0,
      'font-size': '16px',
      top: `calc(100% + 1px)`,
      width: '100%',
      zIndex: 10,
      maxHeight: 50 * 4.5, 
    }),
    menuList: () => ({
      maxHeight: 50 * 4.5,
      zIndex: 10,

      'font-size': '12px',
      opacity: '0.7',
      'font-weigth': 'bold',
      overflowY: 'auto'
    }),
    option: (base, state) => ({
      ...base
    })
  };

  if (Array.isArray(props.tags)) {
    tag = props.tags.map((tag) => {
      return { value: tag, label: `#${tag}` };
    });
  }

  return (
    <Field
      options={tag}
      label={props.label}
      isLoading={props.isLoading}
      validate={props.validations}
      getTagAutoComplete={props.getTagAutoComplete}
      isMulti={props.isMulti}
      id={props.id}
      customControlStyles={customStyles}
      name={props.name}
      component={RenderSelectInput}
      updateChange={props.updateChange}
      onChange={props.onChange}
    />
  );
};
