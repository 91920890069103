import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { reduxForm } from 'redux-form';
import Modal from 'react-awesome-modal';
import PostApi from '../../../../Logicas/PostApi';
import { Button } from '../../../user/components/UsersHeader/UsersHeader.styles';
import DashboardButton from '../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import { TextFieldForm } from '../../../utils_form/FormComponents';
import { number, minValue1 } from '../../../validation/FormValidation'; 
import { ButtonConfirm, BodyDialog } from '../../../utils/dialog_styled';
import IcoExit from '../../../utils/img/exit-dialog.png';

import * as S from './DialogMinimumComments.styles';

export class DialogMinimumComments extends Component {
  constructor() {
    super();
    this.verifyMinSizeEqualsToOne = this.verifyMinSizeEqualsToOne.bind(this);
  }

  verifyMinSizeEqualsToOne(minSize){
    if(minSize === 1 || minSize === 0) this.props.setMinCommentsEnabled();
    this.props.closeDialogMinimumComments();
  }

  render() {
    const {
        showDialog,
        closeDialogMinimumComments,
        updateMinSizeForm,
        setMinComments,
        min_size_comments,
        min_size_form,
    } = this.props;
    return (
      <Modal
        effect="fadeInUp"
        width="500px"
        height="330px"
        visible={showDialog}
      >
        <S.CloseButton src={IcoExit} onClick={() => closeDialogMinimumComments()} />
        <BodyDialog>
            <>
              <S.ModalTitle>
              Limite de Caracteres
              </S.ModalTitle>
              <S.ModalText>
              Digite abaixo a quantidade mínima de caracteres para realizar um comentário
              </S.ModalText>
              <S.ModalForm>
                  <TextFieldForm
                      name="min_size_form"
                      floatingLabelText="Mínimo de caracteres"
                      validations={[minValue1,number]}
                      type="number"
                      onChange={(e)=>updateMinSizeForm(e.target.value)}
                  />
              </S.ModalForm>
              <S.ModalFooter>
                  <DashboardButton
                      label="Cancelar"
                      onClick={() => this.verifyMinSizeEqualsToOne(min_size_comments)}
                  >Cancelar</DashboardButton>
                  <Button
                      disabled={min_size_form <= 0}
                      onClick={()=>{
                          setMinComments(this.props.postId, min_size_form);
                          closeDialogMinimumComments();
                      }}
                  >
                      Confirmar
                  </Button>
              </S.ModalFooter>
            </>
        </BodyDialog>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  showDialog: state.editpostform.modal_min_comments_is_visible,
  min_size_comments: state.editpostform.min_size_comments,
  min_size_form: state.editpostform.min_size_form,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeDialogMinimumComments: () =>
      dispatch({
        type: 'CLOSE_MINIMUM_COMMENTS_MODAL'
      }),
    updateMinSizeForm: (min_size_form) =>
      dispatch({
        type: 'UPDATE_MIN_SIZE_FORM',
        payload: min_size_form
      }),
    setMinComments: (postId, value) =>
      dispatch(PostApi.setMinComments(postId, value)),
    setMinCommentsEnabled:()=>
      dispatch({
        type:'SET_MIN_COMMENTS_ENABLED'
      })
  };
};

const DialogMinimumCommentsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogMinimumComments);

export default DialogMinimumCommentsForm;
