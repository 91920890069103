import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import Modal from 'react-awesome-modal';
import ToolsButton from '../../ToolsList/Components/ToolsButton/ToolsButton';
import {
  LongTextFieldTitle,
  AutoCompleteForm
} from '../../../utils_form/FormComponents';
import { required } from '../../../validation/FormValidation';
import * as SectionApi from '../../../../Logicas/SectionApi';
import TagApi from '../../../../Logicas/TagApi';
import DialogApi from '../../../../Logicas/DialogApi';

import * as S from './SectionCreate.style';

export class SectionCreate extends Component {
  constructor() {
    super();
    this.state = {
      sectionNames: [],
      sectionIds: [],
      selectedSectorId: ''
    };
    this.formRef = React.createRef();
  }

  sectionEdit = (values) => {
    this.props.sectionEdit(values, this.props.sectionId);

    this.props.hideModalHandler(this.props.sectionId);
  };

  editSection = () => {
    const sections = this.props.sectionList;
    const targetId = this.props.sectionId;

    const targetSection = sections.find((target) => targetId === target.id);

    this.props.getSingleSection(targetSection.id);

    this.props.showModalHandler(this.props.sectionId);
  };

  deleteSection = async () => {
    this.props.deleteSection(this.props.sectionId);

    this.props.showModalHandler(this.props.sectionId);
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <>
        <S.SectionContentWrapper>
          <S.ToolsContentTitleWrapper className="container">
            <S.ToolsContentTitle>{this.props.name}</S.ToolsContentTitle>
            <ToolsButton label="Editar seção" onClick={this.editSection} />
          </S.ToolsContentTitleWrapper>
        </S.SectionContentWrapper>
        <Modal
          visible={this.props.showModal[this.props.sectionId]}
          width="600"
          height="400"
          effect="fadeInUp"
        >
          <form onSubmit={handleSubmit(this.sectionEdit)} ref={this.formRef}>
            <MuiThemeProvider>
              <S.FormContainer>
                <div className="row">
                  <div className="col-md-12">
                    {!this.props.name ? (
                      <h2>Nova seção</h2>
                    ) : (
                      <h2>Editar {this.props.name}</h2>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <LongTextFieldTitle
                      fullWidth={true}
                      name="name"
                      rows={1}
                      validations={[required]}
                      floatingLabelText="Nome da seção"
                      type="text"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <AutoCompleteForm
                      name="tags_names"
                      isLoading={this.props.isLoadingTags}
                      validations={[required]}
                      label="Tags disponiveis*"
                      getTagAutoComplete={this.props.getTagAutoComplete}
                      getAuto={this.props.getTagAutoComplete}
                      tags={this.props.tagsAvailable}
                    />
                  </div>
                </div>
                <div className="row">
                  <S.ButtonsContainer className="col-md-12">
                    <ToolsButton
                      label="Apagar"
                      onClick={() => this.deleteSection(this.props.sectionId)}
                    />
                    <ToolsButton
                      label="Cancelar"
                      onClick={() =>
                        this.props.hideModalHandler(this.props.sectionId)
                      }
                    />
                    <ToolsButton
                      label="Salvar"
                      onClick={() =>
                        this.formRef.current.dispatchEvent(new Event('submit'))
                      }
                    />
                  </S.ButtonsContainer>
                </div>
              </S.FormContainer>
            </MuiThemeProvider>
          </form>
        </Modal>
      </>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'editsection',
  enableReinitialize: true
})(SectionCreate);

const selector = formValueSelector('editsection');

const mapDispatchToProps = (dispatch) => {
  return {
    getToolsList: async () => {
      await dispatch(SectionApi.getSectionList());
    },
    sectionEdit: async (values, sectionId) => {
      await dispatch(SectionApi.sectionEdit(values, sectionId));
    },
    deleteSection: async (sectionId) => {
      await dispatch(SectionApi.sectionDelete(sectionId));
    },
    getSingleSection: async (sectionId) => {
      await dispatch(SectionApi.getSingleSection(sectionId));
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    openDialog: () => {
      dispatch(DialogApi.openDialog());
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    }
  };
};

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.section.sectionEdit,
    sectionList: state.section.sectionList,
    alert: state.dialog,
    isLoadingTags: state.isLoadingtags,
    tagsAvailable: state.tagsparsed
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
