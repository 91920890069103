import React, { Component } from 'react';
import { LabelLink } from '../../../utils/utils_styled';

require('./headercontroller.css');

class HeaderController extends Component {
  onClick = (action) => {
    this.props.getTabContent(action);
  };

  render() {
    return (
      <div className="header-painel-controller">
        <label className="main-label-painel">Painel</label>
        <ul className="header-controller-list">
          {this.props.actions.map(
            function(action) {
              return (
                <LabelLink
                  style={{ marginLeft: '20px' }}
                  onClick={() => this.onClick(action)}
                  selected={this.props.dashboardTab === action}
                >
                  {action}
                </LabelLink>
              );
            }.bind(this)
          )}
        </ul>
      </div>
    );
  }
}
export default HeaderController;
