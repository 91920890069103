import React, { Component } from 'react';
import { debounce } from 'lodash';
import { DialogProgressIndeterminated } from '../../../../utils/DailogComponentes';
import CardContentText from '../../../../utils/img/card-content-text.svg';
import RichTextEditor from '../../../../utils/RichTextEditor/RichTextEditor';
import IcoRemove from '../../../../utils/img/ic-excluir.svg';

export class TextModule extends Component {
  constructor() {
    super();

    this.saveTextContent = this.saveTextContent.bind(this);
  }

  saveTextContent = debounce(async (event, editor) => {
    let aux = this.props.stateSubModules;

    aux[this.props.index].contents[
      this.props.indexContent
    ].text_content = this.props.textInputHandler(event, editor);
    await this.props.setStateSubmodules(aux);

    this.props.content.text_content.length > 0 &&
      this.props.patchTextContent(this.props.index, this.props.indexContent);
  }, 1000);

  render() {
    return (
      <div className="container-content-submodule">
        <div className="loading-content">
          {this.props.loading.loadingCourse &&
            this.props.loading.indexContent === this.props.content.id && (
              <DialogProgressIndeterminated />
            )}
        </div>
        <div className="header-content-submodule">
          <div className="header-title-img">
            <img src={CardContentText} alt="Componente de Texto" />
            <div className="header-content-description">
              <p>CARD TEXTO</p>
            </div>
          </div>
          <div className="removed-submodule">
            <img
              src={IcoRemove}
              alt="Remover Conteudo"
              onClick={() =>
                this.props.removedSubModuleAndContent(
                  this.props.content.id,
                  'content'
                )
              }
            />
          </div>
        </div>
        <div className="container-content-submodule-text">
          <RichTextEditor
            onChange={(e, editor) => this.saveTextContent(e, editor)}
            data={this.props.content.text_content}
          />
        </div>
      </div>
    );
  }
}

export default TextModule;
