import React, { Component } from 'react';

import * as S from './GalleryButton.style';

export class GalleryButton extends Component {
  render() {
    return (
      <S.ButtonBody
        className="btn-click"
        onClick={this.props.onClick}
        pagination={this.props.pagination}
        disabled={this.props.disabled}
        type="button"
      >
        <S.ButtonLabel>{this.props.label}</S.ButtonLabel>
      </S.ButtonBody>
    );
  }
}

export default GalleryButton;
