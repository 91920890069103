import iconSum from '../componentes/canvas/img/icon-sum.svg';
import iconSubtraction from '../componentes/canvas/img/icon-subtraction.svg';
import iconIntersection from '../componentes/canvas/img/icon-intersection.svg';

export const parseTagsObject = (arrayTags) => {
  if (arrayTags.length === 1) {
    return {
      left_operation: arrayTags[0].valueTag,
      operation: arrayTags[0].valueExpression.value
    };
  }

  if (arrayTags.length === 2) {
    return {
      left_operation: arrayTags[0].valueTag,
      operation: arrayTags[0].valueExpression.value,
      right_operation: arrayTags[1].valueTag
    };
  }
  let auxArray = [];
  arrayTags.map((array, index) => {
    if (index > 0) {
      auxArray[index - 1] = array;
    }
  });
  return {
    left_operation: arrayTags[0].valueTag,
    operation: arrayTags[0].valueExpression.value,
    right_operation: parseTagsObject(auxArray)
  };
};

export const parseObjectTags = (objectTags) => {
  let arrayTags = [];
  if (objectTags) {
    objecToTags(arrayTags, objectTags);
  }
  return arrayTags;
};

const objecToTags = (arrayTags, objectTags) => {
  arrayTags.push({
    valueTag: objectTags.left_operation,
    valueExpression: expressionTagConvert(objectTags.operation)
  });
  if (typeof objectTags.right_operation === 'object') {
    return objecToTags(arrayTags, objectTags.right_operation);
  }
  if (objectTags.right_operation) {
    return arrayTags.push({
      valueTag: objectTags.right_operation,
      valueExpression: expressionTagConvert(objectTags.operation)
    });
  }
};

const expressionTagConvert = (operation) => {
  switch (operation) {
    case 'subtraction':
      return {
        value: 'subtraction',
        image: iconSubtraction,
        text: 'Subtração'
      };
    case 'intersection':
      return {
        value: 'intersection',
        image: iconIntersection,
        text: 'Interseção'
      };
    default:
      return { value: 'addition', image: iconSum, text: 'Adição' };
  }
};
