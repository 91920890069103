import API from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import { browserHistory } from 'react-router';
import DialogApi from '../Logicas/DialogApi';
import errorHandler from '../componentes/utils/errorHandler';
import { style } from '../style';

export default class SectorApi {
  static getSectorUser() {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('sectors/', Config.headers())
        .then((response) => {
          let sectors = this.parseObject(response.data);
          dispatch({ type: 'GETSECTOR', sectors });
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static createSectorUser(values) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      let formData = new FormData();
      formData.append('name', values.name_sector);
      API.post('sectors/', formData, Config.headers())
        .then(() => {
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch(DialogApi.openDialog());
          dispatch(this.getSectorUser());
        })
        .catch((err) => {
          if (err.response.status === 401) {
            browserHistory.push('./');
          }
          dispatch({ type: 'HIDE_MODAL_INSERT_TAG' });
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          const message =
            err.response.data.name[0] === 'já está em uso'
              ? 'Setor já cadastrado. Insira um nome diferente.'
              : err.message;
          dispatch({
            type: 'OPEN_ERROR',
            alert: { visible: true, message: message }
          });
        });
    };
  }

  static patchSectorUser(id, values) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      let formData = new FormData();
      formData.append('name', values.name_sector);
      API.patch(`sectors/${id}`, formData, Config.headers())
        .then(() => {
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch(DialogApi.openDialog());
          dispatch(this.getSectorUser());
        })
        .catch((err) => {
          if (err.response.status === 401) {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: { visible: true, message: err.message }
          });
        });
    };
  }

  static deleteSectorUser(id) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(`sectors/${id}`, Config.headers())
        .then(() => {
          dispatch(DialogApi.openDialog());
          dispatch(this.getSectorUser());
        })
        .catch((err) => {
          if (err.response.status === 401) {
            browserHistory.push('./');
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: { visible: true, message: err.message }
          });
        });
    };
  }

  static getSectorNamesList() {
    return async (dispatch) => {
      try {
        const { data } = await API.get('sectors/', Config.headers());

        const sectorNamesList = data.map((sector) => sector.name);

        dispatch({
          type: 'SECTOR_NAMES_LIST_GET',
          sectorNamesList
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static parseObject(arrays) {
    let arraysname = [];
    if (arrays.length > 0) {
      arrays.map(function(arraysindex) {
        arraysname.push({
          name_sector: arraysindex.name,
          id_sector: arraysindex.id,
          associated_users: arraysindex.users
        });
      });
    }
    return arraysname;
  }
}
