import React, { Component } from 'react';
import IcoRemove from '../../utils/img/ic-excluir.svg';
import IcoArrowDown from '../../utils/img/ic-arrow-donw.png';
import IcoArrowUp from '../../utils/img/ic-arrow-up.png';
import ContentApi from '../../../Logicas/ContentApi';
import { connect } from 'react-redux';

class CardContent extends Component {
  constructor() {
    super();
    this.state = { image_arrow: IcoArrowDown };
    this.changeIconArrow = this.changeIconArrow.bind(this);
  }

  changeIconArrow() {
    if (this.state.image_arrow === IcoArrowDown) {
      this.setState({ image_arrow: IcoArrowUp });
    } else {
      this.setState({ image_arrow: IcoArrowDown });
    }
    this.props.setContentEdit(this.props.content);
  }
  render() {
    return (
      <div>
        {this.props.kind === 'new' ? (
          <div className="card-content-hide">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div className="row">
                    <div className="col-md-10">
                      <label className="text-card-content">
                        {this.props.indextext}
                      </label>
                      <label
                        style={{ marginLeft: '10px' }}
                        className="text-card-content"
                      >
                        {this.props.contentname}
                      </label>
                    </div>
                    {this.props.kind !== 'new' ? (
                      <label className="ico-content">{this.props.kind}</label>
                    ) : (
                      undefined
                    )}
                    <div></div>
                    {this.props.kind !== 'new' ? (
                      <div
                        style={{ marginLeft: '10px' }}
                        className="ico-remove"
                      >
                        <img
                          onClick={this.props.Delete}
                          style={{ marginLeft: '9px', marginTop: '7px' }}
                          src={IcoRemove}
                          alt="apagar"
                        />
                      </div>
                    ) : (
                      undefined
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-11">{this.props.children}</div>
            </div>
          </div>
        ) : (
          <div className="card-content-hide">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div className="row">
                    <div className="col-md-10">
                      <label className="text-card-content">
                        {this.props.indextext}
                      </label>
                      <label
                        style={{ marginLeft: '10px' }}
                        className="text-card-content"
                      >
                        {this.props.contentname}
                      </label>
                    </div>
                    {this.props.kind !== 'new' ? (
                      <label className="ico-content">{this.props.kind}</label>
                    ) : (
                      undefined
                    )}
                    <div></div>
                    {this.props.kind !== 'new' ? (
                      <div
                        style={{ marginLeft: '10px' }}
                        className="ico-remove"
                      >
                        <img
                          onClick={this.props.Delete}
                          style={{ marginLeft: '9px', marginTop: '7px' }}
                          src={IcoRemove}
                          alt="apagar"
                        />
                      </div>
                    ) : (
                      undefined
                    )}

                    <div
                      style={{ marginLeft: '10px' }}
                      data-toggle="collapse"
                      href={'#anco' + this.props.anco}
                      className="ico-drop"
                      onClick={this.changeIconArrow}
                    >
                      <img
                        style={{ marginTop: '10px', marginLeft: '-1px' }}
                        data-toggle="collapse"
                        href={'#anco' + this.props.anco}
                        src={this.state.image_arrow}
                        alt="recolher"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-11">
                <div class="collapse" id={'anco' + this.props.anco}>
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    module_data: state.module,
    alert: state.dialog,
    pagination: state.pagination
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setContentEdit: (content) => {
      dispatch(ContentApi.editContentForm(content));
    }
  };
};
const CardContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardContent);

export default CardContentContainer;
