import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import ImageIcon from '../../img/ic-imagem_3x.png';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Dropzone from 'react-dropzone';
import VideoIcon from '../../img/ic-video.png';
import CarouselIcon from '../../img/ic-carousel.png';
import LinkIcon from '../../img/ic-link.png';
import { TextFieldDefault, LongTextLink } from '../../../utils/FormsComponents';
import { style } from '../../../../style';
import { required } from '../../../validation/FormValidation';
import IcoLinkConfirm from '../../img/ic-add-link.png';
import PostApi from '../../../../Logicas/PostApi';
import IcoExit from '../../../utils/img/exit-dialog.png';
import { canAddFiles } from '../../../utils/canAddFiles';

require('./dialogcanvas.css');
class DialogEditMidia extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.saveLink = this.saveLink.bind(this);
    this.isGif = this.isGif.bind(this);
  }
  closeDialog() {}

  isGif(file){
    return file.type === "image/gif";
  }

  async handleChange(e) {
    if (e[0] !== undefined && e !== null) {
      const {canAddAllFiles} = await canAddFiles(e);
      let canAddAllGifs = true;
      e.map(file => {
        if(this.isGif(file) && ((file.size / (1024*1024).toFixed(2)) > 20))
          canAddAllGifs = false;
      });
      if(canAddAllFiles && canAddAllGifs){
        try {
          if (this.props.alert.kind === 'image') {
            this.props.EditImage(
              this.props.idcanvas,
              this.props.alert.id,
              e,
              this.props.index
            );
          }
          if (this.props.alert.kind === 'carousel') {
            this.props.AddImageToCarousel(
              this.props.idcanvas,
              this.props.alert.id,
              e
            );
          }
  
          if (this.props.alert.kind === 'video') {
            this.props.EditVideoV2(this.props.idcanvas, this.props.alert.id, e, style.teamName.name);
          }
          this.props.closeDialogUploadFile();
        } catch (error) {}
      } else {
        this.props.closeDialogUploadFile();
        if(!canAddAllFiles)
          this.props.dispatchImageError();
        else if(!canAddAllGifs)
          this.props.dispatchGifError();
      }
    }
  }

  saveLink(values) {
    this.props.addLink(values.name_button, values.link_button);
    this.props.setPublication('link', values);
    this.props.InsertComponentLink(values);
    this.props.closeDialogUploadFile();
  }
  render() {
    const { handleSubmit, submitting } = this.props;
    if (this.props.alert.kind !== 'link') {
      return (
        <Modal visible={this.props.alert.visible} width="50%" height="50%">
          <div
            onClick={this.props.closeDialogUploadFile}
            className="body-row-exit"
          >
            <img style={{ height: '12px', width: '12px' }} src={IcoExit} alt="fechar"/>
          </div>
          <div
            style={{ width: '100%', height: '90%' }}
            className="dropzone-class"
          >
            {this.state.erro === undefined ? (
              <Dropzone
                className="dropzone-class-border"
                onDrop={this.handleChange}
              >
                {this.props.alert.kind === 'image' && (
                  <div className="dropzone-class">
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={ImageIcon}
                      alt="ícone de imagem"
                    />
                    <div className="label-dialog-main">
                      <label className="label-dialog-main-select">
                        Clique aqui
                      </label>{' '}
                      ou arraste uma imagem para adicionar um conteúdo Imagem
                    </div>
                  </div>
                )}
                {this.props.alert.kind === 'video' && (
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={VideoIcon}
                        alt="ícone de vídeo"
                      />
                    </div>
                    <div style={{ marginTop: '10px' }} className="col-md-8">
                      <div className="label-dialog-main">
                        <label className="label-dialog-main-select">
                          Clique aqui
                        </label>{' '}
                        ou arraste um vídeo para adicionar um conteúdo Vídeo
                      </div>
                    </div>
                  </div>
                )}
                {this.props.alert.kind === 'carousel' && (
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={CarouselIcon}
                        alt="ícone de carrossel"
                      />
                    </div>
                    <div style={{ marginTop: '10px' }} className="col-md-8">
                      <div className="label-dialog-main">
                        <label className="label-dialog-main-select">
                          Clique aqui
                        </label>{' '}
                        ou arraste ao menos duas e até seis imagens para criar
                        um carousel
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            ) : (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="erro-images">
                      Limite de imagens excedido, máximo de seis imagens
                      permitido
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal visible={true} width="613px" height="158px">
          <form onSubmit={handleSubmit(this.saveLink)}>
            <div className="row">
              <div
                style={{ marginTop: '54px', marginLeft: '20px' }}
                className="col-md-2"
              >
                <img style={{ width: '50px', height: '50px' }} src={LinkIcon} alt="ícone de link"/>
              </div>
              <div className="col-md-8">
                <Field
                  name="name_button"
                  rows={1}
                  validate={[required]}
                  id="name"
                  label="Texto do botão*"
                  component={TextFieldDefault}
                  type="text"
                />
              </div>
              <div
                style={{ marginTop: '20px' }}
                onClick={this.props.closeDialogUploadFile}
                className="col-md-1"
              >
                <img style={{ height: '12px', width: '12px' }} src={IcoExit} alt="fechar"/>
              </div>
            </div>
            <div style={{ marginTop: '-50px' }} className="row">
              <div style={{ marginLeft: '20px' }} className="col-md-2"></div>
              <div className="col-md-6">
                <Field
                  name="link_button"
                  rowsMax={1}
                  rows={1}
                  validate={[required]}
                  id="name"
                  label="https://*"
                  component={LongTextLink}
                  type="text"
                />
              </div>
              <div className="col-md-2 img-button-click">
                <button className="img-button-click">
                  {' '}
                  <img src={IcoLinkConfirm} alt="confirmar"/>{' '}
                </button>
              </div>
            </div>
          </form>
        </Modal>
      );
    }
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'createlink'
})(DialogEditMidia);

const mapDispatchToProps = (dispatch) => {
  return {
    sendFiles: (files) => {
      dispatch({ type: 'UPLOAD_FILES', files: files });
    },
    addLink: (link, name) => {
      dispatch({ type: 'ADD_LINK', link: { name: name, link: link } });
    },
    setPublication: (kind, value) =>
      dispatch({ type: 'formPost', kind: kind, value: value }),
    EditImage: (idcanvas, idimage, image, index) =>
      dispatch(PostApi.PatchContentImage(idcanvas, idimage, image, index)),
    closeDialogUploadFile: () => {
      dispatch({ type: 'CLOSE_DIALOG_FILE_MIDIA', alert: { visible: false } });
    },
    AddImageToCarousel: (idcanvas, idimage, image) => {
      dispatch(PostApi.AddImageToCarousel(idcanvas, idimage, image));
    },
    EditVideo: (idcanvas, idimage, videos) => {
      dispatch(PostApi.PatchContentVideo(idcanvas, idimage, videos));
    },
    EditVideoV2: (idcanvas, idimage, value, teamName) => {
      dispatch(PostApi.PatchContentVideoV2(idcanvas, idimage, value, teamName));
    },
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels."
        }
      });
    },
    dispatchGifError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.",
        }
      });
    },
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    alert: state.dialogeditmidia
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
