import React, { Component } from 'react';
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';

import Chip from 'material-ui/Chip';
import Modal from 'react-awesome-modal';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import ButtonDefault, {
  LongTextField,
  TextFieldTitle,
  ButtonDisable,
  TextFieldDefault
} from '../../FormsComponents';
import NotificationApi from '../../../../Logicas/NotificationApi';
import { required, maxLengthTitle } from '../../../validation/FormValidation';
import UserApi from '../../../../Logicas/UserApi';

import Avatar from 'material-ui/Avatar';
import IcoExit from '../../../utils/img/exit-dialog.png';
import ImgPlaceHolder from '../../../utils/img/place_holder_user.png';

require('./dialog.css');
require('../form/formscoreusers.css');
class DialogTable extends Component {
  constructor() {
    super();
    this.state = {};
    this.removerUser = this.removerUser.bind(this);
    this.sendGift = this.sendGift.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  removerUser(user) {
    this.props.removeUserMarked(user);
    this.forceUpdate();
  }

  sendGift(values) {
    this.setState({ title: values.title });
    this.props.sendNotification(
      this.props.list_marked,
      values.title,
      values.description
    );
  }

  closeDialog(values) {
    values.title = '';
    values.description = '';
    this.props.closeDialogNotification();
    PubSub.publish('clear-list-marked', true);
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    let chipbody = {
      marginBottom: '5px',
      marginTop: '5px',
      marginRight: '5px',
      paddingLeft: '0px'
    };
    return (
      <Modal fadeInUp width="40%" height="80%" visible={this.props.visible}>
        <div className="container">
          {this.props.showModal ? (
            <div className="body-form-score">
              <div className="row-users">
                {this.props.check_all_users !== true && (
                  <label className="label-users-length">
                    Enviar{' '}
                    {this.props.list_marked.length > 1
                      ? 'notificações'
                      : 'notificação'}{' '}
                    ({this.props.list_marked.length})
                  </label>
                )}

                <img
                  onClick={this.closeDialog}
                  className="btn-exit"
                  src={IcoExit}
                  alt="fechar"
                />
              </div>
              <div className="display-chips">
                {this.props.check_all_users && this.props.userFilter ? (
                  <div className="label-alert">
                    Todos os usuários filtrados por{' '}
                    {this.props.userFilter.selectedFilter ===
                    'Mês do Aniversário'
                      ? 'aniversário em '
                      : this.props.userFilter.filter_selectd.toLowerCase()}{' '}
                    {this.props.userFilter.filterValue === 'M' ||
                    this.props.userFilter.filterValue === 'F'
                      ? this.props.userFilter.filterValue === 'M'
                        ? ' masculino'
                        : ' feminino'
                      : this.props.userFilter.filterValue}{' '}
                    serão notificados.
                  </div>
                ) : (
                  Array.isArray(this.props.list_marked) &&
                  this.props.list_marked.map(
                    function(user) {
                      return (
                        <Chip backgroundColor="#efefef" style={chipbody}>
                          <Avatar
                            src={user.image ? user.image : ImgPlaceHolder}
                          />
                          {user.name}
                          <img
                            onClick={() => this.props.toggleSelectedUser(user)}
                            className="btn-remove-chip"
                            src={IcoExit}
                            alt="ícone remover"
                          />
                        </Chip>
                      );
                    }.bind(this)
                  )
                )}
              </div>
              <form className="form-to-score">
                <Field
                  name="title"
                  rows={1}
                  validate={[required, maxLengthTitle]}
                  id="name"
                  label="Título"
                  component={TextFieldTitle}
                  type="text"
                />
                <div className="description-notification">
                  <Field
                    name="description"
                    validate={[required, maxLengthTitle]}
                    id="name"
                    label="Mensagem"
                    component={LongTextField}
                    type="text"
                  />
                  <p className="view-characters">
                    {this.props.description_product &&
                      `${this.props.description_product.length}/170.`}
                  </p>
                </div>
                <div style={{ width: '80%' }}>
                  <Field
                    disabled={true}
                    name="user_responsible"
                    id="name"
                    label="Responsável"
                    component={TextFieldDefault}
                    type="text"
                  />
                </div>
              </form>
              {this.props.list_marked.length > 0 && this.props.valid ? (
                <div className="submit-btn">
                  <ButtonDefault
                    onClick={handleSubmit(this.sendGift)}
                    label="Enviar"
                  />
                </div>
              ) : (
                <div className="submit-btn">
                  <ButtonDisable label="Enviar" />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="row-exit-confirm">
                <img
                  onClick={(this.closeDialog, handleSubmit(this.closeDialog))}
                  className="btn-exit"
                  src={IcoExit}
                  alt="fechar"
                />
              </div>
              <div className="body-comfirm">
                <label className="label-comfirm-main">
                  {this.props.list_marked.length > 1
                    ? 'Notificações enviadas!'
                    : 'Notificação enviada!'}
                </label>
                <label className="label-comfirm-secondary">
                  {this.props.list_marked.length > 1
                    ? this.props.list_marked.length + ' usuários, '
                    : this.props.list_marked.length + ' usuário, '}
                  {this.state.title}
                </label>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
let DialogContainer = reduxForm({
  form: 'notificationusers'
})(DialogTable);

const selector = formValueSelector('notificationusers');

const mapStateToProps = (state) => {
  return {
    list_marked: state.user.selectedUsers.users,
    visible: state.dialog_notification_users,
    initialValues: { user_responsible: localStorage.getItem('UserName') },
    showModal: state.show_modal_birthday,
    check_all_users: state.check_all_users,
    userFilter: state.user.userFilter,
    description_product: selector(state, 'description')
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeDialogNotification: () =>
      dispatch(NotificationApi.closeDialogNotification()),
    removeUserMarked: (user, checked) =>
      dispatch(UserApi.insertUserToEditScore(user, false)),
    resetDialog: () => dispatch({ type: 'SHOW_MODAL_BIRTHDAY' }),
    sendNotification: (users, title, description) => {
      dispatch(NotificationApi.sendNotification(users, title, description));
    },
    clearFilterStore: () => dispatch({ type: 'CLEAR_FILTER_USER' }),
    toggleSelectedUser: (selectedUser) => {
      dispatch({
        type: 'USER_SELECTED_TOGGLE',
        selectedUser
      });
    }
  };
};
DialogContainer = connect(mapStateToProps, mapDispatchToProps)(DialogContainer);
export default DialogContainer;
