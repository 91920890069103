import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import IcoExit from '../../utils/img/exit-dialog.png';
import { MuiThemeProvider } from 'material-ui/styles';
import { Avatar } from 'material-ui';
import { TextFieldWithoutForm } from '../../utils/FormsComponents';
import PostApi from '../../../Logicas/PostApi';
import moment from 'moment';
import Zoom from 'react-reveal/Zoom';
import PubSub from 'pubsub-js';
require('./dialogcomments.css');
export class DialogComments extends Component {
  constructor() {
    super();
    this.state = { page: 1, request: true, showLabel: true, id_comment: 0 };
    this.handleScroll = this.handleScroll.bind(this);
    this.getByName = this.getByName.bind(this);
  }

  componentWillMount() {
    window.onscroll = () => this.handleScroll();
    PubSub.subscribe(
      'get_next_page_comments',
      function(render) {
        this.setState({ request: true });
      }.bind(this)
    );
  }

  handleScroll() {
    if (document.getElementById('#body-scroll') !== null) {
      let scrollposition = document.getElementById('#body-scroll').scrollTop;
      var max = document.getElementById('#body-scroll').scrollHeight;
      if (
        scrollposition > max / 2 &&
        this.props.comments_list.length % 10 === 0 &&
        this.state.request
      ) {
        this.setState({ page: (this.state.page += 1), request: false });
        this.props.getComments(this.props.id_post, this.state.page);

        document
          .getElementById('#body-scroll')
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }
  getByName(value) {
    if (value.target.value.length > 3) {
      this.props.getCommentByNameUser(this.props.id_post, value.target.value);
    }
    if (value.target.value.length === 0) {
      this.props.getComments(this.props.id_post, 1);
    }
    this.setState({ searchvalue: value.target.value });
  }
  render() {
    let visible = this.props.visible;
    return (
      <Modal visible={visible} width="35%" height="70%" effect="fadeInUp">
        <MuiThemeProvider>
          <div className="header-comments"></div>
          <div className="header-row-comments">
            <div className="label-comments">
              {this.props.comments_list.length !== 1
                ? this.props.comments_list.length + ' comentários'
                : this.props.comments_list.length + ' comentário'}{' '}
            </div>
          </div>
          <div onClick={this.props.closeDialog} className="header-row-exit">
            <img
              style={{
                width: '18px',
                height: '18px',
                marginRight: '20px',
                marginTop: '-15%'
              }}
              src={IcoExit}
              alt="fechar"
            />
          </div>
          <div
            id="#body-scroll"
            onScroll={this.handleScroll}
            className="body-comments"
          >
            <div className="field-search">
              <TextFieldWithoutForm
                value={this.state.searchvalue}
                onChange={this.getByName}
                floatingLabelText="Busque por usuário"
              />
            </div>
            {Array.isArray(this.props.comments_list) &&
              this.props.comments_list.map(
                function(comment) {
                  let hour = moment(comment.created_at).fromNow();
                  return (
                    <div className="col-comments">
                      <div className="row-comments">
                        <Avatar
                          style={{ marginTop: '-20px' }}
                          src={comment.user.image.url}
                        ></Avatar>
                        <div className="col-row-comments">
                          <div className="user-name-comments">
                            {comment.user.name}
                          </div>
                          <label className="comments-text">
                            {comment.body}
                          </label>
                          {this.state.showLabel === true ? (
                            <label
                              onClick={() =>
                                this.setState({
                                  showLabel: false,
                                  id_comment: comment.id
                                })
                              }
                              className="remover-comment-label"
                            >
                              Excluir comentário
                            </label>
                          ) : this.state.id_comment === comment.id ? (
                            <Zoom>
                              <label className="remover-comment-label-confirm">
                                Deseja excluir o comentário ?
                                <label
                                  style={{ marginLeft: '5px' }}
                                  className="remover-comment-label"
                                  onClick={this.props.deleteComments.bind(
                                    this,
                                    comment.id,
                                    this.props.id_post
                                  )}
                                >
                                  {' '}
                                  Sim{' '}
                                </label>
                                <label
                                  style={{ marginLeft: '5px' }}
                                  className="remover-comment-label"
                                  onClick={() =>
                                    this.setState({
                                      showLabel: true,
                                      id_comment: comment.id
                                    })
                                  }
                                >
                                  {' '}
                                  Não{' '}
                                </label>
                              </label>
                            </Zoom>
                          ) : (
                            <label
                              onClick={() =>
                                this.setState({
                                  showLabel: false,
                                  id_comment: comment.id
                                })
                              }
                              className="remover-comment-label"
                            >
                              Excluir comentário
                            </label>
                          )}
                        </div>
                        <div className="comments-timer">{hour}</div>
                      </div>
                    </div>
                  );
                }.bind(this)
              )}
          </div>
        </MuiThemeProvider>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () =>
      dispatch({ type: 'GET_COMMENTS', data: [], visible: false }),
    deleteComments: (value, id, comment) =>
      dispatch(PostApi.deleteComment(id, value)),
    getComments: (id, page) => {
      dispatch(PostApi.getComments(id, page));
    },
    getCommentByNameUser: (id, name) => {
      dispatch(PostApi.getCommentByNameUser(id, name));
    }
  };
};
let DialogCommentsContainer = connect(
  (state) => ({
    comments_list: state.post_comments.data,
    id_post: state.post_comments.id_post
  }),
  mapDispatchToProps
)(DialogComments);

export default DialogCommentsContainer;
