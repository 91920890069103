import Config from '../componentes/restservice/Utils';
import { browserHistory } from 'react-router';
import Axios from 'axios';
import { style } from '../style';

export const editCourseV2 = (idcourse, redirect) => async (dispatch) => {
  dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
  try {
    const { data } = await Axios.get(
      `${style.url.urlClient}api/v2/courses/${idcourse}?origin=admin`,
      Config.headers()
    );
    const course = parseCourseV2(data);
    dispatch({
      type: 'EDIT_COURSE',
      course: course
    });
    dispatch({ type: 'NO_SUBMITTING' });
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
    dispatch({
      type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
      indexContent: 0
    });
    if (redirect) {
      browserHistory.push('/course/modules/create');
    }
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: error.response.datas
      }
    });
  }
};

export const parseCourseV2 = (values) => {
  const course = {};
  course.title = values.title;
  course.score = values.score;
  course.description = values.description;
  course.score_for_certificate = values.minimum_percentage_for_certificate || 0;
  course.module_courses = [];
  course.id = values.id;
  course.tags_course = [];
  values.tags.map(function(tag) {
    return course.tags_course.push({
      value: tag.name,
      label: '#' + tag.name
    });
  });
  values.module_courses.map(function(module_) {
    return course.module_courses.push(module_);
  });
  course.image = values.image.url;
  // course.users_finished = values.users_progress_data.users_who_finished;
  // course.users_finished = values.users_progress_data.users_who_finished;
  // course.users_not_finish = [];
  // course.users_not_started = [];
  // values.users_progress_data.users_who_did_not_finish.map(function(not_finish) {
  //   if (not_finish.finished_modules.length > 0) {
  //     course.users_not_finish.push(not_finish);
  //   } else {
  //     course.users_not_started.push(not_finish);
  //   }
  // });
  return course;
};
