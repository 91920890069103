import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CircularProgressDeterminatedStyled } from '../utils_form/FormComponents';
import Modal from 'react-awesome-modal';
import { BodyDialog, LabelLoad } from './dialog_styled';
import Loader from '../utils/AutoPagination/Loader';
class DialogProgressCircular extends Component {
  render() {
    return (
      <Modal
        visible={this.props.load.visible}
        width="400px"
        height="300px"
        effect="fadeInUp"
      >
        <BodyDialog>
          <CircularProgressDeterminatedStyled
            completed={this.props.load.percent}
          />
          <LabelLoad>
            {this.props.load.percent < 100
              ? `${this.props.load.percent}%`
              : (
                <Loader isDialogProgressCircular={true} loading={true} style={{right:"160px",marginBottom:"70px"}}/>
              )}
          </LabelLoad>
        </BodyDialog>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    load: state.load_progress_dialog
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const DialogProgressCircularContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogProgressCircular);

export default DialogProgressCircularContainer;
