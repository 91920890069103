import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Toggle } from 'material-ui';
import { style } from '../../style';
import ComponentHeader from '../utils/ComponenteHeader';
import { Body } from '../utils/BodyComponente';
import TagApi from '../../Logicas/TagApi';
import { connect } from 'react-redux';
import IcoDocDrop from '../utils/img/ic-doc_newdoc.png';
import { formValueSelector } from 'redux-form';
import RepositorieApi from '../../Logicas/RepositoryApi';
import { DropZoneFileForm, NewDropZoneFileForm } from '../utils_form/FormComponents';
import {
  TextFieldForm,
  LongTextFieldTitle
} from '../utils_form/FormComponents';
import DialogProgressCircular from '../utils/DialogProgressCircular';
import fileTypeFilter from '../utils/fileTypeFilter';
import RemoveIcon from '../utils/img/ic-excluir.svg';
import ExtensionsModal from './Components/ExtensionsModal/ExtensionsModal';
import { addImageProcess } from '../utils/addImageProcess';
let imageico = null;
require('./newFile.css');
class FileCreate extends Component {
  constructor() {
    super();
    this.state = {
      drag_area: false,
      imageico: IcoDocDrop,
      notify_users: false,
      list_files: []
    };
    this.saveFile = this.saveFile.bind(this);
    this.addFiles = this.addFiles.bind(this);
  }

  componentDidMount() {
    this.props.enableBtn();
  }
  saveFile(values) {
    if (
      Array.isArray(values.file) ||
      (values.url_file !== '' && values.url_file !== undefined)
    ) {
      this.props.saveFile(
        values,
        this.state.list_files,
        this.props.list.folder_id,
        this.props.nameFormSend,
        this.state.notify_users
      );
    } else {
      this.props.showErroAlert('Adicione um arquivo ou informe uma url');
    }
  }

  checkInvalidFileType = (files) => {
    let invalidFile = false;

    files.forEach((file) => {
      if (!fileTypeFilter(file.type)) {
        invalidFile = true;
      }
    });

    return invalidFile;
  };

  async addFiles(event, newFiles, value) {
    const currentFiles = this.state.list_files;

    if (this.checkInvalidFileType(newFiles)) {
      return;
    }

    let canAddFiles = true;
    newFiles.map(async newfile => {
      const i = await addImageProcess(URL.createObjectURL(newfile)); 
      if(i.height > 2000 || i.width > 2000) {
        canAddFiles = false;
        this.props.dispatchImageError();
      }
    })
    
    if(canAddFiles) {

      newFiles.map((file) => {
        currentFiles.push(file);
      });

      this.setState({ list_files: currentFiles });

    }
  }

  removeFile(key) {
    const files = this.state.list_files;
    files.splice(key, 1);

    this.setState({ list_files: files });
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    const styles = {
      block: {
        maxWidth: 250
      },
      toggle: {
        marginBottom: 16
      },
      thumbOff: {
        backgroundColor: '#c9c7c7'
      },
      trackOff: {
        backgroundColor: '#c9c7c7'
      },
      thumbSwitched: {
        backgroundColor: style.inpuStyle.colorInput
      },
      trackSwitched: {
        backgroundColor: style.inpuStyle.colorInput
      },
      labelStyle: {
        color: 'red'
      }
    };
    return (
      <form onSubmit={handleSubmit(this.saveFile)}>
        <ComponentHeader
          disabled={submitting}
          tooltip={
            <p>
              Atualizar publicação
              <br />
            </p>
          }
          actions={[]}
          name="Arquivos"
          nameaction="Salvar"
          onClick={handleSubmit(this.saveFile)}
        />
        <Body drag_area={this.state.drag_area}>
          <div className="select-form-send">
            <div className="header-details">
              <p className="title-header-details">Escolha a forma de envio:</p>
            </div>
            <div className="body-details">
              <div className="notify-users">
                <p className="title-notify-users">Notificar os usuários:</p>
                <Toggle
                  thumbStyle={styles.thumbOff}
                  trackStyle={styles.trackOff}
                  thumbSwitchedStyle={styles.thumbSwitched}
                  trackSwitchedStyle={styles.trackSwitched}
                  labelStyle={styles.labelStyle}
                  defaultToggled={this.state.notify_users}
                  onToggle={() =>
                    this.setState({ notify_users: !this.state.notify_users })
                  }
                />
              </div>
              <ul className="body-details-first">
                <li>
                  <div
                    className={
                      this.props.nameFormSend === 'Enviar arquivos'
                        ? 'menu-item-details active'
                        : 'menu-item-details'
                    }
                    onClick={() => this.props.getFormSend('Enviar arquivos')}
                  >
                    Enviar arquivos
                  </div>
                </li>
                <li>
                  <div
                    className={
                      this.props.nameFormSend === 'Enviar link'
                        ? 'menu-item-details active'
                        : 'menu-item-details'
                    }
                    onClick={() => this.props.getFormSend('Enviar link')}
                  >
                    Enviar link
                  </div>
                </li>
                <li>
                  <ExtensionsModal />
                </li>
              </ul>
              <div className="container-body">
                {this.props.nameFormSend === 'Enviar arquivos' ? (
                  <div className="body-send-file">
                    <div className="send-file">
                      <Field
                        onDrop={this.onDrop}
                        showAlert={this.props.showErroAlert}
                        name="file"
                        onChange={this.addFiles}
                        component={(e)=> DropZoneFileForm(
                            {...e, dispatchImageError: this.props.dispatchImageError, 
                              dispatchGifError: this.props.dispatchGifError, isFull:true}
                        )}
                      />
                    </div>
                    <div className="list-send-file">
                      <p className="title-list-file">Lista de arquivos</p>
                      <div className="header-files">
                        <div className="file-name">Arquivo</div>
                        <div className="file-action">Ações</div>
                      </div>
                      <ul className="body-list-file">
                        {this.state.list_files &&
                          this.state.list_files.map((file, key) => {
                            return (
                              <li className="file-selected">
                                <div className="file-name">
                                  {file.name.length > 75
                                    ? `${file.name.slice(0, 75)}...`
                                    : file.name}
                                </div>
                                <div className="file-action">
                                  <img
                                    type="button"
                                    src={RemoveIcon}
                                    className="imagicon"
                                    onClick={() => this.removeFile(key)}
                                    alt="apagar"
                                  />
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="col-md-8">
                        <LongTextFieldTitle
                          name="name_file"
                          id="name"
                          label="Nome do arquivo*"
                          type="text"
                        />
                      </div>
                    </div>
                    <div style={{ marginBottom: '150px' }} className="row">
                      <div className="col-md-6">
                        <TextFieldForm
                          fullWidth={true}
                          name="url_file"
                          rows={1}
                          id="name"
                          floatingLabelText="Digite a URL ou cole para enviar o link do arquivo*"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <DialogProgressCircular />
        </Body>
      </form>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'createfile'
})(FileCreate);

const mapDispatchToProps = (dispatch) => {
  return {
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    saveFile: (values, files, idfolder, sendType, notify_users) => {
      dispatch(
        RepositorieApi.saveFile(values, files, idfolder, sendType, notify_users)
      );
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    },
    showErroAlert: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: message }
      }),
    getFormSend: (titleName) => {
      dispatch(RepositorieApi.getHeaderFileSend(titleName));
    },
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
    dispatchGifError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.",
        }
      });
    },
  };
};
const selector = formValueSelector('createfile');
InitializeFromStateForm = connect(
  (state) => ({
    tags: state.tagsparsed,
    file: selector(state, 'file'),
    list: state.repositories,
    nameFormSend: state.getHeaderFileSend
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
