const initialState = {
  content: {
    gallery_contents: [],
    pagination: {
      next_page: 2
    }
  },
  galleryId: '',
  galleriesList: [],
  pagination: 1,
  selectedTags: [],
  editGallery: {},
  loading: false,
  showGalleryForm: false
};

export function gallery(state = initialState, action) {
  switch (action.type) {
    case 'GALLERY_SELECT':
      const { content, galleryId } = action;

      return { ...state, content, galleryId };
    case 'GET_MORE_PHOTOS':
      return {
        ...state,
        content: {
          ...state.content,
          gallery_contents: [
            ...state.content.gallery_contents,
            ...action.content.gallery_contents
          ],
          pagination: action.content.pagination
        }
      };
    case 'GET_LIST_GALLERIES':
      const {
        galleries: galleriesList,
        pagination: { next_page: firstFetch }
      } = action.galleries;

      return { ...state, galleriesList, pagination: firstFetch };
    case 'GET_MORE_GALLERIES':
      const oldGalleries = state.galleriesList;
      const {
        galleries: newGalleriesList,
        pagination: { next_page: nextFetches }
      } = action.galleries;

      const updatedGalleriesList = [...oldGalleries, ...newGalleriesList];

      return {
        ...state,
        galleriesList: updatedGalleriesList,
        pagination: nextFetches
      };
    case 'ADD_GALLERY':
      const addedGalleryList = [...state.galleriesList, action.newGallery];

      return {
        ...state,
        galleriesList: addedGalleryList
      };
    case 'EDIT_GALLERY':
      const editGallery = state.galleriesList.find(
        (gallery) => gallery.id === action.galleryId
      );

      const galleryTagsNames = editGallery.tags.map((tag) => ({
        value: tag.name,
        label: `#${tag.name}`
      }));

      editGallery.tags = [...galleryTagsNames];

      return {
        ...state,
        editGallery
      };
    case 'DELETE_GALLERY':
      const { galleryId: galleryIdDeleteId } = action;
      const remainingGalleries = state.galleriesList.filter(
        (gallery) => gallery.id !== galleryIdDeleteId
      );

      return {
        ...state,
        galleriesList: remainingGalleries
      };
    case 'SHOW_GALLERY_FORM':
      return { ...state, showGalleryForm: true };
    case 'HIDE_GALLERY_FORM':
      return { ...state, showGalleryForm: false };
    case 'LOADING_GALLERIES_START':
      return {
        ...state,
        loading: true
      };
    case 'LOADING_GALLERIES_FINISH':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
