import API from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import { browserHistory } from 'react-router';
import errorHandler from '../componentes/utils/errorHandler';
import { doesContainHttp } from '../componentes/validation/FormValidation';
import { style } from '../style';

export const getSectionList = () => async (dispatch) => {
  try {
    const {
      data: { external_link_sections: sectionList }
    } = await API.get('external_link_sections/', Config.headers());

    dispatch({
      type: 'GET_SECTION_LIST',
      sectionList: sectionList.sort((prev, next) =>
        prev.name.localeCompare(next.name)
      )
    });

    const sectionNameList = sectionList.map((section) => section.name);

    dispatch({
      type: 'GET_SECTION_NAMES',
      sectionNameList
    });

    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const getSingleSection = (sectionId) => async (dispatch) => {
  try {
    const { data } = await API.get(
      `external_link_sections/${sectionId}`,
      Config.headers()
    );

    dispatch({
      type: 'GET_SECTION_EDIT',
      sectionEdit: data
    });
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const saveToolCreate = (formValues, sectorId) => async (dispatch) => {
  const formData = new FormData();
  formData.append('name', formValues.name);
  formData.append('kind', formValues.kind);
  if (formValues.kind === 'url') {
    if (formValues.image) {
      formData.append('image', formValues.image[0]);
    }
  } else {
    if (formValues.file) {
      formData.append('file', formValues.file[0]);
    } else {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Cadastre um arquivo para continuar.'
        }
      });
      dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      return;
    }
  }
  if (formValues.url) {
    if (!doesContainHttp(formValues.url)) {
      formValues.url = `https://${formValues.url}`;
    }
    formData.append('url', formValues.url);
  }

  try {
    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

    await API.post(
      `external_link_sections/${sectorId}/external_links`,
      formData,
      Config.headers()
    );

    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });

    browserHistory.push('/tools/list');
  } catch (error) {
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const editTool = (sectionId, toolId) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

    const {
      data: { external_link_sections: sectionList }
    } = await API.get('external_link_sections/', Config.headers());

    const { data: toolEdit } = await API.get(
      `/external_link_sections/${sectionId}/external_links/${toolId}`,
      Config.headers()
    );

    const { name: originalSectorName } = sectionList.find(
      (section) => sectionId === section.id
    );
    toolEdit.sector = originalSectorName;

    dispatch({
      type: 'EDIT_SECTION_LINK',
      toolEdit
    });

    dispatch({
      type: 'NO_SUBMITTING'
    });
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });

    if (toolEdit.kind === 'url') {
      browserHistory.push('/tools/link/edit');
    } else {
      browserHistory.push('/tools/file/edit');
    }
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const patchTool = (values, previousSectorId) => async (dispatch) => {
  const { sectorId, id: toolId } = values;

  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('kind', values.kind);
  formData.append('section_destiny_id', sectorId);
  if (Array.isArray(values.image)) {
    formData.append('image', values.image[0]);
  }
  if (Array.isArray(values.file)) {
    formData.append('file', values.file[0]);
  }
  if (values.url) {
    if (!doesContainHttp(values.url)) {
      values.url = `https://${values.url}`;
    }
    formData.append('url', values.url);
  }

  try {
    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

    await API.patch(
      `/external_link_sections/${previousSectorId}/external_links/${toolId}`,
      formData,
      Config.headers()
    );

    dispatch({
      type: 'NO_SUBMITTING'
    });
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
    browserHistory.push('/tools/list');
  } catch (error) {
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const deleteTool = (sectionId, toolId) => async (dispatch) => {
  try {
    let API_CLIENT = API;

    if (style.teamName.name === 'youseed') {
      API_CLIENT = APIDelete;
    }

    await API_CLIENT.delete(
      `external_link_sections/${sectionId}/external_links/${toolId}`,
      Config.headers()
    );

    dispatch({
      type: 'CLOSE_ALERT',
      alert: {
        visible: false
      }
    });
    dispatch({
      type: 'NO_SUBMITTING'
    });
    dispatch(getSectionList());
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const sectionCreate = (values) => async (dispatch) => {
  const formData = new FormData();
  formData.append('name', values.name);
  values.tags.forEach((tag) => {
    formData.append('tags[]', tag.value);
  });

  try {
    await API.post('external_link_sections', formData, Config.headers());

    dispatch(getSectionList());
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const sectionEdit = (values, sectionId) => async (dispatch) => {
  const formData = new FormData();
  formData.append('name', values.name);
  values.tags_names.forEach((tag) => {
    formData.append('tags[]', tag.value);
  });

  try {
    await API.patch(
      `external_link_sections/${sectionId}`,
      formData,
      Config.headers()
    );

    dispatch(getSectionList());
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const sectionDelete = (sectionId) => async (dispatch) => {
  try {
    let API_CLIENT = API;

    if (style.teamName.name === 'youseed') {
      API_CLIENT = APIDelete;
    }

    await API_CLIENT.delete(
      `external_link_sections/${sectionId}`,
      Config.headers()
    );

    dispatch(getSectionList());
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};
