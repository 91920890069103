import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  position: relative;
  ${({ isVisible }) =>
    isVisible &&
    css`
      z-index: 11;
    `}
`;

export const ModalArea = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  z-index: 11;
`;

export const ScheduleHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ScheduleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const ScheduleTitle = styled.h2`
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  color: #3b3b3b;
  justify-self: flex-start;

  /* background: yellow; */
`;

export const ScheduleMessage = styled.h2`
  font-family: Rubik;
  font-size: 27px;
  font-weight: 500;
  color: #3b3b3b;
  justify-self: flex-start;

  /* background: red; */
`;

export const ScheduleMain = styled.div`
  height: 180px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .divisor{
    height: 100%;
    width: 2px;
    background: #BABABA;
  }

  .expiration{

    .checkboxLine{
      display: flex;

      >div{
        margin-left: 0 !important;
      }
    }
  }

  .disabled{
    /* opacity: 0.5; */
  }
`

export const ScheduleColumn = styled.div`
  height: 100%;
  width: 45%;

  span{
    font-size: 18px;
    font-weight: 700;
  }

  form{
    text-align: center;

    >div{
      >div{
        background: red;
        display: flex;
        flex-direction: column;
        align-items:center;

        label{
          position: relative;
          margin-left:5.5rem;
        }
        input{
          text-align: center;
        }
      }

    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
`

export const ScheduleHourContainer = styled.div`
  width: 100%;
`;

export const ScheduleFooter = styled.div`
  justify-self: flex-end;

  .buttonSchedule {
    width: 50px !important;
  }
`;

