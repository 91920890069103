import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import * as S from './DropZoneImage.style';

export class DropZoneTools extends Component {
  render() {
    return (
      <Dropzone onDrop={this.props.onDrop}>
        <S.DropZoneImagePreview
          src={this.props.previewIcon}
          alt={this.props.file[0].name}
        />
      </Dropzone>
    );
  }
}

export default DropZoneTools;
