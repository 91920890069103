import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Modal from 'react-awesome-modal';
import ToolsButton from '../../ToolsList/Components/ToolsButton/ToolsButton';
import {
  LongTextFieldTitle,
  AutoCompleteForm
} from '../../../utils_form/FormComponents';
import { required } from '../../../validation/FormValidation';
import * as SectionApi from '../../../../Logicas/SectionApi';
import TagApi from '../../../../Logicas/TagApi';
import DialogApi from '../../../../Logicas/DialogApi';

import * as S from './SectionEdit.style';

export class SectionEdit extends Component {
  constructor() {
    super();
    this.state = {
      sectionNames: [],
      sectionIds: [],
      selectedSectorId: ''
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.getTags();
  }

  sectionCreate = (values) => {
    this.props.sectionCreate(values);

    this.props.closeDialog();
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Modal
        visible={this.props.alert.visible}
        width="600"
        height="400"
        effect="fadeInUp"
      >
        <form onSubmit={handleSubmit(this.sectionCreate)} ref={this.formRef}>
          <MuiThemeProvider>
            <S.FormContainer>
              <div className="row">
                <div className="col-md-12">
                  <h1>Criar seção</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <LongTextFieldTitle
                    fullWidth={true}
                    name="name"
                    rows={1}
                    validations={[required]}
                    id="name"
                    floatingLabelText="Nome da seção"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <AutoCompleteForm
                    name="tags"
                    isLoading={this.props.isLoadingTags}
                    validations={[required]}
                    label="Tags disponiveis*"
                    getTagAutoComplete={this.props.getTagAutoComplete}
                    getAuto={this.props.getTagAutoComplete}
                    tags={this.props.tagsAvailable}
                  />
                </div>
              </div>
              <div className="row">
                <S.ButtonsContainer className="col-md-12">
                  <ToolsButton
                    label="Cancelar"
                    onClick={() => this.props.closeDialog()}
                  />
                  <ToolsButton
                    label="Salvar"
                    onClick={() =>
                      this.formRef.current.dispatchEvent(new Event('submit'))
                    }
                  />
                </S.ButtonsContainer>
              </div>
            </S.FormContainer>
          </MuiThemeProvider>
        </form>
      </Modal>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'createsection'
})(SectionEdit);

const selector = formValueSelector('createuser');

const mapDispatchToProps = (dispatch) => {
  return {
    getToolsList: async () => {
      await dispatch(SectionApi.getSectionList());
    },
    sectionCreate: async (values) => {
      await dispatch(SectionApi.sectionCreate(values));
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    sectionList: state.section.sectionList,
    alert: state.dialog,
    isLoadingTags: state.isLoadingtags,
    tagsSelected: selector(state, 'tagsSection'),
    tagsAvailable: state.tagsparsed
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
