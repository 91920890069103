import React from 'react';

import styled from 'styled-components';
import { style } from '../../style';

import { ReactComponent as IconAddText } from './icons/add_text.svg';
import { ReactComponent as IconAddVideo } from './icons/add_video.svg';
import { ReactComponent as IconAddImages } from './icons/add_images.svg';
import { ReactComponent as IconAddLink } from './icons/add_link.svg';

const ContainerAddPostContent = styled.div`
    width: 100%;
    height: 70px;

    display:flex;
    justify-content: space-between;
`;

const ItemAddPostContent = styled.div`
    width: 70px;
    height: 100%;
    border-radius: 5px;

    background-color: #F4F6FB;
    color: #7D86A1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor:pointer;


    span{
        font-size:16px;
    }

    &:hover{
        background-color:${style.inpuStyle.colorInput};
        color:white;
        svg {
            path {
              fill: ${({ isIconText }) => isIconText ? 'white' : style.inpuStyle.colorInput};
              stroke: ${({ isIconText }) => !isIconText && 'white'};
            }
          }
    }
`

export function AddPostContent(props) {

    return (
        <ContainerAddPostContent>
            <ItemAddPostContent isIconText={true} onClick={props.InsertComponentText}>
                <IconAddText/>
                <span>texto</span>
            </ItemAddPostContent>

            <ItemAddPostContent onClick={()=>props.showDialogUploadFile('video')}>
                <IconAddVideo/>
                <span>vídeo</span>
            </ItemAddPostContent>

            <ItemAddPostContent onClick={()=>props.showDialogUploadFile('image')}>
                <IconAddImages/>
                <span>imagens</span>
            </ItemAddPostContent>

            <ItemAddPostContent onClick={()=>props.showDialogUploadFile('link')}>
                <IconAddLink/>
                <span>link</span>
            </ItemAddPostContent>
            
        </ContainerAddPostContent>
    )
}