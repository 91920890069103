import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddIcon from './img/add-icon.png';
import CreateContentImage from '../contents/NewContentImage';
import IcoImage from './img/ic-imagem.svg';
import Icovideo from './img/ic-video.svg';
import IcoText from './img/ic-texto.svg';
import Icoquiz from './img/ic-quiz.svg';
import CreateContentVideo from '../contents/NewContentVideo';
import CreateContentText from '../contents/NewContentText';
import CreateContentQuiz from '../contents/NewContentQuiz';
import PubSub from 'pubsub-js';

require('./createcardcourse.css');

class CreateCardsCourse extends Component {
  constructor() {
    super();
    this.state = {
      showKindOfCards: false,
      selectedKind: false,
      kindSelected: null
    };
    this.showKindOfCards = this.showKindOfCards.bind(this);
  }

  showKindOfCards() {
    this.setState({ showKindOfCards: true });
  }

  componentWillMount() {
    PubSub.subscribe(
      'clear_new_elements',
      function(element, ids) {
        this.setState({ kindSelected: null });
      }.bind(this)
    );
  }

  render() {
    if (this.state.kindSelected === null) {
      return (
        <React.Fragment>
          <div className="create-card-course-display">
            <div
              onMouseOver={() => this.setState({ showKindOfCards: true })}
              onMouseLeave={() => this.setState({ showKindOfCards: false })}
              className="create-card-card-default-body"
            >
              {this.state.showKindOfCards === false ? (
                <React.Fragment>
                  <img className="ic-adicionar" src={AddIcon} alt="adicionar"/>
                  <label className="create-card-card-default-label">
                    Adicionar card
                  </label>
                </React.Fragment>
              ) : (
                <div className="create-card-kinds-display">
                  <div
                    onClick={() =>
                      this.setState({
                        selectedKind: true,
                        kindSelected: 'image'
                      })
                    }
                    className="card-kinds-option"
                  >
                    <img src={IcoImage} alt="ícone imagem"/>
                    <label className="create-card-kinds-display-label">
                      Conteúdo com imagem
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        selectedKind: true,
                        kindSelected: 'video'
                      })
                    }
                    className="card-kinds-option"
                  >
                    <img src={Icovideo} alt="ícone vídeo"/>
                    <label className="create-card-kinds-display-label">
                      Conteúdo com vídeo
                    </label>
                  </div>
                  <div
                    onClick={() =>
                      this.setState({
                        selectedKind: true,
                        kindSelected: 'text'
                      })
                    }
                    className="card-kinds-option"
                  >
                    <img src={IcoText} alt="ícone texto"/>
                    <label className="create-card-kinds-display-label">
                      Conteúdo em texto
                    </label>
                  </div>
                  <div
                    style={{ borderBottom: 'none' }}
                    onClick={() =>
                      this.setState({
                        selectedKind: true,
                        kindSelected: 'quiz'
                      })
                    }
                    className="card-kinds-option"
                  >
                    <img src={Icoquiz} alt="ícone quiz"/>
                    <label className="create-card-kinds-display-label">
                      Card de quiz
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      PubSub.publish('add_elements', {
        module_id: this.props.module_id,
        course_id: this.props.course.id
      });
      switch (this.state.kindSelected) {
        case 'image':
          return (
            <CreateContentImage
              module_id={this.props.module_id}
              course_id={this.props.course.id}
              id={this.props.id}
            />
          );
        case 'text':
          return (
            <CreateContentText
              module_id={this.props.module_id}
              course_id={this.props.course.id}
              id={this.props.id}
            />
          );
        case 'video':
          return (
            <CreateContentVideo
              module_id={this.props.module_id}
              course_id={this.props.course.id}
              id={this.props.id}
            />
          );
        case 'quiz':
          return (
            <CreateContentQuiz
              module_id={this.props.module_id}
              course_id={this.props.course.id}
              id={this.props.id}
            />
          );
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    course: state.course
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const CreateCardsCourseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCardsCourse);

export default CreateCardsCourseContainer;
