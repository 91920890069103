import React, { Component } from 'react';
import { debounce } from 'lodash';

import * as S from './RangeInput.styles';

export class RangeInput extends Component {
  constructor() {
    super();
    this.state = {
      min: 0,
      max: 0
    };
  }
  filterByScoreValidation = debounce(() => {
    const { filterUserTagsByScore } = this.props;
    const min = Number(this.state.min);
    const max = Number(this.state.max);

    if (max > min) {
      filterUserTagsByScore({ min, max });
    }
  }, 1500);

  render() {
    const { min, max } = this.state;
    return (
      <S.FormContainer>
        <S.InputContainer>
          <S.TextInput
            placeholder="0"
            value={min}
            onChange={(e) =>
              this.setState(
                { min: e.target.value },
                this.filterByScoreValidation()
              )
            }
          />
          <S.Underline />
        </S.InputContainer>
        <S.InputContainer>Até</S.InputContainer>
        <S.InputContainer>
          <S.TextInput
            placeholder="0"
            value={max}
            onChange={(e) =>
              this.setState(
                { max: e.target.value },
                this.filterByScoreValidation()
              )
            }
          />
          <S.Underline />
        </S.InputContainer>
      </S.FormContainer>
    );
  }
}

export default RangeInput;
