import API, { API_V2 } from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import errorHandler from '../componentes/utils/errorHandler';
import { browserHistory } from 'react-router';
import moment from 'moment-timezone';
import { style } from '../style';

export default class TagApi {
  constructor() {}

  static getTags(page, auto, all = '') {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      dispatch({ type: 'LOADING_TAGS_START' });
      API.get(`tags/?page=${page}&filter=${all}`, Config.headers())
        .then(
          function(response) {
            if (auto) {
              dispatch({
                type: 'GET_TAG_PARSED',
                tags: this.parseObject(response.data)
              });
            } else {
              var tags = response.data;
              dispatch({
                type: 'GET_LIST_TAG',
                tags: tags
              });
              dispatch({ type: 'GET_PAGE', page });
            }
            dispatch({
              type: 'NO_LOADING_TAGS'
            });
            dispatch({ type: 'LOADING_TAGS_FINISH' });
            dispatch({ type: 'MERGE_TAGS_VALUE_RESET' });
            dispatch({ type: 'TAG_USERS_ASSOCIATION_RESET' });
            dispatch({ type: 'RESET_USER_LIST' });
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getTagsUser(parsedId = false, withCount=false) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('tags/', Config.headers())
        .then(
          function(response) {
            let tags, tags_with_ids;
            if (parsedId) {
              tags = this.parseObjectNameIds(response.data);
            } else {
              tags = this.parseObject(response.data);
            }
            if(withCount){
              tags_with_ids = this.parseObjectNameIds(response.data);
            }
            dispatch({
              type: 'GET_TAG_PARSED',
              tags: tags
            });
            dispatch({
              type: 'GET_TAG_PARSED_WITH_IDS',
              tags_with_ids: tags_with_ids,
            });
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getTagsUserV2(page, search = '') {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get(`tags?page=${page}&search=${search}`, Config.headers())
        .then(
          function(response) {
            const tags = this.parseObject(response.data.tags);
            dispatch({
              type: 'GET_TAG_PAGINATION',
              tags: tags,
              pagination: response.data.pagination
            });
            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static createTag(values) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('name', values.name_tag);
      formData.append('description', values.description_tag);
      formData.append('valide', true);
      API.post('tags/', formData, Config.headers())
        .then(
          function(response) {
            dispatch(this.getTags(1, '', 'all'));
            dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
          }.bind(this)
        )
        .catch(function(err) {
          if (err.message === 'Request failed with status code 422') {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: 'Tag já existente.'
              }
            });
          } else {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }
        });
    };
  }

  static setTagAvaiable(id, avaiable) {
    return (dispatch) => {
      var avaiable_ = false;
      if (avaiable) {
        avaiable_ = false;
      } else {
        avaiable_ = true;
      }
      var formData = new FormData();
      formData.append('valide', avaiable_);

      API.patch('tags/' + id, formData, Config.headers())
        .then(
          function(response) {
            dispatch(this.getTags(1, '', 'all'));
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static editTag(id, name, description, available, filter, values) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      var formData = new FormData();
      formData.append('name', name);
      if(description === null || description === undefined) formData.append('description', '');
      else formData.append('description', description);
      formData.append('valide', available);

      API.patch('tags/' + id, formData, Config.headers())
        .then(
          function(response) {
            dispatch(this.mergeTags(name, filter, values));
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static getTagEdit(id) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('tags/' + id, Config.headers())
        .then(function(response) {
          var tag = response.data;
          dispatch({
            type: 'GET_EDIT_TAG',
            tag: tag
          });
          browserHistory.push('/tags/edit');
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static getTagAutoComplete(name) {
    return (dispatch) => {
      if (name.length > 3) {
        dispatch({
          type: 'LOADING_TAGS'
        });
        API.get(`tags/?filter=${name}`, Config.headers())
          .then(
            function(response) {
              var tags = this.parseObject(response.data);
              dispatch({
                type: 'GET_TAG_PARSED',
                tags: tags
              });
              dispatch({
                type: 'NO_LOADING_TAGS'
              });
            }.bind(this)
          )
          .catch(function(err) {
            dispatch({
              type: 'NO_LOADING_TAGS'
            });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } else if (name === '') {
        dispatch(this.getTags(1, true));
      }
    };
  }

  static parseObject(arrays) {
    var arraysname = [];
    if (arrays.length > 0) {
      arrays.map(function(arraysindex, index) {
        return arraysname.push(arraysindex.name);
      });
    }
    return arraysname;
  }

  static parseObjectNameIds(arrays) {
    let objectNameId = [];
    if (arrays.length > 0) {
      arrays.map(function(arraysindex, index) {
        return objectNameId.push({
          id: arraysindex.id,
          name: arraysindex.name
        });
      });
    }
    return objectNameId;
  }

  static filterUserTagsByBirthday(month, tagId) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        dispatch({
          type: 'MERGE_TAGS_VALUE_SET',
          mergeTagsValue: month
        });

        const parsedMonth = this.parseMonth(month);

        const { data: {users} } = await API_V2.get(
          `users/can_associate?page=1&per_page=99999&month_birthday=${parsedMonth}&tag_id=${tagId}`,
          Config.headers()
        );

        dispatch({ type: 'GET_USER_LIST', user_list: users });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static filterUserTagsByGender(gender, tagId) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        dispatch({
          type: 'MERGE_TAGS_VALUE_SET',
          mergeTagsValue: gender
        }); 

        const parsedGender = [
          'Homem cis',
          'Mulher cis',
          'Não binário',
          'Prefere não se identificar',
          'Outros',
          'Homem trans',
          'Mulher trans'
        ].indexOf(gender);

        const { data: { users } } = await API_V2.get(
          `users/can_associate?page=1&per_page=99999&sex=${parsedGender}&tag_id=${tagId}`,
          Config.headers()
        );

        dispatch({ type: 'GET_USER_LIST', user_list: users });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static filterUserTagsBySector(sector, tagId) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        dispatch({
          type: 'MERGE_TAGS_VALUE_SET',
          mergeTagsValue: sector
        });

        const { data: { users } } = await API_V2.get(
          `users/can_associate?filter=sector&page=1&per_page=99999&sector=${sector}&tag_id=${tagId}`,
          Config.headers()
        );

        dispatch({ type: 'GET_USER_LIST', user_list: users });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static filterUserTagsByHiringDate(hiringDate, tagId) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        dispatch({
          type: 'MERGE_TAGS_VALUE_SET',
          mergeTagsValue: hiringDate
        });

        const parsedDate = moment
          .tz(hiringDate, 'Europe/Berlin')
          .format('DD/MM/YYYY');

        const { data: { users } } = await API_V2.get(
          `users/can_associate?filter=newuser&page=1&per_page=99999&datecreate=${parsedDate}&tag_id=${tagId}`,
          Config.headers()
        );

        dispatch({ type: 'GET_USER_LIST', user_list: users });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static filterUserTagsByScore(values, tagId) {
    const { min, max } = values;

    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data: { users } } = await API_V2.get(
          `users/can_associate?page=1&per_page=99999&min_score=${min}&max_score=${max}&tag_id=${tagId}`,
          Config.headers()
        );

        dispatch({ type: 'GET_USER_LIST', user_list: users });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static filterUserTagsByActivity(autoDeclared, tagId) {
    const autoDeclaredConvert = {
      "Pintor": "painter",
      "Consumidor":"consumer",
      "Quero me formar pintor":"become_painter",
      "Balconista":"clerk",
      "Arquiteto / Decorador": "architec",
    }
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data: { users } } = await API_V2.get(
          `users/can_associate?page=1&per_page=99999&auto_declared=${autoDeclaredConvert[autoDeclared]}&tag_id=${tagId}`,
          Config.headers()
        );

        dispatch({ type: 'GET_USER_LIST', user_list: users });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static mergeTags(tag, filter, values) {
    return (dispatch) => {
      var formData = new FormData();
      switch (filter) {
        case 'Gênero':
          const userGenders = [
            'Homem cis',
            'Mulher cis',
            'Não binário',
            'Prefere não se identificar',
            'Outros',
            'Homem trans',
            'Mulher trans'
          ];
          formData.append('method', userGenders.indexOf(values));
          filter = 'sex';
          break;
        case 'Pontuação':
          const { min, max } = values;

          formData.append('min', min);
          formData.append('max', max);
          filter = 'score';
          break;

        case 'Mês do Aniversário':
          formData.append('month', this.parseMonth(values));
          filter = 'birthday_month';
          break;
        case 'Data de contratação':
          formData.append(
            'monthini',
            moment.tz(values, 'Europe/Berlin').format('DD/MM/YYYY')
          );
          filter = 'create';
          break;
        case 'Setor':
          formData.append('sector', values);
          filter = 'sector';
          break;
      }
      formData.append('filter', filter);
      formData.append('tags[]', tag);
      API.post('tags/tag_controll', formData, Config.headers())
        .then(function(response) {
          browserHistory.push('/tags/list');
          dispatch({ type: 'NO_SUBMITTING' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getTagUsersAssociation(tag) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        if (tag) {
          const { data: singleUser } = await API.get(
            `users/?filter=tag&page=1&per_page=1&tag=${tag}`,
            Config.headers()
          );

          if (singleUser.length) {
            const usersListSize = singleUser[0].size[0];

            const { data: tagUserAssociation } = await API.get(
              `users/?filter=tag&page=1&per_page=${usersListSize}&tag=${tag}`,
              Config.headers()
            );

            dispatch({
              type: 'TAG_USERS_ASSOCIATION',
              tagUserAssociation
            });
          }
        }

        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static getTagUsersAssociationV2(tagId) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data : {users} } = await API_V2.get(
          `users/by_tag?page=1&per_page=99999&tag_id=${tagId}`,
          Config.headers()
        );

        dispatch({
          type: 'TAG_USERS_ASSOCIATION',
          tagUserAssociation: users
        });

        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static parseMonth(values) {
    var month = 0;
    switch (values) {
      case 'Janeiro':
        month = 1;
        break;
      case 'Fevereiro':
        month = 2;
        break;
      case 'Março':
        month = 3;
        break;
      case 'Abril':
        month = 4;
        break;
      case 'Maio':
        month = 5;
        break;
      case 'Junho':
        month = 6;
        break;
      case 'Julho':
        month = 7;
        break;
      case 'Agosto':
        month = 8;
        break;
      case 'Setembro':
        month = 9;
        break;
      case 'Outubro':
        month = 10;
        break;
      case 'Novembro':
        month = 11;
        break;
      case 'Dezembro':
        month = 12;
        break;
    }

    return month;
  }

  static mergeTagInOne(tagold, tagnew, tagremove) {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        const formData = new FormData();
        formData.append('tags', tagnew);
        formData.append('tagsmaster', tagold.name);
        if (tagremove) {
          formData.append('remove_tag', tagremove);
        }

        await API.post(
          'tags/tag_controll?filter=merge',
          formData,
          Config.headers()
        );

        if (tagremove) {
          let API_CLIENT = API;

          if (style.teamName.name === 'youseed') {
            API_CLIENT = APIDelete;
          }

          await API_CLIENT.delete(`tags/${tagold.id}`, Config.headers());
        }

        const message = `Tags ${tagold.name} e ${tagnew} mescladas com sucesso!`;
        dispatch({
          type: 'TAG_MERGE_MODAL_OPEN',
          tagMergeModalOpen: {
            message
          }
        });

        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        browserHistory.push('/tags/list');
      } catch (error) {
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }
}
