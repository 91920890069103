export function tagsWithPagination(
    state = { tags: [], pagination: {} }, 
    action
  ) {
    if (action.type === 'GET_TAG_PAGINATION') {
      return {
        tags: action.tags,
        pagination: action.pagination || {}
      }
    }
  
    return state;
  }
  