import React, { Component } from 'react';
import { connect } from 'react-redux';
import GalleryApi from '../../../Logicas/GalleryApi';
import deleteIcon from '../../utils/img/ic-excluir.svg';
import EditIcon from '../../utils/img/ic-editar.svg';

import * as S from './GalleryCard.style';

class GalleryCard extends Component {
  constructor() {
    super();
    this.state = { cover: '' };
  }

  componentDidMount() {
    this.setState({ cover: this.props.cover });
  }

  render() {
    const { cover } = this.state;
    const {
      selectGallery,
      editGallery,
      showGalleryForm,
      deleteGallery,
      item,
      item: { id: galleryId }
    } = this.props;

    return (
      <>
        <S.BodyCardGallery>
          <S.ImageCard
            src={cover}
            alt=""
            onClick={() => selectGallery(galleryId)}
          />
          <S.LabelCard
            style={{ fontSize: '16px', marginLeft: '10px', marginTop: '10px' }}
          >
            {item.name}
          </S.LabelCard>
          <S.FooterCard>
            <S.LabelCard>
              {item.contents_amount}{' '}
              {item.contents_amount > 1 ? 'Conteúdos ' : 'Conteúdo'}
            </S.LabelCard>
            <S.LabelCard>
              <S.EditIcon
                src={EditIcon}
                alt="Editar"
                onClick={() => [
                  editGallery(galleryId, item.name, item.tags),
                  showGalleryForm()
                ]}
              />
              <S.DeleteIcon
                src={deleteIcon}
                alt="Apagar"
                onClick={() => deleteGallery(galleryId)}
              />
            </S.LabelCard>
          </S.FooterCard>
        </S.BodyCardGallery>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    galleries: state.gallery.galleriesList
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    selectGallery: (galleryId) => {
      dispatch(GalleryApi.showGallery(galleryId));
    },
    editGallery: (galleryId, galleryName, tags) => {
      dispatch({ type: 'EDIT_GALLERY', galleryId, galleryName, tags });
    },
    showGalleryForm: () => {
      dispatch({ type: 'SHOW_GALLERY_FORM' });
    },
    deleteGallery: (galleryId) => {
      dispatch(GalleryApi.destroyGallery(galleryId));
    }
  };
};
const GalleryCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryCard);

export default GalleryCardContainer;
