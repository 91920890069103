export function module_reducer(state = [], action) {
  if (action.type === 'DELETE_MODULE') {
    return action.publication_list;
  }
  if (action.type === 'EDIT_MODULE') {
    if (!action.edit) {
      return action.module;
    } else {
      return { data: action.module };
    }
  }
  return state;
}
