import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserApi from '../../../../Logicas/UserApi';
import { ToogleNoReduxForm } from '../../../utils_form/FormComponents';
import DashboardButton from '../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton/index';

import * as S from './UserTeamToggle.styles';

export class UserTeamToggle extends Component {
  componentDidMount() {
    const { getAvailableBrands } = this.props;
    getAvailableBrands();
  }

  render() {
    const {
      availableBrands,
      userBrands,
      pagination: { next_page },
      toggleSelectedBrand,
      fetchMoreBrands,
      isLoading,
      mainBrand
    } = this.props;

    if (availableBrands.length) {
      return (
        <>
          <div className="row mt-5">
            <div className="col-md-12">
              <h3 className="label-tags">Marcas pertencentes</h3>
              <S.ToggleGroup>
                {availableBrands.map(({ id, name }) => {
                  return (
                    <S.ToggleContainer key={id}>
                      <ToogleNoReduxForm
                        name={name}
                        label={name}
                        onChange={() => toggleSelectedBrand(id)}
                        value={userBrands.some((brand) => brand.id === id)}
                        disabled={mainBrand === name}
                      />
                    </S.ToggleContainer>
                  );
                })}
              </S.ToggleGroup>
            </div>
          </div>
          {next_page && (
            <div className="row mt-5">
              <div className="mx-auto">
                <DashboardButton
                  label={'Mais marcas'}
                  isActive={isLoading}
                  onClick={() => fetchMoreBrands(next_page)}
                />
              </div>
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  userBrands: state.user.userEdit.brands,
  availableBrands: state.user.teamBrands.availableBrands,
  mainBrand: state.user.userEdit.mainBrand,
  pagination: state.user.teamBrands.pagination,
  isLoading: state.user.teamBrands.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  getAvailableBrands: () => {
    dispatch(UserApi.getAvailableBrands());
  },
  fetchMoreBrands: (next_page) => {
    dispatch(UserApi.fetchMoreBrands(next_page));
  },
  toggleSelectedBrand: (id) => {
    dispatch({
      type: 'TOGGLE_SELECTED_BRAND',
      id
    });
  }
});

const UserTeamToggleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTeamToggle);

export default UserTeamToggleContainer;
